import React from 'react'

function Banner() {
    return (
        <div className="sd_banner sd_paf_banner">
            <div className="sd_container">
                <div className="sd_flex sd_aligncenter sd_banner_inner">
                    <div className="sd_banner_inner_title">
                        <h1 className="">Artist Portal</h1>
                    </div>
                    <div className="sd_banner_inner_detail">
                        <p>Thank you for joining us for the 2025 Sundance Film Festival. If you have any questions, please reach out to your Artist Relations Liaison and your Publicity Liaison.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;