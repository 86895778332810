import React from 'react'
import Dropdown from './Dropdown'
import TextArea from './TextArea'
import TextField from './TextField'

function DropdownQuestion(props) {
    const {question,extrafield,suggestion,options,onChange, inputChange,required,fpgerror,name,value,errorValidation,TextAreaName,TextAreavalue,DefaultOption, disable ,label , isTextField} = props
    return (
        <div className="question sd_flex">
            <div className="question_label">
                <p className={`${required ? 'required' : null}`}>{question}</p>
            </div>
            <div className="question_dropdown">
                <div className="question_dropdown_inner sd_flex">
                    <div className="">
                        <Dropdown require={required} disable={disable} options={options} onChange={onChange} name={name} value={value} fpgerror={fpgerror} DefaultOption={DefaultOption} />
                        
                        {
                            
                            extrafield ? <div className="mt_10"> <TextArea disabled={disable} placeholder="Please enter software used" value={TextAreavalue} name={TextAreaName} errorValidation={errorValidation} handleChange={inputChange}/> </div>: ''
                        }

                        { isTextField &&
                            <div className="sd_form_group border_select">
                                <div className="field_row_inner">
                                    <div className="field_input relative">
                                        <TextField disabled={disable} label={label} type="text" name={TextAreaName} value={TextAreavalue} handleChange={inputChange} errorValidation={errorValidation} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {suggestion ? <span className="sd_textuppercase not_appliocable">If not applicable, Select None</span> : ''}
                </div>
            </div>
        </div>
    )
}
export default DropdownQuestion