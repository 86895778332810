import React from 'react';
import TextField from "../../components/TextField";
import RemoveFieldIcon from '../../icons/RemoveFieldIcon';
import PlusFieldIcon from "../../icons/PlusFieldIcon";


function FullName(props) {
    // console.log('props-------',props);
    return (
        <div className="sd_container_990">
            <div className='sd_flex'>
                <p className="form_sub_2_title" style={{textTransform:'capitalize'}}>{props.title}</p> {props.multiPal ? <>
                    <span style={{ marginLeft: 10, cursor: props.item?.creditType?.length >= 3 ? 'not-allowed' : 'pointer' }} className='sd_p_cursor' onClick={() => props.item?.creditType?.length >= 3 ? '' : props.onSalesAgentAdd('ADD', props.credit._id)}><PlusFieldIcon /></span>
                    {props.index === 0 ? '' : <span style={{ marginLeft: 10 }} className='sd_p_cursor' onClick={() => props.onSalesAgentAdd('SUB', props.credit._id)}><RemoveFieldIcon /></span>}  </> : ' '}
            </div>
            <div className="sd_flex fullname_div sd_justbetween">
                <TextField credit={props.credit} item={props.item} require={props.required} handleChange={props.onChange} errorValidation={props.Errors?.FirstName} value={props.values?.firstName} name='firstName' disabled={props.disabled} uppercase={true} label="First Name" required="false" />
                <TextField credit={props.credit} item={props.item} handleChange={props.onChange} value={props.values?.middleName} name='middleName' disabled={props.disabled} uppercase={true} label="Middle Name" required="false" />
                <TextField  credit={props.credit} item={props.item}  require={props.required} handleChange={props.onChange} errorValidation={props.Errors?.LastName} value={props.values?.lastName} name='lastName' disabled={props.disabled} uppercase={true} label="Last Name" required="false" />
            </div>
            {/*  {props.fullValidation && <span style={{ color: 'red' }}>{props.fullValidation}</span>} */}

            {/* <span>hello</span> */}
        </div >
    )
}

export default FullName
