import React, { useEffect, useRef, useState } from 'react';
// Layouts
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
// PAF Header
import FormHeader from "../../../PAF/Components/FormHeader";
import FormFooter from "../../../PAF/Components/FormFooter";
import TextField from "../../../components/TextField";
import Dropdown from "../../../components/Dropdown";
import FullName from './../FullName';
import MainContactForm from './../MainContactForm';
import { useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import service from '../../../common/service';
import SuccessMessage from './../SuccessMessage';
import FailedMessage from './../FailedMessage';
import {ErrorScroll} from './../../../common/functions';

let sub
export default function Step4() {

    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const pafType = useSelector(e => e.pafType);
    console.log("pafType==",pafType);
    const dispatch = useDispatch();
    // const history = useHistory()
    const apiServe = new service();
    const [SavedMessage, setSavedMessage] = useState(false)
    const [failedMessage, setFailedMessage] = useState(false)
    const [isNext, setIsNext] = useState(true)
    const [isShare, setIsShare] = useState(false)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            ProvinceScreen: {  // name the rule
                message: 'State/Province is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            ProvinceScreen1: {  // name the rule
                message: 'State/Province is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            MainFirstName: {  // name the rule
                message: 'Main Contact First Name is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            MainLastName: {  // name the rule
                message: 'Main Contact Last Name is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            MainEmail: {  // name the rule
                message: 'Main Contact Email is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            MainValidEmail: {  // name the rule
                message: 'Main Contact Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            MainMobile: {  // name the rule
                message: 'Main Contact Phone is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },

            //------------------------------------ 

            MainPublicityFirstName: {  // name the rule
                message: 'Main Publicity Contact First Name is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            MainPublicityLastName: {  // name the rule
                message: 'Main Publicity Contact Last Name is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            MainPublicityEmail: {  // name the rule
                message: 'Main Publicity Contact Email is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            MainPublicityValidEmail: {  // name the rule
                message: 'Main Publicity Contact Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            PostMobile: {  // name the rule
                message: ' Public Contact Phone is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            PublicMobile: {  // name the rule
                message: ' Public Contact Phone is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },  
            DcpMobile: {  // name the rule
                message: ' DCP/Print or Tech Contact Phone is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
          
            MainPublicityMobile: {  // name the rule
                message: 'Main Publicity Contact Phone is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },

            // ----------------------------------------------

            TechContactFirstName: {  // name the rule
                message: 'DCP/Print or Tech Contact First Name is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            TechContactLastName: {  // name the rule
                message: 'DCP/Print or Tech Contact Last Name is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            TechContactEmail: {  // name the rule
                message: 'DCP/Print or Tech Contact Email is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            TechContactValidEmail: {  // name the rule
                message: 'DCP/Print or Tech Contact Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            // ----------------------------------------------

            PublicContactFirstName: {  // name the rule
                message: 'Public Contact First Name is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            PublicContactLastName: {  // name the rule
                message: 'Public Contact Last Name is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            PublicContactEmail: {  // name the rule
                message: 'Public Contact Public Email is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            PublicContactValidEmail: {  // name the rule
                message: 'Public Contact Public Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            // ----------------------------------------------

            postFestivalFirstName: {  // name the rule
                message: 'Post Festival Contact First Name is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            postFestivalLastName: {  // name the rule
                message: 'Post Festival Contact Last Name is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            postFestivalEmail: {  // name the rule
                message: 'Post Festival Contact Public Email is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            postFestivalValidEmail: {  // name the rule
                message: 'Post Festival Contact Public Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
        }
    }))
    const [isSubmitted, setisSubmitted] = useState(ProjectInfo.submitNo)
    const [formFour, setFormFour] = useState({
        mainContacts: {
            address1: ProjectInfo.mainContacts.address1,
            address2: ProjectInfo.mainContacts.address2,
            city: ProjectInfo.mainContacts.city,
            company: ProjectInfo.mainContacts.company,
            country: ProjectInfo.mainContacts.country,
            email: ProjectInfo.mainContacts.email,
            firstName: ProjectInfo.mainContacts.firstName,
            lastName: ProjectInfo.mainContacts.lastName,
            middleName: ProjectInfo.mainContacts.middleName,
            phoneNumber: ProjectInfo.mainContacts.phoneNumber,
            state: ProjectInfo.mainContacts.state,
            title: ProjectInfo.mainContacts.title,
            type: ProjectInfo.mainContacts.type,
            zipCode: ProjectInfo.mainContacts.zipCode,
        },
        mainPublicityContacts: {
            address1: ProjectInfo.mainPublicityContacts.address1,
            address2: ProjectInfo.mainPublicityContacts.address2,
            city: ProjectInfo.mainPublicityContacts.city,
            company: ProjectInfo.mainPublicityContacts.company,
            country: ProjectInfo.mainPublicityContacts.country,
            email: ProjectInfo.mainPublicityContacts.email,
            firstName: ProjectInfo.mainPublicityContacts.firstName,
            lastName: ProjectInfo.mainPublicityContacts.lastName,
            middleName: ProjectInfo.mainPublicityContacts.middleName,
            phoneNumber: ProjectInfo.mainPublicityContacts.phoneNumber,
            state: ProjectInfo.mainPublicityContacts.state,
            title: ProjectInfo.mainPublicityContacts.title,
            type: ProjectInfo.mainPublicityContacts.type,
            zipCode: ProjectInfo.mainPublicityContacts.zipCode,
        },
        techContact: {
            email: ProjectInfo.techContact.email,
            firstName: ProjectInfo.techContact.firstName,
            lastName: ProjectInfo.techContact.lastName,
            title: ProjectInfo.techContact.title,
            middleName: ProjectInfo.techContact.middleName,
            phoneNumber: ProjectInfo.techContact.phoneNumber,
            type: ProjectInfo.techContact.type,
        },
        publicContact: {
            company: ProjectInfo.publicContact.company,
            firstName: ProjectInfo.publicContact.firstName,
            lastName: ProjectInfo.publicContact.lastName,
            middleName: ProjectInfo.publicContact.middleName,
            phoneNumber: ProjectInfo.publicContact.phoneNumber,
            publicEmail: ProjectInfo.publicContact.publicEmail,
            type: ProjectInfo.publicContact.type,
            website: ProjectInfo.publicContact.website

        },
        postFestivalContact: {
            firstName: ProjectInfo.postFestivalContact.firstName,
            middleName: ProjectInfo.postFestivalContact.middleName,
            lastName: ProjectInfo.postFestivalContact.lastName,
            company: ProjectInfo.postFestivalContact.company,
            publicEmail: ProjectInfo.postFestivalContact.publicEmail,
            website: ProjectInfo.postFestivalContact.website,
            phoneNumber: ProjectInfo.postFestivalContact.phoneNumber,
            type: ProjectInfo.postFestivalContact.type,
        },

    })
    const onNextPrev = () => {
        if (Validator.current.allValid()) {
            // onFormSave()
            return true
        } else {
            Validator.current.showMessages()
            forceUpdate()
            setTimeout(() => {
                ErrorScroll()
            }, 500);
            return false

        }
    }
    const onFormSave = (NAV) => {
        
        // if (Validator.current.allValid()) {
            let data = {
                ...formFour,
                savePageNo: '4',
                projectId: ProjectInfo._id
            }
            setFormFour((last) => {
                data = {
                    ...data,
                   ...last
                }
                return last
            });
            forceUpdate();
            if (NAV === 'NEXT') {
                data.completedStepNo =  4
            }else if(NAV === 'SHARE'){
                setIsShare(true)
            }
            apiServe.sendevent('paf|project|allUpdate', data)
        //     return true
        // } else {
        //     Validator.current.showMessages()
        //     forceUpdate()
        //     return false

        // }
    }

    useEffect(() => {
        setFormFour({  mainContacts: {
            address1: ProjectInfo.mainContacts.address1,
            address2: ProjectInfo.mainContacts.address2,
            city: ProjectInfo.mainContacts.city,
            company: ProjectInfo.mainContacts.company,
            country: ProjectInfo.mainContacts.country,
            email: ProjectInfo.mainContacts.email,
            firstName: ProjectInfo.mainContacts.firstName,
            lastName: ProjectInfo.mainContacts.lastName,
            middleName: ProjectInfo.mainContacts.middleName,
            phoneNumber: ProjectInfo.mainContacts.phoneNumber,
            state: ProjectInfo.mainContacts.state,
            title: ProjectInfo.mainContacts.title,
            type: ProjectInfo.mainContacts.type,
            zipCode: ProjectInfo.mainContacts.zipCode,
        },
        mainPublicityContacts: {
            address1: ProjectInfo.mainPublicityContacts.address1,
            address2: ProjectInfo.mainPublicityContacts.address2,
            city: ProjectInfo.mainPublicityContacts.city,
            company: ProjectInfo.mainPublicityContacts.company,
            country: ProjectInfo.mainPublicityContacts.country,
            email: ProjectInfo.mainPublicityContacts.email,
            firstName: ProjectInfo.mainPublicityContacts.firstName,
            lastName: ProjectInfo.mainPublicityContacts.lastName,
            middleName: ProjectInfo.mainPublicityContacts.middleName,
            phoneNumber: ProjectInfo.mainPublicityContacts.phoneNumber,
            state: ProjectInfo.mainPublicityContacts.state,
            title: ProjectInfo.mainPublicityContacts.title,
            type: ProjectInfo.mainPublicityContacts.type,
            zipCode: ProjectInfo.mainPublicityContacts.zipCode,
        },
        techContact: {
            email: ProjectInfo.techContact.email,
            firstName: ProjectInfo.techContact.firstName,
            lastName: ProjectInfo.techContact.lastName,
            title: ProjectInfo.techContact.title,
            middleName: ProjectInfo.techContact.middleName,
            phoneNumber: ProjectInfo.techContact.phoneNumber,
            type: ProjectInfo.techContact.type,
        },
        publicContact: {
            company: ProjectInfo.publicContact.company,
            firstName: ProjectInfo.publicContact.firstName,
            lastName: ProjectInfo.publicContact.lastName,
            middleName: ProjectInfo.publicContact.middleName,
            phoneNumber: ProjectInfo.publicContact.phoneNumber,
            publicEmail: ProjectInfo.publicContact.publicEmail,
            type: ProjectInfo.publicContact.type,
            website: ProjectInfo.publicContact.website

        },
        postFestivalContact: {
            firstName: ProjectInfo.postFestivalContact.firstName,
            middleName: ProjectInfo.postFestivalContact.middleName,
            lastName: ProjectInfo.postFestivalContact.lastName,
            company: ProjectInfo.postFestivalContact.company,
            publicEmail: ProjectInfo.postFestivalContact.publicEmail,
            website: ProjectInfo.postFestivalContact.website,
            phoneNumber: ProjectInfo.postFestivalContact.phoneNumber,
            type: ProjectInfo.postFestivalContact.type,
        }, })
        setisSubmitted(ProjectInfo.submitNo)
        // eslint-disable-next-line
    }, [ProjectInfo])
   
    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|allUpdate":
                    if (!response.error) {
                        dispatch({ type: 'FORM_DATA', payload: response.data })
                        setSavedMessage(true)
                        setTimeout(() => {
                            setSavedMessage(false)
                            setIsShare(false)
                        }, 1000);
                        setIsNext(true)
                    }else{
                        setIsNext(false)
                        setFailedMessage(true)
                        setTimeout(() => {
                            setFailedMessage(false)
                        }, 2000);
                    }
                    break;
                default:
                    break;
            }
        })
        setisSubmitted(ProjectInfo.submitNo)
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

    const onMainContactsChange = (e) => {
    
        // const { name, value } = e.target
        // if (!e.target.validity.valid) {
        //     return null;
        // }
        // if (name === 'country') {
        //     setFormFour({ ...formFour, mainContacts: { ...formFour.mainContacts, [name]: value, state : '' } })
        // } else { 
        //    setFormFour({ ...formFour, mainContacts: { ...formFour.mainContacts, [name]: value } })
        //    forceUpdate()
        // }
        const { name, value } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
       
           setFormFour({ ...formFour, mainContacts: { ...formFour.mainContacts, [name]: name === 'email' ? value.trim() : value } })
           forceUpdate()
        
    }
    const onMainPublicityContactsChange = (e) => {
        
        // const { name, value } = e.target
        // if (!e.target.validity.valid) {
           
        //     return null;
        // }
        // else if (name === 'country') {
        //     setFormFour({ ...formFour, mainPublicityContacts: { ...formFour.mainPublicityContacts, [name]: value, state : '' } })
        // } else { 
        // setFormFour({ ...formFour, mainPublicityContacts: { ...formFour.mainPublicityContacts, [name]: value } })
        // }
        const { name, value } = e.target
        if (!e.target.validity.valid) {
           
            return null;
        }
    
        setFormFour({ ...formFour, mainPublicityContacts: { ...formFour.mainPublicityContacts, [name]: name === 'email' ? value.trim() : value } })
        
    }
    const onTechContactChange = (e) => {
        
        const { name, value } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
        setFormFour({ ...formFour, techContact: { ...formFour.techContact, [name]: name === 'email' ? value.trim() : value } })
    }
    const onPublicContactChange = (e) => {
        const { name, value } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
        setFormFour({ ...formFour, publicContact: { ...formFour.publicContact, [name]: name === 'publicEmail' ? value.trim() : value } })
    }
    const onPostFestivalContactChange = (e) => {
        const { name, value } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
        setFormFour({ ...formFour, postFestivalContact: { ...formFour.postFestivalContact, [name]: name === 'publicEmail' ? value.trim() : value } })
    }
    const closeSuccessMessage = () =>{
        setSavedMessage(false)
    }
    const closeFailedMessage = () => {
        setFailedMessage(false)
    }
    return (
        <div>
            {console.log(">>>>>>",formFour)}
            <Header />
            <FormHeader step={4} />
            {!isShare && <SuccessMessage active={SavedMessage} close={closeSuccessMessage} text={'Save'}/>}
            <FailedMessage active={failedMessage} close={closeFailedMessage} text={'Save'} />
            <div className="container form_inner step_common">
                <div className='underline'>
                    <div className='step sd_justbetween' >
                        <div className='step_no'>
                            <p>Page 4 of 9</p>
                        </div>
                        <div className='step_des'>
                            <p>After you submit at the end of Page 9, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons.</p>

                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival, and will be placed in the Sundance Institute Archives.</p>
                        </div>
                    </div>
                </div>
                <div className="conatct_form">
                    <div className="form_inner_title">
                        <h3 className="sd_flex sd_aligncenter">Contacts</h3>
                        <h5>Main Contact</h5>
                    </div>
                    <div className="conatct_info_form_inner">
                        <div className="contact_desc">
                            <p>Individual from your film/project team who works with the Artist Relations Department regarding the logistics of attending the Festival. </p>
                            <p>One who may:</p>
                            <ul>
                                <li> Receive and respond to frequent emails from us.</li>
                                <li> Share Festival information with your team.</li>
                                {
                                ( pafType === "Documentary Features"|| pafType === "Documentary Shorts" || pafType === "Narrative Shorts") ?
                                <li> Receive event invitations from us and our official partners/sponsors, distribute to appropriate team members and RSVP.</li>:
                                <li> Receive event invitations from us and our official partners/sponsors and distribute to appropriate team members.</li>
                                }
                                <li> Make decisions regarding your film/project’s benefits; screening tickets and credentials.</li>
                                {
                                    (pafType === "Narrative Features" || pafType ===  "New Frontier" || pafType === "Indie Series/Special Events") &&
                                    <li> RSVP for events.</li>
                                }
                                {
                                    (pafType === "Documentary Features" || pafType === "Documentary Shorts" || pafType === "Narrative Shorts") ?
                                    <li> Attend Festival, check in for orientation, benefit distribution, and act as point person during premiere screening.</li>
                                    :
                                    <li> Attend Festival, check in for orientation, screening ticket + credential distribution, and act as point person during premiere screening.</li>

                                }
                            </ul>
                            <p> (Main contact information will not be published; it's for internal use and will only be shared with our official partners and sponsors)</p>
                        </div>
                        <div className="conatct_info_form_inner_top">
                            
                            <div className="sd_container_990">
                                <MainContactForm  required={formFour.mainContacts?.country === 'United States' || formFour.mainContacts?.country === 'Canada'? true:false}disabled={isSubmitted > 1 ? true : false} onChange={onMainContactsChange} info={formFour.mainContacts} Errors={{
                                    FirstName: Validator.current.message('FirstName', formFour.mainContacts.firstName, 'MainFirstName|required'),
                                    LastName: Validator.current.message('lastName', formFour.mainContacts.lastName, 'MainLastName|required'),
                                    Email: Validator.current.message('Main Contacts Email', formFour.mainContacts.email, 'MainEmail|MainValidEmail|required'),
                                    Province:  (formFour.mainContacts?.country === 'United States' || formFour.mainContacts?.country === 'Canada') ? Validator.current.message('Province Screen',  formFour.mainContacts.state, 'ProvinceScreen|required') : '',
                                    Mobile: Validator.current.message('Main Contacts Phone', formFour.mainContacts.phoneNumber, 'MainMobile|numeric|required')
                                }} />
                            </div>
                        </div>
                        <div className="form_inner_title">
                            <h5 className="mt-35">Main Publicity Contact</h5>
                        </div>
                        <div className="contact_desc">
                            <h6>INFORMATION YOU PROVIDE WILL BE MADE PUBLIC ON A CONTACT LIST <u>BEFORE & DURING</u> THE FESTIVAL</h6>
                            <p>Individual from your publicity team or an external PR agency hired to publicize your film/project at the Festival, who will regularly communicate with the Press Office regarding the publicity of your film/project pre-Festival, at Festival, and post-Festival.</p>
                            <p>One who may:</p>
                            <ul>
                                
                                {
                                    (pafType === "Documentary Features" || pafType === "Documentary Shorts" || pafType === "Narrative Shorts") ? 
                                    <li> Receive and respond to frequent emails from your Publicity Liaison, especially during the peak time leading up to the Sundance Film Festival program announcement. Publicity Liaisons will also need your Main Publicity Contact to provide information, answer questions and make decisions on deadlines around Press Materials, Press Lines, Talent Attendance, Media Alerts, Face Sheets, Press Line RSVP’s, and Embargo Times."</li>
                                    :
                                    <li> Receive and respond to frequent emails from your Publicity Liaison, especially during the peak time leading up to the Sundance Film Festival program announcement.</li>
                                }
                            </ul>
                            <p>Main Publicity Contact information will not be published, is for internal use and will be shared with our official partners and sponsors.</p>
                        </div>
                        <div className="conatct_info_form_inner_top">
                            <div className="sd_container_990">
                                <MainContactForm required={formFour.mainPublicityContacts?.country === 'United States' || formFour.mainPublicityContacts?.country === 'Canada'? true:false} disabled={isSubmitted > 1 ? true : false} onChange={onMainPublicityContactsChange} info={formFour.mainPublicityContacts} Errors={{
                                    FirstName: Validator.current.message('FirstName', formFour.mainPublicityContacts.firstName, 'MainPublicityFirstName|required'),
                                    LastName: Validator.current.message('LastName', formFour.mainPublicityContacts.lastName, 'MainPublicityLastName|required'),
                                    Email: Validator.current.message('Main Publicity Contact Email', formFour.mainPublicityContacts.email, 'MainPublicityEmail|MainPublicityValidEmail|required'),
                                    Province: (formFour.mainPublicityContacts?.country === 'United States' || formFour.mainPublicityContacts?.country === 'Canada') ? Validator.current.message('Province Screen',  formFour.mainPublicityContacts.state, 'ProvinceScreen1|required'):'' ,
                                    Mobile: Validator.current.message('Main Publicity Contact Phone', formFour.mainPublicityContacts.phoneNumber, 'MainPublicityMobile|numeric|required')
                                }} />
                            </div>
                        </div>
                        <div className="form_inner_title">
                            <h5 className="mt-35">Film/Project Tech & Delivery Contact</h5>
                            {
                                 (pafType === "Documentary Features" || pafType === "Documentary Shorts" || pafType === "Narrative Shorts") ? <p>Individual who can provide technical details for your film/project and will ship/deliver your screening materials for exhibition. We ask that they be available for regular communication and provide updates to tracking / inbound delivery of screening materials.</p>
                                 :
                                 <p>Individual who can provide technical details for your film/project and will ship/deliver your screening materials for exhibition. We ask that they be available for regular communication and provide updates to tracking / inbound delivery of screening materials. </p>

                            }
                        </div>
                        <div className="conatct_inner_form_block">
                            <div className="sd_container_990">
                                <FullName required={true} disabled={isSubmitted > 1 ? true : false} onChange={onTechContactChange} values={formFour.techContact} Errors={{
                                    FirstName: Validator.current.message('TechContactFirstName', formFour.techContact.firstName, 'TechContactFirstName|required'),
                                    LastName: Validator.current.message('TechContactLastName', formFour.techContact.lastName, 'TechContactLastName|required'),
                                }} />
                                <div className="sd_flex single_field">
                                    <TextField require={true} disabled={isSubmitted > 1 ? true : false} name='title' value={formFour.techContact.title} handleChange={onTechContactChange} errorValidation={Validator.current.message('Title', formFour.techContact.title, 'TechContactTitle|required')} uppercase={true} label="Title (Affiliation to Film/Project)" required="false" />
                                </div>
                                <div className="sd_flex single_field">
                                    <TextField require={true} disabled={isSubmitted > 1 ? true : false} name='email' value={formFour.techContact.email} handleChange={onTechContactChange} errorValidation={Validator.current.message('Tech Contact Email', formFour.techContact.email, 'TechContactEmail|TechContactValidEmail|required')} uppercase={true} label="email" required="false" />
                                </div>
                                <div className="sd_flex two_field">
                                    <TextField  pattern="[0-9]*" disabled={isSubmitted > 1 ? true : false} name='phoneNumber'   value={formFour.techContact.phoneNumber}  handleChange={onTechContactChange} uppercase={true} label="Phone Number" required="false" />
                                    <Dropdown disable={isSubmitted > 1 ? true : false} name='type' value={formFour.techContact.type} onChange={onTechContactChange} label="Type" options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
                                </div>
                            </div>
                        </div>
                        <div className="form_inner_title">
                            <h5 className="sd_flex sd_aligncenter">Public Contact
                            </h5>
                        </div>
                        <div className='contact_desc'>
                            {
                                (pafType === "Documentary Features" || pafType === "Documentary Shorts" || pafType === "Narrative Shorts") ?
                                <h6>INFORMATION YOU PROVIDE WILL BE MADE PUBLIC ON A CONTACT LIST <u>BEFORE & DURING</u> THE FESTIVAL</h6>
                                :
                                <h6>PROVIDED INFORMATION WILL BE MADE PUBLIC</h6>
                            }
                            <p>This individual is the designated public-facing contact for the film/project. This information will be released to members of the industry and press, and it will be listed on your film/project page on our online program guide on the Festival website.</p>
                        </div>
                        <div className="conatct_inner_form_block">
                            <div className="sd_container_990">
                                <FullName required={true} disabled={isSubmitted > 1 ? true : false} onChange={onPublicContactChange} values={formFour.publicContact} Errors={{
                                    FirstName: Validator.current.message('PublicContactFirstName', formFour.publicContact.firstName, 'PublicContactFirstName|required'),
                                    LastName: Validator.current.message('PublicContactLastName', formFour.publicContact.lastName, 'PublicContactLastName|required'),
                                }} />
                                <div className="sd_flex single_field">
                                    <TextField disabled={isSubmitted > 1 ? true : false} name='company' value={formFour.publicContact.company} handleChange={onPublicContactChange} uppercase={true} label="Company" required="false" />
                                </div>
                                <div className="sd_flex single_field">
                                    <TextField require={true} disabled={isSubmitted > 1 ? true : false} name='publicEmail' value={formFour.publicContact.publicEmail} handleChange={onPublicContactChange} errorValidation={Validator.current.message('Public Contact Email', formFour.publicContact.publicEmail, 'PublicContactEmail|PublicContactValidEmail|required')} uppercase={true} label="Public Email" required="false" />
                                </div>
                                <div className="sd_flex single_field">
                                    <TextField disabled={isSubmitted > 1 ? true : false} name='website' value={formFour.publicContact.website} handleChange={onPublicContactChange} uppercase={true} label="Website (format: HTTP://www.)" required="false" />
                                </div>
                                <div className="sd_flex two_field">
                                    <TextField  pattern="[0-9]*" disabled={isSubmitted > 1 ? true : false} name='phoneNumber' value={formFour.publicContact.phoneNumber}   handleChange={onPublicContactChange} uppercase={true} label="Phone Number" required="false" />
                                    <Dropdown disable={isSubmitted > 1 ? true : false} name='type' value={formFour.publicContact.type} onChange={onPublicContactChange} label="Type" options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
                                </div>
                            </div>
                        </div>
                        <div className="form_inner_title">
                            <h5 className="sd_flex sd_aligncenter">Post Festival Contact 
                            </h5>
                            </div>
                            <div className='contact_desc'>
                                {
                                (pafType === "Documentary Features" || pafType === "Documentary Shorts" || pafType === "Narrative Shorts") ?
                                 <h6>INFORMATION YOU PROVIDE WILL BE MADE PUBLIC ON A CONTACT LIST <u>AFTER</u> THE FESTIVAL</h6>
                                 :
                                <h6>PROVIDED INFORMATION WILL BE MADE PUBLIC</h6>
                                }
                            <p>Company or individual who may be reached after the Festival regarding your film/project.</p>
                            <p>After the Festival, the provided information will replace the public contact on your film/project page, on our online Festival program guide.</p>
                        </div>
                        <div className="conatct_inner_form_block">
                            <div className="sd_container_990">
                                <FullName required={true} disabled={isSubmitted > 1 ? true : false} onChange={onPostFestivalContactChange} values={formFour.postFestivalContact} Errors={{
                                    FirstName: Validator.current.message('postFestivalFirstName', formFour.postFestivalContact.firstName, 'postFestivalFirstName|required'),
                                    LastName: Validator.current.message('postFestivalLastName', formFour.postFestivalContact.lastName, 'postFestivalLastName|required'),
                                }} />
                                <div className="sd_flex single_field">
                                    <TextField disabled={isSubmitted > 1 ? true : false} name='company' value={formFour.postFestivalContact.company} handleChange={onPostFestivalContactChange} uppercase={true} label="Company" required="false" />
                                </div>
                                <div className="sd_flex single_field">
                                    <TextField require={true} disabled={isSubmitted > 1 ? true : false} name='publicEmail' value={formFour.postFestivalContact.publicEmail} handleChange={onPostFestivalContactChange} uppercase={true} errorValidation={Validator.current.message('Post Festival Email', formFour.postFestivalContact.publicEmail, 'postFestivalEmail|postFestivalValidEmail|required')} label="Public Email" required="false" />
                                </div>
                                <div className="sd_flex single_field">
                                    <TextField disabled={isSubmitted > 1 ? true : false} name='website' value={formFour.postFestivalContact.website} handleChange={onPostFestivalContactChange} uppercase={true} label="Website (format: HTTP://www.)" required="false" />
                                </div>
                                <div className="sd_flex two_field">
                                    <TextField  pattern="[0-9]*" disabled={isSubmitted > 1 ? true : false} name='phoneNumber'  value={formFour.postFestivalContact.phoneNumber} handleChange={onPostFestivalContactChange} uppercase={true} label="Phone Number" required="false" />
                                 
                                    <Dropdown disable={isSubmitted > 1 ? true : false} name='type' value={formFour.postFestivalContact.type} onChange={onPostFestivalContactChange} label="Type" options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormFooter step={4} onFormSave={onFormSave} isNext={isNext} checkValidate={onNextPrev} />
                </div>
            </div>
            <Footer />
        </div>
    )
}
