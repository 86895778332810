import React from "react";

const TextField = (props) => {
  const { label, name, type, require, disabled, value, handleChange, readonly, pattern, placeholder, uppercase, onBlur, item, credit, errorValidation, fpgerror, data_id, desc='',prefix=false } = props
  const onchange = (e) => {
    if (item && credit) {
      handleChange(e, item._id, credit._id)
    } else if (credit) {
      handleChange(e,  credit._id)
    } else if (data_id) {
      handleChange(e, data_id)
    } else {
      handleChange(e)
    }
  }
  return (
    <div className="field_row_inner">
      {label ? <label className={`${require && 'required '} ${uppercase && 'sd_textcapitalize'}`}>{label} </label> : ""}
      <div className={prefix ? "field_input relative sd_field_prefix_row" : "field_input relative"}>
        {prefix && <span className="sd_input_prefix">https://www.</span>}
        <input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          pattern={pattern}
          readOnly={readonly}
          onChange={(e) => onchange(e)}
          onBlur={onBlur}
        />
      </div>
      {desc && <p className="sd_input_desc">{desc}</p>}
      {errorValidation ? <span className="error" style={{ color: 'red' }} >{errorValidation}</span> : ""}
      {fpgerror ? <span className="error" style={{ color: 'red' }} >{fpgerror}</span> : ""}
    </div>
  )

}

export default TextField;