import React, { useEffect, useRef, useState } from 'react';
// Layouts
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
// PAF Header
import FormHeader from "./../FormHeader";
import FormSubmitFooter from "./../FormSubmitFooter";
import TextField from "../../../components/TextField";
import Tooltip from "../../../components/Tooltip";
import Dropdown from "../../../components/Dropdown";
import PlusFieldIcon from "../../../icons/PlusFieldIcon";
import RemoveFieldIcon from '../../../icons/RemoveFieldIcon';
import FullName from "../../../PAF/Components/FullName";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { COUNTRIES } from '../../../common/constant'
import SimpleReactValidator from 'simple-react-validator';
import service from '../../../common/service';
// import ArrowIcon from '../../icons/ArrowIcon';
import FormFooter from './../FormFooter';
import SuccessMessage from './../SuccessMessage';
import FailedMessage from './../FailedMessage';
import { ErrorScroll, routeLocater } from './../../../common/functions'
import Popup from './../Popup';
import Demographics from '../Demographics';

let sub
const Step1 = () => {
    const COUNTRIES_LIST = COUNTRIES
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const dispatch = useDispatch();
    const history = useHistory()
    const ProjectGetDetail = useSelector(e => e.projectGetDetail);
    const pafType = useSelector(e => e.pafType);
    const apiServe = new service();
    const [SubmitSuccess, setSubmitSuccess] = useState()
    const [isErrorPopup, setIsErrorPopup] = useState(false)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [isSubmitPopup, setisSubmitPopup] = useState(false)
    const [SavedMessage, setSavedMessage] = useState(false)
    const [failedMessage, setFailedMessage] = useState(false)
    const [loglineLength, setLoglineLength] = useState(0)
    const [isNext, setIsNext] = useState(true)
    const [showMaxCredit, setShowMaxCredit] = useState(false)
    const [ShowOriginValidation, setShowOriginValidation] = useState(false)
    const [isShare, setIsShare] = useState(false)
    // const [indieFilmCreditLabels, setIndieFilmCreditLabels] = useState([])
    const [state, setSate] = useState({
        PremiereStatus: ['World Premiere', 'International Premiere', 'North American Premiere', 'U.S. Premiere', 'None'],
    })
    const [allSelected, setAllSelected] = useState(false)

    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            CFirstLast: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast1: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast2: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast3: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast4: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast5: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            ClFirstLast6: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast_d: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast_s: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            ClFirstLast_s: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast_p: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            title: {  // name the rule
                message: 'Title is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            logline: {  // name the rule
                message: 'Logline is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            filmProjectType: {  // name the rule
                message: 'Film / Project Type is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            country: {  // name the rule
                message: 'Country of Origin is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            premiereStatus: {  // name the rule
                message: 'Premiere Status is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            required: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            topEng: {  // name the rule
                message: 'English Phonetic Pronuuciation is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
        }
    }))
    const [formOne, setFormOne] = useState({
        title: ProjectInfo.title,
        article: ProjectInfo.article,
        countryOfOrigin: ProjectInfo.countryOfOrigin,
        filmProjectType: ProjectInfo.filmProjectType,
        credits: ProjectInfo.credits,
        premiereStatus: ProjectInfo.projectInfo.premiereStatus,
        logline: ProjectInfo.logline,
        englishPhoneticProdununciation: ProjectInfo.englishPhoneticProdununciation,
        projectId: ProjectInfo._id,
        projectInfo:ProjectInfo.projectInfo,
        indieTopcredit: ProjectInfo.indieTopcredit,
        indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
    })

    const [isSubmitted, setIsSubmitted] = useState(ProjectInfo.submitNo)
    const [loglineValidation, setLoglineValidation] = useState(false)
    useEffect(() => {
        setFormOne({
            title: ProjectInfo.title,
            article: ProjectInfo.article,
            countryOfOrigin: ProjectInfo.countryOfOrigin,
            filmProjectType: ProjectInfo.filmProjectType,
            premiereStatus: ProjectInfo.projectInfo.premiereStatus,
            credits: ProjectInfo.credits,
            logline: ProjectInfo.logline,
            englishPhoneticProdununciation: ProjectInfo.englishPhoneticProdununciation,
            projectId: ProjectInfo._id,
            projectInfo:ProjectInfo.projectInfo,
            indieTopcredit: ProjectInfo.indieTopcredit,
            indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
        })
        formOne.credits.forEach(item => {
            if (item.identifier === 'leadartist') {
                item.creditType.forEach((credit) => {
                    credit.creditDetails = {
                        email: credit.creditDetails.email ? credit.creditDetails.email : '',
                        englishPhoneticProdununciation: credit.creditDetails.englishPhoneticProdununciation ? credit.creditDetails.englishPhoneticProdununciation : '',
                        preferredPronouns: credit.creditDetails.preferredPronouns ? credit.creditDetails.preferredPronouns : '',
                        directorGenderExpression: credit.creditDetails.directorGenderExpression ? credit.creditDetails.directorGenderExpression : '',
                        directorIdentifyTransgender: credit.creditDetails.directorIdentifyTransgender ? credit.creditDetails.directorIdentifyTransgender : '',
                        sexualOrientation: credit.creditDetails.sexualOrientation ? credit.creditDetails.sexualOrientation : '',
                        personWithDisability: credit.creditDetails.personWithDisability ? credit.creditDetails.personWithDisability : '',
                        directorBornCountry: credit.creditDetails.directorBornCountry ? credit.creditDetails.directorBornCountry : '',
                        bio: credit.creditDetails.bio ? credit.creditDetails.bio : '',
                        firstFeature: credit.creditDetails.firstFeature ? credit.creditDetails.firstFeature : '',
                        // asian: credit.creditDetails.asian === undefined ? false : credit.creditDetails.asian,
                        africanAmerican: credit.creditDetails.africanAmerican === undefined ? false : credit.creditDetails.africanAmerican,
                        whiteEuropean: credit.creditDetails.whiteEuropean === undefined ? false : credit.creditDetails.whiteEuropean,
                        Hispaniclati: credit.creditDetails.Hispaniclati === undefined ? false : credit.creditDetails.Hispaniclati,
                        middleEastern: credit.creditDetails.middleEastern === undefined ? false : credit.creditDetails.middleEastern,
                        nativeAmerican: credit.creditDetails.nativeAmerican === undefined ? false : credit.creditDetails.nativeAmerican,
                        pacificIslanderorHawaiian: credit.creditDetails.pacificIslanderorHawaiian === undefined ? false : credit.creditDetails.pacificIslanderorHawaiian,
                        // biRacialMultiRacial: credit.creditDetails.biRacialMultiRacial === undefined ? false : credit.creditDetails.biRacialMultiRacial,
                        other: credit.creditDetails.other === undefined ? false : credit.creditDetails.other,
                        // AmericanIndian: credit.creditDetails.AmericanIndian === undefined ? false : credit.creditDetails.AmericanIndian,
                        EastAsian: credit.creditDetails.EastAsian === undefined ? false : credit.creditDetails.EastAsian,
                        SouthAsian: credit.creditDetails.SouthAsian === undefined ? false : credit.creditDetails.SouthAsian,
                        // NativeHawaiian: credit.creditDetails.NativeHawaiian === undefined ? false : credit.creditDetails.NativeHawaiian,
                        SoutheastAsian: credit.creditDetails.SoutheastAsian === undefined ? false : credit.creditDetails.SoutheastAsian,
                        declineToState: credit.creditDetails.declineToState === undefined ? false : credit.creditDetails.declineToState,
                        city: credit.creditDetails.city ? credit.creditDetails.city : '',
                        country: credit.creditDetails.country ? credit.creditDetails.country : '',
                        state: credit.creditDetails.state ? credit.creditDetails.state : '',
                        zipCode: credit.creditDetails.zipCode ? credit.creditDetails.zipCode : '',
                        phoneNumber: credit.creditDetails.phoneNumber ? credit.creditDetails.phoneNumber : '',
                        type: credit.creditDetails.type ? credit.creditDetails.type : '',
                    }
                })
            }
        });
        setIsSubmitted(ProjectInfo.submitNo)
        forceUpdate()

        // eslint-disable-next-line
    }, [ProjectInfo])

    useEffect(() => {
        let newCredits = formOne.credits
        let defaultCredit = {
            _id: '',
            creditId: '',
            creditLabel: '',
            identifier: '',
            creditType: [{
                _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString(),
                firstName: '',
                middleName: '',
                lastName: '',
                creditDetails: {
                    email: '',
                    preferredPronouns: '',
                    englishPhoneticProdununciation: '',
                    directorGenderExpression: '',
                    directorIdentifyTransgender: '',
                    sexualOrientation: '',
                    personWithDisability: '',
                    directorBornCountry: '',
                    // asian: '',
                    africanAmerican: '',
                    whiteEuropean: '',
                    Hispaniclati: '',
                    middleEastern: '',
                    nativeAmerican: '',
                    pacificIslanderorHawaiian: '',
                    // biRacialMultiRacial: '',
                    other: '',
                    // AmericanIndian: '',
                    EastAsian: '',
                    SouthAsian: '',
                    // NativeHawaiian: '',
                    SoutheastAsian: '',
                    declineToState: '',
                    city: '',
                    country: '',
                    state: '',
                    zipCode: '',
                    phoneNumber: '',
                    type: '',
                }
            }]
        }
        let DirectorExist = formOne.credits?.findIndex(element => element.identifier === 'leadartist')
        let ScreenWriterExist = formOne.credits?.findIndex(element => element.identifier === 'keycollaborator')
        let ProducerExist = formOne.credits?.findIndex(element => element.identifier === 'producer')
        let CastExist = formOne.credits.findIndex(element => element.identifier === 'principalcast')

        let filmcreditLabels = JSON.parse(JSON.stringify(ProjectGetDetail.newFrontierCreditLabel))
        if (DirectorExist === -1) {
            let newD = JSON.parse(JSON.stringify(defaultCredit))
            newD._id = (new Date().getTime() + Math.floor(Math.random() * 1000000000)).toString()
            newD.creditId = filmcreditLabels.find(_ => _.identifier === "leadartist")._id
            newD.creditLabel = filmcreditLabels.find(_ => _.identifier === 'leadartist').name
            newD.identifier = filmcreditLabels.find(_ => _.identifier === 'leadartist').identifier
            formOne.credits.push(newD)
            setFormOne({ ...formOne, credits: newCredits })
            // console.log('=-=-=> Effect', formOne);
        }
        if (ScreenWriterExist === -1) {
            let newS = JSON.parse(JSON.stringify(defaultCredit))
            newS._id = (new Date().getTime() + Math.floor(Math.random() * 100)).toString()
            newS.creditId = filmcreditLabels.find(_ => _.identifier === 'keycollaborator')._id
            newS.creditLabel = filmcreditLabels.find(_ => _.identifier === 'keycollaborator').name
            newS.identifier = filmcreditLabels.find(_ => _.identifier === 'keycollaborator').identifier
            formOne.credits.push(newS)
            setFormOne({ ...formOne, credits: newCredits })
            // setFormOne({ ...formOne, credits: newCredits })
        }

        if (CastExist === -1) {
            let newC = JSON.parse(JSON.stringify(defaultCredit))
            newC._id = (new Date().getTime() + Math.floor(Math.random() * 1000)).toString()
            newC.creditId = filmcreditLabels.find(_ => _.identifier === 'principalcast')._id
            newC.creditLabel = filmcreditLabels.find(_ => _.identifier === 'principalcast').name
            newC.identifier = filmcreditLabels.find(_ => _.identifier === 'principalcast').identifier
            formOne.credits.push(newC)
            // setFormOne({ ...formOne, credits: newCredits })
        }
        setIsSubmitted(ProjectInfo.submitNo)
        // eslint-disable-next-line
    }, [formOne.credits, formOne.indieTopcredit, formOne.indieAdditionalTopCredit])
    Validator.current.purgeFields();
    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|allUpdate":
                    if (!response.error) {
                        dispatch({ type: 'FORM_DATA', payload: response.data })
                        setSavedMessage(true)
                        setTimeout(() => {
                            setSavedMessage(false)
                            setIsShare(false)
                        }, 1000);
                        setIsNext(true)
                    } else {
                        setIsNext(false)
                        setFailedMessage(true)
                        setTimeout(() => {
                            setFailedMessage(false)
                        }, 2000);
                    }
                    break;
                case "paf|project|submit":
                    if (!response.error) {
                        closeSubmitPopup()
                        setSubmitSuccess(true)
                        setTimeout(() => {
                            setSubmitSuccess(false)
                        }, 5000);
                    }
                    break;
                default:
                    break;
            }
        })
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])
    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'logline') {
            let len = value?.replace(/[\t\n\r\\]/gm, " ").split(' ')
            len = len.filter(_ => _ !== '');
            if (len.length <= 50) {
                setFormOne({ ...formOne, [name]: value });
                setLoglineValidation(false)
            } else setLoglineValidation(true)
        } else if(name === 'premiereStatus') {
            setFormOne({ ...formOne, projectInfo:{...formOne.projectInfo,[name]:value},[name]:value })
        } else {
            setFormOne({ ...formOne, [name]: value })
        }

        console.log('value-=-=-=>', formOne);
    }
    const selectCountryOfOrigin = (e, id) => {
        let countries = formOne.countryOfOrigin
        countries.forEach(country => { if (country.id === id) country.country = e.target.value });
        setFormOne({
            ...formOne,
            countryOfOrigin: countries
        })
        // forceUpdate()
    }

    const closeSubmitPopup = () => {
        setisSubmitPopup(false)
    }

    const addCountry = () => {
        let countries = formOne.countryOfOrigin;
        var newAdded = {
            id: new Date().getTime().toString(),
            country: ''
        }
        countries.push(newAdded);
        setFormOne({
            ...formOne,
            countryOfOrigin: countries
        })
    }

    const removeCountry = (id) => {
        var countryTypes = formOne.countryOfOrigin.filter(_ => _.id !== id)
        setFormOne({
            ...formOne,
            countryOfOrigin: countryTypes
        })
    }

    const handleCredit = (id, CREDIT_TYPE, TYPE) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let CreditLabelID = ProjectGetDetail.newFrontierCreditLabel.find(_ => _.identifier === CREDIT_TYPE)._id
        formOne.credits.forEach(item => {
            if (item.creditId === CreditLabelID) {
                if (TYPE === "ADD") {
                    let newAdded = {
                        _id: new Date().getTime().toString(),
                        firstName: '',
                        middleName: '',
                        lastName: '',
                        creditDetails: {
                            email: '',
                            preferredPronouns: '',
                            englishPhoneticProdununciation: '',
                            directorGenderExpression: '',
                            directorIdentifyTransgender: '',
                            sexualOrientation: '',
                            personWithDisability: '',
                            directorBornCountry: '',
                            // asian: '',
                            africanAmerican: '',
                            whiteEuropean: '',
                            Hispaniclati: '',
                            middleEastern: '',
                            nativeAmerican: '',
                            pacificIslanderorHawaiian: '',
                            // biRacialMultiRacial: '',
                            other: '',
                            // AmericanIndian: '',
                            EastAsian: '',
                            SouthAsian: '',
                            // NativeHawaiian: '',
                            SoutheastAsian: '',
                            declineToState: '',
                        }
                    }
                    item.creditType.push(newAdded)
                } else {
                    item.creditType = item.creditType.filter(_ => _._id !== id)
                }
            }
        });
        forceUpdate()
    }

    const onChangeCredit = (e, CID, IID) => {

        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let newCredit = formOne.credits
        newCredit.forEach(credit => {
            if (credit._id === CID) {
                credit.creditType.forEach(data => {

                    if (data._id === IID) {

                        const { name, value } = e.target
                        data[name] = value
                        setShowMaxCredit(true)

                    }

                })
            }
        });

        forceUpdate()
    }
    const onSubmitPopup = (e, STEP) => {
        if (Validator.current.allValid() && !loglineValidation) {
            history.push(`${routeLocater(pafType)}-page${STEP + 1}`)
        } else {
            Validator.current.showMessages()
            forceUpdate()
        }
    }
    const checkStepValidate = () => {
        var race
        formOne?.credits.forEach((item) => {

            if (item.identifier === 'leadartist') {
                item.creditType.forEach((credit, i) => {
                    race = [{ name: "africanAmerican", value: "Black, African American or of African descent", isChecked: credit.creditDetails.africanAmerican },
                    // { name: 'AmericanIndian', value: 'American Indian, Alaskan Native, or Indigenous', isChecked: AmericanIndian ? AmericanIndian : false },
                    { name: "nativeAmerican", value: "Native American or First Nations or Indigenous", isChecked: credit.creditDetails.nativeAmerican},
                    { name: 'EastAsian', value: 'East Asian', isChecked: credit.creditDetails.EastAsian },
                    { name: 'SouthAsian', value: 'South Asian', isChecked: credit.creditDetails.SouthAsian},
                    { name: 'pacificIslanderorHawaiian', value: 'Pacific Islander and/or Native Hawaiian', isChecked: credit.creditDetails.pacificIslanderorHawaiian},
                    { name: 'SoutheastAsian', value: 'Southeast Asian', isChecked: credit.creditDetails.SoutheastAsian },
                    { name: "whiteEuropean", value: "White, European, or of European descent", isChecked: credit.creditDetails.whiteEuropean },
                    { name: 'Hispaniclati', value: 'Hispanic, Latino/a, Latinx, Latine', isChecked: credit.creditDetails.Hispaniclati },
                    { name: "middleEastern", value: "Middle Eastern", isChecked: credit.creditDetails.middleEastern},
                    // { name: "pacificIslander", value: "Pacific Islander", isChecked: pacificIslander ? pacificIslander : false },
                    // { name: "biRacialMultiRacial", value: "Bi-racial or Multi-Racial", isChecked: biRacialMultiRacial ? biRacialMultiRacial : false },
                    { name: "other", value: "Other", isChecked: credit.creditDetails.other },
                    { name: "declineToState", value: "Decline to State", isChecked: credit.creditDetails.declineToState}]
                })
            }
        })
        let val = race.find(_ => _.isChecked)
        console.log(Validator.current.allValid());

        if (Validator.current.allValid() && !loglineValidation && val) {
            return true
        } else {
            setShowOriginValidation(true)
            Validator.current.showMessages()
            forceUpdate()
            setTimeout(() => {
                ErrorScroll()
            }, 500);
            return false
        }
    }
    const onSubmitClick = (NAV) => {
        if (Validator.current.allValid() && !loglineValidation) {

            let topSection
            let secondSection
            let principalcast
            let Producer
            if (formOne.credits) {
                topSection = formOne.credits.find(_ => _.identifier === 'leadartist')?.creditType
                secondSection = formOne.credits.find(_ => _.identifier === 'keycollaborator')?.creditType
                principalcast = formOne.credits.find(_ => _.identifier === 'principalcast')?.creditType
                Producer = formOne.credits.find(_ => _.identifier === 'producer')?.creditType
            }


            if (topSection?.length > 4 || secondSection?.length > 3 || (pafType === 'Documentary Features' && pafType === 'Documentary Shorts' && Producer?.length > 6) || (pafType !== 'Documentary Features' && pafType !== 'Documentary Shorts' && principalcast?.length > 6) || (pafType !== 'New Frontier' && pafType !== 'Indie Series/Special Events' && Producer?.length > 6)) {
                setIsErrorPopup(true)
            } else {
                setisSubmitPopup(true)
            }
            onFormSave(NAV)
            return true
        }
    }

    const CloseErrorPopup = () => {
        setIsErrorPopup(false)
    }
    const onFormSave = (NAV) => {
        let credits_data = JSON.parse(JSON.stringify(formOne.credits));

        credits_data.forEach(item => item.creditType = item.creditType.filter((sub, i) => (sub.firstName !== '' || sub.lastName !== '')))
        credits_data = credits_data.filter(_ => _.creditType.length > 0)

        let data = {
            ...formOne,
            savePageNo: '1',
            // projectInfo: { premiereStatus: formOne.premiereStatus },
            credits: credits_data,
            logline: formOne.logline.trim(),
        }
        setFormOne((last) => {
            data = {
                ...data,
               ...last
            }
            return last
        })
        forceUpdate()
        if (NAV === 'NEXT') {
            data.completedStepNo = 1
        }else if(NAV === 'SHARE'){
            setIsShare(true)
        }
        apiServe.sendevent('paf|project|allUpdate', data)
    }
    console.log(`formOne.credits`, formOne.credits)

    const closeSuccessMessage = () => {
        setSavedMessage(false)
    }
    const closeSubmit = () => {
        setSavedMessage(false)
    }
    const closeFailedMessage = () => {
        setFailedMessage(false)
    }

    useEffect(() => {
        let len = formOne?.logline?.replace(/[\t\n\r\.\?\!]/gm, " ").split(' ')
        len = len?.filter(_ => _ !== '');
        setLoglineLength(len?.length)

    }, [formOne.logline])
    const getCredit = (CREDIT_TYPE, TYPE) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let ADD_LIMIT = CREDIT_TYPE === 'leadartist' ? 4 : CREDIT_TYPE === 'keycollaborator' ? 3 : 6
        let TITLE = CREDIT_TYPE === 'leadartist' ? 'Lead Artist' : CREDIT_TYPE === 'keycollaborator' ? 'Key Collaborator' : 'principalcast'
        return formOne.credits?.map((item, i) => {
            let CreditLabelID = ProjectGetDetail?.newFrontierCreditLabel?.find(_ => _.identifier === CREDIT_TYPE)?._id
            return (
                <>
                    {item.creditId === CreditLabelID ?
                        item.creditType.map((credit, i) => {
                            <FullName disabled={isSubmitted >= 1 ? true : false} credit={credit} item={item} onChange={onChangeCredit} values={credit} title={`${TITLE === 'principalcast' ? "Principal Cast Member" : pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : TITLE} ${pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : i + 1}`} />

                            return (
                                <>
                                    <div key={credit._id} className="sd_flex sd_aligncenter associate_list">
                                        <FullName disabled={isSubmitted >= 1 ? true : false} credit={credit} item={item} onChange={onChangeCredit} values={credit} title={`${TITLE === 'principalcast' ? "Principal Cast Member" : pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : TITLE} ${pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : i + 1}`} />
                                        {isSubmitted >= 1 ? '' : <div className="sd_flex add_remove_icon">
                                            {i + 1 === ADD_LIMIT ? '' : <span className='sd_p_cursor' style={{ cursor: item.creditType.length > ADD_LIMIT - 1 ? 'not-allowed' : 'pointer' }} onClick={() => item.creditType.length < ADD_LIMIT ? handleCredit(credit._id, CREDIT_TYPE, 'ADD') : ''}><PlusFieldIcon /></span>}
                                            {i !== 0 && < span className='sd_p_cursor' onClick={() => handleCredit(credit._id, CREDIT_TYPE, 'SUB')} > <RemoveFieldIcon /></span>}
                                        </div>}
                                    </div>
                                    {i === 0 &&
                                        item.identifier === 'leadartist' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message('+6546', credit.firstName && credit.lastName, 'CFirstLast_d')}</span>
                                    }
                                    {i !== 0 &&
                                        item.identifier === 'leadartist' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message(`ddCFirstLast${i}`, credit.firstName && credit.lastName, (credit.firstName || credit.lastName) ? `CFirstLast1` : '')}</span>
                                    }
                                    {i === 0 &&
                                        // item.identifier === 'keycollaborator' && <span className="error_single sd_error_single" style={{ color: 'red' }}></span>
                                        item.identifier === 'keycollaborator' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message('132', credit.firstName && credit.lastName, 'CFirstLast_s')}</span>
                                    }
                                    {i !== 0 &&
                                        item.identifier === 'keycollaborator' && <span className="error_single sd_error_single" style={{ color: 'red' }}> {Validator.current.message(`dCFirstLast${i}`, credit.firstName && credit.lastName, (credit.firstName || credit.lastName) ? `CFirstLast2` : '')} </span>
                                        // item.identifier === 'keycollaborator' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message('132', credit.firstName && credit.lastName, 'CFirstLast_s')}</span>
                                    }
                                    {/* {i === 0 && pafType !== 'Indie Series/Special Events' && pafType !== 'New Frontier' ?
                                        item.identifier === 'producer' && <span className="error_single sd_error_single" style={{ color: 'red' }}></span> : ''
                                        // item.identifier === 'producer' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message('13', credit.firstName && credit.lastName, 'CFirstLast_p')}</span> : ''
                                    } */}
                                    {pafType !== 'Indie Series/Special Events' && pafType !== 'New Frontier' ?
                                        item.identifier === 'producer' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message(`FirstLast${i}`, credit.firstName && credit.lastName, (credit.firstName || credit.lastName) ? `CFirstLast3` : '')}</span> : ''
                                        // item.identifier === 'producer' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message('13', credit.firstName && credit.lastName, 'CFirstLast_p')}</span> : ''
                                    }
                                    {i === 0 &&
                                        // item.identifier === 'principalcast' && <span className="error_single sd_error_single" style={{ color: 'red' }}></span>
                                        item.identifier === 'principalcast' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message('13', credit.firstName && credit.lastName, 'CFirstLast')}</span>
                                    }
                                    {i !== 0 &&
                                        item.identifier === 'principalcast' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message(`CFirstLast${i}`, credit.firstName && credit.lastName, (credit.firstName || credit.lastName) ? `CFirstLast4` : '')}</span>
                                        // item.identifier === 'principalcast' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message('13', credit.firstName && credit.lastName, 'CFirstLast')}</span>
                                    }
                                </>
                            )
                        })
                        : ''}
                </>)
        })
    }


    const selectAll = (item, credit) => {
        let CID = item._id
        let IID = credit._id

        let newCredit = formOne.credits
        newCredit.forEach(item => {
            if (item._id === CID) {
                item.creditType.forEach((credit) => {
                    if (credit._id === IID) {

                        credit.creditDetails = {
                            ...credit.creditDetails,
                            // asian: !allSelected,
                            africanAmerican: !allSelected,
                            whiteEuropean: !allSelected,
                            Hispaniclati: !allSelected,
                            middleEastern: !allSelected,
                            nativeAmerican: !allSelected,
                            pacificIslanderorHawaiian: !allSelected,
                            // biRacialMultiRacial: !allSelected,
                            // AmericanIndian: !allSelected,
                            EastAsian: !allSelected,
                            SouthAsian: !allSelected,
                            // NativeHawaiian: !allSelected,
                            SoutheastAsian: !allSelected,
                            other: !allSelected,
                            declineToState: !allSelected,
                        }
                    }
                })
            }
        })

        setAllSelected(!allSelected)
        setFormOne({ ...formOne, credits: newCredit })
        // setadobecheck(newAdobelist)
        forceUpdate()
    }

    const onDirectorDetailChange = (e, CID, IID, NAME,OPTION = []) => {

        if (NAME === 'personWithDisability') {
            formOne.credits.forEach(item => {
                if (item._id === CID) {
                    item.creditType.forEach((credit) => {
                        if (credit._id === IID) {
                            credit.creditDetails = {
                                ...credit.creditDetails,
                                [NAME]: (e.length > 0 &&  e.some(_ => _.value === '*')) ? OPTION.slice(1) : (e.length > 1 && e.some(_ => _.value === 'No') && e.findIndex(_ => _.value === 'No') !== 0) ? e.filter(_ => _.value === 'No') : (e.length > 1 && e.some(_ => _.value === 'No') && e.findIndex(_ => _.value === 'No') === 0) ? e.filter(_ => _.value !== 'No') : e
                            }
                        }
                    })
                }
            })
        } else {
            const { name, value, type, checked } = e.target
            if (!e.target.validity.valid) {
                return null;
            }
            formOne.credits.forEach(item => {
                if (item._id === CID) {
                    item.creditType.forEach((credit) => {
                        if (credit._id === IID) {
                            if (type === 'checkbox') {
                                console.log(e.target);
                                credit.creditDetails = {
                                    ...credit.creditDetails,
                                    [name]: checked,
                                }
                            } else {
                                if (name === 'country') {
                                    credit.creditDetails = {
                                        ...credit.creditDetails,
                                        [name]: value,
                                        // state: ''
                                    }
                                } else {
                                    credit.creditDetails = {
                                        ...credit.creditDetails,
                                        [name]: value
                                    }
                                }
                            }
                        }

                    })
                }
            })
        }
        forceUpdate()

    }

    return (
        <div>
            <Header />
            <FormHeader step={1} />
            {/* {console.log('==-=-=-=> render', formOne)} */}
            {!isShare && <SuccessMessage active={SubmitSuccess} close={closeSubmit} text={'Submission'} />}

            {!isShare && <SuccessMessage active={SavedMessage} close={closeSuccessMessage} text={'Save'} />}
            <FailedMessage active={failedMessage} close={closeFailedMessage} text={'Save'} />
            <div className="form_inner step_1 step_common">
                <div className="container">
                    <div className="f_step_1_heading sd_flex sd_justbetween">
                        <div className="f_step_1_left_title">
                            {/* <h1>Program Announcement Information</h1>
                            <p> This information will be used in our public program announcement. Additional credits may be added in the next step.</p> */}
                             <h2>Please have all of your film/project information with you before you begin.</h2>
                        </div>
                        <div className="f_step_1_right_desc">
                            <h5>Program Announcement Information</h5>
                            <p>This information will be used in our public program announcement. Additional credits may be added in the next step.</p>
                            <p>After you submit each step, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons.</p>
                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival, and will be placed in the Sundance Institute Archives.</p>
                        </div>
                    </div>
                    <div className="film_deatil_form">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Film/Project Title </h3>
                        </div>
                        <div className="film_deatil_form_inner">
                            <div className="sd_flex f_detail_f_row sd_justbetween">
                                <TextField disabled={isSubmitted >= 1 ? true : false} uppercase={true} label="article" name="article" value={formOne.article} handleChange={handleChange} required="false" />
                                <TextField disabled={isSubmitted >= 1 ? true : false} uppercase={true} label="Title" name="title" value={formOne.title} handleChange={handleChange} errorValidation={Validator.current.message('title', formOne.title, 'title|required')} require="true" desc={''} />
                            </div>
                            <p className='mb_25'>Please submit your project title in Title Case (first letter of each word capitalized, minor words are lowercase). We do not write film titles in all-capitals unless it is an intentional stylistic choice.</p>
                            <div className="sd_flex f_detail_s_row sd_justbetween">
                                <Dropdown disable={isSubmitted >= 1 ? true : false} uppercase={true} name="filmProjectType" onChange={handleChange} label="Film/Project Type" fpgerror={Validator.current.message('filmProjectType', formOne.filmProjectType, 'filmProjectType|required')} options={ProjectGetDetail.pafProjectType} value={formOne.filmProjectType} />
                                <TextField disabled={isSubmitted >= 1 ? true : false} uppercase={true} name='englishPhoneticProdununciation' value={formOne.englishPhoneticProdununciation} handleChange={handleChange} label="English Phonetic Pronunciation" require={true} errorValidation={Validator.current.message('English Phonetic Prodununciation', formOne.englishPhoneticProdununciation, 'topEng|required')} />
                            </div>
                            {formOne.countryOfOrigin?.map((item, i) => {
                                return (
                                    <>
                                    <div className='detail_country'>
                                        <div className="sd_flex sd_aligncenter f_detail_country_row">
                                            <Dropdown key={item._id} disable={isSubmitted >= 1 ? true : false} onChange={selectCountryOfOrigin} name='Country of Origin' value={item.country ? item.country : ''} uppercase={true} optionID={item.id} label="Country of Origin" DefaultOption={'Select Country'} options={COUNTRIES_LIST} />
                                            {isSubmitted >= 1 ? '' : <div className="sd_flex add_remove_icon">
                                                {i === 0 ?
                                                    <span className='sd_p_cursor' style={{ cursor: formOne.countryOfOrigin.length > 3 ? 'not-allowed' : 'pointer' }} onClick={(e) => formOne.countryOfOrigin.length < 4 ? addCountry(e) : ''}><PlusFieldIcon /></span>
                                                    :
                                                    <>
                                                        {i >= 3 ? '' : <span className='sd_p_cursor' style={{ cursor: formOne.countryOfOrigin.length > 3 ? 'not-allowed' : 'pointer' }} onClick={(e) => formOne.countryOfOrigin.length < 4 ? addCountry(e) : ''}><PlusFieldIcon /></span>}
                                                        <span className='sd_p_cursor' onClick={() => removeCountry(item.id)}><RemoveFieldIcon /></span>
                                                    </>
                                                }
                                            </div>}
                                        </div>
                                        <span style={{ color: 'red' }}>{Validator.current.message('Country of Origin', item.country, 'country|required')}</span>
                                    </div>
                                    <p className='mb_25'>Country of origin is based on where the majority of the project’s financing originated. If your project was financed in the United States but produced in another country, you may list both countries in the order you choose. Up to 4 Countries may be listed.</p>
                                    </>)
                            })}
                            <div className="sd_flex f_detail_s_row sd_justbetween">
                                <Dropdown name="premiereStatus" disable={isSubmitted >= 1 ? true : false} value={formOne.premiereStatus} onChange={handleChange} label="Premiere Status" uppercase={true} require={true} fpgerror={Validator.current.message('premiereStatus', formOne.premiereStatus, 'premiereStatus|required')} toolId={'premiereStatus'} options={state.PremiereStatus} DefaultOption={'Select From Below'} />
                            </div>
                            <p className='pl_25'>
                                <ul>
                                    <li><strong>World Premiere:</strong> Completed film/project has never exhibited publicly before</li>
                                    <li><strong>International Premiere:</strong>  Completed film/project has never screened publicly outside of its country of origin  </li>
                                    <li><strong>North American Premiere:</strong> Completed film/project has never screened publicly in North America (Canada, U.S.A., or Mexico)</li>
                                    <li><strong> U.S. Premiere: </strong> Completed film/project has never screened publicly in the U.S.A.</li>
                                    <li><strong> None: </strong>  Completed film/project has screened publicly in the U.S.A. </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className="associate_people">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Associated People</h3>
                        </div>
                        <div className="ass_container_990">
                            <div className={`form_inner_sub_title sd_flex sd_aligncenter ${pafType === 'Indie Series/Special Events' ? 'sd_justbetween' : ''}`}>
                                <div className="sd_flex sd_aligncenter ind_title_row">
                                    <h5 className="mr_10 required ">{'Lead Artist(s)'}</h5>
                                </div>
                                <p className="ml_0">Up to 4 individuals; list in credit order for Festival publications. For these individuals we will include their headshot and bio in our online Festival Program Guide and we ask that they provide a "Meet the Artist" video.</p>
                            </div>
                        </div>
                        {
                            getCredit('leadartist')
                        }
                        <div className="ass_container_990">
                            <div className={`form_inner_sub_title sd_flex sd_aligncenter mt-40 ${pafType === 'Indie Series/Special Events' ? 'sd_justbetween' : ''} `}>
                                <div className="sd_flex sd_aligncenter ind_title_row">
                                    <h5 className="mr_10">{'Key Collaborator(s)' ? 'Additional Top Credit(s)' : 'Screenwriter(s)'} </h5>
                                </div>
                                <p className="ml_0">List in desired order for all places where credits appear. Up to 3.</p>
                            </div>
                        </div>
                        {
                            // getCredit('Screenwriter')
                            getCredit('keycollaborator')
                        }
                        {/* {pafType === 'New Frontier' || pafType === 'Indie Series/Special Events' ? "" : <> 
                        <div className="ass_container_990">
                            <div className="form_inner_sub_title sd_flex sd_aligncenter mt-40">
                                <h5>Producer(s)
                                    <Tooltip id="accessibility" customClass="tooltip-button tooltip-short-program" data="Include up to six names of individuals receiving full producer credit only (no executive producers, co-producers, associate producers, etc.).

                                        List in desired order for press announcement." place="right" data_top={25} />
                                </h5>
                                <p>List in desired order for all places where credits appear.</p>
                            </div>
                        </div>
                            {
                                getCredit('producer')
                            }
                        </>} */}

                        {pafType === 'Documentary Features' || pafType === 'Documentary Shorts' ? "" : <><div className="ass_container_990">
                            <div className="form_inner_sub_title sd_flex sd_aligncenter mt-40" style={{flexWrap:'wrap'}}>
                                <h5>Principal Cast Member(s)</h5>
                                <p>List in desired order for all places where credits appear. Up to 6.</p>
                                <div className='sd_block mt_10' style={{width:'100%'}}>
                                    <p className='ml_0'>Include up to six names of individuals receiving full producer credit only (no executive producers, co-producers, associate producers, etc.).List in desired order for press announcement.</p>
                                </div>
                            </div>
                        </div>
                            {
                                getCredit('principalcast')
                            }</>
                        }



                    </div>
                    <div className="associate_people">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Lead Artist Demographics</h3>
                        </div>
                        <div className='demographic_desc'>
                            <p>Collecting demographic data is a critical step in helping us support and promote your film/project and fully reflect the program composition. We use this information to create editorial posts on our site and official social channels, and for press purposes, including specialized media outreach, external-facing fact sheets detailing overall demo data for the festival (i.e. “30 Projects by women filmmakers of color” or “60% of films in the U.S. Documentary Competition were directed by people of color”) and coverage. Examples of the type of coverage include “Films from Black Filmmakers We Can’t Wait to See,” “LGBTQ+ Sundance Selections To Watch For,” “Indigenous Films Featured at Sundance Film Festival,” and similar.</p>

                            <p>Please note if you decline to state in any category, then we are unable to include you and/or your project in these Sundance created lists on our house channels or presented to media for this specific coverage. Feel free to reach out to your Publicity Liaison should you have any questions about the information or its use. "</p>
                        </div>
                        <div className="ass_container_990">
                            {
                                formOne.credits.map(item => {
                                    if (item.identifier === 'leadartist') {
                                        return item?.creditType.map((credit, i) => {
                                            return <Demographics title={'Lead Artist'} disabled={isSubmitted >= 1 ? true : false} selectAll={selectAll} onChange={onDirectorDetailChange} ShowOriginValidation={ShowOriginValidation} item={item} index={i} DirectorData={credit} Error={{
                                                directorGenderExpression: Validator.current.message(`directorGenderExpression${i}`, credit.creditDetails.directorGenderExpression, 'required|required'),
                                                preferredPronouns: Validator.current.message(`preferredPronouns${i}`, credit.creditDetails.preferredPronouns, 'required|required'),
                                                englishPhoneticProdununciation: Validator.current.message('englishPhoneticProdununciation', credit.creditDetails.englishPhoneticProdununciation, 'required|required'),
                                                directorIdentifyTransgender: Validator.current.message(`directorIdentifyTransgender${i}`, credit.creditDetails.directorIdentifyTransgender, 'required|required'),
                                                sexualOrientation: Validator.current.message(`sexualOrientation${i}`, credit.creditDetails.sexualOrientation, 'required|required'),
                                                personWithDisability: Validator.current.message(`personWithDisability${i}`, credit.creditDetails.personWithDisability, 'required|required'),
                                                directorBornCountry: Validator.current.message(`directorBornCountry${i}`, credit.creditDetails.directorBornCountry, 'required|required'),
                                            }} />
                                        })
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="logline_form">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter required">Logline</h3>
                            <p>Below is a logline for your film/project based on your initial submission via FilmFreeway.<strong>THIS IS THE FINAL LOGLINE.</strong> We will consider edits if there are factual errors or inaccuracies.</p>
                            <p>It is drafted in Associated Press style with Sundance house guidelines by our PR, editorial, and programming teams. This will be used in our press release and marketing materials such as the website.</p>
                            <p>Loglines for this use describe the plot of the film/project and follow Sundance house guidelines, which include: {pafType === 'Narrative Shorts' ? '40' : '50'} words max.</p>
                            <p>No film titles, filmmaker or actor names in the logline (directors, screenwriters, producers, actors are already directly attached to the logline as a part of the credit list).</p>
                            <p>No descriptions of the type of film, genre, nor self-describe or editorializing (i.e. “this groundbreaking documentary”, “a coming-of-age drama”, “a story about”, “a cinematic journey”).</p>
                            <p>No spoilers or major plot points that are intended to be discovered by the audience.</p>
                        </div>
                        <div className="ass_container_990">
                            <div className="field_row_inner">
                                <label>{pafType === 'Narrative Shorts' ? '40' : '50'} words max</label>
                                <div className="field_input relative">
                                    <textarea disabled={isSubmitted >= 1 ? true : false} value={formOne.logline} onChange={(e) => handleChange(e)} name='logline' />
                                </div>
                                <span style={{ color: 'red' }}>{loglineValidation ? <div className='srv-validation-message'>More than {pafType === 'Narrative Shorts' ? '40' : '50'} Words is not allowed.</div> : Validator.current.message('logline', formOne.logline, 'logline|required')}</span>
                                <div className="textarea_word sd_flex sd_aligncenter">
                                    <label>Word Count</label>
                                    <span>{loglineLength}/{pafType === 'Narrative Shorts' ? '40' : '50'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isSubmitted >= 1 ? <div className='mt_25'> </div> : <> <div className="privacy_policy_link">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Privacy Policy</h3>
                        </div>
                        <div className="ass_container_990 ">
                            <p>By signing below you are agreeing to <a href="https://www.sundance.org/about/privacy-policy" target="_blank" rel="noreferrer" >Sundance Institute’s Privacy Policy.</a></p>
                        </div>
                    </div>
                        <div className="electronic_sign">
                            <div className="form_inner_title">
                                <h3 className="sd_flex sd_aligncenter">Electronic Signature</h3>
                            </div>
                            <div className="ass_container_990 ">
                                <p>On behalf of the film/project owners, I certify that (1) all of the above is true to the best of my/our knowledge and (2) I am authorized to submit this information, enter into a release, and confer the following rights for this film/project. I understand that this information will appear in all Festival materials and will be provided to third-party groups. I understand and agree that Sundance Institute may make non-substantive edits to the information submitted here, for reasons such as style, grammar, and brevity.</p>
                                <p> I agree to indemnify and hold harmless the Institute, its officers, directors, and employees from any claims, losses, demands, costs, damages, judgments, liabilities and expenses (including attorneys’ fees) of any type arising out of or in connection with the information I have provided here and/or the project’s participation in the Festival.</p>
                                <p>By providing this information, you are providing Sundance Institute with a non-exclusive, worldwide,  perpetual license to use the electronic materials (e.g., film/project stills, headshots, press kits, posters, film/project clips, photographs, videos or video clips, and Meet the Artist videos) and information submitted for all Sundance Institute publications and materials relating to or in connection with the Festival, whether printed or online, both before and after the Festival. Sundance Institute reserves the right to crop images as needed. We are not able to submit materials to artists or their representatives for approval prior to publication. You also authorize Sundance Institute to share the provided information with its official supporters.</p>
                            </div>
                        </div> </>}
                    <Popup open={isErrorPopup} setOpen={CloseErrorPopup} submitForm={true} okPopup={true} title=''>
                        <p>{'Lead Artist that can be added is 4 and Principal Cast that can be added is 6 and Key collaborator that can be added is 3'}</p>
                    </Popup>
                    {isSubmitted >= 1 ? <FormFooter step={1} onFormSave={onFormSave}/> : <FormSubmitFooter isNext={isNext} checkStepValidate={checkStepValidate} onSubmitClick={onSubmitClick} onFormSave={onFormSave} isSubmitPopup={isSubmitPopup} closeSubmitPopup={closeSubmitPopup} onSubmitPopup={onSubmitPopup} step={1} CounterStep={1} />}
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Step1
