import React, { Component } from 'react';

class Loader extends Component {
    componentDidMount() {
        document.querySelector('body').classList.add('no-scroll');
    }
    componentWillUnmount() {
        document.querySelector('body').classList.remove('no-scroll');
    }

    render() {
        return (
            <div className="sd_loader">
                <svg xmlns="http://www.w3.org/2000/svg"  width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#04ac80" stroke="none">
                        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51" />
                    </path>
                </svg>
            </div>
        )
    }
}
export default Loader;