import React from 'react'
import Tooltip from './Tooltip'

function Dropdown(props) {
    const { label, require, name, value, disable, defaultValue, fpgerror, toolTip, uppercase, optionID, creditOption, DefaultOption , toolId, id} = props
    const options = props.options && props.options.map(function (option, i) {
        if (creditOption) {
            return (
                <option defaultValue={defaultValue} key={option} value={option._id}>{option.name}</option>
            )
        } else {
            return (
                <option defaultValue={defaultValue} key={option} value={option}>{option}</option>
            )
        }
    })


    const HandleChange = (e) => {
        if (optionID) {
            props.onChange(e, optionID)
        }
        else if (props.item && props.credit) {
            props.onChange(e, props.item._id, props.credit._id)
        }
        else {
            props.onChange(e)
        }
    }

    return (
        <div className="field_row_inner">
            {label ? <label className={`${require ? 'required' : null} ${uppercase ? 'sd_textuppercase' : ''}`}>{label}
            </label> : ""}
                {toolTip ?
                    <Tooltip
                        id={toolId}
                        customClass="tooltip-button tooltip-short-program"
                        data={toolTip}
                        place="right"
                        width={13}
                        data_top={25}
                        html={true}
                    />
                    : ''}
            <div className="select_field relative">
                <select disabled={disable} name={name} onChange={(e) => HandleChange(e)} value={value} id={id}>
                    {DefaultOption ? <option value="" >{DefaultOption}</option> : ''}
                    {options}
                </select>
                {fpgerror ? <span className="error" style={{ color: 'red' }} >{fpgerror}</span> : ""}
            </div>
        </div>
    )
}

export default Dropdown
