import React, { useState, useEffect } from 'react'
import Header from '../../layout/Header'
import Footer from "../../layout/Footer";
import Dropdown from '../../components/Dropdown';
import TextField from '../../components/TextField';
import {  useSelector } from 'react-redux';

import FullName from "./../Components/FullName";
import { COUNTRIES, STATE_PRO } from '../../common/constant';

import PreviewFormHeader from './PreviewFormHeader';
import PreviewFormFooter from './PreviewFormFooter';
import service from '../../common/service';
import { useParams } from 'react-router-dom';




const TeamForm = (props) => {
    const { CreditData, index, onChange, item, Error, nameDisabled, disabled } = props
    let COUNTRIES_LIST = COUNTRIES
    let STATE_LIST = STATE_PRO
   console.log("CreditData123123", CreditData);
    return (
        <div className="border_bottom_gray team_info_section">
            {/* <div className="form_content_top">
                <div class="form_content_top_title pb_0">
                    <i>Screenwriter 1</i>
                </div>
            </div> */}
            <div className="sd_flex sd_aligncenter associate_list">
                <FullName credit={CreditData}  item={item} disabled={nameDisabled} values={CreditData} title={`${item.creditLabel} ${index === false ? '' : index + 1}`} onSalesAgentAdd={props.onSalesAgentAdd} index={index} multiPal={props.multiPal} />
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField disabled={disabled} credit={CreditData} item={item} type="text" name="title" value={CreditData.creditDetails?.title} label="Title" uppercase />
                <TextField disabled={disabled} credit={CreditData} item={item} type="text" name="company" value={CreditData.creditDetails?.company} label="Company" uppercase />

            </div>
            <div className="sd_flex fullname_div one_field">
                <TextField disabled={disabled} credit={CreditData} errorValidation={Error?.Email} item={item} type="text" name="email" value={CreditData.creditDetails?.email} label="Email" uppercase />
            </div>
            <div className="sd_flex fullname_div one_field">
                <TextField disabled={disabled} credit={CreditData} item={item} type="text" name="address1" value={CreditData.creditDetails?.address1} label="Address 1" uppercase />
            </div>
            <div className="sd_flex fullname_div one_field">
                <TextField disabled={disabled} credit={CreditData} item={item} type="text" name="address2" value={CreditData.creditDetails?.address2} label="Address 2" uppercase />
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField disabled={disabled} credit={CreditData} item={item} errorValidation={Error?.City} type="text" name="city" value={CreditData.creditDetails?.city} label="City" uppercase />
                {CreditData.creditDetails?.country === 'United States' || CreditData.creditDetails?.country === 'Canada' ?
                    <Dropdown disable={disabled} credit={CreditData} item={item} fpgerror={index === 0 && Error?.Province} options={STATE_LIST} name="state"  value={CreditData.creditDetails?.state} label="State/Province" DefaultOption={'Select State/Province'} uppercase /> :
                    <TextField disabled={disabled} credit={CreditData} item={item} errorValidation={index === 0 && Error?.Province} type="text" name="state" value={CreditData.creditDetails?.state} label="State/Province" uppercase />}
                {/* <TextField disabled={disabled} errorValidation={Error?.Province} type="text" name="state" value={CreditData.state} label="State/Province" uppercase /> */}
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField disabled={disabled} credit={CreditData} item={item} errorValidation={Error?.Zip} type="text" name="zipCode" value={CreditData.creditDetails?.zipCode} label="Zip Code" uppercase />
                <Dropdown disable={disabled} label="Country" name='country' credit={CreditData} item={item}  fpgerror={Error?.Country} value={CreditData.creditDetails?.country} uppercase options={COUNTRIES_LIST} DefaultOption={'Select Country'} />
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField disabled={disabled} credit={CreditData} item={item} errorValidation={Error?.Mobile} type="text" name="phoneNumber" value={CreditData.creditDetails?.phoneNumber} label="Phone Number" uppercase />
                <Dropdown disable={disabled} credit={CreditData} item={item} label="Type" name='type'  uppercase value={CreditData.creditDetails?.type} options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
            </div>
        </div>
    )
}

const TeamFormExtra = (props) => {
    const { CreditData, onChange, Error, disabled, salesAgent } = props
    let COUNTRIES_LIST = COUNTRIES
    let STATE_LIST = STATE_PRO

    return (
        <div className="border_bottom_gray team_info_section">
            {/* <div className="form_content_top">
                <div class="form_content_top_title pb_0">
                    <i>Screenwriter 1</i>
                </div>
            </div> */}

            <div className="sd_flex sd_aligncenter associate_list">
                <div className="sd_container_990">
                    <div className='sd_flex'>
                        <p className="form_sub_2_title">{props.title}</p>
                    </div>
                    <div className="sd_flex fullname_div sd_justbetween">
                        <TextField data_id={CreditData?._id} errorValidation={props.Errors?.FirstName} value={CreditData.firstName} name='firstName' disabled={props.disabled} uppercase={true} label="First Name" required="false" />
                        <TextField data_id={CreditData?._id} value={CreditData.middleName} name='middleName' disabled={props.disabled} uppercase={true} label="Middle Name" required="false" />
                        <TextField data_id={CreditData?._id} errorValidation={props.Errors?.LastName} value={CreditData.lastName} name='lastName' disabled={props.disabled} uppercase={true} label="Last Name" required="false" />
                    </div>
                </div>
            </div>

            {props.title === 'Distributor/ Gallery/ Institution' ?
                <div className="sd_flex fullname_div two_field">
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="company" value={CreditData.company} label="Company / Gallery Name" uppercase />

                </div> : props.title.includes('Sales Agent') ? <div className="sd_flex fullname_div two_field">
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="title" value={CreditData.title} label="Title" uppercase />
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="company" value={CreditData.company} label="Company" uppercase />
                    <Dropdown optionID={CreditData?._id} disable={disabled} options={['International', 'Domestic', 'Both']} name="typeofsale"  value={CreditData.typeofsale} label="Type of Sales" DefaultOption={'Select From Below'} uppercase />

                </div> : <div className="sd_flex fullname_div two_field">
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="title" value={CreditData.title} label="Title" uppercase />
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="company" value={CreditData.company} label="Company" uppercase />

                </div>}
            <div className="sd_flex fullname_div one_field">
                <TextField data_id={CreditData?._id} disabled={disabled} errorValidation={Error?.Email} type="text" name="email" value={CreditData.email} label="Email" uppercase />
            </div>
            <div className="sd_flex fullname_div one_field">
                <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="address1" value={CreditData.address1} label="Address 1" uppercase />
            </div>
            <div className="sd_flex fullname_div one_field">
                <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="address2" value={CreditData.address2} label="Address 2" uppercase />
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField data_id={CreditData?._id} disabled={disabled} errorValidation={Error?.City} type="text" name="city" value={CreditData.city} label="City" uppercase />
                {CreditData.country === 'United States' || CreditData.country === 'Canada' ?
                    <Dropdown optionID={CreditData?._id} disable={disabled} fpgerror={Error?.Province} options={STATE_LIST} name="state"  value={CreditData.state} label="State/Province" DefaultOption={'Select State/Province'} uppercase /> :
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="state" value={CreditData.state} label="State/Province" uppercase />}
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField data_id={CreditData?._id} disabled={disabled} errorValidation={Error?.Zip} type="text" name="zipCode" value={CreditData.zipCode} label="Zip Code" uppercase />
                <Dropdown optionID={CreditData?._id} disable={disabled} label="Country" name='country'  fpgerror={Error?.Country} value={CreditData.country} uppercase options={COUNTRIES_LIST} DefaultOption={'Select Country'} />
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField data_id={CreditData?._id} disabled={disabled} errorValidation={Error?.Mobile} type="text" name="phoneNumber" value={CreditData.phoneNumber} label="Phone Number" uppercase />
                <Dropdown optionID={CreditData?._id} disable={disabled} label="Type" name='type'  uppercase value={CreditData.type} options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
            </div>
        </div>
    )
}



function Step6() {
    const apiServe = new service();
    const { ProjectId } = useParams();
    const refcode = useSelector(e => e.refcode);
    const ProjectInfo = useSelector(e => e.SharedProjectInfo);
    // const ProjectGetDetail = useSelector(e => e.projectGetDetail);

    // const history = useHistory()
  
 
    
    const pafType = useSelector(e => e.SharedPafType);
   

    // const [SalesAgentExist, setSalesAgentExist] = useState(false)
    // const [DGIExist, setDGIExist] = useState(false)
    // const [EditorExist, setEditorExist] = useState(false)
    // const [CinematographerExist, setCinematographerExist] = useState(false)
    const [formSix, setFormSix] = useState({
        credits: ProjectInfo.credits,
        composer: ProjectInfo.composer,
        distributor: ProjectInfo.distributor,
        editor: ProjectInfo.editor,
        salesAgent: ProjectInfo.salesAgent.length > 0 ? ProjectInfo.salesAgent : [{
            address1: "",
            address2: "",
            city: "",
            company: "",
            country: "",
            email: "",
            firstName: "",
            lastName: "",
            middleName: "",
            phoneNumber: "",
            state: "",
            title: "",
            type: "",
            zipCode: "",
            _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString(),
        }],
        cinematographer: ProjectInfo.cinematographer,
        indieTopcredit: ProjectInfo.indieTopcredit,
        indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
    })

    const [isSubmitted, setisSubmitted] = useState(ProjectInfo.submitNo)

    const onLoad = (e) => {
        apiServe.sendevent('paf|project|previewForm', { projectUserId: ProjectId })
        apiServe.sendevent('paf|project|CheckValidEmail', { projectUserId: ProjectId ,referralCode:refcode})
    }

    useEffect(() => {
        window.addEventListener('load',onLoad)
        return () => {
            window.removeEventListener('load',onLoad)
        }
    },[])

    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        formSix.credits.forEach(item => {

            if (item.identifier !== (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formSix.indieTopcredit?.identifier : 'director')) {
                item.creditType.forEach((credit) => {
                    credit.creditDetails = {
                        company: credit.creditDetails?.company ? credit.creditDetails?.company : '',
                        email: credit.creditDetails?.email ? credit.creditDetails?.email : '',
                        address1: credit.creditDetails?.address1 ? credit.creditDetails?.address1 : '',
                        address2: credit.creditDetails?.address2 ? credit.creditDetails?.address2 : '',
                        city: credit.creditDetails?.city ? credit.creditDetails?.city : '',
                        state: credit.creditDetails?.state ? credit.creditDetails?.state : '',
                        zipCode: credit.creditDetails?.zipCode ? credit.creditDetails?.zipCode : '',
                        country: credit.creditDetails?.country ? credit.creditDetails?.country : '',
                        phoneNumber: credit.creditDetails?.phoneNumber ? credit.creditDetails?.phoneNumber : '',
                        type: credit.creditDetails?.type ? credit.creditDetails?.type : '',
                        title: credit.creditDetails?.title ? credit.creditDetails?.title : '',
                        teamType: credit.creditDetails?.teamType ? credit.creditDetails?.teamType : '',
                    }
                })
            }
        });
        setisSubmitted(ProjectInfo.submitNo)
   
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        setFormSix({
            credits: ProjectInfo.credits,
            composer: ProjectInfo.composer,
            distributor: ProjectInfo.distributor,
            editor: ProjectInfo.editor,
            salesAgent: ProjectInfo.salesAgent.length > 0 ? ProjectInfo.salesAgent : [{
                address1: "",
                address2: "",
                city: "",
                company: "",
                country: "",
                email: "",
                firstName: "",
                lastName: "",
                middleName: "",
                typeofsale: '',
                phoneNumber: "",
                state: "",
                title: "",
                type: "",
                zipCode: "",
                _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString(),
            }],
            cinematographer: ProjectInfo.cinematographer,
            indieTopcredit: ProjectInfo.indieTopcredit,
            indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
        })
        // setisSubmitted(isSubmitted)
        setisSubmitted(ProjectInfo.submitNo)

        // eslint-disable-next-line
    }, [ProjectInfo])
   

   


   
   
  

    return (
        <div id='downloadStep6'>
            <Header />
            <PreviewFormHeader step={6} />
           
            <div className="container form_inner step2 step_eight step_common">
                <div className="underline">
                    <div className='step sd_justbetween'>
                        <div className='step_no '>
                            <p>Page 6 of {pafType === 'New Frontier' ? 8 : 9}</p>
                        </div>
                        <div className='step_des'>
                            <p>After you submit at the end of Page {pafType === 'New Frontier' ? 8 : 9}, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons. </p>

                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival and will be placed in the Sundance Institute Archives. </p>
                        </div>
                    </div>
                </div>
                <div className="form_content_questions">
                    <div className="form_content form_content_questions_inner pt_0">
                        <div className="form_content_top">
                            <div className="form_content_top_title pb_0">
                                <h3 className="mb_0">Team Information</h3>
                            </div>
                        </div>
                        {/* <TeamForm /> */}
                        {formSix?.credits.map((item) => {
                            return (
                                <>
                                    {item.identifier === (pafType === 'New Frontier' ? 'keycollaborator' : pafType === 'Indie Series/Special Events' ? formSix.indieAdditionalTopCredit?.identifier : 'screenwriter') ? <>{
                                        item.creditType.map((credit, i) => {
                                            if (credit.firstName !== '') {
                                                return (
                                                    <TeamForm disabled={true} CreditData={credit} item={item} index={i} nameDisabled={true} />

                                                )
                                            }
                                        })
                                    }</> : ''}
                                </>
                            )
                        })}
                        {pafType !== 'Indie Series/Special Events' && formSix?.credits.map((item) => {
                            return (
                                <>
                                    {item.identifier === 'producer' || item.identifier === 'producer' ? <>{
                                        item.creditType.map((credit, i) => {
                                            if(credit.firstName !== ''){
                                                return (
                                                    <TeamForm disabled={true} CreditData={credit} item={item} index={i} nameDisabled={true}  />
    
                                                )
                                            }
                                        })
                                    }</> : ''}
                                </>
                            )
                        })}
                        <TeamFormExtra disabled={true} CreditData={formSix.composer} title={'Composer'} />
                        <TeamFormExtra disabled={true} CreditData={formSix.editor} title={'Editor'} />
                        <TeamFormExtra disabled={true}  CreditData={formSix.cinematographer} title={'Cinematographer'} />
                        {
                            formSix.salesAgent.map((sales, i) => {
                                return <TeamFormExtra disabled={true}  index={i} salesAgent={formSix.salesAgent} multiPal={isSubmitted >= 3 ? false : true} CreditData={sales} title={`Sales Agent ${i + 1}`} />
                            })
                        }
                        <TeamFormExtra disabled={true}  CreditData={formSix.distributor} title={'Distributor/ Gallery/ Institution'} />
                    </div>
                </div>
                <div className="footer">
                    <PreviewFormFooter step={6}  rootId='downloadStep6'/>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Step6