import React, { useEffect, useRef, useState } from 'react';
// Layouts
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
// PAF Header
import FormHeader from "../../../PAF/Components/FormHeader";
import FormSubmitFooter from "./../FormSubmitFooter";
import TextField from "../../../components/TextField";
import TextArea from "../../../components/TextArea";
import Checkbox from "../../../components/Checkbox";
import Tooltip from "../../../components/Tooltip";
import Dropdown from "../../../components/Dropdown";
import FullName from "../../../PAF/Components/FullName";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import service from '../../../common/service';
import SimpleReactValidator from 'simple-react-validator';
import { COUNTRIES, STATE_PRO } from '../../../common/constant';
import FormFooter from './../FormFooter';
import SuccessMessage from './../SuccessMessage';
import FailedMessage from './../FailedMessage';
import { ErrorScroll, routeLocater } from './../../../common/functions';
import Popup from './../Popup';
import MultiSelect from '../../../components/MultiSelect';


const DirectorInfo = (props) => {
    const { DirectorData, index, onChange, item, BioValidation, Error, disabled, selectAll, indieTopcredit, bioCount } = props
    let COUNTRIES_LIST = COUNTRIES
    let STATE_LIST = STATE_PRO
    const [BioLength, setBioLength] = useState(0)
    const pafType = useSelector(e => e.pafType);


    const dropOption = {
        preferredPronouns: ['He/Him', 'She/Her', 'They/Them', 'Other'],
        directorGenderExpression: ['Woman', 'Man', 'Non-Binary or Gender Non-conforming', 'Intersex', '2Spirit', 'Other', 'Decline to State'],
        directorIdentifyTransgender: ['Yes', 'No', 'Other', 'Decline to State'],
        sexualOrientation: ['Heterosexual', 'Gay or Lesbian', 'Bisexual and/or Pansexual', 'Other', 'Decline to State'],
        personWithDisability: [{ label: 'Yes, Cognitive', value: 'Yes, Cognitive' },
        { label: 'Yes, Emotional', value: 'Yes, Emotional' },
        { label: 'Yes, Hearing', value: 'Yes, Hearing' },
        { label: 'Yes, Visual', value: 'Yes, Visual' },
        { label: 'Yes, Sensory (non-vision/hearing)', value: 'Yes, Sensory (non-vision/hearing)' },
        { label: 'Yes, Mental', value: 'Yes, Mental' },
        { label: 'Yes, Physical', value: 'Yes, Physical' },
        { label: 'No', value: 'No' },
        { label: 'Other', value: 'Other' },
        { label: 'Decline to State', value: 'Decline to State' }]
    }


    const { asian, africanAmerican, whiteEuropean, Hispaniclati, middleEastern, nativeAmerican, pacificIslanderorHawaiian, biRacialMultiRacial, other, declineToState, AmericanIndian, EastAsian, SouthAsian, NativeHawaiian, SoutheastAsian } = DirectorData.creditDetails
    const [ethnicOrigin] = useState([
        { name: "africanAmerican", value: "Black, African American or of African descent", isChecked: africanAmerican ? africanAmerican : false },
        // { name: 'AmericanIndian', value: 'American Indian, Alaskan Native, or Indigenous', isChecked: AmericanIndian ? AmericanIndian : false },
        { name: "nativeAmerican", value: "Native American or First Nations or Indigenous", isChecked: nativeAmerican ? nativeAmerican : false },
        { name: 'EastAsian', value: 'East Asian', isChecked: EastAsian ? EastAsian : false },
        { name: 'SouthAsian', value: 'South Asian', isChecked: SouthAsian ? SouthAsian : false },
        // { name: 'NativeHawaiian', value: 'Native Hawaiian', isChecked: NativeHawaiian ? NativeHawaiian : false },
        { name: 'SoutheastAsian', value: 'Southeast Asian', isChecked: SoutheastAsian ? SoutheastAsian : false },
        { name: "whiteEuropean", value: "White, European, or of European descent", isChecked: whiteEuropean ? whiteEuropean : false },
        { name: 'Hispaniclati', value: 'Hispanic, Latino/a, Latinx, Latine', isChecked: Hispaniclati ? Hispaniclati : false },
        { name: "middleEastern", value: "Middle Eastern", isChecked: middleEastern ? middleEastern : false },
        { name: "pacificIslanderorHawaiian", value: "Pacific Islander and/or Native Hawaiian", isChecked: pacificIslanderorHawaiian ? pacificIslanderorHawaiian : false },
        // { name: "biRacialMultiRacial", value: "Bi-racial or Multi-Racial", isChecked: biRacialMultiRacial ? biRacialMultiRacial : false },
        { name: "other", value: "Other", isChecked: other ? other : false },
        { name: "declineToState", value: "Decline to State", isChecked: declineToState ? declineToState : false },])
    useEffect(() => {
        if (DirectorData.creditDetails?.bio) {
            let len = DirectorData.creditDetails?.bio?.match(/\S+/g)
            len = len?.filter(_ => _ !== '');
            setBioLength(len?.length || 0)
        } else {
            setBioLength(0)
        }

    }, [DirectorData.creditDetails.bio])

    return (
        <div >
            <div className="sd_flex sd_aligncenter associate_list">
                <FullName credit={DirectorData} item={item} disabled={true} values={DirectorData} title={`${indieTopcredit?.name} ${index + 1}`} />
            </div>
            <div className="sd_flex sd_aligncenter associate_list ">
                <div className="sd_container_990">
                    <div className="sd_flex fullname_div sd_justbetween">
                        <Dropdown disable={true} credit={DirectorData} item={item} onChange={onChange} value={DirectorData.creditDetails?.preferredPronouns} uppercase name='preferredPronouns' options={dropOption.preferredPronouns} DefaultOption={'Select From Below'} label=" Pronouns" required="false" />
                        <TextField disabled={true} uppercase={true} credit={DirectorData} item={item} handleChange={onChange} value={DirectorData.creditDetails?.englishPhoneticProdununciation} name="englishPhoneticProdununciation" label="English Phonetic Pronunciation" required="false" />
                        <Dropdown disable={disabled} credit={DirectorData} item={item} value={DirectorData.creditDetails?.firstFeature} onChange={onChange} uppercase label="First Feature?" name='firstFeature' options={["Yes", "No"]} DefaultOption={'Select From Below'} />
                    </div>
                </div>

                {/* <FullName /> */}
            </div>
            <div className="sd_container_990">
                <div class="field_row_inner icon_label">
                    <label className='sd_flex sd_aligncenter'>BIO</label>
                    <p className='mb_10'>Please provide a short bio for each individual listed as the top credit for your project. All bios will be edited before being published. Each bio must not exceed 50 words; however, the combined total for ALL bios must not exceed 100 words</p>
                    <div class="field_input relative">
                        <TextArea disabled={disabled} height='225px' credit={DirectorData} item={item} handleChange={onChange} name='bio' value={DirectorData.creditDetails?.bio} />
                        {(bioCount.count >= 100 && bioCount.bioId === DirectorData._id) ? <span style={{ color: 'red' }}>For multiple directors, combined word limit is 100 words</span> : ''}
                        <span style={{ color: 'red' }}>{(BioValidation.value && BioValidation.id === DirectorData._id) ? 'More than 50 Word is not allowed' : ''}</span>
                    </div>
                    <div className="textarea_word sd_flex sd_aligncenter">
                        <label className='mb_0'>Word Count</label>
                        <span>{BioLength}/50</span>
                    </div>
                </div>
            </div>
            <div className="for_padding team_info_section sd_container_990 mb_0 pb_0">
                <div className="sd_flex fullname_div one_field">
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="company" handleChange={onChange} value={DirectorData.creditDetails?.company} label="Company" uppercase />
                </div>
                <div className="sd_flex fullname_div one_field">
                    <TextField require={true} disabled={disabled} credit={DirectorData} errorValidation={Error.Email} item={item} type="text" name="email" handleChange={onChange} value={DirectorData.creditDetails?.email} label="Email" uppercase />
                </div>
                <div className="sd_flex fullname_div one_field">
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="address1" handleChange={onChange} value={DirectorData.creditDetails?.address1} label="Address 1" uppercase />
                </div>
                <div className="sd_flex fullname_div one_field">
                    <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="address2" handleChange={onChange} value={DirectorData.creditDetails?.address2} label="Address 2" uppercase />
                </div>
                <div className="sd_flex fullname_div two_field">
                    <TextField require={true} disabled={disabled} credit={DirectorData} item={item} errorValidation={Error.City} type="text" name="city" handleChange={onChange} value={DirectorData.creditDetails?.city} label="City" uppercase />
                    <Dropdown require={DirectorData.creditDetails?.country === 'United States' || DirectorData.creditDetails?.country === 'Canada' ? true : false} disable={disabled} credit={DirectorData} item={item} fpgerror={DirectorData.creditDetails?.country === 'United States' || DirectorData.creditDetails?.country === 'Canada' ? Error.Province : ''} name="state" options={STATE_LIST} onChange={onChange} value={DirectorData.creditDetails?.state} label="State/Province" DefaultOption={'Select State/Province'} uppercase />
                    {/*  {DirectorData.creditDetails?.country === 'United States' || DirectorData.creditDetails?.country === 'Canada' ?
                        <Dropdown disable={disabled} credit={DirectorData} item={item} fpgerror={DirectorData.creditDetails?.country === 'United States' || DirectorData.creditDetails?.country === 'Canada' ? Error.Province : ''} name="state" options={STATE_LIST} onChange={onChange} value={DirectorData.creditDetails?.state} label="State/Province" DefaultOption={'Select State/Province'} uppercase /> :
                        <TextField disabled={disabled} credit={DirectorData} item={item} type="text" name="state" handleChange={onChange} value={DirectorData.creditDetails?.state} label="State/Province" uppercase />} */}
                </div>
                <div className="sd_flex fullname_div two_field">
                    <TextField require={true} disabled={disabled} credit={DirectorData} item={item} errorValidation={Error.Zip} type="text" name="zipCode" handleChange={onChange} value={DirectorData.creditDetails?.zipCode} label="Zip Code" uppercase />
                    <Dropdown require={true} disable={disabled} label="Country" name='country' credit={DirectorData} item={item} onChange={onChange} fpgerror={Error.Country} value={DirectorData.creditDetails?.country} uppercase options={COUNTRIES_LIST} DefaultOption={'Select Country'} />
                </div>
                <div className="sd_flex fullname_div two_field">
                    <TextField require={true} disabled={disabled} pattern="[0-9]*" credit={DirectorData} item={item} errorValidation={Error.Mobile} type="text" name="phoneNumber" handleChange={onChange} value={DirectorData.creditDetails?.phoneNumber} label="Phone Number" uppercase />
                    <Dropdown disable={disabled} credit={DirectorData} item={item} label="Phone Type" name='type' onChange={onChange} uppercase value={DirectorData.creditDetails?.type} options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
                </div>
                <div className="sd_flex fullname_div two_field">
                    <Dropdown require={true} disable={true} credit={DirectorData} item={item} label={`What is ${`${indieTopcredit?.name} ${index + 1}`}'s gender expression?`} fpgerror={Error.directorGenderExpression} name='directorGenderExpression' onChange={onChange} value={DirectorData.creditDetails?.directorGenderExpression} uppercase options={dropOption.directorGenderExpression} DefaultOption={'Select From Below'} />
                    <Dropdown require={true} disable={true} credit={DirectorData} item={item} label={`Does ${`${indieTopcredit?.name} ${index + 1}`} identify as Transgender?`} fpgerror={Error.directorIdentifyTransgender} name='directorIdentifyTransgender' onChange={onChange} value={DirectorData.creditDetails?.directorIdentifyTransgender} uppercase options={dropOption.directorIdentifyTransgender} DefaultOption={'Select From Below'} />
                </div>
                <div className="sd_flex fullname_div two_field">
                    <Dropdown require={true} disable={true} credit={DirectorData} item={item} label={`How would ${`${indieTopcredit?.name} ${index + 1}`} define their sexual orientation?`} fpgerror={Error.sexualOrientation} name='sexualOrientation' onChange={onChange} value={DirectorData.creditDetails?.sexualOrientation} uppercase options={dropOption.sexualOrientation} DefaultOption={'Select From Below'} />
                    <MultiSelect require={true} disable={true} credit={DirectorData} item={item} label={`Does ${`${indieTopcredit?.name} ${index + 1}`} identify as a person with a disability?`} fpgerror={Error.personWithDisability} name='personWithDisability' onChange={onChange} value={DirectorData.creditDetails?.personWithDisability} uppercase options={dropOption.personWithDisability} DefaultOption={'Select From Below'} />
                </div>
                <div className="sd_flex fullname_div two_field">
                    {DirectorData?.creditDetails?.personWithDisability && DirectorData?.creditDetails?.personWithDisability.length > 0 && DirectorData.creditDetails?.personWithDisability.some(_ => _.value === 'Other / Prefer to describe') && <TextField disabled={true} uppercase={true} credit={DirectorData} item={item} handleChange={onChange} value={DirectorData.creditDetails?.personWithDisabilityOther} name="personWithDisabilityOther" label="Other / Prefer to describe" />}
                    <Dropdown require={true} disable={true} credit={DirectorData} item={item} label={`In which country is  ${`${indieTopcredit?.name} ${index + 1}`} born?`} name='directorBornCountry' fpgerror={Error.directorBornCountry} onChange={onChange} value={DirectorData.creditDetails?.directorBornCountry} uppercase options={COUNTRIES_LIST} DefaultOption={'Select Country'} />
                </div>
                <div className="no_border">
                    <div className="question">
                        <div className="question_label">
                            <p className='required'>What is {pafType === 'New Frontier' ? `Lead Artist ${index + 1}` : pafType === 'Indie Series/Special Events' ? `${indieTopcredit?.name} ${index + 1}` : `Director${index + 1}`}’s race and/or ethnic origin?</p>
                            {/* {disabled ? '' : <span className="sd_textuppercase " onClick={() => selectAll(item, DirectorData)}>Check all that apply.</span>} */}
                        </div>
                        <div className="check_col_2 sd_flex sd_flexwrap">
                            {
                                ethnicOrigin.length > 0 && ethnicOrigin.map((Origin, i) => {
                                    // if (Origin.name === 'asian') Origin.isChecked = asian
                                    if (Origin.name === 'africanAmerican') Origin.isChecked = africanAmerican
                                    if (Origin.name === 'whiteEuropean') Origin.isChecked = whiteEuropean
                                    if (Origin.name === 'Hispaniclati') Origin.isChecked = Hispaniclati
                                    if (Origin.name === 'middleEastern') Origin.isChecked = middleEastern
                                    if (Origin.name === 'nativeAmerican') Origin.isChecked = nativeAmerican
                                    if (Origin.name === 'pacificIslanderorHawaiian') Origin.isChecked = pacificIslanderorHawaiian
                                    // if (Origin.name === 'biRacialMultiRacial') Origin.isChecked = biRacialMultiRacial
                                    // if (Origin.name === 'AmericanIndian') Origin.isChecked = AmericanIndian
                                    if (Origin.name === 'EastAsian') Origin.isChecked = EastAsian
                                    if (Origin.name === 'SouthAsian') Origin.isChecked = SouthAsian
                                    // if (Origin.name === 'NativeHawaiian') Origin.isChecked = NativeHawaiian
                                    if (Origin.name === 'SoutheastAsian') Origin.isChecked = SoutheastAsian
                                    if (Origin.name === 'other') Origin.isChecked = other
                                    if (Origin.name === 'declineToState') Origin.isChecked = declineToState
                                    return (
                                        <Checkbox disable={true} credit={DirectorData} item={item} type="checkbox" onChange={onChange} name={Origin?.name} label={Origin?.value} value={Origin.isChecked} checked={Origin.isChecked} id={`Origin_${index}${i}`} />
                                    )
                                })
                            }
                            {
                                !ethnicOrigin.find(_ => _.isChecked) && props.ShowOriginValidation && <span className="srv-validation-message" style={{ color: 'red' }}>This field is required.</span>
                            }
                            {/* <TextField disabled={disabled} credit={DirectorData} item={item} type="text" /> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="field_row_inner mb_0">
                        <label className="sd_textuppercase">
                        By providing Instagram, Facebook, X (formerly Twitter), and TikTok information, the Sundance Film Festival will consider it public and may tag our posts with this information.
                        </label>
                    </div>
                    <div className="sd_flex fullname_div two_field">
                        <TextField disabled={disabled} credit={DirectorData} item={item} type="text" placeholder='https://www.instagram.com/mymovie' name="instagram" handleChange={onChange} value={DirectorData.creditDetails?.instagram} label="Instagram" uppercase />
                        <TextField disabled={disabled} credit={DirectorData} item={item} type="text" placeholder='https://www.twitter.com/mymovie' name="twitter" handleChange={onChange} value={DirectorData.creditDetails?.twitter} label='X (Formerly "Twitter")' uppercase />
                        <TextField disabled={disabled} credit={DirectorData} item={item} type="text" placeholder='mymovie' name="tiktok" handleChange={onChange} value={DirectorData.creditDetails?.tiktok} label="Tiktok" uppercase />
                    </div>
                </div>
            </div>
        </div>
    )
}
let sub
const Step5 = () => {
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const dispatch = useDispatch();
    const history = useHistory()
    const pafType = useSelector(e => e.pafType);
    const apiServe = new service();
    const [SavedMessage, setSavedMessage] = useState(false)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [isNext, setIsNext] = useState(true)
    const [SubmitSuccess, setSubmitSuccess] = useState()
    const [isShare, setIsShare] = useState(false)
    const [isSubmitPopup, setisSubmitPopup] = useState(false)
    const [BioLengthPopup, setBioLengthPopup] = useState(false)
    const [formFive, setFormFive] = useState({
        credits: ProjectInfo.credits,
        indieTopcredit: ProjectInfo.indieTopcredit,
        indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
    })
    const [totalWord, setTotalWord] = useState({ count: 0, bioId: '' })
    const [BioLength, setBioLength] = useState(0)
    const [isSubmitted, setisSubmitted] = useState(ProjectInfo.submitNo)
    const [BioValidation, setBioValidation] = useState({value:false,id:''})
    const [failedMessage, setFailedMessage] = useState(false)
    const [ShowOriginValidation, setShowOriginValidation] = useState(false)
    useEffect(() => {
        setFormFive({
            credits: ProjectInfo.credits,
            indieTopcredit: ProjectInfo.indieTopcredit,
            indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
        })
        formFive.credits.forEach(item => {
            if (item.identifier === (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formFive.indieTopcredit?.identifier : 'director')) {
                item.creditType.forEach((credit) => {
                    credit.creditDetails = {
                        englishPhoneticProdununciation: credit.creditDetails.englishPhoneticProdununciation ? credit.creditDetails.englishPhoneticProdununciation : '',
                        preferredPronouns: credit.creditDetails.preferredPronouns ? credit.creditDetails.preferredPronouns : '',
                        firstFeature: credit.creditDetails.firstFeature ? credit.creditDetails.firstFeature : '',
                        bio: credit.creditDetails.bio ? credit.creditDetails.bio : '',
                        company: credit.creditDetails.company ? credit.creditDetails.company : '',
                        email: credit.creditDetails.email ? credit.creditDetails.email : '',
                        address1: credit.creditDetails.address1 ? credit.creditDetails.address1 : '',
                        address2: credit.creditDetails.address2 ? credit.creditDetails.address2 : '',
                        city: credit.creditDetails.city ? credit.creditDetails.city : '',
                        state: credit.creditDetails.state ? credit.creditDetails.state : '',
                        zipCode: credit.creditDetails.zipCode ? credit.creditDetails.zipCode : '',
                        country: credit.creditDetails.country ? credit.creditDetails.country : '',
                        phoneNumber: credit.creditDetails.phoneNumber ? credit.creditDetails.phoneNumber : '',
                        type: credit.creditDetails.type ? credit.creditDetails.type : '',
                        directorGenderExpression: credit.creditDetails.directorGenderExpression ? credit.creditDetails.directorGenderExpression : '',
                        directorIdentifyTransgender: credit.creditDetails.directorIdentifyTransgender ? credit.creditDetails.directorIdentifyTransgender : '',
                        sexualOrientation: credit.creditDetails.sexualOrientation ? credit.creditDetails.sexualOrientation : '',
                        personWithDisability: credit.creditDetails.personWithDisability ? credit.creditDetails.personWithDisability : '',
                        directorBornCountry: credit.creditDetails.directorBornCountry ? credit.creditDetails.directorBornCountry : '',
                        // asian: credit.creditDetails.asian === undefined ? false : credit.creditDetails.asian,
                        africanAmerican: credit.creditDetails.africanAmerican === undefined ? false : credit.creditDetails.africanAmerican,
                        whiteEuropean: credit.creditDetails.whiteEuropean === undefined ? false : credit.creditDetails.whiteEuropean,
                        Hispaniclati: credit.creditDetails.Hispaniclati === undefined ? false : credit.creditDetails.Hispaniclati,
                        middleEastern: credit.creditDetails.middleEastern === undefined ? false : credit.creditDetails.middleEastern,
                        nativeAmerican: credit.creditDetails.nativeAmerican === undefined ? false : credit.creditDetails.nativeAmerican,
                        pacificIslanderorHawaiian: credit.creditDetails.pacificIslanderorHawaiian === undefined ? false : credit.creditDetails.pacificIslanderorHawaiian,
                        // biRacialMultiRacial: credit.creditDetails.biRacialMultiRacial === undefined ? false : credit.creditDetails.biRacialMultiRacial,
                        other: credit.creditDetails.other === undefined ? false : credit.creditDetails.other,
                        declineToState: credit.creditDetails.declineToState === undefined ? false : credit.creditDetails.declineToState,
                        instagram: credit.creditDetails.instagram ? credit.creditDetails.instagram : '',
                        twitter: credit.creditDetails.twitter ? credit.creditDetails.twitter : '',
                        tiktok: credit.creditDetails.tiktok ? credit.creditDetails.tiktok : '',
                    }
                })
            }
        });
        forceUpdate()
        setisSubmitted(ProjectInfo.submitNo)
        // eslint-disable-next-line
    }, [ProjectInfo])
    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            Email: {  // name the rule
                message: 'Please Enter valid Email.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            City: {  // name the rule
                message: 'City is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            Province: {  // name the rule
                message: 'State/Province is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            Zip: {  // name the rule
                message: 'Zip is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            Phone: {  // name the rule
                message: 'The Phone must be a number.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            Country: {  // name the rule
                message: 'Country is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            directorGenderExpression: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            directorIdentifyTransgender: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            sexualOrientation: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            personWithDisability: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            directorBornCountry: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
        }
    }))
    const [allSelected, setAllSelected] = useState(false)

    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|allUpdate":
                    if (!response.error) {
                        dispatch({ type: 'FORM_DATA', payload: response.data })
                        setSavedMessage(true)
                        setTimeout(() => {
                            setSavedMessage(false)
                            setIsShare(false)
                        }, 1000);
                        setIsNext(true)
                    } else {
                        setIsNext(false)
                        setFailedMessage(true)
                        setTimeout(() => {
                            setFailedMessage(false)
                        }, 2000);
                    }
                    break;
                case "paf|project|submit":
                    if (!response.error) {
                        closeSubmitPopup()
                        setSubmitSuccess(true)
                        setTimeout(() => {
                            setSubmitSuccess(false)
                        }, 5000);
                    }
                    break;
                default:
                    break;
            }
        })
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

    const onDirectorChange = (e, CID, IID) => {
        console.log(e.target,"e.target");
        const { name, value, type, checked } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
        if (name === 'bio') {
            let index = formFive.credits.findIndex((e) => e._id === CID);
            let InIndex = formFive.credits[index].creditType.findIndex((e) => e._id === IID);
            
            let og = { ...formFive };
            let temp = [...formFive.credits];
            let tempTypes = [...formFive.credits[index].creditType];

            let total = tempTypes.reduce((acc, cur) => {
                let val = (cur._id === IID ? acc : (cur?.creditDetails?.bio?.match(/\S+/g)?.length || 0) + acc)
                return val
            }, 0);

            let newVal = value.trimStart()
            if(newVal.match(/^$|.*\S+.*/) === null){
                newVal = value.trim()
            }
            let len = newVal?.match(/\S+/g)
            len = len?.filter(_ => _ !== '');
            let newTotal = total + (len?.length || 0);
            if (newTotal <= 100) {
                if ((len?.length || 0) <= 50) {
                    tempTypes[InIndex].creditDetails = { ...tempTypes[InIndex].creditDetails, [name]: newVal };
                    setBioValidation({value:false,id:''})
                } else {
                    tempTypes[InIndex].creditDetails = { ...tempTypes[InIndex].creditDetails, bio: tempTypes?.[InIndex]?.creditDetails?.bio || "" };
                    setBioValidation({value:true,id:IID})
                }
                setTotalWord({count : newTotal, bioId : newTotal > 100 ? IID : ''})
            } else {
                setTotalWord({count : newTotal, bioId : newTotal > 100 ? IID : ''})
                tempTypes[InIndex].creditDetails = { ...tempTypes[InIndex].creditDetails, bio: tempTypes?.[InIndex]?.creditDetails?.bio || "" };
            }
            
            

            temp[index].creditType = tempTypes;
            og = { ...og, credits: [...temp] };
            setFormFive(og);
            return;
            // let len = value?.replace(/[\t\n\r\.\?\!]/gm, " ").split(' ')
            // len = len?.filter(_ => _ !== '');
            // if (len.length <= 60) {
            //     formFive.credits.forEach(item => {
            //         if (item._id === CID) {
            //             item.creditType.forEach((credit) => {
            //                 if (credit._id === IID) {
            //                     if (type === 'checkbox') {
            //                         console.log(e.target);

            //                     } else {
            //                         credit.creditDetails = {
            //                             ...credit.creditDetails,
            //                             [name]: value
            //                         }
            //                     }
            //                 }

            //             })
            //         }
            //     })
            //     setBioValidation(false)
            // } else setBioValidation(true)
        }
        else {
            console.log(CID, IID);
            formFive.credits.forEach(item => {
                if (item._id === CID) {
                    item.creditType.forEach((credit) => {
                        if (credit._id === IID) {
                            if (type === 'checkbox') {
                                console.log(e.target);
                                credit.creditDetails = {
                                    ...credit.creditDetails,
                                    [name]: checked,
                                }
                            } else {
                                if (name === 'country') {
                                    credit.creditDetails = {
                                        ...credit.creditDetails,
                                        [name]: value,
                                        // state: ''
                                    }
                                } else {
                                    credit.creditDetails = {
                                        ...credit.creditDetails,
                                        [name]: name === 'email' ? value.trim() : value
                                    }
                                }
                            }
                        }

                    })
                }
            })
        }
        forceUpdate()
    }


    const selectAll = (item, credit) => {
        let CID = item._id
        let IID = credit._id

        let newCredit = formFive.credits
        newCredit.forEach(item => {
            if (item._id === CID) {
                item.creditType.forEach((credit) => {
                    if (credit._id === IID) {

                        credit.creditDetails = {
                            ...credit.creditDetails,
                            // asian: !allSelected,
                            africanAmerican: !allSelected,
                            whiteEuropean: !allSelected,
                            Hispaniclati: !allSelected,
                            middleEastern: !allSelected,
                            nativeAmerican: !allSelected,
                            pacificIslanderorHawaiian: !allSelected,
                            // biRacialMultiRacial: !allSelected,
                            other: !allSelected,
                            declineToState: !allSelected,
                        }
                    }
                })
            }
        })

        setAllSelected(!allSelected)
        setFormFive({ ...formFive, credits: newCredit })
        // setadobecheck(newAdobelist)
        forceUpdate()
    }
    const onSubmitClick = (NAV) => {
        let BioLengths
        formFive.credits.forEach((item) => {
            if (item.identifier === (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formFive.indieTopcredit?.identifier : 'director')) {
                item.creditType.forEach((credit, index) => {
                    let lenG = credit.creditDetails.bio?.split(' ')
                    lenG = lenG?.filter(_ => _ !== '');
                    lenG = lenG?.length >= 0 ? lenG.length : 0
                    if (index !== 0) {
                        BioLengths = BioLengths + lenG
                    } else {
                        BioLengths = lenG
                    }
                })
            }
        })
        if (BioLengths >= 100) {
            setBioLengthPopup(true)

        } else {
            onFormSave(NAV)
            setisSubmitPopup(true)
            return true
        }

    }

    const checkStepValidate = () => {
        var race
        formFive?.credits.forEach((item) => {

            if (item.identifier === (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formFive.indieTopcredit?.identifier : 'director')) {
                item.creditType.forEach((credit, i) => {
                    race = [{ name: "africanAmerican", value: "Black, African American or of African descent", isChecked: credit.creditDetails.africanAmerican },
                    // { name: 'AmericanIndian', value: 'American Indian, Alaskan Native, or Indigenous', isChecked: AmericanIndian ? AmericanIndian : false },
                    { name: "nativeAmerican", value: "Native American or First Nations or Indigenous", isChecked: credit.creditDetails.nativeAmerican},
                    { name: 'EastAsian', value: 'East Asian', isChecked: credit.creditDetails.EastAsian },
                    { name: 'SouthAsian', value: 'South Asian', isChecked: credit.creditDetails.SouthAsian},
                    { name: 'pacificIslanderorHawaiian', value: 'Pacific Islander and/or Native Hawaiian', isChecked: credit.creditDetails.pacificIslanderorHawaiian},
                    { name: 'SoutheastAsian', value: 'Southeast Asian', isChecked: credit.creditDetails.SoutheastAsian },
                    { name: "whiteEuropean", value: "White, European, or of European descent", isChecked: credit.creditDetails.whiteEuropean },
                    { name: 'Hispaniclati', value: 'Hispanic, Latino/a, Latinx, Latine', isChecked: credit.creditDetails.Hispaniclati },
                    { name: "middleEastern", value: "Middle Eastern", isChecked: credit.creditDetails.middleEastern},
                    // { name: "pacificIslander", value: "Pacific Islander", isChecked: pacificIslander ? pacificIslander : false },
                    // { name: "biRacialMultiRacial", value: "Bi-racial or Multi-Racial", isChecked: biRacialMultiRacial ? biRacialMultiRacial : false },
                    { name: "other", value: "Other", isChecked: credit.creditDetails.other },
                    { name: "declineToState", value: "Decline to State", isChecked: credit.creditDetails.declineToState}]

                })
            }
        })
        // setShowOriginValidation(true)
        // let val = race.find(_ => _.isChecked)
        if (Validator.current.allValid() && !BioValidation ) {
            return true
        } else {
            Validator.current.showMessages()
            forceUpdate()
            setTimeout(() => {
                ErrorScroll()
            }, 500);
            return false

        }
    }
    const onFormSave = (NAV) => {
        let data = {
            ...formFive,
            savePageNo: '5',
            projectId: ProjectInfo._id
        }
        setFormFive((last) => {
            data = {
                ...data,
               ...last
            }
            return last
        });
        forceUpdate();
        if (NAV === 'NEXT') {
            data.completedStepNo = 5
        }else if(NAV === 'SHARE'){
            setIsShare(true)
        }
        console.log('in-=-=-=->');
        apiServe.sendevent('paf|project|allUpdate', data)

    }
    const onSubmitPopup = (e, STEP) => {
        if (Validator.current.allValid() && !BioValidation) {
            history.push(`${routeLocater(pafType)}-page${STEP + 1}`)
        } else {
            Validator.current.showMessages()
            forceUpdate()
        }
    }
    const closeBioLengthPopup = () => {
        setBioLengthPopup(false)
    }
    const closeSubmitPopup = () => {
        setisSubmitPopup(false)
    }

    const onNextPrev = () => {
        if (Validator.current.allValid()) {
            // onFormSave()
            return true
        } else {
            Validator.current.showMessages()
            forceUpdate()
            setTimeout(() => {
                ErrorScroll()
            }, 500);
            return false

        }
    }

    const closeSuccessMessage = () => {
        setSavedMessage(false)
    }
    const closeSubmit = () => {
        setSubmitSuccess(false)
    }
    const closeFailedMessage = () => {
        setFailedMessage(false)
    }
    const ethnicOriginValidation = (TYPE) => {
        //     if(TYPE === 'IF')  setOriginValidation(true)
        //     else setOriginValidation(false)
        //     console.log('hello');
    }
    return (
        <div>
            <Header />
            <FormHeader step={5} />
            {console.log(isShare,'isShare')}
            {!isShare && <SuccessMessage active={SubmitSuccess} close={closeSubmit} text={'Submission'} />}
            {!isShare && <SuccessMessage active={SavedMessage} close={closeSuccessMessage} text={'Save'} />}
            <FailedMessage active={failedMessage} close={closeFailedMessage} text={'Save'} />
            <div className="form_inner step_common  step_1">
                <div className="container">
                    <div className='underline'>
                        <div className='step sd_justbetween' >
                            <div className='step_no'>
                                <p>Page 5 of 9</p>
                            </div>
                            <div className='step_des'>
                                <p>After you submit at the end of Page 9, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons.</p>

                                <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival, and will be placed in the Sundance Institute Archives.</p>
                            </div>
                        </div>
                    </div>
                    <div className="associate_people pb_0">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">{pafType === 'New Frontier' ? 'Lead Artist(s)' : pafType === 'Indie Series/Special Events' ? 'Top Credit' : 'Director(s)'} Information
                                {/* <Tooltip
                                    id="director"
                                    customClass="tooltip-button tooltip-short-program"
                                    data="Film/Project Title"
                                    place="right"
                                    data_top={25}
                                /> */}
                            </h3>
                        </div>
                        {formFive?.credits.map((item) => {
                            return (
                                <>
                                    {item.identifier === (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formFive.indieTopcredit?.identifier : 'director') ? <>{
                                        item.creditType.map((credit, i) => {

                                            return (
                                                <div className='director sd_container_990'>
                                                    <DirectorInfo indieTopcredit={formFive.indieTopcredit} disabled={isSubmitted >= 2 ? true : false} ethnicOriginValidation={ethnicOriginValidation} ShowOriginValidation={ShowOriginValidation} DirectorData={credit} item={item} BioValidation={BioValidation} index={i} selectAll={selectAll} onChange={onDirectorChange} bioCount={totalWord} Error={{
                                                        Email: Validator.current.message(`email${i}`, credit.creditDetails.email, 'Email|email|required'),
                                                        Mobile: Validator.current.message('phone', credit.creditDetails.phoneNumber, 'Phone|numeric|required'),
                                                        City: Validator.current.message('City', credit.creditDetails.city, 'City|required'),
                                                        Province: Validator.current.message('Province', credit.creditDetails.state, credit.creditDetails.country === 'United States' || credit.creditDetails.country === 'Canada' ? 'Province|required' : ''),
                                                        Zip: Validator.current.message('Zip', credit.creditDetails.zipCode, 'Zip|required'),
                                                        Country: Validator.current.message('Country', credit.creditDetails.country, 'Country|required'),
                                                        directorGenderExpression: Validator.current.message('directorGenderExpression', credit.creditDetails.directorGenderExpression, 'directorGenderExpression|required'),
                                                        directorIdentifyTransgender: Validator.current.message('directorIdentifyTransgender', credit.creditDetails.directorIdentifyTransgender, 'directorIdentifyTransgender|required'),
                                                        sexualOrientation: Validator.current.message('sexualOrientation', credit.creditDetails.sexualOrientation, 'sexualOrientation|required'),
                                                        personWithDisability: Validator.current.message('personWithDisability', credit.creditDetails.personWithDisability, 'personWithDisability|required'),
                                                        directorBornCountry: Validator.current.message('directorBornCountry', credit.creditDetails.directorBornCountry, 'directorBornCountry|required'),
                                                    }} />
                                                </div>
                                            )
                                        })
                                    }</> : ''}
                                </>
                            )
                        })}

                    </div>
                    {/* {isSubmitted >= 2 ? <div className='mt_25'> </div> : <> <div className="privacy_policy_link">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Privacy Policy</h3>
                        </div>
                        <div className="ass_container_990 ">
                            <p>By signing below you are agreeing to <a href={`https://www.sundance.org/about/privacy-policy`} rel="noreferrer" target='_blank'>Sundance Institute’s Privacy Policy.</a></p>
                        </div>
                    </div>
                        <div className="electronic_sign">
                            <div className="form_inner_title">
                                <h3 className="sd_flex sd_aligncenter">Electronic Signature</h3>
                            </div>
                            <div className="ass_container_990 ">
                                <p>
                                    By providing this information, you are authorizing Sundance Institute to share the information with its supporters. By submitting the form you are confirming that Sundance Institute can use the electronic materials (film stills, director headshot, etc.) and information submitted for all publications and printed materials both before and after the Festival. Sundance Institute reserves the right to crop images as needed for print and web publications. Due to the intense nature of the Festival publication cycle, we are not able to submit edited materials to artists or their representatives for approval prior to publication.
                                </p>
                            </div>
                        </div></>} */}
                    {console.log(`BioLengthPopup`, BioLengthPopup)}
                    <Popup onSubmit={onSubmitPopup} open={BioLengthPopup} setOpen={closeBioLengthPopup} submitForm={true} okPopup={true} title=''>
                        <p>The combined length of all {pafType === 'New Frontier' ? <span style={{ fontSize: "24px" }}>Lead Artists</span> : pafType === 'Indie Series/Special Events' ? <span style={{ fontSize: "24px" }}>{formFive.indieTopcredit?.name}s</span> : <span style={{ fontSize: "24px" }}>Directors</span>} Bios must be less than 100 words. The combined length of all bios currently exceeds the limit of 100 words. Please edit bios to ensure the combined length of all bios does not exceed 100 words.</p>
                    </Popup>
                    <FormFooter step={5} onFormSave={onFormSave} isNext={isNext} checkValidate={onNextPrev} />
                    {/* {isSubmitted >= 2 ? <FormFooter step={5} /> : <FormSubmitFooter isNext={isNext} step={5} CounterStep={2} checkStepValidate={checkStepValidate} onSubmitClick={onSubmitClick} onSubmitPopup={onSubmitPopup} onFormSave={onFormSave} isSubmitPopup={isSubmitPopup} closeSubmitPopup={closeSubmitPopup} />} */}
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Step5