import React, { useEffect, useRef, useState } from 'react';
// Layouts
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
// PAF Header
import FormHeader from "../../../PAF/Components/FormHeader";
//close button
import { IoMdClose } from "react-icons/io"
import FormFooter from './../FormFooter';
import FullName from './../FullName';
import PlusFieldIcon from '../../../icons/PlusFieldIcon';
import RemoveFieldIcon from '../../../icons/RemoveFieldIcon';
import Dropdown from '../../../components/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SimpleReactValidator from 'simple-react-validator';
import service from '../../../common/service';
import SuccessMessage from './../SuccessMessage';
import FailedMessage from './../FailedMessage';

const AddCredit = (props) => {
    console.log(props)
    const ProjectGetDetail = useSelector(e => e.projectGetDetail);
    const pafType = useSelector(e => e.pafType);
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const dispatch = useDispatch();    
    const [addNewCredit] = useState({
        _id: new Date().getTime().toString(),
        creditId: '',
        creditLabel: '',
        identifier: '',
        creditType: [{
            _id: new Date().getTime().toString(),
            firstName: '',
            middleName: '',
            lastName: '',
            creditDetails: { email: '' }
        }]
    })
    const [NewCreditOption, setNewCreditOption] = useState([])
    useEffect(() => {
        let newcreditLabel = []
        let allCredit
        allCredit = pafType === 'New Frontier' ? ProjectGetDetail.newFrontierCreditLabel : ProjectGetDetail.filmcreditLabels
        ProjectInfo.credits.forEach((allCredit) => {
            newcreditLabel.push({
                _id: allCredit.creditId,
                name: allCredit.creditLabel,
                identifier: allCredit.identifier
            })
        })
        console.log(newcreditLabel, ProjectGetDetail.filmcreditLabels);

        function comparer(otherArray) {
            return function (current) {
                return otherArray.filter(function (other) {
                    return other._id === current._id && other.name === current.name
                }).length === 0;
            }
        }
        let onlyInA = allCredit.filter(comparer(newcreditLabel));
        let onlyInB = newcreditLabel.filter(comparer(allCredit));
        let result = onlyInA.concat(onlyInB);
        setNewCreditOption(result)
        // eslint-disable-next-line
    }, [])

    const { setOpen } = props
    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            CFirstLast: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CreditType: {  // name the rule
                message: 'Credit Type is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            }
        }
    }))


    const ChangeCredit = (e) => {
        console.log(e.target.value);
        let newCredit = addNewCredit
        let allCreditLabels
        allCreditLabels = pafType === 'New Frontier' ? ProjectGetDetail.newFrontierCreditLabel : ProjectGetDetail.filmcreditLabels
        allCreditLabels.forEach(ele => {
            if (ele._id === e.target.value) {
                newCredit.creditLabel = ele.name
                newCredit.creditId = e.target.value
                newCredit.identifier = ele.identifier
            }
        });
        forceUpdate()
    }
    const onAddClick = () => {

        if (Validator.current.allValid()) {
            let newProjectInfo = ProjectInfo
            newProjectInfo.credits.push(addNewCredit)

            dispatch({ type: 'FORM_DATA', payload: newProjectInfo })
            setOpen(false)
        } else {
            Validator.current.showMessages()
            forceUpdate()
        }
    }
    const onChangeCredit = (e, CID, IID) => {

        let newCredit = addNewCredit
        newCredit.creditType.forEach(data => {
            if (data._id === IID) {
                const { name, value } = e.target
                data[name] = value
            }
        })
        // setAddNewCredit({ ...addNewCredit, CreditType: { ...addNewCredit.CreditType, newCredit } })
        forceUpdate()
    }
    const handleCredit = (id, TYPE) => {
        let newCredit = addNewCredit
        if (TYPE === "ADD") {
            let newAdded = {
                _id: new Date().getTime().toString(),
                firstName: '',
                middleName: '',
                lastName: '',
                creditDetails: { email: '' }
            }
            newCredit.creditType.push(newAdded)
            console.log(props.creditCount, "============================", newCredit.creditType.length)
            forceUpdate()
        } else {
            newCredit.creditType = newCredit.creditType.filter(_ => _._id !== id)
            forceUpdate()
        }
    }
    const compare = (a, b) => {
        if (a.identifier < b.identifier) {
            return -1;
        }
        if (a.identifier > b.identifier) {
            return 1;
        }
        return 0;
    }
    Validator.current.purgeFields();
    return (
        <>
            <div className="sd_custom_popup">
                <div className="sd_popup_inner"  >
                    <div className='sd_close_popup' onClick={() => setOpen(false)}><span></span><span></span></div> : ''
                    <div className="sd_popup_header">
                        <h3>Add Additional Credit</h3>
                        {props.creditCount >= 24 && <span>The maximum number of credits that can be added is 24.</span>}
                    </div>
                    <>
                        <div className='underline change_margin'>
                            <div className="sd_popup_content">
                                <div className='addcredit_form'>
                                    <Dropdown name='Add Credit' options={NewCreditOption.sort(compare)} creditOption={true} value={addNewCredit.creditId} onChange={ChangeCredit} fpgerror={Validator.current.message('Add Credit', addNewCredit.creditId, 'CreditType|required')} DefaultOption={'Select Credit Type'} />
                                    {console.log(`addNewCredit.creditId`, addNewCredit.creditId)}
                                    {addNewCredit.creditType.map((credit, i) => (
                                        <>
                                            {console.log(addNewCredit.creditType.length + props.creditCount, "///")}
                                            <div className='addcredit'>
                                                <FullName credit={credit} item={addNewCredit} onChange={onChangeCredit} values={credit} index={i} />
                                                <div className="sd_flex add_remove_icon">
                                                    {addNewCredit.creditType.length + props.creditCount >= 24 ? '' : <span className="sd_p_cursor" style={{ cursor: addNewCredit?.creditType?.length >= 10 ? 'not-allowed' : 'pointer' }} onClick={addNewCredit.creditType?.length >= 10 ? '' : () => handleCredit(credit._id, 'ADD')}><PlusFieldIcon /></span>}
                                                    {i !== 0 && <span className="sd_p_cursor" onClick={() => handleCredit(credit._id, 'SUB')}><RemoveFieldIcon /></span>}
                                                </div>
                                            </div>
                                            <span style={{ color: 'red' }}>{Validator.current.message(`firstName${i}`, credit.firstName && credit.lastName, `CFirstLast|required`)}</span>
                                            {/* <span style={{ color: 'red' }}>{Validator.current.message('firstName', credit.firstName && credit.lastName, 'CFirstLast|required')}</span> */}

                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='sd_popup_buttons'>
                            <button onClick={(e) => { onAddClick(e) }}>Add</button>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}
const EditCredit = (props) => {
    const ProjectGetDetail = useSelector(e => e.projectGetDetail);
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const pafType = useSelector(e => e.pafType);
    const CopyProjectInfo = JSON.parse(JSON.stringify(ProjectInfo))
    const dispatch = useDispatch();
    const { SelectedCreditId, setOpen, SelectedCreditIdentifier } = props
    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            CFirstLast: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            }
        }
    }))
    const [creditData, setCreditData] = useState({})
    const [newCreditCount, setNewCreditCount] = useState()
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    useEffect(() => {
        ProjectInfo.credits.forEach((item) => {
            if (item.creditId === SelectedCreditId) {
                setCreditData(JSON.parse(JSON.stringify(item)))
                setNewCreditCount(props.creditCount - item.creditType.length)
            }
        })
        // eslint-disable-next-line
    }, [])


    const ChangeCredit = () => {
        console.log('hello');
    }
    const onChangeCredit = (e, CID, IID) => {

        let newCredit = creditData
        newCredit.creditType.forEach(data => {
            if (data._id === IID) {
                const { name, value } = e.target
                data[name] = value
            }
        })
        forceUpdate()
    }
    console.log(`creditData`, creditData)
    const handleCredit = (id, TYPE) => {
        let newCredit = creditData
        if (TYPE === "ADD") {
            let newAdded = {
                _id: new Date().getTime().toString(),
                firstName: '',
                middleName: '',
                lastName: '',
            }
            newCredit.creditType.push(newAdded)
            forceUpdate()
        } else {
            newCredit.creditType = newCredit.creditType.filter(_ => _._id !== id)
            forceUpdate()
        }

    }
    const onAddClick = () => {
        if (Validator.current.allValid()) {
            let newProjectInfo = ProjectInfo
            newProjectInfo.credits.forEach((item) => {
                if (item.creditId === SelectedCreditId) {
                    item.creditType = creditData.creditType
                }
            })
            dispatch({ type: 'FORM_DATA', payload: newProjectInfo })
            setOpen(false)
        } else {
            Validator.current.showMessages()
            forceUpdate()
        }
    }
    const onCloseClick = () => {
        dispatch({ type: 'FORM_DATA', payload: CopyProjectInfo })
        setOpen(false)
    }
    Validator.current.purgeFields();

    return (
        <>
            <div className="sd_custom_popup">
                <div className="sd_popup_inner"  >
                    <div className='sd_close_popup' onClick={() => onCloseClick()}><span></span><span></span></div> : ''
                    <div className="sd_popup_header">
                        <h3>Edit Additional Credit</h3>
                        {newCreditCount >= 24 && <span>The maximum number of credits that can be added is 24.</span>}
                    </div>

                    <>
                        <div className='underline change_margin'>
                            <div className="sd_popup_content">
                                <div className='addcredit_form'>
                                    <Dropdown disable={true} value={creditData?.creditId} creditOption={true} onChange={ChangeCredit} options={pafType === 'New Frontier' ? ProjectGetDetail.newFrontierCreditLabel : ProjectGetDetail.filmcreditLabels} />
                                    {creditData?.creditType?.map((credit, i) => {

                                        console.log(':::', credit);
                                        console.log(':::', SelectedCreditIdentifier);

                                        return (

                                            <>
                                                {console.log(`credit`, creditData.creditType.length, newCreditCount)}
                                                <div className='addcredit'>
                                                    <FullName credit={credit} item={creditData} onChange={onChangeCredit} values={credit} />
                                                    <div className="sd_flex add_remove_icon">
                                                        {(newCreditCount + creditData.creditType.length) >= 24 ? '' : (<span className="sd_p_cursor" onClick={() => handleCredit(credit._id, 'ADD')}><PlusFieldIcon /></span>)}
                                                        {i !== 0 && <span className="sd_p_cursor" style={{ cursor: creditData?.creditType?.length === 1 ? 'not-allowed' : 'pointer' }} onClick={creditData?.creditType?.length === 1 ? '' : () => handleCredit(credit._id, 'SUB')}><RemoveFieldIcon /></span>}
                                                    </div>
                                                </div>
                                                {<span style={{ color: 'red' }}>{Validator.current.message(`firstName${i}`, credit.firstName && credit.lastName, `CFirstLast|required`)}</span>}
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='sd_popup_buttons'>
                            <button onClick={(e) => { onAddClick(e) }}>Add</button>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}
let sub
const Step2 = () => {
    const [addCredit, setAddCredit] = useState(false)
    const [editCredit, setEditCredit] = useState(false)
    const [creditValue, setCreditValue] = useState({})
    const [creditIdentifier, setCreditIdentifier] = useState({})
    const pafType = useSelector(e => e.pafType);
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const dispatch = useDispatch();
    const [isNext, setIsNext] = useState(true)
    const [isShare, setIsShare] = useState(false)

    // const history = useHistory()
    const [failedMessage, setFailedMessage] = useState(false)
    const [SavedMessage, setSavedMessage] = useState(false)
    const ProjectGetDetail = useSelector(e => e.projectGetDetail);
    const apiServe = new service();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [formTwo, setFormTwo] = useState({
        credits: ProjectInfo.credits,
        indieTopcredit: ProjectInfo.indieTopcredit,
        indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
    })

    const [creditCounter, setCreditCounter] = useState(0)
    const [isSubmitted, setisSubmitted] = useState(ProjectInfo.submitNo)
    const [showMaxCredit, setShowMaxCredit] = useState(false)
    const [ShowCreditCounter, setShowCreditCounter] = useState(false)
    console.log(`formTwo`, formTwo)
    useEffect(() => {
        setFormTwo({
            credits: ProjectInfo.credits,
            indieTopcredit: ProjectInfo.indieTopcredit,
            indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
        })
        setisSubmitted(ProjectInfo.submitNo)
        // eslint-disable-next-line
    }, [ProjectInfo])

    useEffect(() => {
        getCreditCount()
        // eslint-disable-next-line
    }, [formTwo.credits])
    const getCreditCount = () => {
        // console.log(formTwo.credits, "formTwo.credits", creditCounter)
        let num = 0
        formTwo.credits.forEach((item, i) => {
            if (getCreditLabel(item.creditId) !== 'director' && getCreditLabel(item.creditId) !== 'screenwriter') {
                num = num + item.creditType.length
            }
        })
        console.log(num, "num...")
        setCreditCounter(num)
        return num
    }
    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|allUpdate":
                    if (!response.error) {
                        dispatch({ type: 'FORM_DATA', payload: response.data })
                        setIsNext(true)
                        setSavedMessage(true)
                        setTimeout(() => {
                            setSavedMessage(false)
                            setIsShare(false)
                        }, 1000);
                    } else {
                        setIsNext(false)
                        setFailedMessage(true)
                        setTimeout(() => {
                            setFailedMessage(false)
                        }, 2000);
                    }
                    break;
                default:
                    break;
            }
        })
        setisSubmitted(ProjectInfo.submitNo)
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])
    const getCredit = (CREDIT_TYPE) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let CreditLabelID = ProjectGetDetail.filmcreditLabels.find(_ => _.identifier === CREDIT_TYPE)
        // let ADD_LIMIT = CREDIT_TYPE === 'Director' ? 4 : CREDIT_TYPE === 'Cast' ? 6 : 3
        return formTwo.credits.map((item, i) => {
            return (
                <>
                    {item.creditId === CreditLabelID?._id ?
                        item.creditType.map((credit, i) => {
                            if (credit.firstName !== '') {
                                return (
                                    <>
                                        <p>{credit.firstName} {credit.middleName} {credit.lastName}</p>
                                    </>
                                )
                            }
                        })
                        : ''}
                </>)
        })
    }

    const ReachCreditLimit = () => {
        if (creditCounter >= 24) {
            setShowCreditCounter(true)
            setTimeout(() => {
                setShowCreditCounter(false)
            }, 3000);
        }
        setShowMaxCredit(true)
        setTimeout(() => {
            setShowMaxCredit(false)
        }, 3000);
        // console.log('jsdgfj');
    }

    const onDragEnd = (result) => {
        const { destination, source } = result;
        const oldIndex = source.index;
        const newIndex = destination.index;
        const arr = [...formTwo.credits];
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        const updateArr = [];
        arr.forEach((item, i) => {
            updateArr.push({
                _id: item._id,
                creditId: item.creditId,
                creditLabel: item.creditLabel,
                identifier: item.identifier,
                creditType: item.creditType,
                sortIndex: i + 1,
            });
        });
        ProjectInfo.credits = updateArr
        setFormTwo({ ...formTwo, credits: ProjectInfo.credits })
        // formTwo.credits = updateArr
    }

    const getCreditLabel = (ID) => {
        return ProjectGetDetail.filmcreditLabels.find(_ => _._id === ID)?.identifier
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _._id === ID)
        // return ProjectGetDetail.filmcreditLabels[creditIndex].name
    }

    const removeCredit = (e, id) => {
        ProjectInfo.credits = formTwo.credits.filter(_ => _._id !== id)
        setFormTwo({ ...formTwo, credits: ProjectInfo.credits })
        forceUpdate()
    }

    const sortCredit = (callBack) => {

        let sortAbleCredit = JSON.parse(JSON.stringify(ProjectInfo.credits))
        setFormTwo((last) => {
            sortAbleCredit = JSON.parse(JSON.stringify(last.credits))
            return last
        } )
        let sortedCredit = []
        let topTwoCredit = sortAbleCredit.filter(_ => _.identifier === 'director' || _.identifier === 'screenwriter')
        // let castCredit = sortAbleCredit.filter(_ => _.identifier === 'principalcast')
        let extraCredit = sortAbleCredit.filter(_ => _.identifier !== 'director' && _.identifier !== 'screenwriter')
        sortedCredit = [...topTwoCredit, ...extraCredit]
        callBack(sortedCredit)
    }

    const onFormSave = (NAV) => {
        sortCredit((sortedCredit) => {
            const data = {
                savePageNo: '2',
                credits: sortedCredit,
                projectId: ProjectInfo._id
            }
            if (NAV === 'NEXT') {
                data.completedStepNo = 2
            }else if(NAV === 'SHARE'){
                setIsShare(true)
            }
            apiServe.sendevent('paf|project|allUpdate', data)
        })
    }

    const editCreditValue = (e, data) => {
        console.log(data);
        setCreditValue(data.creditId)
        setCreditIdentifier(data.identifier)
        setEditCredit(true)
    }
    const closeSuccessMessage = () => {
        setSavedMessage(false)
    }
    const closeFailedMessage = () => {
        setFailedMessage(false)
    }
    console.log(creditCounter, "counter")
    return (
        <>
            <div className='step2'>
                <Header />
                <FormHeader step={2} />
                {!isShare && <SuccessMessage active={SavedMessage} close={closeSuccessMessage} text={'Save'} />}
                <FailedMessage active={failedMessage} close={closeFailedMessage} text={'Save'} />
                <div className='container'>
                    {/* <div className='underline'></div> */}
                    <div className='underline'>
                        <div className='step sd_justbetween' >
                            <div className='step_no'>
                                <p>Page 2 of 9</p>
                            </div>
                            <div className='step_des'>
                                <p>After you submit at the end of Page 9, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons. </p>

                                <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival and will be placed in the Sundance Institute Archives. </p>
                            </div>
                        </div>
                    </div>
                    <div className='underline'>
                        <div className='credits'>
                            <div className='inner_title'>
                                <p>Credits from Page 1 - Included in all publications, with select credits also included in the program announcement.</p>
                            </div>
                            {/* <div className='credit sd_flex'>
                                <div className='dest'>
                                    {'Director'}
                                </div>
                                <div className='details'>
                                    {getCredit('director')}
                                </div>
                            </div>
                            <div className='credit sd_flex'>
                                <div className='dest'>
                                    {'Screenwriter'}
                                </div>
                                <div className='details'>
                                    {getCredit('screenwriter')}
                                </div>
                            </div> */}
                            {
                                formTwo.credits.map((credit, i) => {
                                    if (credit.creditType[0]?.firstName !== '' && (credit.creditLabel === 'Director' || credit.creditLabel === 'Screenwriter')) {
                                        return (
                                            <div className='credit sd_flex'>
                                                <div className='dest'>
                                                    {credit.creditLabel}
                                                </div>
                                                <div className='details'>
                                                    {getCredit(credit.identifier)}
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className='underline'>
                        <div className='additional text_transform_unset'>
                            <div className='inner_title'>
                                <p>Additional Credits</p>
                            </div>
                            <div className='des'>
                                <div className="add_des">
                                For your convenience, any producers entered on Page 1 have been included here and may be edited and reordered. They apply to the total number of additional credits allowed, which is 10 credit titles (as in: types of credits “executive producer”, “co-producer”, “editor”, “cinematographer”, “additional cast”, etc) with a total of 24 individuals' names.
                                </div>
                                <div className='name_count'>Name Count {creditCounter}/24</div>
                            </div>
                            <div className='for_table text_transform_capitalize'>

                                {editCredit && <EditCredit SelectedCreditIdentifier={creditIdentifier} SelectedCreditId={creditValue} setOpen={setEditCredit} creditCount={creditCounter} />}
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <table  {...provided.droppableProps} ref={provided.innerRef}>
                                                <tbody>
                                                    {formTwo.credits.map((item, i) => {
                                                        // setCreditCounter(i - 3)
                                                        getCreditCount()
                                                        if (getCreditLabel(item.creditId) === (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formTwo.indieTopcredit?.identifier : 'director') || getCreditLabel(item.creditId) === (pafType === 'New Frontier' ? 'keycollaborator' : pafType === 'Indie Series/Special Events' ? formTwo.indieAdditionalTopCredit?.identifier : 'screenwriter') || ((pafType === 'Documentary Features' || pafType === 'Documentary Shorts') ? '' : getCreditLabel(item.creditId) === 'principalcast')) {
                                                            return ''
                                                        } else {
                                                            if (isSubmitted > 1) {
                                                                return (
                                                                    <tr >
                                                                        <td ></td>
                                                                        <td className='dest'>{item.creditLabel}</td>
                                                                        <td className='details'>{item.creditType?.map((credit, i) => (
                                                                            <p key={credit._id}>{credit.firstName} {credit.middleName} {credit.lastName}</p>
                                                                        ))}</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <>
                                                                        <Draggable key={item._id} draggableId={item._id} index={i}>
                                                                            {(provided, snapshot) => (
                                                                                <>
                                                                                    <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                        <td onClick={(e) => editCreditValue(e, item)}>EDIT</td>
                                                                                        <td className='dest'>{item.creditLabel}</td>
                                                                                        <td className='details'>{item.creditType?.map((credit, i) => {
                                                                                            return (
                                                                                                <p key={credit._id}>{credit.firstName} {credit.middleName} {credit.lastName}</p>
                                                                                            )
                                                                                        })}</td>
                                                                                        {console.log(`credit`, formTwo.credits)}
                                                                                        {/* <td> {pafType !== 'New Frontier' && item.identifier === 'producer' ? '' : <span onClick={(e) => removeCredit(e, item._id)} className='close_icon'><IoMdClose /></span>}</td> */}
                                                                                        <td> <span onClick={(e) => removeCredit(e, item._id)} className='close_icon'><IoMdClose /></span></td>
                                                                                        <td><img src="/images/dragger.png" alt='drag' /></td>
                                                                                    </tr>
                                                                                </>
                                                                            )}
                                                                        </Draggable>
                                                                    </>
                                                                )
                                                            }
                                                        }

                                                    })}
                                                </tbody>
                                            </table>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            {isSubmitted > 1 ? '' :
                                <div className='add_credit'>
                                    {/* <div className='icon'><PlusFieldIcon onClick={creditCounter >= 24 ? '' : () => pafType=== 'Indie Series/Special Events' ? formTwo.credits.length >= 12 ? ReachCreditLimit() : setAddCredit(true) : formTwo.credits.length >= 13 ? ReachCreditLimit() : setAddCredit(true)} style={{ cursor: creditCounter === 24 ? 'not-allowed' : 'auto' }} /></div> */}
                                    <div className='icon'><PlusFieldIcon onClick={creditCounter >= 24 ? '' : () => formTwo.credits.length >= 12 ? ReachCreditLimit() : setAddCredit(true)} style={{ cursor: creditCounter === 24 ? 'not-allowed' : 'auto' }} /></div>
                                    {addCredit && <AddCredit setOpen={setAddCredit} ProjectInfo={ProjectInfo} creditCount={creditCounter} setCreditCounter={setCreditCounter} setFormTwo={setFormTwo} />}
                                    <div onClick={creditCounter >= 24 ? '' : () => formTwo.credits.length >= 12 ? ReachCreditLimit() : setAddCredit(true)} className='text sd_p_cursor' style={{ cursor: creditCounter === 24 ? 'not-allowed' : 'pointer' }}>Add additional Credit Type</div>
                                </div>}

                            {showMaxCredit && <span className='mt_10 sd_width_full' style={{ color: 'red' }}>The maximum number of credit types that can be added is 10.</span>}
                            {ShowCreditCounter && <span className='sd_width_full' style={{ color: 'red' }}>The maximum number of credits that can be added is 24.</span>}

                        </div>
                    </div>
                    {/**
                     * cast is not displayed in documentary features and documentary shorts  
                     *  {pafType === 'Documentary Features' || pafType === 'Documentary Shorts' ? "" : <div className='underline change_margin'>
                        <div className='principal text_transform_unset'>
                            <div className='inner_title '>
                                <p className='text_transform_unset'>Principal Cast Member(s)</p>
                            </div>
                            <div className='des'>
                                <div className="add_des">
                                    This credit, entered on Page 1, is displayed below Additional Credits in all publications. 
                                </div>
                            </div>
                            <div className='credit sd_flex text_transform_capitalize'>
                                <div className='dest'>
                                    Cast
                                </div>
                                <div className='details'>
                                    {getCredit('principalcast')}
                                </div>
                            </div>
                        </div>
                    </div>} */}
                    <div className='footer'>
                        <FormFooter isNext={isNext} step={2} onFormSave={onFormSave} />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Step2
