import React from 'react';
import TextField from "../../components/TextField";
import Dropdown from "../../components/Dropdown";
import { COUNTRIES, STATE_PRO } from '../../common/constant';


const MainContactForm = (props) => {
    let COUNTRIES_LIST = COUNTRIES
    let STATE_LIST = STATE_PRO
    const { info, onChange, disabled, Errors,required } = props
    console.log(`required`, required)
    return (
        <>
        
            <div className="sd_flex top_2_field">
              
                <div className="sd_container_990">
                    <div className="sd_flex fullname_div sd_justbetween">
                        <TextField  require={true} name='firstName' handleChange={onChange} value={info.firstName} disabled={disabled} uppercase={true} errorValidation={Errors?.FirstName} label="First Name" required="false" />
                        <TextField   name='middleName' handleChange={onChange} value={info.middleName} disabled={disabled} uppercase={true} label="Middle Name" required="false" />
                        <TextField  require={true}  name='lastName' handleChange={onChange} value={info.lastName} disabled={disabled} uppercase={true} errorValidation={Errors?.LastName} label="Last Name" required="false" />
                    </div>
                </div >
            </div>
            <div className="sd_flex two_field">
                <TextField name='title' handleChange={onChange} value={info.title} disabled={disabled} uppercase="true" label="Title" required="false" />
                <TextField name='company' handleChange={onChange} value={info.company} disabled={disabled} uppercase="true" label="Company" required="false" />
            </div>
            <div className="sd_flex single_field">
                <TextField require={true} name='email' handleChange={onChange} value={info.email} disabled={disabled} uppercase="true" errorValidation={Errors?.Email} label="Email" required="false" />
            </div>
            <div className="sd_flex single_field">
                <TextField name='address1' handleChange={onChange} value={info.address1} disabled={disabled} uppercase="true" label="Address 1" required="false" />
            </div>
            <div className="sd_flex single_field">
                <TextField name='address2' handleChange={onChange} value={info.address2} disabled={disabled} uppercase="true" label="Address 2" required="false" />
            </div>
            <div className="sd_flex two_field">
               
                <TextField name='city'  handleChange={onChange} value={info.city} disabled={disabled} uppercase="true" label="City" required="false" />
                <Dropdown name='state' require={required}  options={STATE_LIST} onChange={onChange} fpgerror={info.country === 'United States' || info.country === 'Canada' ? Errors?.Province : ''} value={info.state} disable={disabled} uppercase="true" DefaultOption={'Select State'} label="State/Province"  />
                {/* {info.country === 'United States' || info.country === 'Canada' ?
                     :
                    <TextField name='state' errorValidation={info.country === 'United States' || info.country === 'Canada' ? Errors.Province : ''} handleChange={onChange} value={info.state} disabled={disabled} uppercase="true" label="State/Province" required="false" />} */}
            </div>
            <div className="sd_flex two_field">
                {console.log(`info.country`, info.country)}
                <TextField name='zipCode' handleChange={onChange} value={info.zipCode} disabled={disabled} uppercase="true" label="Zip Code" required="false" />
                <Dropdown name='country' options={COUNTRIES_LIST} onChange={onChange} value={info.country} disable={disabled} uppercase="true" DefaultOption={'Select Country'} label="Country" required="false" />
            </div>
            <div className="sd_flex two_field">
                {/* <TextField name='phoneNumber' handleChange={onChange} value={info.phoneNumber} disabled={disabled} errorValidation={Errors?.Mobile} uppercase="true" label="Phone Number" required="false" /> */}
                <TextField require={true} name='phoneNumber' errorValidation={Errors?.Mobile} handleChange={onChange} value={info.phoneNumber} disabled={disabled}  uppercase="true" label="Phone Number" required="false" />
                <Dropdown name='type' onChange={onChange} value={info.type} disable={disabled} label="Type" options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
            </div>
        </>
    )
}

export default MainContactForm