import React from 'react'

function TextArea(props) {
    const {label,name, require,value,handleChange,height,placeholder,uppercase,errorValidation,disabled} = props
    const ONCHANGE = (e) => {
        if (props.item && props.credit) {

            handleChange(e, props.item._id, props.credit._id)
        }
        else {
            handleChange(e)
        }
    }
    return (
        <div className="field_row_inner">
            {label ? <label className={`${require && 'required '}${uppercase && 'sd_textuppercase'}`}>{label} </label> : ""}
            <div className="field_input relative">
                <textarea name={name} disabled={disabled} onChange={(e) => ONCHANGE(e)} value={value} placeholder={placeholder} style={{ height: `${height ? height : "120px"}` }}>
                </textarea>
            
            </div>
            {errorValidation ? <span className="error" style={{ color: 'red' }} >{errorValidation}</span> : ""}
        </div>
    )
}

export default TextArea