import React from 'react'
import Slider from "react-slick";
import service from '../../common/service';
const PafPreview=(props)=> {
    const {ProjectInfo,ProjectDetail } = props
    const apiServe = new service()

    const getSliderImages = (images) => {
        // const { filmData } = this.props;

        return images && images.images && images.images.length > 0 && images.images.map((image, i) => {

            if (image.link === '' && image.inSite === '') {
                return false
            }
            else {
                return (
                    <div className="sd_film_desc_img" key={i}>
                        <img src={image.inSite ? apiServe.imageUrl + image.inSite : image.link} alt={images && images.title} title={images && images.title} />
                    </div>
                )
            }

        })
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    return (
        <div>
             <div className="sd_film_desc_header sd_film_detail_pg">
                    <div className="sd_inner_container">
                        <div className="sd_film_desc_sec">
                            <div className="sd_film_desc_img_slider">
                                <div className="">
                                    <Slider {...settings}>
                                        {getSliderImages(ProjectInfo)}
                                    </Slider>
                                </div>
                            </div>
                            <div className="sd_film_desc_wpr ">
                                <div className="sd_film_description">

                                    <h2 class="sd_textuppercase"> {ProjectInfo.article + '  '+ ProjectInfo.title} </h2>
                                    {/* <h2 class="sd_textuppercase"> {ProjectInfo.title} </h2> */}
                                    <div class="sd_film_description_content sd_rtf_content">
                                        <p>{ProjectInfo.synopsis}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* meet the artist */}
                <section className="sd_film_artists_section">
                    <div className="sd_inner_container">
                        <div className="sd_film_artists_sec sd_flex">
                            <div className='sd_film_artists_meet sd_film_artists_wpr'>
                                <div class="sd_film_artists_header"><h2 class="sd_textuppercase">Meet the Artist</h2></div>
                                {ProjectInfo?.credits?.map((item) => (
                                    <>
                                        {
                                            item.identifier === (ProjectInfo.pafType === 'New Frontier' ? 'leadartist' : ProjectInfo?.credits[0].identifier) ?
                                                item.creditType?.map((credit) => {
                                                    return (
                                                        <div className="sd_film_artists_meet_sec" style={{ marginBottom: 20 }}>
                                                            <div className="sd_panelist_block">
                                                                <div class="sd_panelist_img">
                                                                    <div class="sd_panelist_img_inner">
                                                                        <img src={credit.creditDetails.ProfileUrl ? apiServe.imageUrl + credit.creditDetails.ProfileUrl : "/images/user.svg"} alt="Panelist Name" title={credit.firstName} />
                                                                    </div>
                                                                </div>
                                                                <div class="sd_panelist_name"><h3 class="sd_textuppercase">{credit.firstName} {credit.middleName} {credit.lastName}</h3>
                                                                    <ul class="sd_panelist_social">
                                                                        {credit.creditDetails.twitter && <li className='sd_twitter_preview'> <a href={credit.creditDetails.twitter} target='_blank'  rel="noreferrer" > <img src={`${process.env.REACT_APP_IMAGE_PATH}sff_twitter_icon.svg`} alt="Twitter" title="Twitter" /> </a> </li>}
                                                                        {credit.creditDetails.instagram && <li> <a href={credit.creditDetails.instagram} target='_blank'  rel="noreferrer" >  <img src={`${process.env.REACT_APP_IMAGE_PATH}insta_black.svg`} alt="Instagram" title="Instagram" /> </a> </li>}
                                                                    </ul>
                                                                </div>
                                                                <div class="sd_panelist_desc"><div class="sd_rtf_content"><p>{credit.creditDetails.bio}</p></div></div>
                                                            </div>
                                                            {
                                                                credit?.creditDetails?.youtube ?
                                                                <div className="sd_film_artists_video sd_video_frame_wpr">
                                                                    <div className="sd_video_frame">
                                                                        <iframe src={credit?.creditDetails?.youtube} title="video title"></iframe>
                                                                    </div>
                                                                </div>
                                                                :''
                                                            }
                                                        </div>)
                                                })

                                                : ''}
                                        
                                    </>))}
                            </div>
                            {/* Credits */}
                            <div className="sd_film_artists_credits sd_film_artists_wpr">
                                <div className="sd_film_artists_credits_sec">
                                    <div className="sd_film_artists_header">
                                        <h2 className="sd_textuppercase">Credits</h2>
                                    </div>
                                   
                                    <ul>
                                        {ProjectInfo?.credits?.map((item) => (
                                            <>
                                                {item.creditType[0].firstName !== '' &&
                                                    <li><span class="sd_film_artists_cr_pos">{item.creditLabel}</span>
                                                        <div class="sd_film_artists_cr_name">
                                                            {item.creditType?.map((credit) => {
                                                                return (
                                                                    <>
                                                                        <p>{credit.firstName} {credit.middleName} {credit.lastName}</p>
                                                                    </>)
                                                            })}
                                                        </div>
                                                    </li>
                                                }
                                            </>))}
                                        {/* {ProjectInfo.projectInfo?.yearFinished && */}
                                            <li><span class="sd_film_artists_cr_pos">Year</span>
                                                <div class="sd_film_artists_cr_name">
                                                    <p>{ProjectInfo.projectInfo?.yearFinished}</p>
                                                </div>
                                            </li>
                                            {/* } */}
                                        {ProjectInfo?.category &&
                                            <li><span class="sd_film_artists_cr_pos">Category</span>
                                                <div class="sd_film_artists_cr_name">
                                                    <p>{ProjectDetail.filmCategoryData?.find(_ => _._id === ProjectInfo.category)?.name}</p>
                                                </div>
                                            </li>}
                                        {ProjectInfo?.countryOfOrigin ?
                                            <li><span class="sd_film_artists_cr_pos">Country</span>
                                                <div class="sd_film_artists_cr_name">
                                                    {ProjectInfo?.countryOfOrigin?.map(country => (

                                                        <p key={country.id}>{country.country}</p>
                                                    ))}
                                                </div>
                                            </li> : ''
                                        }

                                        {ProjectInfo?.projectInfo?.runTime ?
                                            <li><span class="sd_film_artists_cr_pos">Run time</span>
                                                <div class="sd_film_artists_cr_name">
                                                    <p>{ProjectInfo?.projectInfo?.runTime}</p>
                                                </div>
                                            </li> : ''}
                                        {/* {ProjectInfo?.publicContact?.company && */}
                                            <li><span class="sd_film_artists_cr_pos">Company</span>
                                                <div class="sd_film_artists_cr_name">
                                                    <p>{ProjectInfo?.publicContact?.company}</p>
                                                </div>
                                            </li>
                                            {/* } */}
                                        {ProjectInfo?.publicContact?.website &&
                                            <li><span class="sd_film_artists_cr_pos">Website </span>
                                                <div class="sd_film_artists_cr_name">
                                                    <p><a className='sd_credit_link_title' href={ProjectInfo?.publicContact?.website} target='_blank' rel="noreferrer">{ProjectInfo?.publicContact?.website}</a></p>
                                                </div>
                                            </li>}
                                        {ProjectInfo?.publicContact &&
                                            <li><span class="sd_film_artists_cr_pos">Contact</span>
                                                <div class="sd_film_artists_cr_name">
                                                    <p>{ProjectInfo?.publicContact?.firstName} {ProjectInfo?.publicContact?.middleName} {ProjectInfo?.publicContact?.lastName}</p>
                                                    <p>{ProjectInfo?.publicContact?.publicEmail}</p>
                                                </div>
                                            </li>}
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                </section>
        </div>
    )
}

export default PafPreview
