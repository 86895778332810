import React, { useEffect,  useState } from 'react';
// Layouts
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
// PAF Header
//close button

import {  useSelector } from 'react-redux';
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import PreviewFormFooter from './PreviewFormFooter';
import PreviewFormHeader from './PreviewFormHeader';
import service from '../../common/service';
import { useParams } from 'react-router-dom';



let sub
const Step2 = () => {
    const apiServe = new service();
    const { ProjectId } = useParams();
    const refcode = useSelector(e => e.refcode);
    const pafType = useSelector(e => e.SharedPafType);
    const ProjectGetDetail = useSelector(e => e.SharedGetDetail);
    const ProjectInfo = useSelector(e => e.SharedProjectInfo);
    const [formTwo, setFormTwo] = useState({
        credits: ProjectInfo.credits,
        indieTopcredit: ProjectInfo.indieTopcredit,
        indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
    })
    const [creditCounter, setCreditCounter] = useState(0)

    const onLoad = (e) => {
        apiServe.sendevent('paf|project|previewForm', { projectUserId: ProjectId })
        apiServe.sendevent('paf|project|CheckValidEmail', { projectUserId: ProjectId ,referralCode:refcode})
    }

    useEffect(() => {
        window.addEventListener('load',onLoad)
        return () => {
            window.removeEventListener('load',onLoad)
        }
    },[])
    
    useEffect(() => {
        setFormTwo({
            credits: ProjectInfo.credits,
            indieTopcredit: ProjectInfo.indieTopcredit,
            indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
        })
        // eslint-disable-next-line
    }, [ProjectInfo])

    useEffect(() => {
        getCreditCount()
        // eslint-disable-next-line
    }, [formTwo.credits])
    const getCreditCount = () => {
        // console.log(formTwo.credits, "formTwo.credits", creditCounter)
        let num = 0
        formTwo.credits.forEach((item, i) => {
            if (pafType === 'New Frontier') {
                if (getCreditLabel(item.creditId) !== 'leadartist' && getCreditLabel(item.creditId) !== 'keycollaborator' && getCreditLabel(item.creditId) !== 'principalcast') {
                    num = num + item.creditType.length
                }
            } else if (pafType === 'Documentary Features' || pafType === 'Documentary Shorts') {
                if (getCreditLabel(item.creditId) !== 'director' && getCreditLabel(item.creditId) !== 'screenwriter') {
                    num = num + item.creditType.length
                }
            } else {
                if (getCreditLabel(item.creditId) !== 'director' && getCreditLabel(item.creditId) !== 'screenwriter' && getCreditLabel(item.creditId) !== 'principalcast') {
                    if(pafType === 'Indie Series/Special Events'){
                        if(getCreditLabel(item.creditId) === (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formTwo.indieTopcredit?.identifier : 'director') || getCreditLabel(item.creditId) === (pafType === 'New Frontier' ? 'keycollaborator' : pafType === 'Indie Series/Special Events' ? formTwo.indieAdditionalTopCredit?.identifier : 'screenwriter') || ((pafType === 'Documentary Features' || pafType === 'Documentary Shorts') ? '' : getCreditLabel(item.creditId) === 'principalcast')){
                        } else {
                            num = num + item.creditType.length
                        }
                    } else {
                        num = num + item.creditType.length
                    }
                }
            }
        })
        console.log(num, "num...")
        setCreditCounter(num)
        return num
    }

    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])
    const getCredit = (CREDIT_TYPE) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let CreditLabelID = pafType === 'New Frontier' ? ProjectGetDetail.newFrontierCreditLabel.find(_ => _.identifier === CREDIT_TYPE) : ProjectGetDetail.filmcreditLabels.find(_ => _.identifier === CREDIT_TYPE)
        // let ADD_LIMIT = CREDIT_TYPE === 'Director' ? 4 : CREDIT_TYPE === 'Cast' ? 6 : 3
        return formTwo.credits.map((item, i) => {
            return (
                <>
                    {item.creditId === CreditLabelID?._id ?
                        item.creditType.map((credit, i) => {
                            return (
                                <>
                                    <p>{credit.firstName} {credit.middleName} {credit.lastName}</p>
                                </>
                            )
                        })
                        : ''}
                </>)
        })
    }



    const onDragEnd = (result) => {
        
    }

    const getCreditLabel = (ID) => {
        if (pafType === 'New Frontier') {
            return ProjectGetDetail.newFrontierCreditLabel.find(_ => _._id === ID)?.identifier
        } else {
            return ProjectGetDetail.filmcreditLabels.find(_ => _._id === ID)?.identifier
        }
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _._id === ID)
        // return ProjectGetDetail.filmcreditLabels[creditIndex].name
    }

    
    console.log(creditCounter, "counter")
    return (
        <>
            <div className='step2' id='downloadStep2'>
                <Header />
                <PreviewFormHeader step={2} />
                <div className='container'>
                    {/* <div className='underline'></div> */}
                    <div className='underline'>
                        <div className='step sd_justbetween'>
                            <div className='step_no'>
                                <p>Page 2 of {pafType === 'New Frontier' ? 8 : 9}</p>
                            </div>
                            <div className='step_des'>
                                <p>After you submit at the end of Page {pafType === 'New Frontier' ? 8 : 9}, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons. </p>

                                <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival and will be placed in the Sundance Institute Archives. </p>
                            </div>
                        </div>
                    </div>
                    <div className='underline'>
                        <div className='credits'>
                            <div className='inner_title'>
                                <p>Credits from Page 1 - Included in all publications, with select credits also included in the program announcement.</p>
                            </div>
                            <div className='credit sd_flex'>
                                <div className='dest'>
                                    {/* Director */}
                                    {pafType === 'New Frontier' ? 'Lead Artist' : pafType === 'Indie Series/Special Events' ? formTwo.indieTopcredit?.name : 'Director'}
                                </div>
                                <div className='details'>
                                    {
                                        pafType === 'New Frontier' ? getCredit('leadartist') : pafType === 'Indie Series/Special Events' ? getCredit(formTwo.indieTopcredit?.identifier) : getCredit('director')
                                    }
                                    {/* {getCredit('Director')} */}
                                </div>
                            </div>
                            <div className='credit sd_flex'>
                                <div className='dest'>
                                    {pafType === 'New Frontier' ? 'Key Collaborator' : pafType === 'Indie Series/Special Events' ? formTwo.indieAdditionalTopCredit?.name : 'Screenwriter'}
                                </div>
                                <div className='details'>
                                    {pafType === 'New Frontier' ? getCredit('keycollaborator') : pafType === 'Indie Series/Special Events' ? getCredit(formTwo.indieAdditionalTopCredit?.identifier) : getCredit('screenwriter')}
                                    {/* {getCredit('Screenwriter')} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='underline'>
                        <div className='additional text_transform_unset'>
                            <div className='inner_title'>
                                <p>Additional Credits</p>
                            </div>
                            <div className='des'>
                                <div className="add_des">
                                {pafType === 'Indie Series/Special Events' ? (formTwo.indieAdditionalTopCredit.identifier === 'executiveproducer' || formTwo.indieAdditionalTopCredit.identifier === 'coexecutiveproducer' || formTwo.indieTopcredit.identifier === 'coexecutiveproducer' || formTwo.indieTopcredit.identifier === 'executiveproducer') ? `For your convenience, any producers entered on Page 1 have been included here and may be edited and reordered. They apply to the total number of additional credits allowed, which is 10 credit titles (as in: types of credits “executive producer”, “co-producer”, “editor”, “cinematographer”, “additional cast”, etc) with a total of 24 individuals' names.` : pafType === 'New Frontier' ? `You may list 10 credit titltes with a total of 24 individuals' names, in the desired order for all places credits appear. ` : `For your convenience, any producers entered on Page 1 have been included here and may be edited and reordered. They apply to the total number of additional credits allowed, which is 10 credit titles with a total of 24 individuals' names.` : `For your convenience, any producers entered on Page 1 have been included here and may be edited and reordered. They apply to the total number of additional credits allowed, which is 10 credit titles (as in: types of credits “executive producer”, “co-producer”, “editor”, “cinematographer”, “additional cast”, etc) with a total of 24 individuals' names.`}
                                </div>
                                <div className='name_count'>Name Count {creditCounter}/24</div>
                            </div>
                            <div className='for_table text_transform_capitalize'>

                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <table  {...provided.droppableProps} ref={provided.innerRef}>
                                                <tbody>
                                                    {formTwo.credits.map((item, i) => {
                                                        // setCreditCounter(i - 3)
                                                        getCreditCount()
                                                        if (getCreditLabel(item.creditId) === (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formTwo.indieTopcredit?.identifier : 'director') || getCreditLabel(item.creditId) === (pafType === 'New Frontier' ? 'keycollaborator' : pafType === 'Indie Series/Special Events' ? formTwo.indieAdditionalTopCredit?.identifier : 'screenwriter') || ((pafType === 'Documentary Features' || pafType === 'Documentary Shorts') ? '' : getCreditLabel(item.creditId) === 'principalcast')) {
                                                            return ''
                                                        } else {

                                                            return (
                                                                <tr >
                                                                    <td ></td>
                                                                    <td className='dest'>{item.creditLabel}</td>
                                                                    <td className='details'>{item.creditType?.map((credit, i) => (
                                                                        <p key={credit._id}>{credit.firstName} {credit.middleName} {credit.lastName}</p>
                                                                    ))}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            )

                                                        }

                                                    })}
                                                </tbody>
                                            </table>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                    {pafType === 'Documentary Features' || pafType === 'Documentary Shorts' ? "" : <div className='underline change_margin'>
                        <div className='principal text_transform_unset'>
                            <div className='inner_title '>
                                <p className='text_transform_unset'>Principal Cast Member(s)</p>
                            </div>
                            <div className='des'>
                                <div className="add_des">
                                    This credit, entered on Page 1, is displayed below Additional Credits in all publications. 
                                </div>
                            </div>
                            <div className='credit sd_flex text_transform_capitalize'>
                                <div className='dest'>
                                    Cast
                                </div>
                                <div className='details'>
                                    {getCredit('principalcast')}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className='footer'>
                        <PreviewFormFooter step={2} rootId='downloadStep2'/>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Step2
