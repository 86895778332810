import React from 'react'

const FailedMessage = (props) => {
    const {active,close,text} = props
    return (
        <div>
            <div className={`text_center failed_form_msg ${active && "active"}`}>
                <p>{text} failed. <p>Please fill out all required fields and try again.</p><span className="sd_p_cursor close_success" onClick={close}>X</span></p>
            </div>
        </div>
    )
}

export default FailedMessage
