import React, { useState } from 'react'
import Dropdown from '../../components/Dropdown'
import { COUNTRIES } from '../../common/constant';
import Checkbox from '../../components/Checkbox';
import TextField from '../../components/TextField';
import MultiSelect from '../../components/MultiSelect';

const Demographics = (props) => {
    let COUNTRIES_LIST = COUNTRIES
    const { DirectorData, index, onChange, item, Error, disabled, selectAll, title, optionID } = props

    const dropOption = {
        preferredPronouns: ['He/Him', 'She/Her', 'They/Them', 'Other'],
        directorGenderExpression: ['Woman', 'Man', 'Non-Binary or Gender Non-conforming', 'Intersex', 'Other', 'Decline to State'],
        directorIdentifyTransgender: ['Yes', 'No', 'Other', 'Decline to State'],
        sexualOrientation: ['Heterosexual', 'Gay or Lesbian', 'Bisexual and/or Pansexual', 'Other', 'Decline to State'],
        personWithDisability: [{ value: '*', label: 'Choose all that apply' },{ label: 'Autism/ Neurodivergent', value: 'Autism/ Neurodivergent' },
        { label: 'Blind or low vision', value: 'Blind or low vision' },
        { label: 'Cognitive disability', value: 'Cognitive disability' },
        { label: 'Chronic illness / pain', value: 'Chronic illness / pain' },
        { label: 'd/Deaf or hard of hearing', value: 'd/Deaf or hard of hearing' },
        { label: 'Developmental disability', value: 'Developmental disability' },
        { label: 'Learning / Behavioral disability', value: 'Learning / Behavioral disability' },
        { label: 'Mental health condition', value: 'Mental health condition' },
        { label: 'Physical disability', value: 'Physical disability' },
        { label: 'Speech-related disability', value: 'Speech-related disability' },
        { label: 'Other / Prefer to describe', value: 'Other / Prefer to describe' },
        { label: 'No', value: 'No' },
        { label: 'Prefer not to say', value: 'Prefer not to say' }]
    }



    const { asian, africanAmerican, caucasian, Hispaniclati, middleEastern, nativeAmerican, pacificIslanderorHawaiian,whiteEuropean, biRacialMultiRacial, other, declineToState, AmericanIndian, EastAsian, SouthAsian, NativeHawaiian, SoutheastAsian } = DirectorData.creditDetails

    const [ethnicOrigin] = useState([
        { name: "africanAmerican", value: "Black, African American or of African descent", isChecked: africanAmerican ? africanAmerican : false },
        // { name: 'AmericanIndian', value: 'American Indian, Alaskan Native, or Indigenous', isChecked: AmericanIndian ? AmericanIndian : false },
        { name: "nativeAmerican", value: "Native American or First Nations or Indigenous", isChecked: nativeAmerican ? nativeAmerican : false },
        { name: 'EastAsian', value: 'East Asian', isChecked: EastAsian ? EastAsian : false },
        { name: 'SouthAsian', value: 'South Asian', isChecked: SouthAsian ? SouthAsian : false },
        { name: 'pacificIslanderorHawaiian', value: 'Pacific Islander and/or Native Hawaiian', isChecked: NativeHawaiian ? NativeHawaiian : false },
        { name: 'SoutheastAsian', value: 'Southeast Asian', isChecked: SoutheastAsian ? SoutheastAsian : false },
        { name: "whiteEuropean", value: "White, European, or of European descent", isChecked: whiteEuropean ? whiteEuropean : false },
        { name: 'Hispaniclati', value: 'Hispanic, Latino/a, Latinx, Latine', isChecked: Hispaniclati ? Hispaniclati : false },
        { name: "middleEastern", value: "Middle Eastern", isChecked: middleEastern ? middleEastern : false },
        // { name: "pacificIslander", value: "Pacific Islander", isChecked: pacificIslander ? pacificIslander : false },
        // { name: "biRacialMultiRacial", value: "Bi-racial or Multi-Racial", isChecked: biRacialMultiRacial ? biRacialMultiRacial : false },
        { name: "other", value: "Other", isChecked: other ? other : false },
        { name: "declineToState", value: "Decline to State", isChecked: declineToState ? declineToState : false },
    ])

    // const race = ['African American or of African Descent', 'American Indian,  Alaskan Native, or Indigenous', 'Caucasian/White', 'East Asian', 'Latinx or Hispanic', 'Middle Eastern', 'Native Hawaiian', 'Pacific Islander', 'Southeast Asian', 'South Asian', 'Other', 'Decline to state']
    return (
        <>
            <div className='sd_flex pb_10 f_bold'>
                <p className="form_sub_2_title" style={{ textTransform: 'capitalize' }}>{title} {index + 1}</p>

            </div>
            <div style={{ borderBottom: '2px solid #E1E0E0' }}></div>
            <div className='pt_10'>
                <div className="sd_flex fullname_div two_field">
                    <Dropdown disable={disabled} optionID={optionID ? optionID : false} require={true}  credit={DirectorData} item={item} onChange={onChange} value={DirectorData.creditDetails?.preferredPronouns} uppercase name='preferredPronouns' fpgerror={Error?.preferredPronouns} options={dropOption.preferredPronouns} DefaultOption={'Select From Below'} label="Pronouns"/>
                    <TextField disabled={disabled} uppercase={true} credit={DirectorData} item={item} handleChange={onChange} value={DirectorData.creditDetails?.englishPhoneticProdununciation} name="englishPhoneticProdununciation" label="English Phonetic Pronunciation" require="true" errorValidation={Error?.englishPhoneticProdununciation}/>

                </div>
                <div className="sd_flex fullname_div two_field">
                    <Dropdown disable={disabled} optionID={optionID ? optionID : false} require={true} credit={DirectorData} item={item} onChange={onChange} value={DirectorData.creditDetails?.directorGenderExpression} fpgerror={Error?.directorGenderExpression} label={`What is ${`${title} ${index + 1}`}'s gender expression?`} name='directorGenderExpression' uppercase options={dropOption.directorGenderExpression} DefaultOption={'Select From Below'} />
                    <Dropdown disable={disabled} optionID={optionID ? optionID : false} require={true} credit={DirectorData} item={item} onChange={onChange} value={DirectorData.creditDetails?.directorIdentifyTransgender} fpgerror={Error?.directorIdentifyTransgender} label={`Does ${`${title} ${index + 1}`} identify as Transgender?`} name='directorIdentifyTransgender' uppercase options={dropOption.directorIdentifyTransgender} DefaultOption={'Select From Below'} />
                </div>
                <div className="sd_flex fullname_div two_field">
                    <Dropdown disable={disabled} optionID={optionID ? optionID : false} require={true} credit={DirectorData} item={item} onChange={onChange} value={DirectorData.creditDetails?.sexualOrientation} fpgerror={Error?.sexualOrientation} label={`How would ${`${title} ${index + 1}`} define their sexual orientation?`} name='sexualOrientation' uppercase options={dropOption.sexualOrientation} DefaultOption={'Select From Below'} />
                    {/* <Dropdown disable={disabled} optionID={optionID ? optionID : false} require={true} credit={DirectorData} item={item} onChange={onChange} value={DirectorData.creditDetails?.personWithDisability} fpgerror={Error?.personWithDisability} label={`Does ${`${title} ${index + 1}`} identify as a person with a disability?`} name='personWithDisability' uppercase options={dropOption.personWithDisability} DefaultOption={'Select All that Apply'} /> */}
                    <MultiSelect disable={disabled} optionID={optionID ? optionID : false} require={true} credit={DirectorData} item={item} onChange={onChange} value={DirectorData.creditDetails?.personWithDisability} fpgerror={Error?.personWithDisability} label={`Does ${`${title} ${index + 1}`} identify as a person with a disability?`} name='personWithDisability' uppercase options={dropOption.personWithDisability} DefaultOption={'Select all that apply'} />


                </div>
                <div className="sd_flex fullname_div two_field">
                    {DirectorData?.creditDetails?.personWithDisability && DirectorData?.creditDetails?.personWithDisability.length > 0 && DirectorData.creditDetails?.personWithDisability.some(_ => _.value === 'Other / Prefer to describe') && <TextField disabled={disabled} uppercase={true} credit={DirectorData} item={item} handleChange={onChange} value={DirectorData.creditDetails?.personWithDisabilityOther} name="personWithDisabilityOther" label="Other / Prefer to describe" />}
                    <Dropdown disable={disabled} optionID={optionID ? optionID : false} require={true} credit={DirectorData} item={item} onChange={onChange} value={DirectorData.creditDetails?.directorBornCountry} fpgerror={Error?.directorBornCountry} label={`In which country is ${`${title} ${index + 1}`} born?`} name='directorBornCountry' uppercase options={COUNTRIES_LIST} DefaultOption={'Select Country'} />
                </div>
                <div className="no_border">
                    <div className="question">
                        <div className="question_label">
                            <p className='required'>What is {`${title}${index + 1}`}’s race and/or ethnic origin?</p>
                            {disabled ? '' : <span className="sd_textuppercase " onClick={() => selectAll(item, DirectorData)}>Check all that apply.</span>}
                        </div>
                        <div className="check_col_2 sd_flex sd_flexwrap">
                            {
                                ethnicOrigin.length > 0 && ethnicOrigin.map((Origin, i) => {
                                    if (Origin.name === 'asian') Origin.isChecked = asian
                                    if (Origin.name === 'africanAmerican') Origin.isChecked = africanAmerican
                                    if (Origin.name === 'caucasian') Origin.isChecked = caucasian
                                    if (Origin.name === 'Hispaniclati') Origin.isChecked = Hispaniclati
                                    if (Origin.name === 'middleEastern') Origin.isChecked = middleEastern
                                    if (Origin.name === 'nativeAmerican') Origin.isChecked = nativeAmerican
                                    if (Origin.name === 'pacificIslanderorHawaiian') Origin.isChecked = pacificIslanderorHawaiian
                                    if (Origin.name === 'whiteEuropean') Origin.isChecked = whiteEuropean
                                    // if (Origin.name === 'biRacialMultiRacial') Origin.isChecked = biRacialMultiRacial
                                    if (Origin.name === 'AmericanIndian') Origin.isChecked = AmericanIndian
                                    if (Origin.name === 'EastAsian') Origin.isChecked = EastAsian
                                    if (Origin.name === 'SouthAsian') Origin.isChecked = SouthAsian
                                    // if (Origin.name === 'NativeHawaiian') Origin.isChecked = NativeHawaiian
                                    if (Origin.name === 'SoutheastAsian') Origin.isChecked = SoutheastAsian
                                    if (Origin.name === 'other') Origin.isChecked = other
                                    if (Origin.name === 'declineToState') Origin.isChecked = declineToState

                                    return (
                                        <Checkbox disable={disabled} credit={DirectorData} item={item} type="checkbox" onChange={onChange} name={Origin?.name} label={Origin?.value} value={Origin.isChecked} checked={Origin.isChecked} id={`Origin_${index}${i}`} />
                                    )
                                })
                            }
                            {
                                !ethnicOrigin.find(_ => _.isChecked) && props.ShowOriginValidation && <span className="srv-validation-message" style={{ color: 'red' }}>This field is required.</span>
                            }
                            {/* <TextField disabled={disabled} credit={DirectorData} item={item} type="text" /> */}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}


export default Demographics