import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routeLocater } from '../../common/functions';
import RightBlackIcon from '../../icons/RightBlackIcon';
// import SuccessMessage from './SuccessMessage';


function FormHeader(props) {
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const [SubmitNo, setSubmitNo] = useState(ProjectInfo.submitNo)
    const [completedStepNo, setCompletedStepNo] = useState(ProjectInfo.completedStepNo)
    const pafType = useSelector(e => e.pafType);
    const history =  useHistory()

    useEffect(() => {
        setSubmitNo(ProjectInfo.submitNo)
        setCompletedStepNo(ProjectInfo.completedStepNo)
    }, [ProjectInfo])
    const RedirectTo = (STEP) =>{
        let subno
        if (ProjectInfo.submitNo === 1) subno = 1 
        if (ProjectInfo.submitNo === 2) subno = 9
        // if (ProjectInfo.submitNo === 3) subno = 9
        if (subno >= STEP) {
            history.push(`${routeLocater(pafType)}-page${STEP}`)
        }
    }
    return (
        <>
            <div className="form_header sd_relative sd_post_acception_form">
                <div className="sd_large_container">
                    <div className="sd_header_top_title ">
                        <h1 className="">Post Acceptance Form</h1>
                    </div>
                </div>
                {/* <div className="header_timezone sd_flex sd_aligncenter sd_flexwrap">
                    <span className="sd_textuppercase label_timezone" data-uw-styling-context="true">TIMEZONE</span>
                    <div className={`sd_p_cursor sd_flex sd_aligncenter select_timezone ${'active'}`}>
                        <input name="timezoneSelect" disabled="" value="India Standard Time" data-uw-styling-context="true" />
                        <span className="sd_p_cursor">
                            <svg width="11" height="6" viewBox="0 0 11 6" fill="none">
                                <path d="M1.29285 0.714355L5.75626 5.00007L10.0071 0.918437" stroke="white" stroke-width="1.218"></path>
                            </svg>
                        </span>
                        <ul>
                            <li data-uw-styling-context="true">Mountain Daylight Time</li>
                        </ul>
                    </div>
                </div> */}
            </div>
            <div className="container">
                <div className="form_steps_list">
                    <ul className={`sd_flex ${pafType === 'New Frontier' ?  'sd_justcenter' :''}`}>
                        {
                            <>
                                <li onClick={()=>RedirectTo(1)} className={`${props.step === 1 && `current`} ${completedStepNo >= 1 && `complete`}  ${'hover_Active'}`}><span>1</span><p>Program Announcement</p><RightBlackIcon /></li>
                                <li onClick={()=>RedirectTo(2)} className={`${props.step === 2 && `current`} ${completedStepNo >= 2 && `complete`}  ${ProjectInfo.submitNo >= 2 && 'hover_Active'}`}><span>2</span><p>Credits</p></li>
                                <li onClick={()=>RedirectTo(3)} className={`${props.step === 3 && `current`} ${completedStepNo >= 3 && `complete`} ${ProjectInfo.submitNo >= 2 && 'hover_Active'}`}><span>3</span><p>Project Info</p></li>
                                <li onClick={()=>RedirectTo(4)} className={`${props.step === 4 && `current`} ${completedStepNo >= 4 && `complete`} ${ProjectInfo.submitNo >= 2 && 'hover_Active'}`}><span>4</span><p>Contacts</p></li>
                                <li onClick={()=>RedirectTo(5)} className={`${props.step === 5 && `current`} ${(completedStepNo >= 5 || SubmitNo >= 2) && `complete`} ${ProjectInfo.submitNo >= 2 && 'hover_Active'}`}><span>5</span><p> {  pafType === 'New Frontier' ? 'Lead Artist(s)' : pafType === 'Indie Series/Special Events' ? 'Top Credit(s)' :  'Director(s)'}  Info</p></li>
                                <li onClick={()=>RedirectTo(6)} className={`${props.step === 6 && `current`} ${completedStepNo >= 6 && `complete`}  ${ProjectInfo.submitNo >= 2 && 'hover_Active'}`}><span>6</span><p>Team Info</p></li>
                                {pafType === 'New Frontier' ?  '' :  <li onClick={()=>RedirectTo(pafType === 'New Frontier' ? '' : 7)} className={`${props.step === 7 && `current`} ${completedStepNo >= 7 && `complete`} ${ProjectInfo.submitNo >= 2 && 'hover_Active'}`}><span>7</span><p>Audiences</p></li>}
                                <li onClick={()=>RedirectTo(pafType === 'New Frontier' ? 7 : 8)} className={`${props.step === 8 && `current`} ${completedStepNo >= 8 && `complete`}  ${ProjectInfo.submitNo >= 2 && 'hover_Active'}`}><span>{pafType === 'New Frontier' ?  '7'  : '8'}</span><p>History</p></li>
                                <li onClick={()=>RedirectTo(pafType === 'New Frontier' ? 8 : 9)} className={`${props.step === 9 && `current`} ${(completedStepNo >= 9 || SubmitNo >= 2) && `complete`}  ${ProjectInfo.submitNo >= 2 && 'hover_Active'}`}><span>{pafType === 'New Frontier' ?  '8' :'9'}</span><p>Production Questions</p><RightBlackIcon /></li>
                                {/* <li className={ProjectInfo.submitNo >= 1 && `complete`}><span>1</span><p>Program Announcement</p><RightBlackIcon /></li>
                                <li className={props.step===2 && `current`}><span>2</span><p>Credits</p></li>
                                <li className={props.step===3 && `current`}><span>3</span><p>Project Info</p></li>
                                <li className={props.step===4 && `current`}><span>4</span><p>Contacts</p></li>
                                <li className={`${props.step===5 && `current`} ${ProjectInfo.submitNo >=5 && `complete`}`}><span>5</span><p>Director(s)  Info</p><RightBlackIcon /></li>
                                <li className={props.step===6 && `current`}><span>6</span><p>Team Info</p></li>
                                <li className={props.step===7 && `current`}><span>7</span><p>Audiences</p></li>
                                <li className={props.step===8 && `current`}><span>8</span><p>History</p></li>
                                <li className={`${props.step===9 && `current`}  ${ProjectInfo.submitNo >=9 && `complete`}`}><span>9</span><p>Production Questions</p><RightBlackIcon /></li> */}
                            </>}
                    </ul>
                </div>
            </div>
        </>
    )
}
export default FormHeader