import React, { useEffect, useState } from 'react'
import FolderRight from '../../icons/FolderRight';
import ArrowIcon from '../../icons/ArrowIcon';
import ArrowIconBack from '../../icons/ArrowIconBack';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import PreviewIcon from '../../icons/PreviewIcon';
import ShareWithTeamIcon from '../../icons/ShareWithTeamIcon';
import { routeLocater } from '../../common/functions';
import ShareWithTeam from './ShareWithTeam';
import service from '../../common/service';
import SuccessMessage from './SuccessMessage';

let sub;
function FormFooter(props) {
    const history = useHistory();
    const apiServe = new service();
    const { checkValidate, onFormSave,isNext } = props
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const [isSubmitted, setisSubmitted] = useState(ProjectInfo.submitNo === 1 ? 1 : ProjectInfo.submitNo === 2 ?  9 : ProjectInfo.submitNo)
    const pafType = useSelector(e => e?.pafType);
    const [isShareWithTeam,setIsShareWithTeam] = useState(false)
    const [SavedMsg, setSavedMsg] = useState(false)
    const [failedMsg, setFailedMsg] = useState(false)

    useEffect(() => {
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|shareLinktoEmail":
                    if (!response.error) {
                        setSavedMsg(true)
                        setIsShareWithTeam(false)
                        onFormSave('SHARE')
                        setTimeout(() => {
                            setSavedMsg(false)
                        }, 1000);
                    } else {
                        setFailedMsg(true)
                        setTimeout(() => {
                            setFailedMsg(false)
                        }, 2000);
                    }
                    break;
                default:
                    break;
            }
        })
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

    const checkValidation = (NAV) => {
        if (props.step === 2 || props.step === 8) {
            // onFormSave()
            if (NAV === "NEXT") {
                onFormSave('NEXT')
               if (isNext)  {
                if (pafType === 'New Frontier' && props.step === 8) {
                    history.push(`/${routeLocater(pafType)}-page${props.step}`);
                } else {
                    history.push(`/${routeLocater(pafType)}-page${props.step + 1}`);
                }
               }
            //    if (NAV === "BACK") { 
            //     if (pafType === 'New Frontier') {
                    
            //         history.push(props.step === 8 ? `/${routeLocater(pafType)}-page${props.step - 2}` : `/${routeLocater(pafType)}-page${props.step - 1}`)
            //     } else {
            //         history.push(`/${routeLocater(pafType)}-page${props.step - 1}`);
            //     }   
            //    } 
            }
            else {
                onFormSave()
                if (pafType === 'New Frontier') {
                    history.push(props.step === 8 ? `/newFrontier-page${props.step - 2}` : `/newFrontier-page${props.step - 1}`)
                } else {
                    history.push(`/${routeLocater(pafType)}-page${props.step - 1}`);
                }
            }
            // if (NAV === "NEXT") history.push(`/${routeLocater(pafType)}-page${props.step + 1}`);
            // else history.push(`/${routeLocater(pafType)}-page${props.step - 1}`);
        } else {
            if (checkValidate()) {

                if (NAV === "NEXT") {
                    onFormSave('NEXT')
                    if (pafType === 'New Frontier') {
                        if (isNext) history.push(props.step === 8 ? `/newFrontier-page${props.step}` : `/newFrontier-page${props.step + 1}`)
                        // if (NAV === "BACK") history.push(props.step === 8 ? `/${routeLocater(pafType)}-page${props.step - 2}` : `/${routeLocater(pafType)}-page${props.step - 1}`)
                    } else {
                        if (isNext)  history.push(`/${routeLocater(pafType)}-page${props.step + 1}`);
                        // if (NAV === "BACK") history.push(`/${routeLocater(pafType)}-page${props.step - 1}`);
                    }
                    // history.push(`/${routeLocater(pafType)}-page${props.step + 1}`);
                }
                else {
                    onFormSave()
                    history.push(`/${routeLocater(pafType)}-page${props.step - 1}`);
                }

            }
        }
    }
    useEffect(() => {
        // setisSubmitted(ProjectInfo.submitNo)
        if (ProjectInfo.submitNo === 1) setisSubmitted(1)
        if (ProjectInfo.submitNo === 2) setisSubmitted(9)
    }, [ProjectInfo])

    const nextPrev = (NAV) => {
        if (NAV === 'NEXT') {
            if (pafType === 'New Frontier') {
                history.push(props.step === 8 ? `/newFrontier-page${props.step}` : `/newFrontier-page${props.step + 1}`)
            } else {
                history.push(`/${routeLocater(pafType)}-page${props.step + 1}`);
            }
        }
        else {
            if (props.step === 1) history.push(`film-information`);
            else {
                if (pafType === 'New Frontier') {
                    history.push((props.step === 8 || props.step === 9) ? `/newFrontier-page${props.step - 2}` : `/newFrontier-page${props.step - 1}`)
                } else {
                    history.push(`/${routeLocater(pafType)}-page${props.step - 1}`);
                }
            }
            // onFormSave()
        }
    }
    const onPreviewClick = (TYPE) => {
        
        
        if (TYPE === 'PREV') {
            onFormSave()
            window.open(`${process.env.REACT_APP_PAF_HOME}Preview/${ProjectInfo.projectUserId}`, '_blank')
        } else {
            // window.open(`${process.env.REACT_APP_PAF_HOME}${ProjectInfo.projectUserId}/shared-Step1`, '_blank')
            setIsShareWithTeam(true)
        }
    }
    return (
        <>
            {SavedMsg && <SuccessMessage step={props.step} active={SavedMsg} fullMessage={'Email Sent'} />}
            <div className='form_footer'>
                {isSubmitted >= props.step && <div className="left_side sd_p_cursor">
                    {(props.step === 1 || props.step === 5 || props.step === 9) && <>
                    <div className='icon ' onClick={() => onPreviewClick('PREV')}><PreviewIcon /></div> 
                    <div className='text mr_25'  onClick={() => onPreviewClick('PREV')}>Preview</div></>}
                    <div className='icon' onClick={() => onPreviewClick('SHARE')}><ShareWithTeamIcon /> </div>
                    <div className='text' onClick={() => onPreviewClick('SHARE')}>Share with your team</div>
                </div>}
                {isSubmitted >= props.step ? <div className='left_side sd_p_cursor'> </div> :
                    <div className='left_side sd_p_cursor' >
                        {props.step === 2 ? 
                        <>
                            <div className='icon ' onClick={() => onPreviewClick('PREV')}><PreviewIcon /></div> 
                            <div className='text mr_25'  onClick={() => onPreviewClick('PREV')}>Preview</div>
                        </> : 
                        <>
                            <div className='icon'  onClick={() => onFormSave()}><FolderRight /></div>
                            <div className='text mr_25'  onClick={() => onFormSave()}>Save</div>
                        </>}
                        
                        <div className='icon' onClick={() => onPreviewClick('SHARE')}><ShareWithTeamIcon /> </div><div className='text' onClick={() => onPreviewClick('SHARE')}>Share with your team</div>
                    </div>}

                <div className='right_side'>
                <div className='btn'><button className="sd_p_cursor" onClick={() => {  nextPrev('BACK') }}><ArrowIconBack className='back' />Back</button></div>
                    {/* <div className='btn'><button className="sd_p_cursor" onClick={() => { isSubmitted >= props.step ? nextPrev('BACK') : checkValidation() }}><ArrowIconBack className='back' />Back</button></div> */}
                    {props.step === 9 ? '' : <div className='btn'><button className="sd_p_cursor" onClick={() => { isSubmitted >= props.step ? nextPrev('NEXT') : checkValidation('NEXT') }}>Next<ArrowIcon className='next' /></button></div>}
                </div>
                {isShareWithTeam && <ShareWithTeam setOpen={setIsShareWithTeam} step={props.step} onFormSave={onFormSave}/>}
            </div>
        </>
    )
}

export default FormFooter
