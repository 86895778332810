import React, { useEffect, useRef, useState } from 'react';
// Layouts
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
// PAF Header
import FormHeader from "./../FormHeader";
import FormSubmitFooter from "./../FormSubmitFooter";
import TextField from "../../../components/TextField";
import Tooltip from "../../../components/Tooltip";
import Dropdown from "../../../components/Dropdown";
import PlusFieldIcon from "../../../icons/PlusFieldIcon";
import RemoveFieldIcon from '../../../icons/RemoveFieldIcon';
import FullName from "../../../PAF/Components/FullName";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { COUNTRIES } from '../../../common/constant'
import SimpleReactValidator from 'simple-react-validator';
import service from '../../../common/service';
// import ArrowIcon from '../../../icons/ArrowIcon';
import FormFooter from './../FormFooter';
import SuccessMessage from './../SuccessMessage';
import FailedMessage from './../FailedMessage';
import { ErrorScroll, routeLocater } from './../../../common/functions'
import Popup from './../Popup';
import DemographicsIndie from '../DemographicsIndie';


let sub
const IndieStep1 = () => {
    const COUNTRIES_LIST = COUNTRIES
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const dispatch = useDispatch();
    const history = useHistory()
    const ProjectGetDetail = useSelector(e => e.projectGetDetail);
    const pafType = useSelector(e => e.pafType);
    const apiServe = new service();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [isSubmitPopup, setisSubmitPopup] = useState(false)
    const [SavedMessage, setSavedMessage] = useState(false)
    const [failedMessage, setFailedMessage] = useState(false)
    const [loglineLength, setLoglineLength] = useState(0)
    const [isShare, setIsShare] = useState(false)
    const [isNext, setIsNext] = useState(true)
    const [isOldData, setIsOldData] = useState(true)
    const [isErrorPopup, setIsErrorPopup] = useState(false)
    const [oldDataCopy, setOldDataCopy] = useState([{
        _id: new Date().getTime().toString(),
        firstName: '',
        middleName: '',
        lastName: '',
        creditDetails: {
            email: '',
        }
    }])
    // console.log(`isErrorPopup`, isErrorPopup)
    const [isAddOldData, setIsaddOldData] = useState(true)
    const [oldAddDataCopy, setOldaddDataCopy] = useState([{
        _id: new Date().getTime().toString(),
        firstName: '',
        middleName: '',
        lastName: '',
        creditDetails: {
            email: '',
            preferredPronouns: '',
            englishPhoneticProdununciation: '',
            directorGenderExpression: '',
            directorIdentifyTransgender: '',
            sexualOrientation: '',
            personWithDisability: '',
            directorBornCountry: '',
            // asian: '',
            africanAmerican: '',
            whiteEuropean: '',
            Hispaniclati: '',
            middleEastern: '',
            nativeAmerican: '',
            pacificIslanderorHawaiian: '',
            // biRacialMultiRacial: '',
            other: '',
            // AmericanIndian: '',
            EastAsian: '',
            SouthAsian: '',
            // NativeHawaiian: '',
            SoutheastAsian: '',
            declineToState: '',
        }
    }])
    const [allSelected, setAllSelected] = useState(false)
    const [ShowOriginValidation, setShowOriginValidation] = useState(false)

    const [state, setSate] = useState({
        PremiereStatus: ['World Premiere', 'International Premiere', 'North American Premiere', 'U.S. Premiere', 'None'],
    })

    // const [indieFilmCreditLabels, setIndieFilmCreditLabels] = useState([])
    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            CFirstLast: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast1: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast2: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast3: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast4: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast_d: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast_S: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            CFirstLast_p: {  // name the rule
                message: 'First and Last Name are required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            title: {  // name the rule
                message: 'Title is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            logline: {  // name the rule
                message: 'Logline is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            filmProjectType: {  // name the rule
                message: 'Film / Project Type is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            country: {  // name the rule
                message: 'Country of Origin is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            indieTopcredit: {
                message: 'This Field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            indieAdditionalTopCredit: {
                message: 'This Field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            premiereStatus: {  // name the rule
                message: 'Premiere Status is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            required: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            topEng: {  // name the rule
                message: 'English Phonetic Pronuuciation is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
        }
    }))
    const [formOne, setFormOne] = useState({
        title: ProjectInfo.title,
        article: ProjectInfo.article,
        premiereStatus: ProjectInfo.projectInfo.premiereStatus,
        countryOfOrigin: ProjectInfo.countryOfOrigin,
        filmProjectType: ProjectInfo.filmProjectType,
        credits: ProjectInfo.credits,
        logline: ProjectInfo.logline,
        englishPhoneticProdununciation: ProjectInfo.englishPhoneticProdununciation,
        projectId: ProjectInfo._id,
        projectInfo:ProjectInfo.projectInfo,
        indieTopcredit: ProjectInfo.indieTopcredit,
        indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
    })
    const [temptop, setTemptop] = useState()
    const [tempadditionaltop, setTempadditionaltop] = useState()
    const [indieFilmCreditLabels, setIndieFilmCreditLabels] = useState([])
    const [indieFilmCreditLabelsAdditions, setIndieFilmCreditLabelsAdditions] = useState()
    const [tempcredittop, setTempcredittop] = useState([
        {
            _id: (new Date().getTime() + Math.floor(Math.random() * 1000000)).toString(),
            firstName: '',
            middleName: '',
            lastName: '',
            creditDetails: {
                email: '',
                preferredPronouns: '',
                englishPhoneticProdununciation: '',
                directorGenderExpression: '',
                directorIdentifyTransgender: '',
                sexualOrientation: '',
                personWithDisability: '',
                directorBornCountry: '',
                // asian: '',
                africanAmerican: '',
                whiteEuropean: '',
                Hispaniclati: '',
                middleEastern: '',
                nativeAmerican: '',
                pacificIslanderorHawaiian: '',
                // biRacialMultiRacial: '',
                other: '',
                // AmericanIndian: '',
                EastAsian: '',
                SouthAsian: '',
                // NativeHawaiian: '',
                SoutheastAsian: '',
                declineToState: '',
            }
        }
    ])
    const [tempcreditadditionaltop, setTempcreditadditionaltop] = useState([
        {
            _id: (new Date().getTime() + Math.floor(Math.random() * 1000000000)).toString(),
            firstName: '',
            middleName: '',
            lastName: '',
            creditDetails: {
                email: '',
                preferredPronouns: '',
                englishPhoneticProdununciation: '',
                directorGenderExpression: '',
                directorIdentifyTransgender: '',
                sexualOrientation: '',
                personWithDisability: '',
                directorBornCountry: '',
                // asian: '',
                africanAmerican: '',
                whiteEuropean: '',
                Hispaniclati: '',
                middleEastern: '',
                nativeAmerican: '',
                pacificIslanderorHawaiian: '',
                // biRacialMultiRacial: '',
                other: '',
                // AmericanIndian: '',
                EastAsian: '',
                SouthAsian: '',
                // NativeHawaiian: '',
                SoutheastAsian: '',
                declineToState: '',
            }
        }])

    const compare = (a, b) => {
        if (a.identifier < b.identifier) {
            return -1;
        }
        if (a.identifier > b.identifier) {
            return 1;
        }
        return 0;
    }
    const stringCompar = (elements) => {
        let arr = elements.sort((a, b) => a.identifier?.localeCompare(b.identifier));
        return arr
    }


    useEffect(() => {
        if (pafType === 'Indie Series/Special Events') {
            let newData = formOne.credits.length > 0 && stringCompar(formOne.credits)
            let newCreditData = ProjectGetDetail.filmcreditLabels.length > 0 && stringCompar(ProjectGetDetail.filmcreditLabels)
            let indieFilmCredit = []
            let indieFilmCreditAdditional = []
            // var filteredCreditLabels  = ProjectGetDetail.filmcreditLabels.filter(_=>_.identifier === 'creator' || 'director' || 'showrunner' || 'executiveproducer' || 'co-executiveproducer')
            newCreditData.forEach(item => {

                if (item.identifier === 'creator' || item.identifier === 'coexecutiveproducer' || item.identifier === 'director' || item.identifier === 'executiveproducer' || item.identifier === 'showrunner') {


                    indieFilmCredit.push(item)

                    // formOne.indieTopcredit = { ...indieFilmCreditLabels[0] }
                    if (formOne?.indieTopcredit?.identifier === '') {
                        let newc = newData.find(_ => _?.identifier === item?.identifier)
                        let newc2 = newCreditData.find(_ => _?.identifier === newc?.identifier)

                        if (newc) formOne.indieTopcredit = newc2;
                        else formOne.indieTopcredit = ProjectInfo.indieTopcredit
                        if (newc2) {
                            setTempcredittop(newc?.creditType)
                            setOldDataCopy([{
                                _id: (new Date().getTime() + Math.floor(Math.random() * 1000000)).toString(),
                                firstName: '',
                                middleName: '',
                                lastName: '',
                                creditDetails: {
                                    email: '',
                                    preferredPronouns: '',
                                    englishPhoneticProdununciation: '',
                                    directorGenderExpression: '',
                                    directorIdentifyTransgender: '',
                                    sexualOrientation: '',
                                    personWithDisability: '',
                                    directorBornCountry: '',
                                    // asian: '',
                                    africanAmerican: '',
                                    whiteEuropean: '',
                                    Hispaniclati: '',
                                    middleEastern: '',
                                    nativeAmerican: '',
                                    pacificIslanderorHawaiian: '',
                                    // biRacialMultiRacial: '',
                                    other: '',
                                    // AmericanIndian: '',
                                    EastAsian: '',
                                    SouthAsian: '',
                                    // NativeHawaiian: '',
                                    SoutheastAsian: '',
                                    declineToState: '',
                                }
                            }])
                            setIsOldData(false)
                        }
                        else setTempcredittop([{
                            _id: (new Date().getTime() + Math.floor(Math.random() * 1000000)).toString(),
                            firstName: '',
                            middleName: '',
                            lastName: '',
                            creditDetails: {
                                email: '',
                            }
                        }])
                        forceUpdate()
                    }

                }
                if (item.identifier === 'creator' || item.identifier === 'director' || item.identifier === 'coexecutiveproducer' || item.identifier === 'executiveproducer' || item.identifier === 'showrunner' || item.identifier === 'screenwriter') {
                    indieFilmCreditAdditional.push(item)
                    if (formOne.indieAdditionalTopCredit.identifier === '' && indieFilmCreditAdditional.length > 1) {
                        let newc = newData.find(_ => _?.identifier === item?.identifier)
                        let newc2 = newCreditData.find(_ => _?.identifier === newc?.identifier)
                        if (newc && formOne.indieTopcredit.identifier !== newc2.identifier) setFormOne({ ...formOne, indieAdditionalTopCredit: newc2 })
                        else formOne.indieAdditionalTopCredit = ProjectInfo.indieAdditionalTopCredit
                        if (newc2 && formOne.indieTopcredit.identifier !== newc.identifier) {
                            setTempcreditadditionaltop(newc?.creditType)
                            setOldaddDataCopy([{
                                _id: (new Date().getTime() + Math.floor(Math.random() * 10000)).toString(),
                                firstName: '',
                                middleName: '',
                                lastName: '',
                                creditDetails: {
                                    email: '',

                                }
                            }])
                            setIsaddOldData(false)
                        }
                        else {

                            //     setTempcreditadditionaltop([{
                            //     _id: (new Date().getTime() + Math.floor(Math.random() * 10000)).toString(),
                            //     firstName: '',
                            //     middleName: '',
                            //     lastName: '',
                            //     creditDetails: {
                            //         email: '',
                            //     }
                            // }])
                        }
                        forceUpdate()
                    }
                }
            })

            setIndieFilmCreditLabels(indieFilmCredit)
            setIndieFilmCreditLabelsAdditions(indieFilmCreditAdditional)

        }


        return () => {

        }
    }, [ProjectGetDetail, ProjectInfo, formOne])

    const [isSubmitted, setIsSubmitted] = useState(ProjectInfo.submitNo)
    const [loglineValidation, setLoglineValidation] = useState(false)
    useEffect(() => {
        let indieTopcredit = ProjectInfo.credits.find(_ => _._id === formOne.indieTopcredit._id)?.indieTopcredit
        if (indieTopcredit) {

            setTemptop(indieTopcredit)
        } else {

            setTemptop(temptop)
        }
        let indieAdditionalTopCredit = ProjectInfo.credits.find(_ => _._id === formOne.indieAdditionalTopCredit._id)?.indieAdditionalTopCredit
        if (indieAdditionalTopCredit) {

            setTempadditionaltop(indieAdditionalTopCredit)
        } else {

            setTempadditionaltop(tempadditionaltop)
        }
        let typeTOP = ProjectInfo.credits.find(_ => _.creditId === formOne.indieTopcredit._id)?.creditType
        let typeAdd = ProjectInfo.credits.find(_ => _.creditId === formOne.indieAdditionalTopCredit._id)?.creditType
        if (typeTOP) {
            setTempcredittop(typeTOP)
        } else {
            setTempcredittop(tempcredittop)

        }
        if (typeAdd) {
            setTempcreditadditionaltop(typeAdd)
        } else {

            setTempcreditadditionaltop(tempcreditadditionaltop)
        }




        setFormOne({
            title: ProjectInfo.title,
            premiereStatus: ProjectInfo.projectInfo.premiereStatus,
            article: ProjectInfo.article,
            countryOfOrigin: ProjectInfo.countryOfOrigin,
            filmProjectType: ProjectInfo.filmProjectType,
            credits: ProjectInfo.credits,
            logline: ProjectInfo.logline,
            englishPhoneticProdununciation: ProjectInfo.englishPhoneticProdununciation,
            projectId: ProjectInfo._id,
            projectInfo:ProjectInfo.projectInfo,
            indieTopcredit: ProjectInfo.indieTopcredit,
            indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
        })
        formOne.credits.forEach(item => {
            if (item.identifier === formOne.indieTopcredit.identifier) {
                item.creditType.forEach((credit) => {
                    credit.creditDetails = {
                        email: credit.creditDetails.email ? credit.creditDetails.email : '',
                        preferredPronouns: credit.creditDetails.preferredPronouns ? credit.creditDetails.preferredPronouns : '',
                        englishPhoneticProdununciation: credit.creditDetails.englishPhoneticProdununciation ? credit.creditDetails.englishPhoneticProdununciation : '',
                        directorGenderExpression: credit.creditDetails.directorGenderExpression ? credit.creditDetails.directorGenderExpression : '',
                        directorIdentifyTransgender: credit.creditDetails.directorIdentifyTransgender ? credit.creditDetails.directorIdentifyTransgender : '',
                        sexualOrientation: credit.creditDetails.sexualOrientation ? credit.creditDetails.sexualOrientation : '',
                        personWithDisability: credit.creditDetails.personWithDisability ? credit.creditDetails.personWithDisability : '',
                        directorBornCountry: credit.creditDetails.directorBornCountry ? credit.creditDetails.directorBornCountry : '',
                        bio: credit.creditDetails.bio ? credit.creditDetails.bio : '',
                        firstFeature: credit.creditDetails.firstFeature ? credit.creditDetails.firstFeature : '',
                        // asian: credit.creditDetails.asian === undefined ? false : credit.creditDetails.asian,
                        africanAmerican: credit.creditDetails.africanAmerican === undefined ? false : credit.creditDetails.africanAmerican,
                        whiteEuropean: credit.creditDetails.whiteEuropean === undefined ? false : credit.creditDetails.whiteEuropean,
                        Hispaniclati: credit.creditDetails.Hispaniclati === undefined ? false : credit.creditDetails.Hispaniclati,
                        middleEastern: credit.creditDetails.middleEastern === undefined ? false : credit.creditDetails.middleEastern,
                        nativeAmerican: credit.creditDetails.nativeAmerican === undefined ? false : credit.creditDetails.nativeAmerican,
                        pacificIslanderorHawaiian: credit.creditDetails.pacificIslanderorHawaiian === undefined ? false : credit.creditDetails.pacificIslanderorHawaiian,
                        // biRacialMultiRacial: credit.creditDetails.biRacialMultiRacial === undefined ? false : credit.creditDetails.biRacialMultiRacial,
                        other: credit.creditDetails.other === undefined ? false : credit.creditDetails.other,
                        // AmericanIndian: credit.creditDetails.AmericanIndian === undefined ? false : credit.creditDetails.AmericanIndian,
                        EastAsian: credit.creditDetails.EastAsian === undefined ? false : credit.creditDetails.EastAsian,
                        SouthAsian: credit.creditDetails.SouthAsian === undefined ? false : credit.creditDetails.SouthAsian,
                        // NativeHawaiian: credit.creditDetails.NativeHawaiian === undefined ? false : credit.creditDetails.NativeHawaiian,
                        SoutheastAsian: credit.creditDetails.SoutheastAsian === undefined ? false : credit.creditDetails.SoutheastAsian,
                        declineToState: credit.creditDetails.declineToState === undefined ? false : credit.creditDetails.declineToState,
                        city: credit.creditDetails.city ? credit.creditDetails.city : '',
                        country: credit.creditDetails.country ? credit.creditDetails.country : '',
                        state: credit.creditDetails.state ? credit.creditDetails.state : '',
                        zipCode: credit.creditDetails.zipCode ? credit.creditDetails.zipCode : '',
                        phoneNumber: credit.creditDetails.phoneNumber ? credit.creditDetails.phoneNumber : '',
                        type: credit.creditDetails.type ? credit.creditDetails.type : '',
                    }
                })
            }
        });
        setIsSubmitted(ProjectInfo.submitNo)
        forceUpdate()

        // eslint-disable-next-line
    }, [ProjectInfo])

    useEffect(() => {
        let newCredits = formOne.credits
        let defaultCredit = {
            _id: '',
            creditId: '',
            creditLabel: '',
            identifier: '',
            creditType: [{
                _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString(),
                firstName: '',
                middleName: '',
                lastName: '',
                creditDetails: {
                    email: '',
                    preferredPronouns: '',
                    englishPhoneticProdununciation: '',
                    directorGenderExpression: '',
                    directorIdentifyTransgender: '',
                    sexualOrientation: '',
                    personWithDisability: '',
                    directorBornCountry: '',
                    // asian: '',
                    africanAmerican: '',
                    whiteEuropean: '',
                    Hispaniclati: '',
                    middleEastern: '',
                    nativeAmerican: '',
                    pacificIslanderorHawaiian: '',
                    // biRacialMultiRacial: '',
                    other: '',
                    // AmericanIndian: '',
                    EastAsian: '',
                    SouthAsian: '',
                    // NativeHawaiian: '',
                    SoutheastAsian: '',
                    declineToState: '',
                    city: '',
                    country: '',
                    state: '',
                    zipCode: '',
                    phoneNumber: '',
                    type: '',
                }
            }]
        }
        let DirectorExist = pafType.toLowerCase() === 'new frontier' ? formOne.credits?.findIndex(element => element.identifier === 'leadartist') : formOne.credits?.findIndex(element => element.identifier === 'director');
        let ScreenWriterExist = pafType.toLowerCase() === 'new frontier' ? formOne.credits?.findIndex(element => element.identifier === 'keycollaborator') : formOne.credits?.findIndex(element => element.identifier === 'screenwriter');
        let ProducerExist = formOne.credits?.findIndex(element => element.identifier === 'producer')
        let CastExist = formOne.credits.findIndex(element => element.identifier === 'principalcast')

        if (pafType === 'Indie Series/Special Events') {
            // DirectorExist = 0
            DirectorExist = formOne.credits.findIndex(element => element.identifier === formOne.indieTopcredit.identifier)
            // ScreenWriterExist = 0
            ScreenWriterExist = formOne.credits.findIndex(element => element.identifier === formOne.indieAdditionalTopCredit.identifier)
        }

        let filmcreditLabels = pafType === 'New Frontier' ? JSON.parse(JSON.stringify(ProjectGetDetail.newFrontierCreditLabel)) : JSON.parse(JSON.stringify(ProjectGetDetail.filmcreditLabels))
        if (DirectorExist === -1) {
            let newD = JSON.parse(JSON.stringify(defaultCredit))
            newD._id = (new Date().getTime() + Math.floor(Math.random() * 1000000000)).toString()
            newD.creditId = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === "leadartist")._id : pafType === 'Indie Series/Special Events' ? formOne.indieTopcredit._id : filmcreditLabels.find(_ => _.identifier === 'director')._id
            newD.creditLabel = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === 'leadartist').name : pafType === 'Indie Series/Special Events' ? formOne.indieTopcredit.name : filmcreditLabels.find(_ => _.identifier === 'director').name
            newD.identifier = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === 'leadartist').identifier : pafType === 'Indie Series/Special Events' ? formOne.indieTopcredit.identifier : filmcreditLabels.find(_ => _.identifier === 'director').identifier
            formOne.credits.push(newD)
            setFormOne({ ...formOne, credits: newCredits })
        }
        if (ScreenWriterExist === -1) {
            let newS = JSON.parse(JSON.stringify(defaultCredit))
            newS._id = (new Date().getTime() + Math.floor(Math.random() * 100)).toString()
            newS.creditId = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === 'keycollaborator')._id : pafType === 'Indie Series/Special Events' ? formOne.indieAdditionalTopCredit._id : filmcreditLabels.find(_ => _.identifier === 'screenwriter')._id
            newS.creditLabel = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === 'keycollaborator').name : pafType === 'Indie Series/Special Events' ? formOne.indieAdditionalTopCredit.name : filmcreditLabels.find(_ => _.identifier === 'screenwriter').name
            newS.identifier = pafType === 'New Frontier' ? filmcreditLabels.find(_ => _.identifier === 'keycollaborator').identifier : pafType === 'Indie Series/Special Events' ? formOne.indieAdditionalTopCredit.identifier : filmcreditLabels.find(_ => _.identifier === 'screenwriter').identifier
            formOne.credits.push(newS)
            setFormOne({ ...formOne, credits: newCredits })
            // setFormOne({ ...formOne, credits: newCredits })
        }
        if (pafType !== 'New Frontier' && pafType !== 'Indie Series/Special Events') {
            if (ProducerExist === -1) {
                let newP = JSON.parse(JSON.stringify(defaultCredit))
                newP._id = (new Date().getTime() + Math.floor(Math.random() * 100000)).toString()
                newP.creditId = filmcreditLabels.find(_ => _.identifier === 'producer')._id
                newP.creditLabel = filmcreditLabels.find(_ => _.identifier === 'producer').name
                newP.identifier = filmcreditLabels.find(_ => _.identifier === 'producer').identifier
                formOne.credits.push(newP)
            }
            // setFormOne({ ...formOne, credits: newCredits })
        }
        if (pafType !== 'Documentary Features' && pafType !== 'Documentary Shorts') {
            if (CastExist === -1) {
                let newC = JSON.parse(JSON.stringify(defaultCredit))
                newC._id = (new Date().getTime() + Math.floor(Math.random() * 1000)).toString()
                newC.creditId = filmcreditLabels.find(_ => _.identifier === 'principalcast')?._id
                newC.creditLabel = filmcreditLabels.find(_ => _.identifier === 'principalcast')?.name
                newC.identifier = filmcreditLabels.find(_ => _.identifier === 'principalcast')?.identifier
                formOne.credits.push(newC)
                // setFormOne({ ...formOne, credits: newCredits })
            }
        }
        setIsSubmitted(ProjectInfo.submitNo)
        // eslint-disable-next-line
    }, [formOne.credits, formOne.indieTopcredit, formOne.indieAdditionalTopCredit])
    Validator.current.purgeFields();
    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|allUpdate":
                    if (!response.error) {

                        dispatch({ type: 'FORM_DATA', payload: response.data })
                        setSavedMessage(true)
                        setTimeout(() => {
                            setSavedMessage(false)
                            setIsShare(false)
                        }, 1000);
                        setIsNext(true)
                    } else {
                        setIsNext(false)
                        setFailedMessage(true)
                        setTimeout(() => {
                            setFailedMessage(false)
                        }, 2000);
                    }
                    break;
                default:
                    break;
            }
        })
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])
    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'logline') {
            let len = value?.replace(/[\t\n\r\\]/gm, " ").split(' ')
            len = len.filter(_ => _ !== '');
            if (len.length <= (pafType === 'Narrative Shorts' || pafType === 'Documentary Features' ? 40 : 50)) {
                setFormOne({ ...formOne, [name]: value });
                setLoglineValidation(false)
            } else setLoglineValidation(true)
        } else if(name === 'premiereStatus') {
            setFormOne({ ...formOne, projectInfo:{...formOne.projectInfo,[name]:value},[name]:value })
        } else {
            console.log(name,"name",value);
            setFormOne({ ...formOne, [name]: value })
        }
        forceUpdate()
    }
    const selectCountryOfOrigin = (e, id) => {
        let countries = formOne.countryOfOrigin
        countries.forEach(country => { if (country.id === id) country.country = e.target.value });
        setFormOne({
            ...formOne,
            countryOfOrigin: countries
        })
        // forceUpdate()
    }

    const closeSubmitPopup = () => {
        setisSubmitPopup(false)
    }

    const addCountry = () => {
        let countries = formOne.countryOfOrigin;
        var newAdded = {
            id: new Date().getTime().toString(),
            country: ''
        }
        countries.push(newAdded);
        setFormOne({
            ...formOne,
            countryOfOrigin: countries
        })
    }

    const removeCountry = (id) => {
        var countryTypes = formOne.countryOfOrigin.filter(_ => _.id !== id)
        setFormOne({
            ...formOne,
            countryOfOrigin: countryTypes
        })
    }

    const handleCredit = (id, CREDIT_TYPE, TYPE) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let CreditLabelID = pafType === 'New Frontier' ? ProjectGetDetail.newFrontierCreditLabel.find(_ => _.identifier === CREDIT_TYPE)._id : ProjectGetDetail.filmcreditLabels.find(_ => _.identifier === CREDIT_TYPE)._id
        formOne.credits.forEach(item => {
            if (item.creditId === CreditLabelID) {
                if (TYPE === "ADD") {
                    let newAdded = {
                        _id: new Date().getTime().toString(),
                        firstName: '',
                        middleName: '',
                        lastName: '',
                        creditDetails: {
                            email: '',
                            preferredPronouns: '',
                            englishPhoneticProdununciation: '',
                            directorGenderExpression: '',
                            directorIdentifyTransgender: '',
                            sexualOrientation: '',
                            personWithDisability: '',
                            directorBornCountry: '',
                            // asian: '',
                            africanAmerican: '',
                            whiteEuropean: '',
                            Hispaniclati: '',
                            middleEastern: '',
                            nativeAmerican: '',
                            pacificIslanderorHawaiian: '',
                            // biRacialMultiRacial: '',
                            other: '',
                            // AmericanIndian: '',
                            EastAsian: '',
                            SouthAsian: '',
                            // NativeHawaiian: '',
                            SoutheastAsian: '',
                            declineToState: '',
                        }
                    }
                    item.creditType.push(newAdded)
                } else {
                    item.creditType = item.creditType.filter(_ => _._id !== id)
                }
            }
        });
        forceUpdate()
    }


    const handleTopCredit = (id, CREDIT_TYPE, TYPE) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        if (TYPE === "ADD") {
            let newAdded = {
                _id: new Date().getTime().toString(),
                firstName: '',
                middleName: '',
                lastName: '',
                creditDetails: {
                    email: '',
                    preferredPronouns: '',
                    englishPhoneticProdununciation: '',
                    directorGenderExpression: '',
                    directorIdentifyTransgender: '',
                    sexualOrientation: '',
                    personWithDisability: '',
                    directorBornCountry: '',
                    // asian: '',
                    africanAmerican: '',
                    whiteEuropean: '',
                    Hispaniclati: '',
                    middleEastern: '',
                    nativeAmerican: '',
                    pacificIslanderorHawaiian: '',
                    // biRacialMultiRacial: '',
                    other: '',
                    // AmericanIndian: '',
                    EastAsian: '',
                    SouthAsian: '',
                    // NativeHawaiian: '',
                    SoutheastAsian: '',
                    declineToState: '',
                }
            }
            tempcredittop.push(newAdded)
        } else {

            var temp = tempcredittop.filter(_ => _._id !== id)
            setTempcredittop(temp)
        }
        forceUpdate()
    }




    const handleAdditionalTopCredit = (id, CREDIT_TYPE, TYPE) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        if (TYPE === "ADD") {
            let newAdded = {
                _id: new Date().getTime().toString(),
                firstName: '',
                middleName: '',
                lastName: '',
                creditDetails: {
                    email: '',
                }
            }
            tempcreditadditionaltop.push(newAdded)
        } else {

            var tempe = tempcreditadditionaltop.filter(_ => _._id !== id)
            setTempcreditadditionaltop(tempe)
        }
        forceUpdate()
    }

    const onChangeCredit = (e, CID, IID) => {

        let newCredit = formOne.credits
        newCredit.forEach(credit => {
            if (credit._id === CID) {
                credit.creditType.forEach(data => {
                    if (data._id === IID) {
                        const { name, value } = e.target
                        data[name] = value
                    }

                })

            }
        }
        );
        forceUpdate()
    }
    const onChangetopCredit = (e, IID) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let newCredit = tempcredittop
        newCredit.forEach(data => {
            if (data._id === IID) {
                const { name, value } = e.target
                data[name] = value
            }
        })

        setTempcredittop(newCredit)




        forceUpdate()
    }
    const onChangeadditionaltopCredit = (e, IID) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let newCredit = tempcreditadditionaltop

        newCredit.forEach(data => {

            if (data._id === IID) {

                const { name, value } = e.target
                data[name] = value
            }
        })
        setTempcreditadditionaltop(newCredit)
        forceUpdate()
    }
    const onSubmitPopup = (e, STEP) => {
        if (Validator.current.allValid() && !loglineValidation) {
            history.push(`${routeLocater(pafType)}-page${STEP + 1}`)
        } else {
            Validator.current.showMessages()
            forceUpdate()
        }
    }
    const checkStepValidate = () => {

        var race = []
        // console.log(formOne,"formOne", ProjectInfo, tempcredittop);
        formOne?.credits.forEach((item) => {

            if (item.identifier === formOne.indieTopcredit.identifier) {
                item.creditType = tempcredittop
                item.creditType.forEach((credit, i) => {
                    race = [{ name: "africanAmerican", value: "Black, African American or of African descent", isChecked: credit.creditDetails.africanAmerican },
                    // { name: 'AmericanIndian', value: 'American Indian, Alaskan Native, or Indigenous', isChecked: AmericanIndian ? AmericanIndian : false },
                    { name: "nativeAmerican", value: "Native American or First Nations or Indigenous", isChecked: credit.creditDetails.nativeAmerican},
                    { name: 'EastAsian', value: 'East Asian', isChecked: credit.creditDetails.EastAsian },
                    { name: 'SouthAsian', value: 'South Asian', isChecked: credit.creditDetails.SouthAsian},
                    { name: 'pacificIslanderorHawaiian', value: 'Pacific Islander and/or Native Hawaiian', isChecked: credit.creditDetails.pacificIslanderorHawaiian},
                    { name: 'SoutheastAsian', value: 'Southeast Asian', isChecked: credit.creditDetails.SoutheastAsian },
                    { name: "whiteEuropean", value: "White, European, or of European descent", isChecked: credit.creditDetails.whiteEuropean },
                    { name: 'Hispaniclati', value: 'Hispanic, Latino/a, Latinx, Latine', isChecked: credit.creditDetails.Hispaniclati },
                    { name: "middleEastern", value: "Middle Eastern", isChecked: credit.creditDetails.middleEastern},
                    // { name: "pacificIslander", value: "Pacific Islander", isChecked: pacificIslander ? pacificIslander : false },
                    // { name: "biRacialMultiRacial", value: "Bi-racial or Multi-Racial", isChecked: biRacialMultiRacial ? biRacialMultiRacial : false },
                    { name: "other", value: "Other", isChecked: credit.creditDetails.other },
                    { name: "declineToState", value: "Decline to State", isChecked: credit.creditDetails.declineToState}]
                })
            }
        })
        let val = race.length > 0 && race?.find(_ => _.isChecked)
        // console.log(Validator.current.allValid(), !loglineValidation, !isErrorPopup, race, Validator.current);


        if (Validator.current.allValid() && !loglineValidation && !isErrorPopup && val) {
            return true
        } else {
            setShowOriginValidation(true)
            Validator.current.showMessages()
            forceUpdate()
            setTimeout(() => {
                ErrorScroll()
            }, 500);
            return false
        }
    }



    const onSubmitClick = (NAV) => {
        if (Validator.current.allValid() && !loglineValidation) {
            let principalCast
            if (formOne.credits) {
                principalCast = formOne.credits.find(_ => _.identifier === 'principalcast').creditType
            }
            if (tempcredittop.length > 4 || tempcreditadditionaltop.length > 3 || principalCast.length > 6) {
                setIsErrorPopup(true)
            } else {
                setisSubmitPopup(true)
            }
            onFormSave(NAV)
            return true
        }
    }

    const CloseErrorPopup = () => {
        setIsErrorPopup(false)
    }

    const onFormSave = (NAV) => {
        
        let temT = JSON.parse(JSON.stringify(formOne.indieTopcredit))
        let temAT = JSON.parse(JSON.stringify(formOne.indieAdditionalTopCredit))
        let temTCopy = JSON.parse(JSON.stringify(formOne.indieTopcredit))
        let temATCopy = JSON.parse(JSON.stringify(formOne.indieAdditionalTopCredit))
        let credits_data = JSON.parse(JSON.stringify(formOne.credits))
        let tempcredit = tempcredittop
        let tempcreditadditional = tempcreditadditionaltop
        setTempcredittop((last) => {
            tempcredit = last
            return last
        })
        setTempcreditadditionaltop((last) => {
            tempcreditadditional = last
            return last
        })
        // console.log(formOne,"lasrt");
        setFormOne((last) => {
            temT = JSON.parse(JSON.stringify(last.indieTopcredit))
            temAT = JSON.parse(JSON.stringify(last.indieAdditionalTopCredit))
            temTCopy = JSON.parse(JSON.stringify(last.indieTopcredit))
            temATCopy = JSON.parse(JSON.stringify(last.indieAdditionalTopCredit))
            credits_data = JSON.parse(JSON.stringify(last.credits))
            return last
        })
        temT = {
            ...temT,
            creditType: tempcredit,
            creditId: temT._id,
            creditLabel: temT.name
        }
        temAT = {
            ...temAT,
            creditType: tempcreditadditional,
            creditId: temAT._id,
            creditLabel: temAT.name
        }
        // tempadditionaltop.creditType = tempcreditadditionaltop
        credits_data = credits_data.filter(_ => _.creditId !== temAT.creditId)
        credits_data = [ ...credits_data , {...temAT}]
        
        credits_data = credits_data.filter(_ => _.creditId !== temT.creditId)
        credits_data = [ ...credits_data , {...temT}]
        


        function removeDuplicates(arr, equals) {
            var originalArr = arr.slice(0);
            var i, len, val;
            arr.length = 0;

            for (i = 0, len = originalArr.length; i < len; ++i) {
                val = originalArr[i];
                if (!arr.some(function (item) { return equals(item, val); })) {
                    arr.push(val);
                }
            }
        }

        function thingsEqual(thing1, thing2) {
            return thing1.creditId === thing2.creditId
            // && thing1.name === thing2.name;
        }

        if (pafType === 'Indie Series/Special Events') {
            credits_data.filter((sub, i) => (sub.firstName !== '' || sub.lastName !== ''))

            credits_data = credits_data.filter(_ => _.creditType && _.creditType.length > 0)
            credits_data = credits_data.filter(_ => _.identifier !== '' && _.creditId !== '')

        }
        else {
            credits_data.forEach(item => item.creditType = item.creditType.filter((sub, i) => (sub.firstName !== '' || sub.lastName !== '')))

            credits_data = credits_data.filter(_ => _.creditType.length > 0)
        }


        credits_data = credits_data.filter(item => item.creditId !== credits_data.forEach(item => { return (item.creditId) }))

        credits_data = credits_data.reverse()


        removeDuplicates(credits_data, thingsEqual);
        let data = {
            ...formOne,
            savePageNo: '1',
            credits: credits_data,
            logline: formOne.logline.trim(),
            indieTopcredit:temTCopy,
            indieAdditionalTopCredit:temATCopy
        }
        // if (NAV === 'NEXT') {
            setFormOne((last) => {
                data = {
                    ...data,
                    ...last
                }
                return last
            });
        // }
        data.indieTopcredit = temTCopy
        data.indieAdditionalTopCredit = temATCopy
        data.credits = credits_data
        forceUpdate(); 
        if (NAV === 'SUBMIT') {
            data.completedStepNo = 1
            data.credits = data.credits.filter(item => (item.identifier !== 'creator' && item.identifier !== 'director' && item.identifier !== 'coexecutiveproducer' && item.identifier !== 'executiveproducer' && item.identifier !== 'showrunner' && item.identifier !== 'screenwriter'))
            data.credits.push(temT)
            data.credits.push(temAT)

            // item.identifier === formOne.indieTopcredit.identifier
            // item.identifier === formOne.indieAdditionalTopCredit.identifier 
            // item.identifier === 'principalcast'

            // })

        }
        if (NAV === 'NEXT') {
            data.completedStepNo = 1
        }else if(NAV === 'SHARE'){
            setIsShare(true)
        }
        apiServe.sendevent('paf|project|allUpdate', data)

    }

    const closeSuccessMessage = () => {
        setSavedMessage(false)
    }
    const closeFailedMessage = () => {
        setFailedMessage(false)
    }
    useEffect(() => {
        if (isOldData) {
            setOldDataCopy(tempcredittop)
        }
        if (isAddOldData) {
            setOldaddDataCopy(tempcreditadditionaltop)
        }
    }, [isOldData, isAddOldData])

    const topCreditChange = (e, TYPE) => {
        const { value } = e.target
        let tempMain = ProjectInfo.credits.find(_ => _.creditId === value)
        let mainCredit = ProjectGetDetail.filmcreditLabels.find(_ => _._id === value)
        if (isOldData) { setOldDataCopy(tempcredittop) }
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        if (tempMain && tempMain.creditType[0].firstName !== '' && tempMain.identifier !== formOne?.indieAdditionalTopCredit?.identifier) {
            setTempcredittop(tempMain.creditType)
            setIsOldData(false)
        } else {
            setTempcredittop(oldDataCopy)
            setIsOldData(true)
        }
        if (TYPE === 'TOP') {
            if (value !== formOne.indieAdditionalTopCredit._id && value !== '') {
                setFormOne({ ...formOne, indieTopcredit: mainCredit })
                setTemptop({ ...temptop, indieTopcredit: mainCredit })
            } else {
                setFormOne({ ...formOne, indieTopcredit: { identifier: '_', name: '', _id: '' } })
                setTemptop({ ...temptop, indieTopcredit: { identifier: '_', name: '', _id: '' } })

            }
        } else {
            if (value !== formOne.indieTopcredit._id && value !== '') {
                setFormOne({ ...formOne, indieAdditionalTopCredit: mainCredit })
                setTempadditionaltop({ ...tempadditionaltop, indieAdditionalTopCredit: mainCredit })
            } else {
                setFormOne({ ...formOne, indieAdditionalTopCredit: { identifier: '_', name: '', _id: '' } })
                setTempadditionaltop({ ...tempadditionaltop, indieAdditionalTopCredit: { identifier: '_', name: '', _id: '' } })
            }
        }
        forceUpdate()

    }

    // ----------------------------------------------------------
    const AdditionaltopCreditChange = (e, TYPE) => {
        const { value } = e.target
        let tempMain = ProjectInfo.credits.find(_ => _.creditId === value)
        let mainCredit = ProjectGetDetail.filmcreditLabels.find(_ => _._id === value)
        if (isAddOldData) {
            setOldaddDataCopy(tempcreditadditionaltop)
        }
        forceUpdate()
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        if (tempMain && tempMain.creditType[0].firstName !== '' && tempMain.identifier !== formOne.indieTopcredit.identifier) {
            setTempcreditadditionaltop(tempMain.creditType)
            setIsaddOldData(false)
        } else {
            setTempcreditadditionaltop(oldAddDataCopy)
            setIsaddOldData(true)
        }
        if (TYPE === 'TOP') {
            if (value !== formOne.indieAdditionalTopCredit._id && value !== '') {
                setFormOne({ ...formOne, indieTopcredit: mainCredit })
                setTemptop({ ...temptop, indieTopcredit: mainCredit })
            } else {
                setFormOne({ ...formOne, indieTopcredit: { identifier: '_', name: '', _id: '' } })
                setTemptop({ ...temptop, indieTopcredit: { identifier: '_', name: '', _id: '' } })
            }
        } else {

            if (value !== formOne.indieTopcredit._id && value !== '') {
                setFormOne({ ...formOne, indieAdditionalTopCredit: mainCredit })
                setTempadditionaltop({ ...tempadditionaltop, indieAdditionalTopCredit: mainCredit })
            } else {
                setFormOne({ ...formOne, indieAdditionalTopCredit: { identifier: '_', name: '', _id: '' } })
                setTempadditionaltop({ ...tempadditionaltop, indieAdditionalTopCredit: { identifier: '_', name: '', _id: '' } })
            }
        }
    }



    useEffect(() => {

        let len = formOne?.logline?.replace(/[\t\n\r\.\?\!]/gm, " ").split(' ')
        len = len?.filter(_ => _ !== '');
        setLoglineLength(len?.length)
    }, [formOne.logline])
    const getCredit = (CREDIT_TYPE, TYPE) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let ADD_LIMIT
        let TITLE
        if (TYPE === 'Top') {
            ADD_LIMIT = 4
            TITLE = formOne.indieTopcredit.name
        } else if (TYPE === 'Additional') {
            ADD_LIMIT = 3
            TITLE = formOne.indieAdditionalTopCredit.name
        } else {
            ADD_LIMIT = 6
            TITLE = 'principalcast'
        }
        return formOne.credits?.map((item, i) => {
            let CreditLabelID
            CreditLabelID = ProjectGetDetail?.filmcreditLabels?.find(_ => _.identifier === CREDIT_TYPE)?._id
            return (
                <>
                    {item.creditId === CreditLabelID ?
                        item.creditType.map((credit, i) => {
                            <FullName disabled={isSubmitted >= 1 ? true : false} credit={credit} item={item} onChange={onChangeCredit} values={credit} title={`${TITLE === 'principalcast' ? "Principal Cast Member" : pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : TITLE} ${pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? '  ' : i + 1}`} />

                            return (
                                <>
                                    <div key={credit._id} className="sd_flex sd_aligncenter associate_list">
                                        <FullName disabled={isSubmitted >= 1 ? true : false} credit={credit} item={item} onChange={onChangeCredit} values={credit} title={`${TITLE === 'principalcast' ? "Principal Cast Member" : pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : TITLE} ${pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? '  ' : i + 1}`} />
                                        {isSubmitted >= 1 ? '' : <div className="sd_flex add_remove_icon">
                                            {i + 1 === ADD_LIMIT ? '' : <span className='sd_p_cursor' style={{ cursor: (item.creditType.length > ADD_LIMIT - 1 ? 'not-allowed' : 'pointer'), marginTop: (item?.identifier !== 'principalcast' ? '15px' : '') }} onClick={() => item.creditType.length < ADD_LIMIT ? handleCredit(credit._id, CREDIT_TYPE, 'ADD') : ''}><PlusFieldIcon /></span>}
                                            {i !== 0 && < span className='sd_p_cursor' onClick={() => handleCredit(credit._id, CREDIT_TYPE, 'SUB')} > <RemoveFieldIcon /></span>}
                                        </div>}
                                    </div>

                                    {
                                        item.identifier === 'principalcast' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message(`CFirstLast${i}`, credit.firstName && credit.lastName, (credit.firstName || credit.lastName) ? `CFirstLast4` : '')}</span>
                                        // item.identifier === 'principalcast' && <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message('13', credit.firstName && credit.lastName, 'CFirstLast')}</span>
                                    }
                                </>
                            )
                        })
                        : ''}
                </>)
        })
    }
    const getindieCredit = (CREDIT_TYPE, TYPE) => {
        // const creditIndex = ProjectGetDetail.filmcreditLabels.findIndex(_ => _.name === CREDIT_TYPE)
        let ADD_LIMIT
        let TITLE

        if (pafType === 'Indie Series/Special Events') {
            if (TYPE === 'Top') {
                ADD_LIMIT = 4
                TITLE = formOne.indieTopcredit?.name
            } else if (TYPE === 'Additional') {
                ADD_LIMIT = 3
                TITLE = formOne?.indieAdditionalTopCredit?.name
            } else {
                ADD_LIMIT = 6
                TITLE = 'principalcast'
            }
        }
        else {
            ADD_LIMIT = CREDIT_TYPE === 'director' ? 4 : CREDIT_TYPE === 'screenwriter' ? 3 : 6
            TITLE = CREDIT_TYPE === 'director' ? 'Director' : CREDIT_TYPE === 'screenwriter' ? 'Screenwriter' : CREDIT_TYPE === 'producer' ? 'Producer' : 'principalcast'
        }


        return (
            <>
                {
                    TYPE === 'Top' && tempcredittop?.length >= 0 ? tempcredittop?.map((credit, i) => {

                        // <FullName disabled={isSubmitted >= 1 ? true : false} credit={credit}  onChange={onChangetopCredit} values={credit} title={`${TITLE === 'principalcast' ? "Principal Cast Member" :  pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ': TITLE} ${ pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast'? ' ':i + 1}`} />

                        return (
                            <>

                                <div key={credit._id} className="sd_flex sd_aligncenter associate_list">

                                    <FullName disabled={isSubmitted >= 1 ? true : false} credit={credit} onChange={onChangetopCredit} values={credit} title={`${TITLE === 'principalcast' ? "Principal Cast Member" : pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : TITLE} ${pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? '  ' : i + 1}`} />
                                    {isSubmitted >= 1 ? '' : <div className="sd_flex add_remove_icon">
                                        {i + 1 === ADD_LIMIT ? '' : <span className='sd_p_cursor' style={{ cursor: tempcredittop.length > ADD_LIMIT - 1 ? 'not-allowed' : 'pointer' }} onClick={() => tempcredittop.length < ADD_LIMIT ? handleTopCredit(credit._id, CREDIT_TYPE, 'ADD') : ''}><PlusFieldIcon /></span>}
                                        {i !== 0 && < span className='sd_p_cursor' onClick={() => handleTopCredit(credit._id, CREDIT_TYPE, 'SUB')} > <RemoveFieldIcon /></span>}
                                    </div>}
                                </div>
                                {i === 0 &&
                                    <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message(`c${i}`, credit.firstName && credit.lastName, 'CFirstLast_d')}</span>
                                }
                                {i !== 0 &&
                                    <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message(`CFirstLast${i}`, credit.firstName && credit.lastName, (credit.firstName || credit.lastName) ? `CFirstLast1` : '')}</span>
                                }
                            </>
                        )
                    }) : TYPE == 'Additional' && tempcreditadditionaltop?.length >= 0 ? tempcreditadditionaltop?.map((credit, i) => {

                        // console.log(`tempcreditadditionaltop`, tempcreditadditionaltop)
                        // <FullName disabled={isSubmitted >= 1 ? true : false} credit={credit} onChange={onChangeadditionaltopCredit} values={credit} title={`${TITLE === 'principalcast' ? "Principal Cast Member" : pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : TITLE} ${pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : i + 1}`} />

                        return (
                            <>
                                <div key={credit._id} className="sd_flex sd_aligncenter associate_list">
                                    <FullName disabled={isSubmitted >= 1 ? true : false} credit={credit} onChange={onChangeadditionaltopCredit} values={credit} title={`${TITLE === 'principalcast' ? "Principal Cast Member" : pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? ' ' : TITLE} ${pafType == 'Indie Series/Special Events' && TITLE !== 'principalcast' ? '  ' : i + 1}`} />
                                    {isSubmitted >= 1 ? '' : <div className="sd_flex add_remove_icon">
                                        {i + 1 === ADD_LIMIT ? '' : <span className='sd_p_cursor' style={{ cursor: tempcreditadditionaltop.length > ADD_LIMIT - 1 ? 'not-allowed' : 'pointer' }} onClick={() => tempcreditadditionaltop.length < ADD_LIMIT ? handleAdditionalTopCredit(credit._id, CREDIT_TYPE, 'ADD') : ''}><PlusFieldIcon /></span>}
                                        {i !== 0 && < span className='sd_p_cursor' onClick={() => handleAdditionalTopCredit(credit._id, CREDIT_TYPE, 'SUB')} > <RemoveFieldIcon /></span>}
                                    </div>}
                                </div>
                                {i === 0 &&
                                    <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message('+6546', credit.firstName && credit.lastName, 'CFirstLast_S')}</span>
                                }
                                {i !== 0 &&
                                    <span className="error_single sd_error_single" style={{ color: 'red' }}>{Validator.current.message(`CFirstLast$${i}`, credit.firstName && credit.lastName, (credit.firstName || credit.lastName) ? `CFirstLast2` : '')}</span>
                                }
                            </>
                        )
                    }) : ''
                }
            </>)

    }


    const selectAll = (credit) => {
        // let CID = item._id
        let IID = credit._id

        // let newCredit = tempcredittop
        // newCredit.forEach(item => {
        // if (item._id === CID) {
        tempcredittop.forEach((credit) => {
            if (credit._id === IID) {

                credit.creditDetails = {
                    ...credit.creditDetails,
                    // asian: !allSelected,
                    africanAmerican: !allSelected,
                    whiteEuropean: !allSelected,
                    Hispaniclati: !allSelected,
                    middleEastern: !allSelected,
                    nativeAmerican: !allSelected,
                    pacificIslanderorHawaiian: !allSelected,
                    // biRacialMultiRacial: !allSelected,
                    // AmericanIndian: !allSelected,
                    EastAsian: !allSelected,
                    SouthAsian: !allSelected,
                    // NativeHawaiian: !allSelected,
                    SoutheastAsian: !allSelected,
                    other: !allSelected,
                    declineToState: !allSelected,
                }
            }
        })
        // }
        // })

        setAllSelected(!allSelected)
        // setFormOne({ ...formOne, credits: newCredit })
        // setadobecheck(newAdobelist)
        forceUpdate()
    }

    const onDirectorDetailChange = (e, IID, NAME, OPTION = []) => {
        // debugger
        if (NAME === 'personWithDisability') {
            tempcredittop.forEach((credit) => {
                if (credit._id === IID) {
                    credit.creditDetails = {
                        ...credit.creditDetails,
                        [NAME]: (e.length > 0 &&  e.some(_ => _.value === '*')) ? OPTION.slice(1) : (e.length > 1 && e.some(_ => _.value === 'No') && e.findIndex(_ => _.value === 'No') !== 0) ? e.filter(_ => _.value === 'No') : (e.length > 1 && e.some(_ => _.value === 'No') && e.findIndex(_ => _.value === 'No') === 0) ? e.filter(_ => _.value !== 'No') : e
                    }
                }
            })
        } else {
            const { name, value, type, checked } = e.target
            if (!e.target.validity.valid) {
                return null;
            }
            tempcredittop.forEach((credit) => {
                if (type === 'checkbox' ? credit._id === IID.substr(0, IID.length - 2) : credit._id === IID) {
                    if (type === 'checkbox') {
                        console.log(e.target);
                        credit.creditDetails = {
                            ...credit.creditDetails,
                            [name]: checked,
                        }
                    } else {
                        if (name === 'country') {
                            credit.creditDetails = {
                                ...credit.creditDetails,
                                [name]: value,
                                // state: ''
                            }
                        } else {
                            credit.creditDetails = {
                                ...credit.creditDetails,
                                [name]: value
                            }
                        }
                    }
                }
            })
        }
        forceUpdate()
    }


    return (
        <div>
            <Header />
            <FormHeader step={1} />
            {!isShare && <SuccessMessage active={SavedMessage} close={closeSuccessMessage} text={'Save'} />}
            <FailedMessage active={failedMessage} close={closeFailedMessage} text={'Save'} />
            <div className="form_inner step_1 step_common">
                <div className="container">
                    <div className="f_step_1_heading sd_flex sd_justbetween">
                        <div className="f_step_1_left_title">
                            {/* <h1>Program Announcement Information</h1>
                            <p> This information will be used in our public program announcement. Additional credits may be added in the next step.</p> */}
                             <h2>Please have all of your film/project information with you before you begin.</h2>
                        </div>
                        <div className="f_step_1_right_desc">
                            <h5>Program Announcement Information</h5>
                            <p>This information will be used in our public program announcement. Additional credits may be added in the next step.</p>
                            <p>After you submit each step, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons.</p>
                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival, and will be placed in the Sundance Institute Archives.</p>
                        </div>
                    </div>
                    <div className="film_deatil_form sd_detail_form">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Film/Project Title </h3>
                        </div>
                        <div className="film_deatil_form_inner">
                            <div className="sd_flex f_detail_f_row sd_justbetween">
                                <TextField disabled={isSubmitted >= 1 ? true : false} uppercase={true} label="article" name="article" value={formOne.article} handleChange={handleChange} required="false" />
                                <TextField disabled={isSubmitted >= 1 ? true : false} uppercase={true} label="Title" name="title" value={formOne.title} handleChange={handleChange} errorValidation={Validator.current.message('title', formOne.title, 'title|required')} require="true" desc={''} />
                            </div>
                            <p className='mb_25'>Please submit your project title in Title Case (first letter of each word capitalized, minor words are lowercase). We do not write film titles in all-capitals unless it is an intentional stylistic choice.</p>
                            <div className="sd_flex f_detail_s_row sd_justbetween">
                                <Dropdown disable={isSubmitted >= 1 ? true : false} uppercase={true} name="filmProjectType" onChange={handleChange} label="Film/Project Type" fpgerror={Validator.current.message('filmProjectType', formOne.filmProjectType, 'filmProjectType|required')} options={ProjectGetDetail.pafProjectType} value={formOne.filmProjectType} />
                                <TextField disabled={isSubmitted >= 1 ? true : false} uppercase={true} name='englishPhoneticProdununciation' value={formOne.englishPhoneticProdununciation} handleChange={handleChange} label="English Phonetic Pronunciation" require={true} errorValidation={Validator.current.message('English Phonetic Prodununciation', formOne.englishPhoneticProdununciation, 'topEng|required')}/>
                            </div>
                            {formOne.countryOfOrigin?.map((item, i) => {
                                return (
                                    <>
                                    <div className='detail_country'>
                                        <div className="sd_flex sd_aligncenter f_detail_country_row">
                                            <Dropdown key={item._id} disable={isSubmitted >= 1 ? true : false} onChange={selectCountryOfOrigin} name='Country of Origin' value={item.country ? item.country : ''} uppercase={true} optionID={item.id} label="Country of Origin" DefaultOption={'Select Country'} options={COUNTRIES_LIST} />
                                            {isSubmitted >= 1 ? '' : <div className="sd_flex add_remove_icon">
                                                {i === 0 ?
                                                    <span className='sd_p_cursor' style={{ cursor: formOne.countryOfOrigin.length > 3 ? 'not-allowed' : 'pointer' }} onClick={(e) => formOne.countryOfOrigin.length < 4 ? addCountry(e) : ''}><PlusFieldIcon /></span>
                                                    :
                                                    <>
                                                        {i >= 3 ? '' : <span className='sd_p_cursor' style={{ cursor: formOne.countryOfOrigin.length > 3 ? 'not-allowed' : 'pointer' }} onClick={(e) => formOne.countryOfOrigin.length < 4 ? addCountry(e) : ''}><PlusFieldIcon /></span>}
                                                        <span className='sd_p_cursor' onClick={() => removeCountry(item.id)}><RemoveFieldIcon /></span>
                                                    </>
                                                }
                                            </div>}
                                        </div>
                                        <span style={{ color: 'red' }}>{Validator.current.message('Country of Origin', item.country, 'country|required')}</span>
                                    </div>
                                    <p className='mb_25'>Country of origin is based on where the majority of the project’s financing originated. If your project was financed in the United States but produced in another country, you may list both countries in the order you choose. Up to 4 Countries may be listed.</p>
                                    </>)
                            })}
                            <div className="sd_flex f_detail_s_row sd_justbetween">
                                <Dropdown name="premiereStatus" disable={isSubmitted >= 1 ? true : false} value={formOne.premiereStatus} onChange={handleChange} label="Premiere Status" uppercase={true} require={true} fpgerror={Validator.current.message('premiereStatus', formOne.premiereStatus, 'premiereStatus|required')} toolId={'premiereStatus'} options={state.PremiereStatus} DefaultOption={'Select From Below'} />
                            </div>
                            <p className='pl_25'>
                                <ul>
                                    <li><strong>World Premiere:</strong> Completed film/project has never exhibited publicly before</li>
                                    <li><strong>International Premiere:</strong>  Completed film/project has never screened publicly outside of its country of origin  </li>
                                    <li><strong>North American Premiere:</strong> Completed film/project has never screened publicly in North America (Canada, U.S.A., or Mexico)</li>
                                    <li><strong> U.S. Premiere: </strong> Completed film/project has never screened publicly in the U.S.A.</li>
                                    <li><strong> None: </strong>  Completed film/project has screened publicly in the U.S.A. </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className="associate_people">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Associated People</h3>
                        </div>
                        <div className="ass_container_990">
                            <div className={`form_inner_sub_title sd_flex sd_aligncenter ${pafType === 'Indie Series/Special Events' ? 'sd_justbetween' : ''}`}>
                                <div className="sd_flex sd_aligncenter ind_title_row">
                                    <h5 className="mr_10">{pafType === 'New Frontier' ? 'Lead Artist(s)' : pafType === 'Indie Series/Special Events' ? 'Top Credit(s) *' : 'Director(s) *'}</h5>
                                    {(pafType === 'Indie Series/Special Events') && <div className="no_margin mr_10"> <Dropdown disable={isSubmitted >= 1 ? true : false} creditOption={true} options={indieFilmCreditLabels} DefaultOption={'Select From Below'} optionID='TOP' onChange={topCreditChange} value={formOne.indieTopcredit?._id} fpgerror={Validator.current.message('Top Credit', formOne.indieTopcredit.name, 'indieTopcredit|required')} />  </div>}
                                </div>
                                <p className="ml_0">Up to 4 individuals; list in credit order for Festival publications. For these individuals we will include their headshot and bio in our online Festival Program Guide and we ask that they provide a "Meet the Artist" video.</p>
                            </div>
                        </div>
                        {
                            getindieCredit(formOne?.indieTopcredit?.identifier, 'Top')
                        }
                        <div className="ass_container_990">
                            <div className={`form_inner_sub_title sd_flex sd_aligncenter mt-40 ${pafType === 'Indie Series/Special Events' ? 'sd_justbetween' : ''} `}>
                                <div className="sd_flex sd_aligncenter ind_title_row">
                                    <h5 className="mr_10">{pafType === 'New Frontier' ? 'Key Collaborator(s)' : pafType === 'Indie Series/Special Events' ? 'Additional Top Credit(s) *' : 'Screenwriter(s) *'} </h5>
                                    {(pafType === 'Indie Series/Special Events') && <div className="no_margin mr_10">  <Dropdown disable={isSubmitted >= 1 ? true : false} creditOption={true} options={indieFilmCreditLabelsAdditions} DefaultOption={'Select From Below'} optionID='ADD' onChange={AdditionaltopCreditChange} value={formOne.indieAdditionalTopCredit._id} fpgerror={Validator.current.message('Additional Top Credit', formOne.indieAdditionalTopCredit.name, 'indieAdditionalTopCredit|required')} /> </div>}
                                </div>
                                <p className="ml_0">List in desired order for all places where credits appear. Up to 3.</p>
                            </div>
                        </div>
                        {
                            // getCredit('Screenwriter')
                            getindieCredit(formOne?.indieAdditionalTopCredit?.identifier, 'Additional')
                        }
                        {pafType === 'New Frontier' || pafType === 'Indie Series/Special Events' ? "" : <> <div className="ass_container_990">
                            <div className="form_inner_sub_title sd_flex sd_aligncenter mt-40" style={{flexWrap:'wrap'}}>
                                <h5>Producer(s) </h5>
                                <p>List in desired order for all places where credits appear.</p>
                                <div className='sd_block mt_10' style={{width:'100%'}}>
                                    <p className='ml_0'>Include up to six names of individuals receiving full producer credit only (no executive producers, co-producers, associate producers, etc.).List in desired order for press announcement.</p>
                                </div>
                            </div>
                        </div>
                            {
                                getCredit('producer')
                            }
                        </>}

                        {pafType === 'Documentary Features' || pafType === 'Documentary Shorts' ? "" : <><div className="ass_container_990">
                            <div className="form_inner_sub_title sd_flex sd_aligncenter mt-40">
                                <h5>Principal Cast Member(s)</h5>
                                <p>List in desired order for all places where credits appear. Up to 6.</p>
                            </div>
                        </div>
                            {
                                getCredit('principalcast')
                            }</>
                        }

                    </div>
                    <div className="associate_people">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Top Credit's Demographics</h3>
                        </div>
                        <div className='demographic_desc'>
                            <p>Collecting demographic data is a critical step in helping us support and promote your film/project and fully reflect the program composition. We use this information to create editorial posts on our site and official social channels, and for press purposes, including specialized media outreach, external-facing fact sheets detailing overall demo data for the festival (i.e. “30 Projects by women filmmakers of color” or “60% of films in the U.S. Documentary Competition were directed by people of color”) and coverage. Examples of the type of coverage include “Films from Black Filmmakers We Can’t Wait to See,” “LGBTQ+ Sundance Selections To Watch For,” “Indigenous Films Featured at Sundance Film Festival,” and similar.</p>

                            <p>Please note if you decline to state in any category, then we are unable to include you and/or your project in these Sundance created lists on our house channels or presented to media for this specific coverage. Feel free to reach out to your Publicity Liaison should you have any questions about the information or its use. "</p>
                        </div>
                        <div className="ass_container_990">
                            {
                                // tempcredittop.map(item => {
                                // if (item.identifier === formOne.indieTopcredit.identifier) {
                                tempcredittop?.map((credit, i) => {
                                    return <DemographicsIndie checkId={credit._id} optionID={credit._id} title={formOne.indieTopcredit.name} disabled={isSubmitted >= 1 ? true : false} selectAll={selectAll} onChange={onDirectorDetailChange} ShowOriginValidation={ShowOriginValidation} index={i} DirectorData={credit} Error={{
                                        directorGenderExpression: Validator.current.message(`directorGenderExpression${i}`, credit.creditDetails.directorGenderExpression, 'required|required'),
                                        preferredPronouns: Validator.current.message(`preferredPronouns${i}`, credit.creditDetails.preferredPronouns, 'required|required'),
                                        englishPhoneticProdununciation: Validator.current.message(`englishPhoneticProdununciation${i}`, credit.creditDetails.englishPhoneticProdununciation, 'required|required'),
                                        directorIdentifyTransgender: Validator.current.message(`directorIdentifyTransgender${i}`, credit.creditDetails.directorIdentifyTransgender, 'required|required'),
                                        sexualOrientation: Validator.current.message(`sexualOrientation${i}`, credit.creditDetails.sexualOrientation, 'required|required'),
                                        personWithDisability: Validator.current.message(`personWithDisability${i}`, credit.creditDetails.personWithDisability, 'required|required'),
                                        directorBornCountry: Validator.current.message(`directorBornCountry${i}`, credit.creditDetails.directorBornCountry, 'required|required'),
                                    }} />
                                })
                                // }
                                // })
                            }
                        </div>
                    </div>
                    <div className="logline_form">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter required">Logline</h3>
                            <p>Below is a logline for your film/project based on your initial submission via FilmFreeway.<strong>THIS IS THE FINAL LOGLINE.</strong> We will consider edits if there are factual errors or inaccuracies.</p>
                            <p>It is drafted in Associated Press style with Sundance house guidelines by our PR, editorial, and programming teams. This will be used in our press release and marketing materials such as the website.</p>
                            <p>Loglines for this use describe the plot of the film/project and follow Sundance house guidelines, which include: {pafType === 'Narrative Shorts' ? '40' : '50'} words max.</p>
                            <p>No film titles, filmmaker or actor names in the logline (directors, screenwriters, producers, actors are already directly attached to the logline as a part of the credit list).</p>
                            <p>No descriptions of the type of film, genre, nor self-describe or editorializing (i.e. “this groundbreaking documentary”, “a coming-of-age drama”, “a story about”, “a cinematic journey”).</p>
                            <p>No spoilers or major plot points that are intended to be discovered by the audience.</p>
                        </div>
                        <div className="ass_container_990">
                            <div className="field_row_inner">
                                <label>{pafType === 'Narrative Shorts' || pafType === 'Documentary Features' ? '40' : '50'} words max</label>
                                <div className="field_input relative">
                                    <textarea disabled={isSubmitted >= 1 ? true : false} value={formOne.logline} onChange={(e) => handleChange(e)} name='logline' />
                                </div>
                                <span style={{ color: 'red' }}>{loglineValidation ? <div className='srv-validation-message'>More than {pafType === 'Narrative Shorts' || pafType === 'Documentary Features' ? '40' : '50'} Words is not allowed.</div> : Validator.current.message('logline', formOne.logline, 'logline|required')}</span>
                                <div className="textarea_word sd_flex sd_aligncenter">
                                    <label>Word Count</label>
                                    <span>{loglineLength}/{pafType === 'Narrative Shorts' || pafType === 'Documentary Features' ? '40' : '50'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isSubmitted >= 1 ? <div className='mt_25'> </div> : <> <div className="privacy_policy_link">
                        <div className="form_inner_title">
                            <h3 className="sd_flex sd_aligncenter">Privacy Policy</h3>
                        </div>
                        <div className="ass_container_990 ">
                            <p>By signing below you are agreeing to <a href="https://www.sundance.org/about/privacy-policy" target="_blank" rel="noreferrer" >Sundance Institute’s Privacy Policy.</a></p>
                        </div>
                    </div>
                        <div className="electronic_sign">
                            <div className="form_inner_title">
                                <h3 className="sd_flex sd_aligncenter">Electronic Signature</h3>
                            </div>
                            <div className="ass_container_990 ">
                                <p>On behalf of the film/project owners, I certify that (1) all of the above is true to the best of my/our knowledge and (2) I am authorized to submit this information, enter into a release, and confer the following rights for this film/project. I understand that this information will appear in all Festival materials and will be provided to third-party groups. I understand and agree that Sundance Institute may make non-substantive edits to the information submitted here, for reasons such as style, grammar, and brevity.</p>
                                <p> I agree to indemnify and hold harmless the Institute, its officers, directors, and employees from any claims, losses, demands, costs, damages, judgments, liabilities and expenses (including attorneys’ fees) of any type arising out of or in connection with the information I have provided here and/or the project’s participation in the Festival.</p>
                                <p>By providing this information, you are providing Sundance Institute with a non-exclusive, worldwide,  perpetual license to use the electronic materials (e.g., film/project stills, headshots, press kits, posters, film/project clips, photographs, videos or video clips, and Meet the Artist videos) and information submitted for all Sundance Institute publications and materials relating to or in connection with the Festival, whether printed or online, both before and after the Festival. Sundance Institute reserves the right to crop images as needed. We are not able to submit materials to artists or their representatives for approval prior to publication. You also authorize Sundance Institute to share the provided information with its official supporters.</p>
                            </div>
                        </div> </>}
                    <Popup open={isErrorPopup} setOpen={CloseErrorPopup} submitForm={true} okPopup={true} title=''>
                        <p>The maximum number of Top credit types that can be added is 4 and Additional Top credit types that can be added is 3 and Principal Cast that can be added is 6 .</p>
                    </Popup>
                    {isSubmitted >= 1 ? <FormFooter step={1} onFormSave={onFormSave}/> : <FormSubmitFooter isNext={isNext} checkStepValidate={checkStepValidate} onSubmitClick={onSubmitClick} onFormSave={onFormSave} isSubmitPopup={isSubmitPopup} closeSubmitPopup={closeSubmitPopup} onSubmitPopup={onSubmitPopup} step={1} CounterStep={1} />}
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default IndieStep1
