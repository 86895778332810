import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import service from '../../common/service';
import ArrowIconBack from '../../icons/ArrowIconBack';
import FolderRight from '../../icons/FolderRight';
import PreviewIcon from '../../icons/PreviewIcon';
import ShareWithTeamIcon from '../../icons/ShareWithTeamIcon';
import FullName from "./FullName";
import Popup from './Popup';
import SuccessMessage from './SuccessMessage';
import { ErrorScroll, routeLocater } from './../../common/functions'
import ShareWithTeam from './ShareWithTeam';
import Cookies from "universal-cookie";
import TextField from '../../components/TextField';

let sub
function FormSubmitFooter(props) {
    const cookies = new Cookies();
    const apiServe = new service();
    const history = useHistory();
    const dispatch = useDispatch()
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const [, setisSubmitted] = useState(ProjectInfo.submitNo)
    const pafType = useSelector(e => e.pafType);
    const { onFormSave, step, onSubmitClick, isSubmitPopup, CounterStep, closeSubmitPopup, checkStepValidate, isNext } = props
    const [submitDetail, setSubmitDetail] = useState({
        projectId: ProjectInfo._id,
        submitNo: CounterStep,
        firstName: '',
        middleName: '',
        lastName: '',
        completedStepNo: step
    })
    // const [pafType, setPafType] = useState('')
    const [SubmitSuccess, setSubmitSuccess] = useState(false)
    const [isShareWithTeam,setIsShareWithTeam] = useState(false)
    const [SavedMsg, setSavedMsg] = useState(false)
    const [failedMsg, setFailedMsg] = useState(false)
    useEffect(() => {
        setSubmitDetail({ ...submitDetail, projectId: ProjectInfo._id })
        setisSubmitted(ProjectInfo.submitNo)
        // eslint-disable-next-line 
    }, [ProjectInfo])

    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            CFirstLast: {  // name the rule
                message: 'First and Last Name are required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            Email: {  // name the rule
                message: 'Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            relationToFilm: {  // name the rule
                message: 'The Relation to Film/Project field is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
        }
    }))
    useEffect(() => {
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|submit":
                    if (!response.error) {
                        let PAFProjectId = cookies.get('PAFProjectId')
                        apiServe.sendevent('paf|project|getProjectInfo', { projectUserId: PAFProjectId })
                        closeSubmitPopup()
                        setSubmitSuccess(true)
                        setTimeout(() => {
                            setSubmitSuccess(false)
                        },5000);
                        history.push(`${routeLocater(pafType)}-page${step === 9 ? 1 : step + 1}`)
                    }
                    break;
                case "paf|project|getProjectInfo":
                    if (!response.error) {
                        dispatch({ type: 'FORM_DATA', payload: response.data })
                        dispatch({ type: 'PAF_TYPE', payload: response.data.pafType })
                    }
                    break;
                case "paf|project|shareLinktoEmail":
                    if (!response.error) {
                        setSavedMsg(true)
                        setIsShareWithTeam(false)
                        onFormSave('SHARE')
                        setTimeout(() => {
                            setSavedMsg(false)
                        }, 2000);
                    } else {
                        setFailedMsg(true)
                        setTimeout(() => {
                            setFailedMsg(false)
                        }, 2000);
                    }
                    break;
                default:
                    break;
            }
        })
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])
    const onChangeSubmitDetail = (e) => {
        const { name, value } = e.target
        setSubmitDetail({ ...submitDetail, [name]: value })
    }
    const onSubmitPopup = () => {

        if (Validator.current.allValid()) {
            onFormSave('SUBMIT')
            apiServe.sendevent('paf|project|submit', submitDetail)
            
            // onFormSave()
        } else {
            Validator.current.showMessages()
            forceUpdate()

        }

    }

    const checkValidation = () => {
        
        if (checkStepValidate()) {
            if (Validator.current.allValid() && onSubmitClick('NEXT')) {
               
                // onFormSave()
            } else {
                Validator.current.showMessages()
                forceUpdate()
                setTimeout(() => {
                    ErrorScroll()
                }, 500);
            }
        }
    }
    // const checkValidationback = () => {
     
    //     if (checkStepValidate()) {

    //         if (step === 1) history.push('')
    //         else history.push(`/${routeLocater(pafType)}-page${step - 1}`);
    //     }
        
    // }
    const onBackClick = () => {
        // checkValidationback()
        //     if(){
            onFormSave()
            if (step === 1) history.push('')
            else history.push(pafType === 'New Frontier' && step === 9 ? `/${routeLocater(pafType)}-page${props.step - 2}` : `/${routeLocater(pafType)}-page${step - 1}`);
    //     }    
    //   }
    // const onBackClick = () => {
    //     if (step === 1) history.push('')
    //     else history.push(`/${routeLocater(pafType)}-page${step - 1}`);

     }
    const onPreviewClick = (TYPE) => {
        
        if (TYPE === 'PREV') {
            onFormSave()
            window.open(`${process.env.REACT_APP_PAF_HOME}Preview/${ProjectInfo.projectUserId}`, '_blank')
        } else {
            // window.open(`${process.env.REACT_APP_PAF_HOME}${ProjectInfo.projectUserId}/shared-Step1`, '_blank')
            setIsShareWithTeam(true)
        }
    }

    const closeSubmit = () => {
        setSubmitSuccess(false)
    }
    return (
        <div className="form_footer_submit">
            {SavedMsg && <SuccessMessage step={step} active={SavedMsg} fullMessage={'Email Sent'} />}
            <SuccessMessage active={SubmitSuccess} close={closeSubmit} text={'Submission'} />
            <div className="form_footer_links sd_flex">
                <Link onClick={() => onFormSave('SAVE')}><FolderRight /> <span>Save</span></Link>
                <Link onClick={() => onPreviewClick('PREV')}><PreviewIcon /> <span>Preview</span></Link>
                <Link onClick={() => onPreviewClick('SHARE')}><ShareWithTeamIcon /> <span>Share with your team</span></Link>
            </div>
            <FullName required={true} onChange={onChangeSubmitDetail} values={submitDetail} />
            <span className='error_single sd_error_single' style={{ color: 'red' }}>{Validator.current.message('firstName', submitDetail.firstName && submitDetail.lastName, 'CFirstLast|required')} </span>
            <div className='sd_flex fullname_div two_field'>
                <TextField require={true} name='email' value={submitDetail.email} handleChange={onChangeSubmitDetail} errorValidation={Validator.current.message('Email', submitDetail.email, 'Email|required')} label="Email" required="true" />
                <TextField require={true} name='relationToFilm' value={submitDetail.relationToFilm} handleChange={onChangeSubmitDetail}  label="Relation to Film/Project" errorValidation={Validator.current.message('relationToFilm', submitDetail.relationToFilm, 'relationToFilm|required')} required="true" />
            </div>
            <div className="form_footer_bottom sd_flex">
                {step !== 1 && <div className='btn'><button onClick={() => onBackClick()}><ArrowIconBack className='back' />Back</button></div>}
                <div className='btn'><button onClick={() => { checkValidation() }}>Submit</button></div>
            </div>
            <Popup step={step} onSubmit={onSubmitPopup} submitForm={true} open={isSubmitPopup} setOpen={closeSubmitPopup} title='Are you sure you want to submit?'>
                <p>After you submit, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations Liaison and Publicity Liaison.</p>
            </Popup>
            {isShareWithTeam && <ShareWithTeam setOpen={setIsShareWithTeam} step={step} onFormSave={onFormSave}/>}
        </div>
    )
}

export default FormSubmitFooter
