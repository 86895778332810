import React, { useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import Footer from "../../../layout/Footer";
import FormHeader from './../FormHeader'
import Dropdown from '../../../components/Dropdown';
import FormFooter from './../FormFooter';
import service from '../../../common/service';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessage from './../SuccessMessage';
import FailedMessage from './../FailedMessage';
let sub


const TextAreaDropdown = (props) => {
    const { label, values, options, disabled } = props
    return (
        <div className="form_content_questions_inner">
            <div className="field_row_inner">
                <div className="sd_flex sd_flexwrap">
                    <div className="question_title">
                        <label >{label}</label>
                    </div>
                    <Dropdown disable={disabled} name={values.dropName} value={values.dropValue} options={options} onChange={props.onChange} DefaultOption='Select from Below'/>
                </div>
                <div className="field_input relative mt_5">
                    <textarea disabled={disabled} name={values.textName} value={values.textValue} onChange={(e) => props.onChange(e)}> </textarea>
                </div>
            </div>
        </div>
    )
}


function Step8() {
    const apiServe = new service();
    const dispatch = useDispatch();
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const [failedMessage, setFailedMessage] = useState(false)
    const [SavedMessage, setSavedMessage] = useState(false)
    const [isSubmitted, setisSubmitted] = useState(ProjectInfo.submitNo)
    const [isNext, setIsNext] = useState(true)
    const [isShare, setIsShare] = useState(false)
    const [state] = useState({
        Flag: ['Yes', 'No'],


    })
    const [historyOfFilm, setHistoryOfFilm] = useState({
        awardWinFlag: ProjectInfo.historyOfFilm.awardWinFlag,
        awardWinNote: ProjectInfo.historyOfFilm.awardWinNote,
        acquiredForUSFlag: ProjectInfo.historyOfFilm.acquiredForUSFlag,
        acquiredForUSNote: ProjectInfo.historyOfFilm.acquiredForUSNote,
        instituteLabGrantFlag: ProjectInfo.historyOfFilm.instituteLabGrantFlag,
        instituteLabGrantNote: ProjectInfo.historyOfFilm.instituteLabGrantNote,
        isUtahFlag: ProjectInfo.historyOfFilm.isUtahFlag,
        isUtahNote: ProjectInfo.historyOfFilm.isUtahNote,
    })
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const form8 = [
        {
            GROUP: {
                dropName: 'awardWinFlag',
                textName: 'awardWinNote',
                textValue: historyOfFilm.awardWinNote,
                dropValue: historyOfFilm.awardWinFlag
            },
            options: state.Flag,
            textLabel: 'Has your film/project screened at other festivals? Please name the festival, and if applicable, the name of any award(s) won.',
        },
        {
            GROUP: {
                dropName: 'acquiredForUSFlag',
                textName: 'acquiredForUSNote',
                textValue: historyOfFilm.acquiredForUSNote,
                dropValue: historyOfFilm.acquiredForUSFlag
            },
            options: state.Flag,
            textLabel: 'Does your film have U.S. or international distribution? If so, please list companies and territories. Was it financed by any of these distributors, or was it acquired upon completion?',
        },
        {
            GROUP: {
                dropName: 'instituteLabGrantFlag',
                textName: 'instituteLabGrantNote',
                textValue: historyOfFilm.instituteLabGrantNote,
                dropValue: historyOfFilm.instituteLabGrantFlag
            },
            options: state.Flag,
            textLabel: 'Was your film/project or its artist(s) supported by a Sundance Institute lab, grant, or other program prior to its selection for the Festival? If yes, please explain.',
        },
        {
            GROUP: {
                dropName: 'isUtahFlag',
                textName: 'isUtahNote',
                textValue: historyOfFilm.isUtahNote,
                dropValue: historyOfFilm.isUtahFlag
            },
            options: state.Flag,
            textLabel: 'Was your film/project made in Utah? Does it have any Utah affiliated filmmakers or cast?',
        }
    ]

    const handleChange = (e) => {
        const { name, value } = e.target
        setHistoryOfFilm({ ...historyOfFilm, [name]: value })
    }



    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|allUpdate":
                    if (!response.error) {
                        dispatch({ type: 'FORM_DATA', payload: response.data })
                        setSavedMessage(true)
                        setTimeout(() => {
                            setSavedMessage(false)
                            setIsShare(false)
                        }, 1000)
                        setIsNext(true)
                    } else {
                        setIsNext(false)
                        setFailedMessage(true)
                        setTimeout(() => {
                            setFailedMessage(false)
                        }, 2000);
                    }
                    break;
                default:
                    break;
            }
        })
        setisSubmitted(ProjectInfo.submitNo)
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        setHistoryOfFilm({
            awardWinFlag: ProjectInfo.historyOfFilm.awardWinFlag,
            awardWinNote: ProjectInfo.historyOfFilm.awardWinNote,
            acquiredForUSFlag: ProjectInfo.historyOfFilm.acquiredForUSFlag,
            acquiredForUSNote: ProjectInfo.historyOfFilm.acquiredForUSNote,
            instituteLabGrantFlag: ProjectInfo.historyOfFilm.instituteLabGrantFlag,
            instituteLabGrantNote: ProjectInfo.historyOfFilm.instituteLabGrantNote,
            isUtahFlag: ProjectInfo.historyOfFilm.isUtahFlag,
            isUtahNote: ProjectInfo.historyOfFilm.isUtahNote,
        })
        setisSubmitted(ProjectInfo.submitNo)
        // eslint-disable-next-line
    }, [ProjectInfo])
    const onFormSave = (NAV) => {
        let data = {
            historyOfFilm: historyOfFilm,
            projectId: ProjectInfo._id,
            savePageNo: '8'
        }
        setHistoryOfFilm((last) => {
            data = {
                ...data,
                historyOfFilm:last
            }
            return last
        })
        forceUpdate()
        if (NAV === 'NEXT') {
            data.completedStepNo = 8
        }else if(NAV === 'SHARE'){
            setIsShare(true)
        }
        apiServe.sendevent('paf|project|allUpdate', data)
    }
    const closeSuccessMessage = () => {
        setSavedMessage(false)
    }
    const closeFailedMessage = () => {
        setFailedMessage(false)
    }
    return (
        <div>
            <Header />
            <FormHeader step={8} />
            {!isShare &&  <SuccessMessage active={SavedMessage} close={closeSuccessMessage} text={'Save'} />}
            <FailedMessage active={failedMessage} close={closeFailedMessage} text={'Save'} />

            <div className="container step2 step_eight step_common">
                <div className='underline'>
                    <div className='step sd_justbetween' >
                        <div className='step_no'>
                            <p>Page 8 of 9</p>
                        </div>
                        <div className='step_des'>
                            <p>After you submit at the end of Page 9, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons.</p>

                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival, and will be placed in the Sundance Institute Archives.</p>
                        </div>
                    </div>
                </div>
                <div className="form_content">
                    <div className="form_content_top">
                        <div className="form_content_top_title pb_0">
                            <h3>History of Film/Project</h3>
                        </div>
                    </div>
                    {
                        form8.map((data, index) => {

                            return (
                                <div className="form_content_questions">
                                    <div className="form_content_questions_inner">
                                        <div>
                                            <TextAreaDropdown disabled={isSubmitted >= 3 ? true : false} label={data.textLabel} values={data.GROUP} options={data.options} onChange={handleChange} />
                                        </div>

                                    </div>
                                </div>

                            )
                        })
                    }
                </div>


                <div className="footer">
                    <FormFooter step={8} isNext={isNext} onFormSave={onFormSave} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Step8