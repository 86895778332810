import React, { useEffect, useState } from 'react'
import Header from '../../layout/Header'
import PreviewFormHeader from './PreviewFormHeader';
import PreviewFormFooter from './PreviewFormFooter';
import Dropdown from '../../components/Dropdown';
import Footer from "../../layout/Footer";
import { useSelector } from 'react-redux';
import service from '../../common/service';
import { useParams } from 'react-router-dom';




const TextAreaDropdown = (props) => {
    const { label, values, options, disabled } = props
    return (
        <div className="form_content_questions_inner">
            <div className="field_row_inner">
                <div className="sd_flex sd_flexwrap">
                    <div className="question_title">
                        <label >{label}</label>
                    </div>
                    <Dropdown disable={disabled} name={values.dropName} value={values.dropValue} options={options} onChange={props.onChange} DefaultOption='Select from Below'/>
                </div>
                <div className="field_input relative mt_5">
                    <textarea disabled={disabled} name={values.textName} value={values.textValue} onChange={(e) => props.onChange(e)}> </textarea>
                </div>
            </div>
        </div>
    )
}


function Step8() {

    const apiServe = new service();
    const { ProjectId } = useParams();
    const refcode = useSelector(e => e.refcode);
    const ProjectInfo = useSelector(e => e.SharedProjectInfo);
    const pafType = useSelector(e => e.SharedPafType);
  

    const [state] = useState({
        Flag: ['Yes', 'No'],


    })
    const [historyOfFilm, setHistoryOfFilm] = useState({
        awardWinFlag: ProjectInfo.historyOfFilm.awardWinFlag,
        awardWinNote: ProjectInfo.historyOfFilm.awardWinNote,
        acquiredForUSFlag: ProjectInfo.historyOfFilm.acquiredForUSFlag,
        acquiredForUSNote: ProjectInfo.historyOfFilm.acquiredForUSNote,
        instituteLabGrantFlag: ProjectInfo.historyOfFilm.instituteLabGrantFlag,
        instituteLabGrantNote: ProjectInfo.historyOfFilm.instituteLabGrantNote,
        isUtahFlag: ProjectInfo.historyOfFilm.isUtahFlag,
        isUtahNote: ProjectInfo.historyOfFilm.isUtahNote,
    })
    const form8 = [
        {
            GROUP: {
                dropName: 'awardWinFlag',
                textName: 'awardWinNote',
                textValue: historyOfFilm.awardWinNote,
                dropValue: historyOfFilm.awardWinFlag
            },
            options: state.Flag,
            textLabel: 'Has your film/project screened at other festivals? Please name the festival, and if applicable, the name of any award(s) won.',
        },
        {
            GROUP: {
                dropName: 'acquiredForUSFlag',
                textName: 'acquiredForUSNote',
                textValue: historyOfFilm.acquiredForUSNote,
                dropValue: historyOfFilm.acquiredForUSFlag
            },
            options: state.Flag,
            textLabel: 'Does your film have U.S. or international distribution? If so, please list companies and territories. Was it financed by any of these distributors, or was it acquired upon completion?',
        },
        {
            GROUP: {
                dropName: 'instituteLabGrantFlag',
                textName: 'instituteLabGrantNote',
                textValue: historyOfFilm.instituteLabGrantNote,
                dropValue: historyOfFilm.instituteLabGrantFlag
            },
            options: state.Flag,
            textLabel: 'Was your film/project or its artist(s) supported by a Sundance Institute lab, grant, or other program prior to its selection for the Festival? If yes, please explain.',
        },
        {
            GROUP: {
                dropName: 'isUtahFlag',
                textName: 'isUtahNote',
                textValue: historyOfFilm.isUtahNote,
                dropValue: historyOfFilm.isUtahFlag
            },
            options: state.Flag,
            textLabel: (pafType === 'New Frontier') ? 'Was your film/project made in Utah?' : 'Was your film/project made in Utah? Does it have any Utah affiliated filmmakers or cast?',
        }
    ]

    const onLoad = (e) => {
        apiServe.sendevent('paf|project|previewForm', { projectUserId: ProjectId })
        apiServe.sendevent('paf|project|CheckValidEmail', { projectUserId: ProjectId ,referralCode:refcode})
    }

    useEffect(() => {
        window.addEventListener('load',onLoad)
        return () => {
            window.removeEventListener('load',onLoad)
        }
    },[])

    const handleChange = (e) => {
        const { name, value } = e.target
        setHistoryOfFilm({ ...historyOfFilm, [name]: value })
    }



 

    return (
        <div id='downloadStep8'>
            <Header />
            <PreviewFormHeader step={pafType === "New Frontier" ? 7 : 8} />
           
            <div className="container step2 step_eight step_common">
                <div className='underline'>
                    <div className='step sd_justbetween'>
                        <div className='step_no'>
                            <p>Page {pafType === "New Frontier" ? 7 : 8} of {pafType === 'New Frontier' ? 8 : 9}</p>
                        </div>
                        <div className='step_des'>
                            <p>After you submit at the end of Page {pafType === 'New Frontier' ? 8 : 9}, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons. </p>

                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival and will be placed in the Sundance Institute Archives. </p>
                        </div>
                    </div>
                </div>
                <div className="form_content">
                    <div className="form_content_top">
                        <div className="form_content_top_title pb_0">
                            <h3>History of Film/Project</h3>
                        </div>
                    </div>
                    {
                        form8.map((data, index) => {

                            return (
                                <div className="form_content_questions">
                                    <div className="form_content_questions_inner">
                                        <div>
                                            <TextAreaDropdown disabled={true} label={data.textLabel} values={data.GROUP} options={data.options} onChange={handleChange} />
                                        </div>

                                    </div>
                                </div>

                            )
                        })
                    }
                </div>


                <div className="footer">
                    <PreviewFormFooter step={pafType === "New Frontier" ? 7 : 8} rootId='downloadStep8' />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Step8