import * as React from "react"

function EditFormIcon(props) {
  return (
    <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 37C0 16.5655 16.5655 0 37 0C57.4248 0.0233845 73.9766 16.5752 74 37C74 57.4345 57.4345 74 37 74C16.5655 74 0 57.4345 0 37ZM2.3125 37C2.3125 56.1574 17.8426 71.6875 37 71.6875C56.1484 71.6658 71.6658 56.1484 71.6875 37C71.6875 17.8426 56.1574 2.3125 37 2.3125C17.8426 2.3125 2.3125 17.8426 2.3125 37Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.899 41.6694L32.2432 43.6131C31.9509 43.6887 31.6411 43.6022 31.4279 43.3855C31.2146 43.1688 31.1295 42.8539 31.2039 42.5569L33.1164 34.7822C33.1537 34.628 33.2324 34.4874 33.3439 34.376L51.6748 15.7332C52.1361 15.2638 52.7622 15 53.415 15C54.0678 15 54.6939 15.2638 55.1553 15.7332L58.027 18.6518C58.6499 19.2837 59 20.1415 59 21.036C59 21.9306 58.6499 22.7884 58.027 23.4203L40.2925 41.4382C40.1847 41.5504 40.0485 41.6304 39.899 41.6694ZM55.1553 22.8016L56.8955 21.0392L53.415 17.5018L51.6748 19.2705L55.1553 22.8016ZM49.9407 21.0392L53.415 24.5702L39.5054 38.7133L36.0249 35.176L49.9407 21.0392ZM36.2479 39.5639C36.3309 39.2602 36.2456 38.9347 36.0249 38.7133C35.8071 38.4891 35.4868 38.4024 35.188 38.4867C34.8893 38.5711 34.6589 38.8132 34.586 39.1195C34.5116 39.4165 34.5967 39.7314 34.8099 39.9482C35.0232 40.1649 35.333 40.2514 35.6252 40.1757C35.9266 40.1016 36.1649 39.8675 36.2479 39.5639Z" fill="white"/>
    <path d="M53.8209 35.001V35.001C53.1416 35.001 52.591 35.5606 52.591 36.2509V52.5001H22.4597V21.8766H38.4477C39.127 21.8766 39.6776 21.317 39.6776 20.6267V20.6267C39.6776 19.9364 39.127 19.3768 38.4477 19.3768H21.2298C20.5506 19.3768 20 19.9364 20 20.6267V53.7501C20 54.4404 20.5506 55 21.2298 55H53.8209C54.5001 55 55.0507 54.4404 55.0507 53.7501V36.2509C55.0507 35.5606 54.5001 35.001 53.8209 35.001Z" fill="white"/>
    </svg>

  )
}

export default EditFormIcon
