import React from 'react'

export default function Checkbox(props) {
    const { label, name, type, id, checked, value, disable } = props;
    const HandleChange = (e) => {
        if (props.item && props.credit) {
            props.onChange(e, props.item._id, props.credit._id)
        }
        else {
            props.onChange(e,id)
        }
    }
    return (
        <div className="checkbox_field_row">
            <div className="checkBox">
                <input
                    type={type}
                    id={id}
                    name={name}
                    checked={checked}
                    disabled={disable}
                    value={value}
                    onChange={(e) => HandleChange(e)} />
                <label for={id}>{label}</label>
            </div>
        </div>
    )
}
