import React from 'react'

export default function FolderRight() {
    return (
        <svg width="41" height="31" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="31">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0H41V31H0V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M26.5311 11.2233C26.5311 15.149 29.6793 18.3539 33.5863 18.4493V28.0689L2.93493 28.0684V2.93127H14.6176L17.6879 7.70751C17.9587 8.129 18.4205 8.38089 18.9227 8.38089H27.1197C26.7327 9.27839 26.5311 10.2471 26.5311 11.2233ZM38.4507 11.2228C38.4507 13.8038 36.3489 15.9036 33.7656 15.9036C31.1818 15.9036 29.08 13.8038 29.08 11.2228C29.08 8.64186 31.1818 6.54247 33.7656 6.54247C36.3489 6.54247 38.4507 8.64186 38.4507 11.2228ZM33.7656 3.99434C32.1863 3.99434 30.6897 4.49659 29.4261 5.44912H19.7235L16.6537 0.673379C16.3824 0.251886 15.9211 0 15.4194 0H1.46721C0.658327 0 0 0.657226 0 1.46589V29.5343C0 30.3424 0.658327 31.0002 1.46721 31.0002H35.053C35.8619 31.0002 36.5197 30.3424 36.5197 29.5343V17.899C39.2141 16.7865 41.0002 14.148 41.0002 11.2228C41.0002 7.23705 37.7545 3.99434 33.7656 3.99434Z" fill="black" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M37.0906 8.97932C37.1153 8.98689 37.1411 8.98739 37.1649 8.99799C37.1411 8.9879 37.1153 8.98689 37.0906 8.97932ZM31.0136 11.588C30.9454 11.6107 30.8802 11.6451 30.8231 11.6945C30.8802 11.6451 30.9454 11.6107 31.0136 11.588ZM31.2187 11.5598C31.1727 11.5582 31.1278 11.5663 31.0823 11.5759C31.1212 11.5678 31.1591 11.5557 31.198 11.5557C31.2051 11.5557 31.2116 11.5593 31.2187 11.5598ZM31.4435 11.6128C31.4198 11.6012 31.3945 11.5996 31.3698 11.5921C31.3945 11.5996 31.4198 11.6012 31.4435 11.6128ZM36.7329 8.97932C36.6647 9.00355 36.5995 9.03939 36.5424 9.09087C36.5995 9.03939 36.6647 9.00355 36.7329 8.97932ZM36.9415 8.94802C36.8951 8.94701 36.8491 8.9566 36.8031 8.9667C36.844 8.95761 36.8844 8.94499 36.9259 8.94499C36.9314 8.94499 36.936 8.94802 36.9415 8.94802ZM36.2898 8.8087L32.4959 12.1776L31.9235 11.5103C31.7421 11.2983 31.4779 11.1771 31.198 11.1771C30.9696 11.1771 30.7483 11.2589 30.5755 11.4078C30.3825 11.5734 30.2648 11.8046 30.2451 12.0595C30.2254 12.3134 30.3062 12.5607 30.4725 12.7546L31.6775 14.1589C31.8432 14.3522 32.0842 14.4729 32.3383 14.49L32.3514 14.491H32.3646L32.404 14.492C32.6379 14.492 32.8632 14.4067 33.0386 14.2513L37.5605 10.2352C37.7509 10.0666 37.8646 9.83341 37.8798 9.57849C37.8949 9.32358 37.8095 9.07775 37.6398 8.88694C37.4599 8.68352 37.1992 8.56641 36.9259 8.56641C36.6904 8.56641 36.4651 8.65222 36.2898 8.8087Z" fill="black" />
        </svg>
    )
}
