import React from "react";
import { Link } from "react-router-dom";

// Layout
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';

const NotFound = () =>  {
	// const history = useHistory()
		const signinRegister = () => {
			window.open(process.env.REACT_APP_PAF_HOME , "_self");
		}
		return (
			<>
				<Header />
				<div className="notfound">
					<div className="sd_header_section">
						<div class="sd_sub_container">
							<div class=" sd_not_found_header">
								<h2 class="uppercase">404</h2>
								<div class="sd_not_found_header_desc">
									<p>We couldn’t find that page!</p>
								</div>
								<div className="nofound_content">
									<span to="/" className="click_here" onClick={(e) => signinRegister()}>CLICK HERE</span> <span > to go to the home page.</span>
								</div>
							</div>
						</div>
					</div>       
				</div>
				<Footer />
			</>
		);
	}

export default NotFound;