import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import service from '../../common/service'
import Loader from '../../components/Loader'
import TextField from '../../components/TextField'
import PlusFieldIcon from '../../icons/PlusFieldIcon'
import RemoveFieldIcon from '../../icons/RemoveFieldIcon'
import FailedMessage from './FailedMessage'
import SuccessMessage from './SuccessMessage'

let sub;
const ShareWithTeam = ({setOpen,step,onFormSave}) => {
    const apiServe = new service();
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    console.log('ProjectInfo',ProjectInfo);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [shareEmail,setShareEmail] = useState(ProjectInfo.emailArr && ProjectInfo.emailArr?.length !== 0 ? ProjectInfo?.emailArr : [{_id: new Date().getTime().toString(),email:'',isExist:false}])
    const [SavedMsg, setSavedMsg] = useState(false)
    const [failedMsg, setFailedMsg] = useState(false)
    const [loader,setLoader] = useState(true)
    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            emailShare: {  // name the rule
                message: 'Email is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            ValidEmail: {  // name the rule
                message: 'Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
        }
    }))

    useEffect(() => {
        setShareEmail(ProjectInfo.emailArr && ProjectInfo.emailArr?.length !== 0 ? ProjectInfo?.emailArr : [{_id: new Date().getTime().toString(),email:''}])
        setTimeout(() => {
            setLoader(false)
        }, 1000);
    },[ProjectInfo])

    useEffect(() => {
        // window.scrollTo(0, 0)
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|shareLinktoEmail":
                    if (!response.error) {
                        setSavedMsg(true)
                        setTimeout(() => {
                            setSavedMsg(false)
                        }, 2000);
                        setTimeout(() => {
                            setOpen(false)
                            onFormSave()
                        },3000)
                    } else {
                        setFailedMsg(true)
                        setTimeout(() => {
                            setFailedMsg(false)
                        }, 2000);
                    }
                    break;
                default:
                    break;
            }
        })
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

    const onSubmitClick = (e) => {
        console.log(Validator,"Validator.current.allValid()");
        if(Validator.current.allValid()){
            let emailData = shareEmail.map(item => item.email)
            let valid = shareEmail.map(item => item.isExist)
            if(valid.filter(_ => _ === true).length === 0){
                let data = {
                    projectUserId:ProjectInfo?.projectUserId,
                    stepNo:step,
                    emailArr:emailData
                }
                apiServe.sendevent('paf|project|shareLinktoEmail', data)
            }
        }else{
            Validator.current.showMessages()
            forceUpdate()
        }
    }

    const handleEmailChange = (e,email) => {
        const {name,value} = e.target
        const emaillist = []
        shareEmail.map(_ => _.email !== '' && emaillist.push(_.email))
        let updateEmails = shareEmail.map((item,i) => {
            if(item._id === email._id){
                if(emaillist.includes(value)){
                    return{...item,email:value,isExist:true}
                } else {
                    return{...item,email:value,isExist:false}
                }
            }
            else return {...item,isExist:false}
        })
        setShareEmail(updateEmails)
    }

    const handleEmail = (id,TYPE) => {
        if(TYPE === 'ADD'){
            let newEmail = {_id: new Date().getTime().toString(),email:''}
            setShareEmail([...shareEmail,newEmail])
        }else{
           let filterEmail = shareEmail.filter(item => item._id !== id)
           setShareEmail(filterEmail)
        }
    }
    Validator.current.purgeFields();
    return (
        <>
            <div className="sd_custom_popup sd_share_mail_popup">
                {/* {SavedMsg && <SuccessMessage step={step} active={SavedMsg} text={'Save'} />} */}
                {failedMsg && <FailedMessage active={failedMsg} text={'Save'} />}
                <div className="sd_popup_inner"  >
                    <div className='sd_close_popup' onClick={() => setOpen(false)}><span></span><span></span></div>
                    <div className="sd_popup_header">
                        <h3>Add Email</h3>
                    </div>
                     <>
                        {loader ? <Loader /> :<div className=''>
                            <div className="sd_popup_content sd_popup_desc">
                                <div className='addcredit_form sd_mail_share_popup'>
                                    {shareEmail.map((item, i) => (
                                        <>
                                            {console.log(shareEmail, "share///")}
                                            <div className='addcredit sd_email_addCreadit'>
                                                <TextField require={true} name='email' value={item.email} handleChange={(e) => handleEmailChange(e,item)} errorValidation={Validator.current.message(`email${i}`, item.email, 'emailShare|ValidEmail|required')} uppercase={true} label="email" required="false" />
                                                <div className="sd_flex add_remove_icon">
                                                    <span className="sd_p_cursor" style={{ cursor: 'pointer' }} onClick={() => handleEmail(item._id, 'ADD')}><PlusFieldIcon /></span>
                                                    {i !== 0 && <span className="sd_p_cursor" onClick={() => handleEmail(item._id, 'SUB')}><RemoveFieldIcon /></span>}
                                                </div>
                                                {item.isExist && <span style={{ color: 'red' }} className='srv-validation-message'>This email already exist.</span>}
                                            </div>
                                            {/* <span style={{ color: 'red' }}>{Validator.current.message(`firstName${i}`, credit.firstName && credit.lastName, (credit.firstName || credit.lastName) ? `CFirstLast` : '')}</span> */}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>}
                        <div className='sd_popup_buttons sd_email_Submit_btn'>
                            <button onClick={(e) => { onSubmitClick(e) }}>Submit</button>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}

export default ShareWithTeam