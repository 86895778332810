import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import RightBlackIcon from '../../icons/RightBlackIcon';
// import SuccessMessage from './SuccessMessage';

function PreviewFormHeader(props) {
    const pafType = useSelector(e => e.SharedPafType);
    // const ProjectInfo = useSelector(e => e.ProjectInfo);
    // const { ProjectId } = useParams();
    const paftype1 = useSelector(e => e?.pafType)
    const history =  useHistory()
    const refcode = useSelector(e => e.refcode);
    const RedirectTo = (STEP) => {
        if(STEP === 1) history.push(`shared-page${STEP}?referralCode=${refcode}`)
        else history.push(`shared-page${STEP}`)
    }
    return (
        <>
            <div className="form_header sd_relative">
                <div className="sd_large_container">
                    <div className="sd_header_top_title sd_post_acception_form_title">
                        <h1 className="">Post Acceptance Form</h1>
                    </div>
                </div>
                {/* <div className="header_timezone sd_flex sd_aligncenter sd_flexwrap">
                    <span className="sd_textuppercase label_timezone" data-uw-styling-context="true">TIMEZONE</span>
                    <div className={`sd_p_cursor sd_flex sd_aligncenter select_timezone ${'active'}`}>
                        <input name="timezoneSelect" disabled="" value="India Standard Time" data-uw-styling-context="true" />
                        <span className="sd_p_cursor">
                            <svg width="11" height="6" viewBox="0 0 11 6" fill="none">
                                <path d="M1.29285 0.714355L5.75626 5.00007L10.0071 0.918437" stroke="white" stroke-width="1.218"></path>
                            </svg>
                        </span>
                        <ul>
                            <li data-uw-styling-context="true">Mountain Daylight Time</li>
                        </ul>
                    </div>
                </div> */}
            </div>
            <div className="container">
                <div className="form_steps_list">
                    <ul className="sd_flex">
                        {console.log(props.step,"props.step")}
                        <>
                            <li onClick={()=>RedirectTo(1)} className={`${props.step === 1 && `current`} ${props.step > 1 && `complete`} hover_Active`}><span>1</span><p>Program Announcement</p><RightBlackIcon /></li>
                            <li onClick={()=>RedirectTo(2)} className={`${props.step === 2 && `current`} ${props.step > 2 && `complete`}  hover_Active`}><span>2</span><p>Credits</p></li>
                            <li onClick={()=>RedirectTo(3)} className={`${props.step === 3 && `current`} ${props.step > 3 && `complete`}  hover_Active`}><span>3</span><p>Project Info</p></li>
                            <li onClick={()=>RedirectTo(4)} className={`${props.step === 4 && `current`} ${props.step > 4 && `complete`}  hover_Active`}><span>4</span><p>Contacts</p></li>
                            <li onClick={()=>RedirectTo(5)} className={`${props.step === 5 && `current`} ${props.step > 5 && `complete`}  hover_Active`}><span>5</span><p>{  paftype1 === 'New Frontier' ? 'Lead Artist(s)' : paftype1 === 'Indie Series/Special Events' ? 'Top Credit(s)' :  'Director(s)'} Info</p></li>
                            <li onClick={()=>RedirectTo(6)} className={`${props.step === 6 && `current`} ${props.step > 6 && `complete`}  hover_Active`}><span>6</span><p>Team Info</p></li>

{pafType === 'New Frontier' ?  '' :  <li onClick={()=>RedirectTo(pafType === 'New Frontier' ? '' : 7)} className={`${props.step === 7 && `current`} ${props.step >= 7 && `complete`} hover_Active`}><span>7</span><p>Audiences</p></li>}
                                     <li onClick={()=>RedirectTo(pafType === 'New Frontier' ? 7 : 8)} className={`${((pafType !== 'New Frontier' && props.step === 8) || (pafType === 'New Frontier' && props.step === 7)) && `current`} ${props.step >= 8 && `complete`}  hover_Active`}><span>{pafType === 'New Frontier' ?  '7'  : '8'}</span><p>History</p></li>
                                     <li onClick={()=>RedirectTo(pafType === 'New Frontier' ? 8 : 9)} className={`${(props.step === 9 || (pafType === 'New Frontier' && props.step === 8)) && `current`} ${(props.step >= 9 ) && `complete`} hover_Active`}><span>{pafType === 'New Frontier' ?  '8' :'9'}</span><p>Production Questions</p><RightBlackIcon /></li>


                            {/* <li onClick={()=>RedirectTo(7)} className={`${props.step === 7 && `current`} ${props.step > 7 && `complete`}  hover_Active`}><span>7</span><p>Audiences</p></li>
                            <li onClick={()=>RedirectTo(8)} className={`${props.step === 8 && `current`} ${props.step > 8 && `complete`}  hover_Active`}><span>8</span><p>History</p></li>
                            <li onClick={()=>RedirectTo(9)} className={`${props.step === 9 && `current`} ${props.step >= 9 && `complete`}  hover_Active`}><span>9</span><p>Production Questions</p><RightBlackIcon /></li> */}
                        </>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default PreviewFormHeader