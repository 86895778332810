import React from 'react'
import Tooltip from './Tooltip'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const MultiSelect = (props) => {
    const { label, require, name, value, disable, defaultValue, fpgerror, toolTip, uppercase, optionID, creditOption, DefaultOption, options, toolId } = props

    const HandleChange = (e, name) => {
        if (optionID) {
            props.onChange(e, optionID, name, options)
        }
        else if (props.item && props.credit) {
            props.onChange(e, props.item._id, props.credit._id, name, options)
        }
        else {
            props.onChange(e)
        }
    }


    return (
        <div className="field_row_inner">
            {label ? <label className={`${require ? 'required' : null} ${uppercase ? 'sd_textuppercase' : ''}`}>{label}
                {toolTip ?
                    <Tooltip
                        id={toolId}
                        customClass="tooltip-button tooltip-short-program"
                        data={toolTip}
                        place="right"
                        width={13}
                        data_top={25}
                        html={true}
                    />
                    : ''}
            </label> : ""}
            <div className="select_field relative">
                <Select isDisabled={disable} options={options} isMulti components={animatedComponents} placeholder={DefaultOption} name={name} onChange={(e) => HandleChange(e, name)} value={value}/>
                {/* <select disabled={disable} name={name} onChange={(e) => HandleChange(e)} value={value}>
                    {DefaultOption ? <option value="" >{DefaultOption}</option> : ''}
                    {options}
                </select> */}
                {fpgerror ? <span className="error" style={{ color: 'red' }} >{fpgerror}</span> : ""}
            </div>
        </div>
    )
}

export default MultiSelect