import React, { useEffect, useState } from 'react'
import ArrowIcon from '../../icons/ArrowIcon';
import ArrowIconBack from '../../icons/ArrowIconBack';
import { useHistory, useParams } from "react-router-dom";
import service from '../../common/service';
import { useDispatch, useSelector } from 'react-redux';
import html2canvas from '@nidi/html2canvas'
import jsPDF from 'jspdf'
import cloud from '../../styles/images/cloud.svg'

let sub
function PreviewFormFooter(props) {
    const history = useHistory();
    const apiServe = new service();
    const dispatch = useDispatch();
    const { ProjectId } = useParams();
    const pafType = useSelector(e => e.SharedPafType);
    const refcode = useSelector(e => e.refcode);
    const [isLoader,setIsLoader] = useState(false)

    // const nextPrev = (NAV) => {
    //     apiServe.sendevent('paf|project|previewForm', { projectUserId: ProjectId })
    //     if (NAV === 'NEXT') history.push(`/${ProjectId}/shared-page${props.step + 1}`);
    //     else history.push(`/${ProjectId}/shared-page${props.step - 1}`);
    // }



    const nextPrev = (NAV) => {
        if (NAV === 'NEXT') {
            // if (pafType === 'New Frontier') {
            //     history.push(props.step === 6 ? `/${ProjectId}/shared-page${props.step + 2}` : `/${ProjectId}/shared-page${props.step + 1}`)
            // } else {
                history.push(`/${ProjectId}/shared-page${props.step + 1}`);
            // }
        }
        else {
            if (props.step === 1) history.push(`film-information`);
            else if(props.step === 2) history.push(`/${ProjectId}/shared-page${props.step - 1}?referralCode=${refcode}`)
            else {
                // if (pafType === 'New Frontier') {
                //     history.push(props.step === 8 ? `/${ProjectId}/shared-page${props.step - 2}` : props.step === 2 ? `/${ProjectId}/shared-page${props.step - 1}?referralCode=${refcode}` : `/${ProjectId}/shared-page${props.step - 1}`)
                // } else {
                    history.push(`/${ProjectId}/shared-page${props.step - 1}`);
                // }
            }
        }
    }


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|previewForm":
                    if (!response.error) {
                        apiServe.sendevent('paf|project|getDetail', {})
                        dispatch({ type: 'SHARED_PROJECT_DATA', payload: response.data })
                        dispatch({ type: 'SHARED_PAF_TYPE', payload: response.data.pafType })
                    }
                    break;
                case "paf|project|getDetail":
                    if (!response.error) {
                        dispatch({ type: 'SHARED_GET_DETAILS', payload: response.data })
                    }
                    break
                default:
                    break;
            }
        })
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

    const downloadPage = () => {
        setIsLoader(true)
        html2canvas(document.body).then((canvas) => {
          var imgData = canvas.toDataURL("image/png");
          var imgWidth = 210;
          var pageHeight = 295;
          var margin = (props.step === 7 || props.step === 3 || props.step === 8 || props.step === 2 || props.step === 5 || props.step === 4) ? -11 : (props.step === 1 || props.step === 9)? -10 : -12
          var imgHeight = ((canvas.height * imgWidth) / canvas.width);
          var heightLeft = imgHeight;
          var doc = new jsPDF("p", "mm", 'a4');
          var position = margin;
          doc.addImage(imgData, "jpeg", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, "jpeg", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
        //   const pages = doc.internal.getNumberOfPages();
    
        // //   for (let j = 1; j < pages + 1; j++) {
        // //     let horizontalPos = imgWidth / 2; //Can be fixed number
        // //     let verticalPos = pageHeight - 10; //Can be fixed number
        // //     doc.setPage(j);
        // //     doc.setFontSize(14);
        // //     doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        // //       align: "center" //Optional text styling});
        // //     });
        // //   }
          doc.save(`${ProjectId}_page${props.step}`);
          setIsLoader(false)
        // });
      })
    }

    return (
        <>
            <div className='mt_25'></div>
            <div className='form_footer' data-html2canvas-ignore>
                <div className='left_side sd_p_cursor'>
                    {isLoader ? <><div className='icon mr_10' onClick={() => downloadPage()}><span className="submit_loading"><img src={apiServe.loader} alt='' /></span></div><div className='text'>Download PDF</div></> : <><div className='icon mr_10' onClick={() => downloadPage()}><img src={cloud} /> </div><div className='text' onClick={() => downloadPage()}>Download PDF</div></>}
                </div>
                <div className='right_side'>
                    {props.step === 1 ? '' : <div className='btn'><button className="sd_p_cursor" onClick={() => { nextPrev('BACK') }}><ArrowIconBack className='back' />Back</button></div>}
                    {(props.step === 9 || (pafType === 'New Frontier' && props.step === 8)) ? '' : <div className='btn'><button className="sd_p_cursor" onClick={() => { nextPrev('NEXT') }}>Next<ArrowIcon className='next' /></button></div>}
                </div>
            </div>
        </>
    )
}

export default PreviewFormFooter
