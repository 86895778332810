import React, { useEffect,  useState } from 'react';
// Layouts
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
// PAF Header
import TextField from "../../components/TextField";
import Dropdown from "../../components/Dropdown";
import FullName from './../Components/FullName';
import MainContactForm from './../Components/MainContactForm';
import {  useSelector } from 'react-redux';
import PreviewFormHeader from './PreviewFormHeader';
import PreviewFormFooter from './PreviewFormFooter';
import service from '../../common/service';
import { useParams } from 'react-router-dom';

export default function Step4() {
    const apiServe = new service();
    const { ProjectId } = useParams();
    const refcode = useSelector(e => e.refcode);
    const ProjectInfo = useSelector(e => e.SharedProjectInfo);
    const pafType = useSelector(e => e.SharedPafType);
    // const history = useHistory()

    const [formFour, setFormFour] = useState({
        mainContacts: {
            address1: ProjectInfo.mainContacts.address1,
            address2: ProjectInfo.mainContacts.address2,
            city: ProjectInfo.mainContacts.city,
            company: ProjectInfo.mainContacts.company,
            country: ProjectInfo.mainContacts.country,
            email: ProjectInfo.mainContacts.email,
            firstName: ProjectInfo.mainContacts.firstName,
            lastName: ProjectInfo.mainContacts.lastName,
            middleName: ProjectInfo.mainContacts.middleName,
            phoneNumber: ProjectInfo.mainContacts.phoneNumber,
            state: ProjectInfo.mainContacts.state,
            title: ProjectInfo.mainContacts.title,
            type: ProjectInfo.mainContacts.type,
            zipCode: ProjectInfo.mainContacts.zipCode,
        },
        mainPublicityContacts: {
            address1: ProjectInfo.mainPublicityContacts.address1,
            address2: ProjectInfo.mainPublicityContacts.address2,
            city: ProjectInfo.mainPublicityContacts.city,
            company: ProjectInfo.mainPublicityContacts.company,
            country: ProjectInfo.mainPublicityContacts.country,
            email: ProjectInfo.mainPublicityContacts.email,
            firstName: ProjectInfo.mainPublicityContacts.firstName,
            lastName: ProjectInfo.mainPublicityContacts.lastName,
            middleName: ProjectInfo.mainPublicityContacts.middleName,
            phoneNumber: ProjectInfo.mainPublicityContacts.phoneNumber,
            state: ProjectInfo.mainPublicityContacts.state,
            title: ProjectInfo.mainPublicityContacts.title,
            type: ProjectInfo.mainPublicityContacts.type,
            zipCode: ProjectInfo.mainPublicityContacts.zipCode,
        },
        techContact: {
            email: ProjectInfo.techContact.email,
            firstName: ProjectInfo.techContact.firstName,
            lastName: ProjectInfo.techContact.lastName,
            title: ProjectInfo.techContact.title,
            middleName: ProjectInfo.techContact.middleName,
            phoneNumber: ProjectInfo.techContact.phoneNumber,
            type: ProjectInfo.techContact.type,
        },
        publicContact: {
            company: ProjectInfo.publicContact.company,
            firstName: ProjectInfo.publicContact.firstName,
            lastName: ProjectInfo.publicContact.lastName,
            middleName: ProjectInfo.publicContact.middleName,
            phoneNumber: ProjectInfo.publicContact.phoneNumber,
            publicEmail: ProjectInfo.publicContact.publicEmail,
            type: ProjectInfo.publicContact.type,
            website: ProjectInfo.publicContact.website

        },
        postFestivalContact: {
            firstName: ProjectInfo.postFestivalContact.firstName,
            middleName: ProjectInfo.postFestivalContact.middleName,
            lastName: ProjectInfo.postFestivalContact.lastName,
            company: ProjectInfo.postFestivalContact.company,
            publicEmail: ProjectInfo.postFestivalContact.publicEmail,
            website: ProjectInfo.postFestivalContact.website,
            phoneNumber: ProjectInfo.postFestivalContact.phoneNumber,
            type: ProjectInfo.postFestivalContact.type,
        },

    })

    const onLoad = (e) => {
        apiServe.sendevent('paf|project|previewForm', { projectUserId: ProjectId })
        apiServe.sendevent('paf|project|CheckValidEmail', { projectUserId: ProjectId ,referralCode:refcode})
    }

    useEffect(() => {
        window.addEventListener('load',onLoad)
        return () => {
            window.removeEventListener('load',onLoad)
        }
    },[])

    useEffect(() => {
        setFormFour({  mainContacts: {
            address1: ProjectInfo.mainContacts.address1,
            address2: ProjectInfo.mainContacts.address2,
            city: ProjectInfo.mainContacts.city,
            company: ProjectInfo.mainContacts.company,
            country: ProjectInfo.mainContacts.country,
            email: ProjectInfo.mainContacts.email,
            firstName: ProjectInfo.mainContacts.firstName,
            lastName: ProjectInfo.mainContacts.lastName,
            middleName: ProjectInfo.mainContacts.middleName,
            phoneNumber: ProjectInfo.mainContacts.phoneNumber,
            state: ProjectInfo.mainContacts.state,
            title: ProjectInfo.mainContacts.title,
            type: ProjectInfo.mainContacts.type,
            zipCode: ProjectInfo.mainContacts.zipCode,
        },
        mainPublicityContacts: {
            address1: ProjectInfo.mainPublicityContacts.address1,
            address2: ProjectInfo.mainPublicityContacts.address2,
            city: ProjectInfo.mainPublicityContacts.city,
            company: ProjectInfo.mainPublicityContacts.company,
            country: ProjectInfo.mainPublicityContacts.country,
            email: ProjectInfo.mainPublicityContacts.email,
            firstName: ProjectInfo.mainPublicityContacts.firstName,
            lastName: ProjectInfo.mainPublicityContacts.lastName,
            middleName: ProjectInfo.mainPublicityContacts.middleName,
            phoneNumber: ProjectInfo.mainPublicityContacts.phoneNumber,
            state: ProjectInfo.mainPublicityContacts.state,
            title: ProjectInfo.mainPublicityContacts.title,
            type: ProjectInfo.mainPublicityContacts.type,
            zipCode: ProjectInfo.mainPublicityContacts.zipCode,
        },
        techContact: {
            email: ProjectInfo.techContact.email,
            firstName: ProjectInfo.techContact.firstName,
            lastName: ProjectInfo.techContact.lastName,
            title: ProjectInfo.techContact.title,
            middleName: ProjectInfo.techContact.middleName,
            phoneNumber: ProjectInfo.techContact.phoneNumber,
            type: ProjectInfo.techContact.type,
        },
        publicContact: {
            company: ProjectInfo.publicContact.company,
            firstName: ProjectInfo.publicContact.firstName,
            lastName: ProjectInfo.publicContact.lastName,
            middleName: ProjectInfo.publicContact.middleName,
            phoneNumber: ProjectInfo.publicContact.phoneNumber,
            publicEmail: ProjectInfo.publicContact.publicEmail,
            type: ProjectInfo.publicContact.type,
            website: ProjectInfo.publicContact.website

        },
        postFestivalContact: {
            firstName: ProjectInfo.postFestivalContact.firstName,
            middleName: ProjectInfo.postFestivalContact.middleName,
            lastName: ProjectInfo.postFestivalContact.lastName,
            company: ProjectInfo.postFestivalContact.company,
            publicEmail: ProjectInfo.postFestivalContact.publicEmail,
            website: ProjectInfo.postFestivalContact.website,
            phoneNumber: ProjectInfo.postFestivalContact.phoneNumber,
            type: ProjectInfo.postFestivalContact.type,
        }, })
        // eslint-disable-next-line
    }, [ProjectInfo])
    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])



    return (
        <div id='downloadStep4'>
            <Header />
            <PreviewFormHeader step={4} />
            <div className="container form_inner step_common">
                <div className='underline'>
                    <div className='step sd_justbetween'>
                        <div className='step_no'>
                            <p>Page 4 of {pafType === 'New Frontier' ? 8 : 9}</p>
                        </div>
                        <div className='step_des'>
                            <p>After you submit at the end of Page {pafType === 'New Frontier' ? 8 : 9}, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons. </p>

                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival and will be placed in the Sundance Institute Archives. </p>
                        </div>
                    </div>
                </div>
                <div className="conatct_form">
                    <div className="form_inner_title">
                        <h3 className="sd_flex sd_aligncenter">Contacts</h3>
                        <h5>Main Contact</h5>
                    </div>
                    <div className="conatct_info_form_inner">
                        <div className="contact_desc">
                            <p>Individual from your film/project team who works with the Artist Relations Department regarding the logistics of attending the Festival. </p>
                            <p>One who may:</p>
                            <ul>
                                <li> Receive and respond to frequent emails from us.</li>
                                <li> Share Festival information with your team.</li>
                                <li> Receive event invitations from us and our official partners/sponsors, distribute to appropriate team members and RSVP.</li>
                                <li> Make decisions regarding your film/project’s benefits; screening tickets and credentials.</li>
                                <li> Attend Festival, check in for orientation, benefit distribution, and act as point person during premiere screening.</li>
                            </ul>
                            <p> {(pafType === 'New Frontier') ? `Point of contact information  will not be published, it's for internal use and will be shared with our official partners and sponsors.` : `(Main contact information will not be published; it's for internal use and will only be shared with our official partners and sponsors)`}</p>
                        </div>
                        <div className="conatct_info_form_inner_top">
                            <div className="sd_container_990">
                                <MainContactForm disabled={true} info={formFour.mainContacts} />
                            </div>
                        </div>
                        <div className="form_inner_title">
                            <h5 className="mt-35">Main Publicity Contact</h5>
                        </div>
                        <div className="contact_desc">
                            <h6>{(pafType === 'New Frontier') ? `PROVIDED INFORMATION WILL BE MADE PUBLIC ` : `INFORMATION YOU PROVIDE WILL BE MADE PUBLIC ON A CONTACT LIST`} <u>BEFORE & DURING</u> THE FESTIVAL</h6>
                            <p>{(pafType === 'New Frontier' || pafType === 'Indie Series/Special Events') ? `Individual from your publicity team or an external PR agency hired to publicize your film/project at the Festival, who will regularly communicate with the Press Office regarding the publicity of your film/project pre-Festival, at Festival, and post-Festival.` : `Individual from your publicity team or an external PR agency hired to publicize your film/project at the Festival, who will regularly communicate with the Press Office regarding the publicity of your film/project pre-Festival, at Festival, and post-Festival.`}</p>
                            <p>One who may:</p>
                            <ul>
                                
                                {
                                    (pafType === "Documentary Features" || pafType === "Documentary Shorts" || pafType === "Narrative Shorts" || pafType === "Narrative Features" || pafType === 'Indie Series/Special Events') ? 
                                    <li> Receive and respond to frequent emails from your Publicity Liaison, especially during the peak time leading up to the Sundance Film Festival program announcement. Publicity Liaisons will also need your Main Publicity Contact to provide information, answer questions and make decisions on deadlines around Press Materials, Press Lines, Talent Attendance, Media Alerts, Face Sheets, Press Line RSVP’s, and Embargo Times.</li>
                                    :
                                    <li> Receive and respond to frequent emails from yourPublicity Liaison, especially during the peak time leading up to the Sundance Film Festival program announcement.</li>
                                }
                                {(pafType === 'New Frontier') && <li> Respond to interview requests for your film/project.</li>}
                            </ul>
                            <p>Main Publicity Contact information will not be published, is for internal use and will be shared with our official partners and sponsors.</p>
                        </div>
                        <div className="conatct_info_form_inner_top">
                            <div className="sd_container_990">
                                <MainContactForm disabled={true}info={formFour.mainPublicityContacts} />
                            </div>
                        </div>
                        <div className="form_inner_title">
                            <h5 className="mt-35">Film/Project Tech & Delivery Contact</h5>
                            {
                                 (pafType === "Documentary Features" || pafType === "Documentary Shorts" || pafType === "Narrative Shorts") ? <p>Individual who can provide technical details for your film/project and will ship/deliver your screening materials for exhibition. We ask that they be available for regular communication and provide updates to tracking / inbound delivery of screening materials.</p>
                                 :
                                 <p>Individual who can provide technical details for your film/project and will ship/deliver your screening materials for exhibition. We ask that they be available for regular communication and provide updates to tracking / inbound delivery of screening materials. </p>

                            }
                        </div>
                        <div className="conatct_inner_form_block">
                            <div className="sd_container_990">
                                <FullName disabled={true} values={formFour.techContact} />
                                <div className="sd_flex single_field">
                                    <TextField disabled={true} name='title' value={formFour.techContact.title}  uppercase={true} label="Title (Affiliation to Film/Project)" required="false" />
                                </div>
                                <div className="sd_flex single_field">
                                    <TextField disabled={true} name='email' value={formFour.techContact.email}  uppercase={true} label="email" required="false" />
                                </div>
                                <div className="sd_flex two_field">
                                    <TextField disabled={true} name='phoneNumber' value={formFour.techContact.phoneNumber} uppercase={true} label="Phone Number" required="false" />
                                    <Dropdown disable={true} name='type' value={formFour.techContact.type} label="Type" options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
                                </div>
                            </div>
                        </div>
                        <div className="form_inner_title">
                            <h5 className="sd_flex sd_aligncenter">Public Contact
                            </h5>
                        </div>
                        <div className='contact_desc'>
                            <h6>{(pafType === 'New Frontier') ? `PROVIDED INFORMATION WILL BE MADE PUBLIC ` : `INFORMATION YOU PROVIDE WILL BE MADE PUBLIC ON A CONTACT LIST`} <u>BEFORE & DURING</u> THE FESTIVAL</h6>
                            <p>{(pafType === 'New Frontier') ? `This individual is the designated public contact for the film/project. This information will be released to members of the industry and press, and listed on your film/project page on our online program guide.` : `This individual is the designated public-facing contact for the film/project. This information will be released to members of the industry and press, and it will be listed on your film/project page on our online program guide on the Festival website.`}</p>
                        </div>
                        <div className="conatct_inner_form_block">
                            <div className="sd_container_990">
                                <FullName disabled={true} values={formFour.publicContact}  />
                                <div className="sd_flex single_field">
                                    <TextField disabled={true} name='company' value={formFour.publicContact.company} uppercase={true} label="Company" required="false" />
                                </div>
                                <div className="sd_flex single_field">
                                    <TextField disabled={true} name='publicEmail' value={formFour.publicContact.publicEmail} uppercase={true} label="Public Email" required="false" />
                                </div>
                                <div className="sd_flex single_field">
                                    <TextField disabled={true} name='website' value={formFour.publicContact.website} uppercase={true} label="Website (format: HTTP://www.)" required="false" />
                                </div>
                                <div className="sd_flex two_field">
                                    <TextField disabled={true} name='phoneNumber' value={formFour.publicContact.phoneNumber} uppercase={true} label="Phone Number" required="false" />
                                    <Dropdown disable={true} name='type' value={formFour.publicContact.type} label="Type" options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
                                </div>
                            </div>
                        </div>
                        <div className="form_inner_title">
                            <h5 className="sd_flex sd_aligncenter">{pafType === 'Indie Series/Special Events' ? `Post-Festival Contact` : `Post Festival Contact`} 
                            </h5>
                        </div>
                        <div className='contact_desc'>
                            {
                                <h6>{`INFORMATION YOU PROVIDE WILL BE MADE PUBLIC ON A CONTACT LIST`} <u>AFTER</u> THE FESTIVAL</h6>
                            }
                            <p>Company or individual who may be reached after the Festival regarding your film/project.</p>
                            <p> After the Festival, the provided information will replace the public contact on your film/project page, on our online Festival program guide.</p> 
                        </div>
                        <div className="conatct_inner_form_block">
                            <div className="sd_container_990">
                                <FullName disabled={true} values={formFour.postFestivalContact} />
                                <div className="sd_flex single_field">
                                    <TextField disabled={true} name='company' value={formFour.postFestivalContact.company} uppercase={true} label="Company" required="false" />
                                </div>
                                <div className="sd_flex single_field">
                                    <TextField disabled={true} name='publicEmail' value={formFour.postFestivalContact.publicEmail} uppercase={true}  label="Public Email" required="false" />
                                </div>
                                <div className="sd_flex single_field">
                                    <TextField disabled={true} name='website' value={formFour.postFestivalContact.website} uppercase={true} label="Website (format: HTTP://www.)" required="false" />
                                </div>
                                <div className="sd_flex two_field">
                                    <TextField disabled={true} name='phoneNumber' value={formFour.postFestivalContact.phoneNumber} uppercase={true} label="Phone Number" required="false" />
                                 
                                    <Dropdown disable={true} name='type' value={formFour.postFestivalContact.type} label="Type" options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <PreviewFormFooter step={4} rootId='downloadStep4'/>
                </div>
            </div>
            <Footer />
        </div>
    )
}
