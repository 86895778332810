import React, { useEffect, useRef } from 'react'
import Header from '../../layout/Header'
import Footer from "../../layout/Footer";
import TextArea from '../../components/TextArea';
import Dropdown from '../../components/Dropdown';
import PreviewFormHeader from './PreviewFormHeader';
import PreviewFormFooter from './PreviewFormFooter';
import Tooltip from '../../components/Tooltip';
import { useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory, useParams } from "react-router-dom";
import service from '../../common/service';


const Step7 = () => {

    const apiServe = new service();
    const { ProjectId } = useParams();
    const refcode = useSelector(e => e.refcode);
    const ProjectInfo = useSelector(e => e.SharedProjectInfo);

 
    const pafType = useSelector(e => e?.SharedPafType);
   

    const history = useHistory()
    if (pafType === 'New Frontier') {
        history.goBack()
    }

    const [state] = useState({
        studentscreening: ['Yes', 'No'],
        ScreeningsandTickets: ['Yes', 'No'],
    })
    const [CommunityAudiences, setCommunityAudiences] = useState({
        freeCommunityScreening: ProjectInfo.freeCommunityScreening,
        studentScreening: ProjectInfo.studentScreening,
    })
    
    const onLoad = (e) => {
        apiServe.sendevent('paf|project|previewForm', { projectUserId: ProjectId })
        apiServe.sendevent('paf|project|CheckValidEmail', { projectUserId: ProjectId ,referralCode:refcode})
    }

    useEffect(() => {
        window.addEventListener('load',onLoad)
        return () => {
            window.removeEventListener('load',onLoad)
        }
    },[])
  
    return (
        <div id='downloadStep7'>
            {console.log(CommunityAudiences)}
            <Header />
            <PreviewFormHeader step={7} />
          
            <div className="container step2 step_eight step_common">
                <div className='underline'>
                    <div className='step sd_justbetween'>
                        <div className='step_no'>
                            <p>Page 7 of {pafType === 'New Frontier' ? 8 : 9}</p>
                        </div>
                        <div className='step_des'>
                            <p>After you submit at the end of Page {pafType === 'New Frontier' ? 8 : 9}, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons. </p>

                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival and will be placed in the Sundance Institute Archives. </p>
                        </div>
                    </div>
                </div>
                <div className="form_content">
                    <div className="form_content_top">
                        <div className="form_content_top_title border_bottom_2">
                            <h3>Utah Student and Community Audiences</h3>
                            <h4>Community Engagement Program</h4>
                            <p>{(pafType === 'New Frontier' || pafType === 'Indie Series/Special Events') ? `Reach beyond the typical Sundance Film Festival attendee and interact with engaged local audiences including communities who may identify with your subject matter or young film fans who are excited to experience independent cinema for the first time.` : `Interact with engaged local audiences including communities who may identify with your subject matter or young film fans who are excited to experience independent cinema for the first time.`}</p>
                        </div>
                    </div>

                    <div className="form_content_questions">
                        <div className="form_content_questions_inner">
                            <div className="form_screening">
                                <h4 className="tooltip_title">Free Utah Community Screenings and Tickets
                                    {/* <Tooltip id="accessibility" customClass="tooltip-button tooltip-short-program" data="Help bring independent film to a broader audience—including through engaging with minoritized communities you hope to reach with your project, or putting on a special community screening to bridge access to the Festival with local working class residents.. When you participate in our community program, which gives tickets to local community members free of charge, you join our ongoing efforts to make cinema more representative and inclusive." place="right" width={13} data_top={25}
                                    /> */}
                                    </h4>
                                    <p>Our community program helps make independent film more accessible, inclusive, and equitable, bringing free Festival films to local audiences. The director or another team member must participate in a Q&A for any scheduled community screenings.</p>
                                <div className="field_row_inner">
                                    {/* <div className="sd_flex sd_flexwrap">
                                        <div className="question_title">
                                            <label>Would you like your film to be considered for the program? </label>
                                            <span className="sd_width_full sd_textuppercase">(The director or another team member must participate.)</span>
                                        </div>
                                        <Dropdown disable={true} DefaultOption={'Select from Below'} options={state.ScreeningsandTickets} name="flag" value={CommunityAudiences.freeCommunityScreening.flag}  />
                                    </div> */}
                                    <div className="form_screening_note">
                                        <TextArea disabled={true} label={(pafType === 'New Frontier' || pafType === 'Indie Series/Special Events') ? `Are there any specific target audiences that you feel may especially connect with your film? For example, particular cultural communities, language speakers, age groups, etc? If you worked with any specific organizations or community groups, especially those with a Utah presence, please include these as well. Put N/A if not applicable.` : `Are there any specific target audiences that may especially connect with your film/project? For example, particular cultural communities, language speakers, age groups, etc? If you are connected with specific organizations or community groups, especially those with a Utah presence, please include these as well. Put N/A if not applicable.`} name="note" value={CommunityAudiences.freeCommunityScreening.note} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form_content_questions">
                        <div className="form_content_questions_inner">
                            <div className="form_screening">
                                <h4 className="tooltip_title">Utah Student Screenings
                                    {/* <Tooltip id="accessibility" customClass="tooltip-button tooltip-short-program" data="Our student program is a favorite among Festival artists, students, and educators alike. We screen features and short films for free to Utah students in grades 9–12, providing them the chance to experience a new perspective, gain exposure to the art of film, and interact with the creative team behind the story." place="right" width={13} data_top={25}
                                    /> */}
                                    </h4>
                                    <p>{(pafType === 'New Frontier' || pafType === 'Indie Series/Special Events') ? `Our student program is a favorite among Festival artists, students, and educators alike. We screen a selection of features and short films for free to Utah students in grades 9–12, providing them the chance to experience a new perspective, gain exposure to the art of film, and interact with the creative team behind the story. The director or another team member must participate in a Q&A for any scheduled student screenings.` : `Our student program is a favorite among Festival artists, students, and educators alike. We screen a selection of features and short films for free to Utah students in grades 9–12, providing them the chance to experience a new perspective, gain exposure to the art of film, and interact with the creative team behind the story. If your film is selected, the director or another team member must participate in a Q&A for any scheduled student screenings.`}</p>
                                <div className="field_row_inner">
                                    {/* <div className="sd_flex sd_flexwrap">
                                        <div className="question_title">
                                            <label>Would you like your film to be in the student screening program, AND is your film appropriate for 13–18-year-olds? </label>
                                            <span className="sd_textuppercase">(The director or another team member must participate.)</span>
                                        </div>
                                        <Dropdown disable={true}options={state.studentscreening} DefaultOption={'Select from Below'} name="flag" value={CommunityAudiences.studentScreening.flag} />
                                    </div> */}
                                    <div className="form_screening_note">
                                        <TextArea disabled={true} label={(pafType === 'New Frontier' || pafType === 'Indie Series/Special Events') ? "Please note if your film includes strong language, substance abuse, violence (including guns), suicide, sex scenes, and/or nudity." : `Does your film contain content that might be concerning for parents/teachers of children ages 13-18? Please elaborate on the type of content your film has, e.g. strong language, substance abuse, violence (including guns), suicide, sex scenes, and/or nudity. Otherwise, please note "APPROPRIATE" if your film is appropriate for 13-18 year olds.`} name="note" value={CommunityAudiences.studentScreening.note}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <PreviewFormFooter step={7} rootId='downloadStep7'/>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Step7;