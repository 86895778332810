import * as React from "react"

function RemoveFieldIcon(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={14} cy={14} r={14} fill="#04AC80" />
      <path
        d="M9.38 14.04h10.08"
        stroke="#000"
        strokeWidth={2.4}
        strokeLinecap="square"
      />
    </svg>
  )
}

export default RemoveFieldIcon
