import React, { useState,useRef, useEffect  } from 'react';
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import FormHeader from "../../../PAF/Components/FormHeader";
import FormFooter from "../../../PAF/Components/FormFooter";
import TextField from "../../../components/TextField";
import Dropdown from "../../../components/Dropdown";
import TextArea from '../../../components/TextArea';
import SimpleReactValidator from 'simple-react-validator';

import service from '../../../common/service';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessage from './../SuccessMessage';
import FailedMessage from './../FailedMessage';
import {ErrorScroll} from './../../../common/functions';
import Tooltip from '../../../components/Tooltip';
import { COUNTRIES } from '../../../common/constant';
import Checkbox from '../../../components/Checkbox';

let sub
 const Step3=()=> {
    const COUNTRIES_LIST = COUNTRIES
    const ProjectGetDetail = useSelector(e => e.projectGetDetail);
    const apiServe = new service();
    const dispatch = useDispatch();
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [SavedMessage, setSavedMessage] = useState(false)
    const [isSubmitted, setisSubmitted] = useState(ProjectInfo.submitNo)
    const [isNext, setIsNext] = useState(true)
    const [isShare, setIsShare] = useState(false)
     const [formThree, setformThree] = useState({
        title: ProjectInfo.title,
        article: ProjectInfo.article,
        countryOfOrigin: ProjectInfo.countryOfOrigin,
        filmProjectType: ProjectInfo.filmProjectType,
        englishPhoneticProdununciation: ProjectInfo.englishPhoneticProdununciation,
        aspectRatio: ProjectInfo.projectInfo.aspectRatio,
        captionAudioInDCP: ProjectInfo.projectInfo.captionAudioInDCP,
        captionAudioInDCPOther: ProjectInfo.projectInfo.captionAudioInDCPOther,
        color: ProjectInfo.projectInfo.color,
        finalRunTime: ProjectInfo.projectInfo.finalRunTime,
        inPersonFormat: ProjectInfo.projectInfo.inPersonFormat,
        includeStroboscopicImg: ProjectInfo.projectInfo.includeStroboscopicImg,
        allOfYourScreening: ProjectInfo.projectInfo.allOfYourScreening,
        repeatScreening: ProjectInfo.projectInfo.repeatScreening,
        premiereScreening: ProjectInfo.projectInfo.premiereScreening,
        requiredDCP: Boolean(ProjectInfo.projectInfo.requiredDCP),
        requiredDCPAndProResFile: Boolean(ProjectInfo.projectInfo.requiredDCPAndProResFile),
        requiredDCPAndProResFileOther: ProjectInfo.projectInfo.requiredDCPAndProResFileOther,
        requiredDCPOther: ProjectInfo.projectInfo.requiredDCPOther,
        premiereScreeningOther: ProjectInfo.projectInfo.premiereScreeningOther,
        repeatScreeningOther: ProjectInfo.projectInfo.repeatScreeningOther,
        allOfYourScreeningOther: ProjectInfo.projectInfo.allOfYourScreeningOther,
        language: ProjectInfo.projectInfo.language,
        onlineFormat: ProjectInfo.projectInfo.onlineFormat,
        premiereStatus: ProjectInfo.projectInfo.premiereStatus,
        soundFormat: ProjectInfo.projectInfo.soundFormat,
        timecodeOccursInProject: ProjectInfo.projectInfo.timecodeOccursInProject,
        yearFinished:ProjectInfo.projectInfo.yearFinished,
        runTime:ProjectInfo.projectInfo.runTime,
        subtitles:ProjectInfo.projectInfo.subtitles

     })
     console.log(`formThree.captionAudioInDCP`,typeof formThree.captionAudioInDCP)
     const [digitalMedia, setDigitalMedia] = useState({
        facebook: ProjectInfo.digitalMedia.facebook.replace('https://www.',''),
        hastag: ProjectInfo.digitalMedia.hastag,
        instagram: ProjectInfo.digitalMedia.instagram,
        officialWebsite: ProjectInfo.digitalMedia.officialWebsite.replace('https://www.',''),
        twitter: ProjectInfo.digitalMedia.twitter,
        youtubeOrVimeoTrailer: ProjectInfo.digitalMedia.youtubeOrVimeoTrailer.replace('https://www.',''),
        tiktok: ProjectInfo.digitalMedia.tiktok,
     })
    const [state] = useState({
        PremiereStatus:['World Premiere','International Premiere','North American Premiere','U.S. Premiere','None'],
        InpersonFormat:['DCP 24fps','DCP 25fps','DCP 30fps','Other'],
        OnlineFormat:['Apple Pro Res LT 24fps','Apple Pro Res LT 25fps','Apple Pro Res LT 30fps'],
        AspectRatio:['Flat - 2.0',' Flat - 1.85','Flat - 1.78','Flat - 1.66','Flat - 1.37','Scope - 2.35','Other',"Don't Know"],
        SoundFormat:['7.1', '5.1', '3', '2','Mono','Atmos',' Other',"Don't Know"],
        Color:['Color','Black & White', 'Color & Black & White'],
        Subtitles:['Yes','No','Some - Mixed Language'],
        PremiereOpenCaption: ['Yes', 'No'],
        RepeatOpenCaption: ['Yes', 'No'],
        allOfYourScreeningOther: ['Yes, I would like all of our screenings to be Open Caption', 'No, I would like only one of our screenings to be Open Caption'],
        FinalRunTime:['Yes','No'],
        captions:["Yes, Closed Captioning", "Yes, Audio Descriptive Services","Yes, Both","Not Available"],
        stroboscopic:['Yes','No'],

    })
    const [failedMessage, setFailedMessage] = useState(false)
    useEffect(() => {
        setformThree({
            title: ProjectInfo.title,
            article: ProjectInfo.article,
            countryOfOrigin: ProjectInfo.countryOfOrigin,
            filmProjectType: ProjectInfo.filmProjectType,
            englishPhoneticProdununciation: ProjectInfo.englishPhoneticProdununciation,
            aspectRatio: ProjectInfo.projectInfo.aspectRatio,
            captionAudioInDCP: ProjectInfo.projectInfo.captionAudioInDCP,
            captionAudioInDCPOther: ProjectInfo.projectInfo.captionAudioInDCPOther,
            color: ProjectInfo.projectInfo.color,
            finalRunTime: ProjectInfo.projectInfo.finalRunTime,
            inPersonFormat: ProjectInfo.projectInfo.inPersonFormat,
            includeStroboscopicImg:ProjectInfo.projectInfo.includeStroboscopicImg,
            allOfYourScreening: ProjectInfo.projectInfo.allOfYourScreening,
            repeatScreening: ProjectInfo.projectInfo.repeatScreening,
            premiereScreening: ProjectInfo.projectInfo.premiereScreening,
            requiredDCP: Boolean(ProjectInfo.projectInfo.requiredDCP),
            requiredDCPAndProResFile: Boolean(ProjectInfo.projectInfo.requiredDCPAndProResFile),
            requiredDCPAndProResFileOther: ProjectInfo.projectInfo.requiredDCPAndProResFileOther,
            requiredDCPOther: ProjectInfo.projectInfo.requiredDCPOther,
            premiereScreeningOther: ProjectInfo.projectInfo.premiereScreeningOther,
            repeatScreeningOther: ProjectInfo.projectInfo.repeatScreeningOther,
            allOfYourScreeningOther: ProjectInfo.projectInfo.allOfYourScreeningOther,
            language: ProjectInfo.projectInfo.language,
            onlineFormat: ProjectInfo.projectInfo.onlineFormat,
            premiereStatus: ProjectInfo.projectInfo.premiereStatus,
            soundFormat: ProjectInfo.projectInfo.soundFormat,
            timecodeOccursInProject: ProjectInfo.projectInfo.timecodeOccursInProject,
            yearFinished:ProjectInfo.projectInfo.yearFinished,
            runTime:ProjectInfo.projectInfo.runTime,
            subtitles:ProjectInfo.projectInfo.subtitles })
            setDigitalMedia({
                facebook: ProjectInfo.digitalMedia.facebook.replace('https://www.',''),
                hastag: ProjectInfo.digitalMedia.hastag,
                instagram: ProjectInfo.digitalMedia.instagram,
                officialWebsite: ProjectInfo.digitalMedia.officialWebsite.replace('https://www.',''),
                twitter: ProjectInfo.digitalMedia.twitter,
                youtubeOrVimeoTrailer: ProjectInfo.digitalMedia.youtubeOrVimeoTrailer.replace('https://www.',''),
                tiktok: ProjectInfo.digitalMedia.tiktok,
            })
        setisSubmitted(ProjectInfo.submitNo)
        // eslint-disable-next-line
    }, [ProjectInfo])
    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|allUpdate":
                    if (!response.error) {
                        dispatch({ type: 'FORM_DATA', payload: response.data })
                        setSavedMessage(true)
                        setTimeout(() => {
                            setSavedMessage(false)
                            setIsShare(false)
                        }, 1000);
                        setIsNext(true)
                    }else{
                        setIsNext(false)
                        setFailedMessage(true)
                        setTimeout(() => {
                            setFailedMessage(false)
                        }, 2000);
                    }
                    break;
                default:
                    break;
            }
        })
        setisSubmitted(ProjectInfo.submitNo)
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])
    
    const handleChange=(e)=>{
        const { name, value } = e.target
        setformThree({ ...formThree, [name]: value })
        forceUpdate()
        console.log(`formThree`, formThree.includeStroboscopicImg)
    }
    const handleCheckBox = (e) => {
        const { name, value, checked } = e.target
        setformThree({ ...formThree, [name]: checked })
        forceUpdate()
    }
  const handleLink=(e)=>{
    let { name, value } = e.target
    value = (name === "youtubeOrVimeoTrailer" || name === "facebook" || name === "officialWebsite") ? value.replace('https://www.','') : value
    setDigitalMedia({ ...digitalMedia, [name]: value })
    console.log(`DigitalMedia`, digitalMedia)
  }
 
  const  getYears = () => {
        var years = [];
        var startYear =  new Date().getFullYear()+2;;
         // const year = new Date().getFullYear();
         const year = 1900;

        for (var start = startYear; start >= year; start--) {
          years.push(start.toString());
        }

        return years;
    };
    const year = getYears(); 
    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        messages:{
            accepted:'This Field is required'
        },
        validators: {
            premiereStatus: {  // name the rule
                message: 'Premiere Status is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            yearFinished: {  // name the rule
                message: 'Year Finished is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            inPersonFormat: {  // name the rule
                message: 'In person Format is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
              
                required: true  // optional
            },
            onlineFormat: {  // name the rule
                message: 'Online Format is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
              
            },
            aspectRatio: {  // name the rule
                message: 'Aspect Ratio is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
                
            },
            soundFormat: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
                
            },
            color: {  // name the rule
                message: 'Color is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
                
            },
            runTime: {  // name the rule
                message: 'Run Time is required. ',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
                
            },
            finalRunTime: {  // name the rule
                message: 'Final Run Time is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
                
            },
            language: {  // name the rule
                message: 'Language is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
                
            },
            subtitles: {  // name the rule
                message: 'Subtitles is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
                
            },
            includeStroboscopicImg: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
                
            },
        }
    }))
    const checkValidate = () => {
        if (Validator.current.allValid()) {
            return true
        } else {
            Validator.current.showMessages()
            forceUpdate()
            setTimeout(() => {
                ErrorScroll()
            }, 500);
            return false
        }
    }
    const onFormSave = (NAV) => {

        // if (Validator.current.allValid()) {
            let data = {
                projectInfo: formThree,
                projectId:ProjectInfo._id,
                digitalMedia:{...digitalMedia, facebook:digitalMedia.facebook !== '' ? "https://www."+digitalMedia.facebook : digitalMedia.facebook, officialWebsite:digitalMedia.officialWebsite !== '' ? "https://www."+digitalMedia.officialWebsite : digitalMedia.officialWebsite, youtubeOrVimeoTrailer:digitalMedia.youtubeOrVimeoTrailer !== "" ? "https://www."+digitalMedia.youtubeOrVimeoTrailer : digitalMedia.youtubeOrVimeoTrailer},
                savePageNo:'3'
            };

            setDigitalMedia((last) => {
               data = {
                    ...data,
                    digitalMedia:last,
                }
                return last
            })
            setformThree((last) => {
                data = {
                    ...data,
                   projectInfo: last
                }
                return last
            });
            forceUpdate();
            if (NAV === 'NEXT') {
                data.completedStepNo =  3
            }else if(NAV === 'SHARE'){
                setIsShare(true)
            }
            apiServe.sendevent('paf|project|allUpdate', data)
        // } else {
        //     Validator.current.showMessages()
        //     forceUpdate()

        // }
    }
    console.log(`formThree`, formThree)
    const closeSuccessMessage = () =>{
        setSavedMessage(false)
    }
    const closeFailedMessage = () => {
        setFailedMessage(false)
    }

    return (
        <div>
            <Header />
            <FormHeader step={3}/>
            {!isShare && <SuccessMessage active={SavedMessage} close={closeSuccessMessage} text={'Save'}/>}
            <FailedMessage active={failedMessage} close={closeFailedMessage} text={'Save'} />
           
            <div className="container form_inner step_common">
                <div className='underline'>
                    <div className='step sd_justbetween' >
                        <div className='step_no'>
                            <p>Page 3 of 9</p>
                        </div>
                        <div className='step_des'>
                            <p>After you submit at the end of Page 9, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons.</p>

                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival, and will be placed in the Sundance Institute Archives.</p>
                        </div>
                    </div>
                </div>
                <div className="film_deatil_form">
                    <div className="form_inner_title">
                        <h3 className="sd_flex sd_aligncenter">Film/Project Title</h3>
                    </div>
                    <div className="film_deatil_form_inner">
                        <div className="sd_flex f_detail_f_row sd_justbetween">
                            <TextField disabled={true} uppercase={true} label="article" name="article" value={formThree.article} required="false" />
                            <TextField disabled={true} uppercase={true} label="Title" name="title" value={formThree.title} required="false" />
                        </div>
                        <p className='mb_25'>Submit in title case (i.e., Best Film Ever, not all caps, i.e., BEST FILM EVER). If your title begins with an article ("A," "The," or an equivalent in another language), please enter that word in the Article field.</p>
                        <div className="sd_flex f_detail_s_row sd_justbetween">
                            <Dropdown disable={true} uppercase={true} name="filmProjectType" label="Film/Project Type" options={ProjectGetDetail.pafProjectType} value={formThree.filmProjectType} />
                            <TextField disabled={true} uppercase={true} name='englishPhoneticProdununciation' value={formThree.englishPhoneticProdununciation} label="English Phonetic Pronunciation" required="false" />
                        </div>

                        {formThree.countryOfOrigin?.map((item, i) => {
                            return (
                                <>
                                    <div className="sd_flex sd_aligncenter f_detail_country_row">
                                        <Dropdown key={item._id} disable={true} name='Country of Origin' value={item.country ? item.country : ''} uppercase={true} optionID={item.id} label="Country of Origin" DefaultOption={'Select Country'} options={COUNTRIES_LIST} />
                                    </div>
                                    <p className='mb_25 mt_10'>Country of origin is based on where the majority of the project’s financing originated. If your project was financed in the United States but produced in another country, you may list both countries in the order you choose. Up to 4 Countries may be listed.</p>
                                </>)
                        })}
                    </div>
                </div>
                <div className="project_info_form">
                    <div className="form_inner_title">
                        <h3 className="sd_flex sd_aligncenter">Project Info</h3>
                    </div>
                    <div className="project_info_form_inner sd_container_990 ">
                        <div className="project_info_form_inner_top">
                            <div className="sd_flex top_2_field">
                                <Dropdown require={true} name="premiereStatus" disable={true} value={formThree.premiereStatus} onChange={handleChange} label="Premiere Status" uppercase={true}
                                toolId={'premiereStatus'}  options={state.PremiereStatus} DefaultOption={'Select From Below'} />
                                <Dropdown require={true} disable={isSubmitted > 1 ? true : false}  name="yearFinished" value={formThree.yearFinished} onChange={handleChange}label="Year Finished"  uppercase={true}  fpgerror={Validator.current.message('yearFinished', formThree.yearFinished , 'yearFinished|required')} options={year} DefaultOption={'Select From Below'}/>
                                
                 
                            </div>
                            <p className='pl_25 mb_25'>
                                <ul>
                                    <li><strong>World Premiere:</strong> Completed film/project has never exhibited publicly before</li>
                                    <li><strong>International Premiere:</strong>  Completed film/project has never screened publicly outside of its country of origin  </li>
                                    <li><strong>North American Premiere:</strong> Completed film/project has never screened publicly in North America (Canada, U.S.A., or Mexico)</li>
                                    <li><strong> U.S. Premiere: </strong> Completed film/project has never screened publicly in the U.S.A.</li>
                                    <li><strong> None: </strong>  Completed film/project has screened publicly in the U.S.A. </li>
                                </ul>
                            </p>
                            {/*<div className="sd_flex sd_justbetween">
                                <Dropdown require={true} disable={isSubmitted > 1 ? true : false}  name="inPersonFormat" value={formThree.inPersonFormat} onChange={handleChange} label="In-Person Format" uppercase={true} fpgerror={Validator.current.message('inPersonFormat', formThree.inPersonFormat,'inPersonFormat|required')} DefaultOption={'Select From Below'} options={state.InpersonFormat} />
                              
                                <Dropdown require={true} disable={isSubmitted > 1 ? true : false}  name="onlineFormat" value={formThree.onlineFormat} onChange={handleChange} label="Online Format" uppercase={true} fpgerror={Validator.current.message('inPersonFormat', formThree.onlineFormat,'onlineFormat|required')} DefaultOption={'Select From Below'} options={state.OnlineFormat}/>
                                <Dropdown require={true} disable={isSubmitted > 1 ? true : false}  name="aspectRatio" value={formThree.aspectRatio} onChange={handleChange} label="Aspect Ratio" uppercase={true} fpgerror={Validator.current.message('aspectRatio', formThree.aspectRatio,'aspectRatio|required')} DefaultOption={'Select From Below'} options={state.AspectRatio}/>
                            </div> */}
                            <div className="sd_flex sd_aligncenter top_2_field">
                                {/* <Dropdown require={true} disable={isSubmitted > 1 ? true : false}  name="soundFormat" value={formThree.soundFormat} onChange={handleChange}  label="DCP or 35mm Sound Format" uppercase={true} fpgerror={Validator.current.message('soundFormat', formThree.soundFormat,'soundFormat|required')} DefaultOption={'Select From Below'} options={state.SoundFormat}/> */}
                                <Dropdown  require={true} disable={isSubmitted > 1 ? true : false}  name="color" value={formThree.color} onChange={handleChange} label="color" uppercase={true} fpgerror={Validator.current.message('color', formThree.color,'color|required')} DefaultOption={'Select From Below'} options={state.Color} />
                                <TextField require={true} disabled={isSubmitted > 1 ? true : false} type="text"  name="runTime" value={formThree.runTime} onChange={handleChange} uppercase={true} fpgerror={Validator.current.message('runTime', formThree.runTime,'runTime|numeric|required')} label="Run Time (mins)" required="false"  handleChange={handleChange}/>
                                
                            </div>
                            <div className="sd_flex sd_aligncenter sd_justbetween">
                              <TextField require={true} disabled={isSubmitted > 1 ? true : false} type="text"  name="language" value={formThree.language} onChange={handleChange}  uppercase={true} fpgerror={Validator.current.message('language', formThree.language,'language|required')} label="language"required="false" handleChange={handleChange} />
                              <Dropdown require={true} disable={isSubmitted > 1 ? true : false}   name="subtitles" label="Subtitles (for non English spoken)" value={formThree.subtitles}  onChange={handleChange} uppercase={true} fpgerror={Validator.current.message('subtitles', formThree.subtitles,'subtitles|required')} DefaultOption={'Select From Below'} options={state.Subtitles} />
                                <Dropdown require={true} disable={isSubmitted > 1 ? true : false}  name="finalRunTime" value={formThree.finalRunTime}  onChange={handleChange} label="Final Run Time ?"  uppercase={true} fpgerror={Validator.current.message('finalRunTime', formThree.finalRunTime,'finalRunTime|required')} DefaultOption={'Select From Below'} options={state.FinalRunTime} />
           
                            </div>
                        </div>
                        <div className="project_info_form_inner_bottom">
                            <div className='sd_page3_heading'>
                                <p><i>For Festival participation, English Language Projects are required to deliver their DCP for in-person screenings with Closed Captions (CCAP) AND Open Captions (OCAP), and their ProRes file for online screenings with Closed Captions (CC). Non English language Projects must provide BOTH standard English subtitles and SDH Subtitles, (Subtitles for the deaf and hard of hearing).</i></p>
                                <p><i>The Festival will schedule at least one in-person Open Caption screening of every Project in the Festival program. The timing of these screenings will be confirmed by the end of December, 2024.</i></p>
                            </div>
                            <div className="sd_flex">
                                <div className="">
                                <Checkbox disable={''}  type="checkbox" onChange={(handleCheckBox)} name={'requiredDCPAndProResFile'} label={'I understand that Closed Captions are required for our DCP (in-person) & ProRes file (online). Closed Captions show spoken English and audio descriptions for Deaf and hard of hearing viewers. I understand and agree that if the Closed Caption file is faulty in any way, Sundance Institute may elect to use the Open Caption file for all in- person public screenings. *'} value={Boolean(formThree.requiredDCPAndProResFile)} checked={Boolean(formThree.requiredDCPAndProResFile)} id={'requiredDCPAndProResFile'} />
                                <span style={{ color: 'red', display : "block",marginBottom: "8px" }}>{Validator.current.message('requiredDCPAndProResFile', formThree.requiredDCPAndProResFile,  'accepted')}</span>
                                </div>
                            </div>
                            {formThree.requiredDCPAndProResFile && <TextArea disabled={isSubmitted > 1 ? true : false} name="requiredDCPAndProResFileOther" value={formThree.requiredDCPAndProResFileOther} handleChange={handleChange} />}
                            <div className="sd_flex">
                                <div className="">
                                <Checkbox disable={''}  type="checkbox" onChange={handleCheckBox} name={'requiredDCP'} label={'I understand that an Open Caption version of our DCP is required. Open Captions show spoken English and audio descriptions on screen for Deaf and hard of hearing viewers at in-person screenings. Non-English language projects are required to provide an SDH ("Subtitles for the deaf and hard of hearing") version to be used for Open Caption screenings. *'} value={Boolean(formThree.requiredDCP)} checked={Boolean(formThree.requiredDCP)} id={'requiredDCP'} />
                                <span style={{ color: 'red' ,display : "block", marginBottom: "8px"}}>{Validator.current.message('requiredDCP', formThree.requiredDCP,  'accepted')}</span>
                                </div>
                            </div>
                            {formThree.requiredDCP && <TextArea disabled={isSubmitted > 1 ? true : false} name="requiredDCPOther" value={formThree.requiredDCPOther} handleChange={handleChange} />}
                            <div className="sd_flex">
                                <div className="left_content">
                                    <p className='required'>Will your DCP or ProRes include an Audio Descriptive track for Blind and low vision viewers? We encourage but do not require Audio Descriptive tracks at this time.</p>
                                </div>
                                <div className="right_content">
                                    <Dropdown require={true} disable={isSubmitted > 1 ? true : false} name="captionAudioInDCP" value={formThree.captionAudioInDCP} DefaultOption={'Select From Below'} fpgerror={Validator.current.message('captionAudioInDCP', formThree.captionAudioInDCP, 'includeStroboscopicImg|required')} onChange={handleChange} options={state.stroboscopic} />
                                    {formThree.captionAudioInDCP === 'Yes' && <TextArea disabled={isSubmitted > 1 ? true : false} name="captionAudioInDCPOther" value={formThree.captionAudioInDCPOther} handleChange={handleChange} />}
                                </div>
                            </div>
                        </div>
                        <div className="project_info_form_inner_bottom">
                            {/* <div className="sd_flex">
                                <div className="left_content">
                                    <p className='required'>Would you like your Premiere screening to be an Open Caption screening?</p>
                                </div>
                                <div className="right_content">
                                    <Dropdown require={true} disable={isSubmitted > 1 ? true : false} name="premiereScreening" value={formThree.premiereScreening} DefaultOption={'Select From Below'} fpgerror={Validator.current.message('premiereScreening', formThree.premiereScreening, 'includeStroboscopicImg|required')} onChange={handleChange} options={state.PremiereOpenCaption} />
                                    {formThree.premiereScreening === 'Yes' && <TextArea disabled={isSubmitted > 1 ? true : false} name="premiereScreeningOther" value={formThree.premiereScreeningOther} handleChange={handleChange} />}
                                </div>
                            </div>
                            <div className="sd_flex">
                                <div className="left_content">
                                    <p className='required'>Would you like one of your Repeat screenings to be Open Caption?</p>
                                </div>
                                <div className="right_content">
                                    <Dropdown require={true} disable={isSubmitted > 1 ? true : false} name="repeatScreening" value={formThree.repeatScreening} DefaultOption={'Select From Below'} onChange={handleChange} fpgerror={Validator.current.message('repeatScreening', formThree.repeatScreening, 'includeStroboscopicImg|required')} options={state.RepeatOpenCaption} />
                                    {formThree.repeatScreening === 'Yes' && <TextArea disabled={isSubmitted > 1 ? true : false} name="repeatScreeningOther" value={formThree.repeatScreeningOther} handleChange={handleChange} />}
                                </div>
                            </div>
                            <div className="sd_flex">
                                <div className="left_content">
                                    <p className='required'>Would you like ALL of your screenings to be Open Caption?</p>
                                </div>
                                <div className="right_content">
                                    <Dropdown require={true} disable={isSubmitted > 1 ? true : false} name="allOfYourScreening" value={formThree.allOfYourScreening} DefaultOption={'Select From Below'} onChange={handleChange} fpgerror={Validator.current.message('allOfYourScreening', formThree.allOfYourScreening, 'includeStroboscopicImg|required')} options={state.allOfYourScreeningOther} />
                                    {formThree.allOfYourScreening === 'Yes, I would like all of our screenings to be Open Caption' && <TextArea disabled={isSubmitted > 1 ? true : false} name="allOfYourScreeningOther" value={formThree.allOfYourScreeningOther} handleChange={handleChange} />}
                                </div>
                            </div> */}
                            <div className="sd_flex">
                                <div className="left_content">
                                    <p className='required'>Does your project include stroboscopic images or extensive light flickering that lasts longer than 5 seconds?</p>
                                    <span className="sd_textuppercase">(We use this information to alert viewers who are sensitive.)</span>
                                </div>
                                <div className="right_content">
                                    <Dropdown require={true} disable={isSubmitted > 1 ? true : false} name="includeStroboscopicImg" value={formThree.includeStroboscopicImg} DefaultOption={'Select From Below'} fpgerror={Validator.current.message('includeStroboscopicImg', formThree.includeStroboscopicImg, 'includeStroboscopicImg|required')} onChange={handleChange} options={state.stroboscopic} />
                                </div>
                            </div>
                            <div className="sd_flex sd_aligncenter">
                                <div className="left_content">
                                    <p className={`${formThree.includeStroboscopicImg === 'Yes' ? 'required' : null}`}>If yes, provide timecode(s) where it occurs in project:</p>
                                </div>
                                <div className="right_content">
                                    <TextArea disabled={isSubmitted > 1 ? true : false} name="timecodeOccursInProject" value={formThree.timecodeOccursInProject} handleChange={handleChange} />
                                    <span style={{ color: 'red' }}>{Validator.current.message('timecodeOccursInProject', formThree.timecodeOccursInProject, formThree.includeStroboscopicImg === 'Yes' ? 'soundFormat|required' : '')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="digital_media_form">
                    <div className="form_inner_title">
                        <h3 className="sd_flex sd_aligncenter">Digital Media <span className="sd_textuppercase">*Please do not include personal or company pages/accounts</span></h3>
                        <p>When you provide this information, the Sundance Film Festival will consider it public and may tag our posts with this information.</p>
                    </div>
                    <div className="digital_media_form_inner">
                        <TextField disabled={isSubmitted > 1 ? true : false} type="url"   name="officialWebsite" value={digitalMedia.officialWebsite}  handleChange={handleLink} placeholder="" uppercase={false} label="Official Website" required="false" prefix={true}/>
                        <TextField disabled={isSubmitted > 1 ? true : false} type="url"   name="youtubeOrVimeoTrailer" value={digitalMedia.youtubeOrVimeoTrailer}  handleChange={handleLink}  placeholder="" uppercase={false} label="YouTube or Vimeo" required="false" prefix={true}/>
                        <TextField disabled={isSubmitted > 1 ? true : false} type="text"  name="instagram" value={digitalMedia.instagram}  handleChange={handleLink}  placeholder=""uppercase={false} label="Instagram" required="false" />
                        <TextField disabled={isSubmitted > 1 ? true : false} type="url"   name="facebook" value={digitalMedia.facebook}  handleChange={handleLink}  placeholder="" uppercase={false} label="Facebook" required="false" prefix={true}/>
                        <TextField disabled={isSubmitted > 1 ? true : false} type="text"  name="twitter" value={digitalMedia.twitter}  handleChange={handleLink}  placeholder="" uppercase={false} label='X (Formerly "Twitter")' required="false" />
                        <TextField disabled={isSubmitted > 1 ? true : false} type="text" name="tiktok" value={digitalMedia.tiktok} handleChange={handleLink} placeholder="" uppercase={false} label="Tiktok" required="false" />
                        <TextField disabled={isSubmitted > 1 ? true : false} type="text"  name="hastag" value={digitalMedia.hastag}  handleChange={handleLink}  placeholder=""uppercase={false} label="Hashtag" required="false" />

                    </div>
                </div>
                <FormFooter step={3} onFormSave={onFormSave} isNext={isNext} checkValidate={checkValidate} />
            </div>
            <Footer />
        </div>
    )
}

export default  Step3;                          