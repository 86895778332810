import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../layout/Header'
import Footer from "../../../layout/Footer";
import FormHeader from './../FormHeader';
import Dropdown from '../../../components/Dropdown';
import FormFooter from './../FormFooter';
import TextField from '../../../components/TextField';
import { useDispatch, useSelector } from 'react-redux';
import service from '../../../common/service';
import SimpleReactValidator from 'simple-react-validator';
import FullName from './../FullName';
import { COUNTRIES, STATE_PRO } from '../../../common/constant';
import SuccessMessage from './../SuccessMessage';
import RemoveFieldIcon from '../../../icons/RemoveFieldIcon';
import PlusFieldIcon from '../../../icons/PlusFieldIcon';
import FailedMessage from './../FailedMessage';
import { ErrorScroll } from './../../../common/functions'
let sub
let data = {
    email: '',
    address1: '',
    phoneNumber: '',
    city: '',
    country: '',
    state: '',
    zipCode: '',
}
let newFrontierData = {
    email: '',
    address1: '',
    phoneNumber: '',
    city: '',
    country: '',
    state: '',
    zipCode: '',
}


const TeamForm = (props) => {
    const { CreditData, index, onChange, item, Error, nameDisabled, disabled, required } = props
    let COUNTRIES_LIST = COUNTRIES
    let STATE_LIST = STATE_PRO
    console.log("CreditData123123", CreditData);
    return (
        <div className="border_bottom_gray team_info_section">
            {/* <div className="form_content_top">
                <div class="form_content_top_title pb_0">
                    <i>Screenwriter 1</i>
                </div>
            </div> */}
            <div className="sd_flex sd_aligncenter associate_list">
                <FullName credit={CreditData} onChange={onChange} item={item} disabled={nameDisabled} values={CreditData} title={`${item.creditLabel === 'ScreenWriter' ? 'screenwriter' : item.creditLabel} ${index === false ? '' : index + 1}`} onSalesAgentAdd={props.onSalesAgentAdd} index={index} multiPal={props.multiPal} />
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField disabled={disabled} credit={CreditData} item={item} type="text" name="title" handleChange={onChange} value={CreditData.creditDetails?.title} label="Title" uppercase />
                <TextField disabled={disabled} credit={CreditData} item={item} type="text" name="company" handleChange={onChange} value={CreditData.creditDetails?.company} label="Company" uppercase />

            </div>
            <div className="sd_flex fullname_div one_field">
                <TextField require={required} disabled={disabled} credit={CreditData} errorValidation={Error?.Email} item={item} type="text" name="email" handleChange={onChange} value={CreditData.creditDetails?.email} label="Email" uppercase />
            </div>
            <div className="sd_flex fullname_div one_field">
                <TextField disabled={disabled} require={required} credit={CreditData} item={item} type="text" name="address1" errorValidation={Error?.address} handleChange={onChange} value={CreditData.creditDetails?.address1} label="Address 1" uppercase />
            </div>
            <div className="sd_flex fullname_div one_field">
                <TextField disabled={disabled} credit={CreditData} item={item} type="text" name="address2" handleChange={onChange} value={CreditData.creditDetails?.address2} label="Address 2" uppercase />
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField disabled={disabled} require={required} credit={CreditData} item={item} errorValidation={Error?.City} type="text" name="city" handleChange={onChange} value={CreditData.creditDetails?.city} label="City" uppercase />
                <Dropdown require={(required && (CreditData.creditDetails?.country === 'United States' || CreditData.creditDetails?.country === 'Canada')) ? true : false} disable={disabled} credit={CreditData} item={item} fpgerror={CreditData.creditDetails?.country === 'United States' || CreditData.creditDetails?.country === 'Canada' ? Error?.Province : ''} options={STATE_LIST} name="state" onChange={onChange} value={CreditData.creditDetails?.state} label="State/Province" DefaultOption={'Select State/Province'} uppercase />
                {/*
                {CreditData.creditDetails?.country === 'United States' || CreditData.creditDetails?.country === 'Canada' ?
                    <Dropdown disable={disabled} credit={CreditData} item={item} fpgerror={CreditData.creditDetails?.country === 'United States' || CreditData.creditDetails?.country === 'Canada' ? Error?.Province : ''} options={STATE_LIST} name="state" onChange={onChange} value={CreditData.creditDetails?.state} label="State/Province" DefaultOption={'Select State/Province'} uppercase /> :
                    <TextField disabled={disabled} credit={CreditData} item={item} type="text" name="state" handleChange={onChange} value={CreditData.creditDetails?.state} label="State/Province" uppercase />} */}
                {/* <TextField disabled={disabled} errorValidation={Error?.Province} type="text" name="state" handleChange={onChange} value={CreditData.state} label="State/Province" uppercase /> */}
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField require={required} disabled={disabled} credit={CreditData} item={item} errorValidation={Error?.Zip} type="text" name="zipCode" handleChange={onChange} value={CreditData.creditDetails?.zipCode} label="Zip Code" uppercase />
                <Dropdown require={required} disable={disabled} label="Country" name='country' credit={CreditData} item={item} onChange={onChange} fpgerror={Error?.Country} value={CreditData.creditDetails?.country} uppercase options={COUNTRIES_LIST} DefaultOption={'Select Country'} />
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField require={required} pattern="[0-9]*" disabled={disabled} credit={CreditData} item={item} errorValidation={Error?.Mobile} type="text" name="phoneNumber" handleChange={onChange} value={CreditData.creditDetails?.phoneNumber} label="Phone Number" uppercase />
                <Dropdown disable={disabled} credit={CreditData} item={item} label="Type" name='type' onChange={onChange} uppercase value={CreditData.creditDetails?.type} options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
            </div>
        </div>
    )
}

const TeamFormExtra = (props) => {
    const { CreditData, onChange, Error, disabled, salesAgent } = props
    let COUNTRIES_LIST = COUNTRIES
    let STATE_LIST = STATE_PRO

    return (
        <div className="border_bottom_gray team_info_section">
            {/* <div className="form_content_top">
                <div class="form_content_top_title pb_0">
                    <i>Screenwriter 1</i>
                </div>
            </div> */}

            <div className="sd_flex sd_aligncenter associate_list">
                <div className="sd_container_990">
                    <div className='sd_flex'>
                        <p className="form_sub_2_title">{props.title}</p> {props.multiPal ? <>
                            {props.index === 2 ? '' : <span style={{ marginLeft: 10, cursor: salesAgent?.length >= 3 ? 'not-allowed' : 'pointer' }} className='sd_p_cursor' onClick={() => salesAgent?.length >= 3 ? '' : props.onSalesAgentAdd('ADD', CreditData._id)}><PlusFieldIcon /></span>}
                            {props.index === 0 ? '' : <span style={{ marginLeft: 10 }} className='sd_p_cursor' onClick={() => props.onSalesAgentAdd('SUB', CreditData._id)}><RemoveFieldIcon /></span>}  </> : ' '}
                    </div>
                    <div className="sd_flex fullname_div sd_justbetween">
                        <TextField data_id={CreditData?._id} handleChange={onChange} errorValidation={props.Errors?.FirstName} value={CreditData.firstName} name='firstName' disabled={props.disabled} uppercase={true} label="First Name" required="false" />
                        <TextField data_id={CreditData?._id} handleChange={onChange} value={CreditData.middleName} name='middleName' disabled={props.disabled} uppercase={true} label="Middle Name" required="false" />
                        <TextField data_id={CreditData?._id} handleChange={onChange} errorValidation={props.Errors?.LastName} value={CreditData.lastName} name='lastName' disabled={props.disabled} uppercase={true} label="Last Name" required="false" />
                    </div>
                </div>
            </div>

            {props.title === 'Distributor/ Gallery/ Institution' ?
                <div className="sd_flex fullname_div two_field">
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="company" handleChange={onChange} value={CreditData.company} label="Company / Gallery Name" uppercase />

                </div> : props.title.includes('Sales Agent') ? <div className="sd_flex fullname_div two_field">
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="title" handleChange={onChange} value={CreditData.title} label="Title" uppercase />
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="company" handleChange={onChange} value={CreditData.company} label="Company" uppercase />
                    <Dropdown optionID={CreditData?._id} disable={disabled} options={['International', 'Domestic', 'Both']} name="typeofsale" onChange={onChange} value={CreditData.typeofsale} label="Type of Sales" DefaultOption={'Select From Below'} uppercase />

                </div> : <div className="sd_flex fullname_div two_field">
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="title" handleChange={onChange} value={CreditData.title} label="Title" uppercase />
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="company" handleChange={onChange} value={CreditData.company} label="Company" uppercase />

                </div>}
            <div className="sd_flex fullname_div one_field">
                <TextField data_id={CreditData?._id} disabled={disabled} errorValidation={Error?.Email} type="text" name="email" handleChange={onChange} value={CreditData.email} label="Email" uppercase />
            </div>
            <div className="sd_flex fullname_div one_field">
                <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="address1" errorValidation={Error?.address} handleChange={onChange} value={CreditData.address1} label="Address 1" uppercase />
            </div>
            <div className="sd_flex fullname_div one_field">
                <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="address2" handleChange={onChange} value={CreditData.address2} label="Address 2" uppercase />
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField data_id={CreditData?._id} disabled={disabled} errorValidation={Error?.City} type="text" name="city" handleChange={onChange} value={CreditData.city} label="City" uppercase />
                <Dropdown optionID={CreditData?._id} disable={disabled} fpgerror={Error?.Province} options={STATE_LIST} name="state" onChange={onChange} value={CreditData.state} label="State/Province" DefaultOption={'Select State/Province'} uppercase />
                {/* {CreditData.country === 'United States' || CreditData.country === 'Canada' ?
                    <Dropdown optionID={CreditData?._id} disable={disabled} fpgerror={Error?.Province} options={STATE_LIST} name="state" onChange={onChange} value={CreditData.state} label="State/Province" DefaultOption={'Select State/Province'} uppercase /> :
                    <TextField data_id={CreditData?._id} disabled={disabled} type="text" name="state" handleChange={onChange} value={CreditData.state} label="State/Province" uppercase />} */}
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField data_id={CreditData?._id} disabled={disabled} errorValidation={Error?.Zip} type="text" name="zipCode" handleChange={onChange} value={CreditData.zipCode} label="Zip Code" uppercase />
                <Dropdown optionID={CreditData?._id} disable={disabled} label="Country" name='country' onChange={onChange} fpgerror={Error?.Country} value={CreditData.country} uppercase options={COUNTRIES_LIST} DefaultOption={'Select Country'} />
            </div>
            <div className="sd_flex fullname_div two_field">
                <TextField data_id={CreditData?._id} disabled={disabled} pattern="[0-9]*" errorValidation={Error?.Mobile} type="text" name="phoneNumber" handleChange={onChange} value={CreditData.phoneNumber} label="Phone Number" uppercase />
                <Dropdown optionID={CreditData?._id} disable={disabled} label="Type" name='type' onChange={onChange} uppercase value={CreditData.type} options={['Office', 'Mobile', 'Other']} DefaultOption={'Select From Below'} />
            </div>
        </div>
    )
}



function Step6() {

    const ProjectInfo = useSelector(e => e.ProjectInfo);
    // const ProjectGetDetail = useSelector(e => e.projectGetDetail);
    const dispatch = useDispatch();
    // const history = useHistory()
    const [SavedMessage, setSavedMessage] = useState(false)
    const apiServe = new service();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const pafType = useSelector(e => e.pafType);
    const [isNext, setIsNext] = useState(true)
    const [isFirstLastEmpty, setIsFirstLastEmpty] = useState(false)
    const [isShare, setIsShare] = useState(false)

    // const [SalesAgentExist, setSalesAgentExist] = useState(false)
    // const [DGIExist, setDGIExist] = useState(false)
    // const [EditorExist, setEditorExist] = useState(false)
    // const [CinematographerExist, setCinematographerExist] = useState(false)
    const [formSix, setFormSix] = useState({
        credits: ProjectInfo.credits,
        composer: ProjectInfo.composer,
        distributor: ProjectInfo.distributor,
        editor: ProjectInfo.editor,
        salesAgent: ProjectInfo.salesAgent.length > 0 ? ProjectInfo.salesAgent : [{
            address1: "",
            address2: "",
            city: "",
            company: "",
            country: "",
            email: "",
            firstName: "",
            lastName: "",
            middleName: "",
            phoneNumber: "",
            state: "",
            title: "",
            type: "",
            zipCode: "",
            _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString(),
        }],
        cinematographer: ProjectInfo.cinematographer,
        indieTopcredit: ProjectInfo.indieTopcredit,
        indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
    })
    const [errormessage, setErrormessage] = useState({
        email: '',
        address1: '',
        phoneNumber: '',
        city: '',
        country: '',
        state: '',
        zipCode: '',
    })
    const [errorNewFrontierMessage, setErrorNewFrontierMessage] = useState({
        email: '',
        address1: '',
        phoneNumber: '',
        city: '',
        country: '',
        state: '',
        zipCode: '',
    })

    const [isSubmitted, setisSubmitted] = useState(ProjectInfo.submitNo)
    const [failedMessage, setFailedMessage] = useState(false)
    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            // Email_producer: {  // name the rule
            //     message: 'Email is required.',
            //     rule: (val, params, validator) => {
            //         return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
            //     },
            //     required: true  // optional
            // },
            // City_producer: {  // name the rule
            //     message: 'City is required.',
            //     rule: (val, params, validator) => {
            //         return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
            //     },
            //     required: true  // optional
            // },
            ProvinceScreen: {  // name the rule
                message: 'State/Province is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            // ProvinceProducer: {  // name the rule
            //     message: 'State/Province is required.',
            //     rule: (val, params, validator) => {
            //         return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
            //     },
            //     required: true  // optional
            // },

            // Zip_producer: {  // name the rule
            //     message: 'Zip is required.',
            //     rule: (val, params, validator) => {
            //         return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
            //     },
            //     required: true  // optional
            // },
            Phone: {  // name the rule
                message: 'Phone is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            // Country_producer: {  // name the rule
            //     message: 'Country is required.',
            //     rule: (val, params, validator) => {
            //         return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
            //     },
            //     required: true  // optional
            // },
            Phone_screenwriter: {  // name the rule
                message: 'Phone is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            // address_producer: {
            //     message: 'Address is required.',
            //     rule: (val, params, validator) => {
            //         return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
            //     },
            //     required: true  // optional
            // },
            require: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            Email_screenwriter: {  // name the rule
                message: 'Email is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            ScreenwriterValidEmail: {  // name the rule
                message: 'Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            City_screenwriter: {  // name the rule
                message: 'City is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            address_screenwriter: {
                message: 'Address is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },

            Zip_screenwriter: {  // name the rule
                message: 'Zip is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            Phone_screenwriter: {  // name the rule
                message: 'Phone is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            Country_screenwriter: {  // name the rule
                message: 'Country is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            ProducerValidEmail: {  // name the rule
                message: 'Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            ComposerValidEmail: {  // name the rule
                message: 'Composer Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            EditorValidEmail: {  // name the rule
                message: 'Editor Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val &&  validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            CinematographerValidEmail: {  // name the rule
                message: 'Cinematographer Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            SalesAgentValidEmail: {  // name the rule
                message: 'Sale Agent Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
            DistributorValidEmail: {  // name the rule
                message: 'Drishtibutor Email must be valid email address.',
                rule: (val, params, validator) => {
                    return val && validator.helpers.testRegex(val, /^\w+([\+\.-]?\w+)*\+?\w+@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                },
                required: true  // optional
            },
        }
    }))

    const CheckOneValidation = () => {
        
        formSix.credits.forEach(item => {
            if (item.identifier === (pafType === 'New Frontier' ? 'keycollaborator' : pafType === 'Indie Series/Special Events' ? formSix.indieAdditionalTopCredit?.identifier : 'screenwriter')) {
                console.log("formSix1234", item);
                item.creditType.forEach((credit) => {
                    if (credit.creditDetails?.email !== '' && credit.creditDetails?.email) { data.email = credit.creditDetails?.email }
                    if (credit.creditDetails?.address1 !== '' && credit.creditDetails?.address1) { data.address1 = credit.creditDetails?.address1 }
                    if (credit.creditDetails?.phoneNumber !== '' && credit.creditDetails?.phoneNumber) { data.phoneNumber = credit.creditDetails?.phoneNumber }
                    if (credit.creditDetails?.city !== '' && credit.creditDetails?.city) { data.city = credit.creditDetails?.city }
                    if (credit.creditDetails?.country !== '' && credit.creditDetails?.country) { data.country = credit.creditDetails?.country }
                    if (credit.creditDetails?.state !== '' && credit.creditDetails?.state) { data.state = credit.creditDetails?.state }
                    if (credit.creditDetails?.zipCode !== '' && credit.creditDetails?.zipCode) { data.zipCode = credit.creditDetails?.zipCode }
                    //ddddd
                    if (credit.creditDetails?.email !== '' && credit.creditDetails?.email) { setErrormessage({ ...errormessage, email: data.email }) }
                    if (credit.creditDetails?.address1 !== '') { setErrormessage({ address1: data.address1 }) }
                    if (credit.creditDetails?.phoneNumber !== '') { setErrormessage({ phoneNumber: data.phoneNumber }) }
                    if (credit.creditDetails?.city !== '') { setErrormessage({ city: data.city }) }
                    if (credit.creditDetails?.country !== '') { setErrormessage({ country: data.country }) }
                    if (credit.creditDetails?.state !== '') { setErrormessage({ state: data.state }) }
                    if (credit.creditDetails?.zipCode !== '') { setErrormessage({ zipCode: data.zipCode }) }
                })

            }
            if (pafType !== 'Indie Series/Special Events') {
                if (item.identifier === 'producer' || item.identifier === 'producer') {
                    console.log("::::::::", item);
                    item.creditType.forEach((credit) => {
                        console.log("::::::::----", credit);
                        if (credit.creditDetails?.email !== '' && credit.creditDetails?.email) { newFrontierData.email = credit.creditDetails?.email }
                        if (credit.creditDetails?.address1 !== '' && credit.creditDetails?.address1) { newFrontierData.address1 = credit.creditDetails?.address1 }
                        if (credit.creditDetails?.phoneNumber !== '' && credit.creditDetails?.phoneNumber) { newFrontierData.phoneNumber = credit.creditDetails?.phoneNumber }
                        if (credit.creditDetails?.city !== '' && credit.creditDetails?.city) { newFrontierData.city = credit.creditDetails?.city }
                        if (credit.creditDetails?.country !== '' && credit.creditDetails?.country) { newFrontierData.country = credit.creditDetails?.country }
                        if (credit.creditDetails?.state !== '' && credit.creditDetails?.state) { newFrontierData.state = credit.creditDetails?.state }
                        if (credit.creditDetails?.zipCode !== '' && credit.creditDetails?.zipCode) { newFrontierData.zipCode = credit.creditDetails?.zipCode }
                        //ddddd
                        if (credit.creditDetails?.email !== '' && credit.creditDetails?.email) { setErrorNewFrontierMessage({ ...errorNewFrontierMessage, email: newFrontierData.email }) }
                        if (credit.creditDetails?.address1 !== '') { setErrorNewFrontierMessage({ address1: newFrontierData.address1 }) }
                        if (credit.creditDetails?.phoneNumber !== '') { setErrorNewFrontierMessage({ phoneNumber: newFrontierData.phoneNumber }) }
                        if (credit.creditDetails?.city !== '') { setErrorNewFrontierMessage({ city: newFrontierData.city }) }
                        if (credit.creditDetails?.country !== '') { setErrorNewFrontierMessage({ country: newFrontierData.country }) }
                        if (credit.creditDetails?.state !== '') { setErrorNewFrontierMessage({ state: newFrontierData.state }) }
                        if (credit.creditDetails?.zipCode !== '') { setErrorNewFrontierMessage({ zipCode: newFrontierData.zipCode }) }
                    })

                }
            }
        })
        forceUpdate();
    }

    useEffect(() => {

        CheckOneValidation()

        return () => {
            data = { email: '', address1: '', phoneNumber: '', city: '', country: '', state: '', zipCode: '', }
            newFrontierData = { email: '', address1: '', phoneNumber: '', city: '', country: '', state: '', zipCode: '', }
        }
    }, [])


    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        formSix.credits.forEach(item => {

            if (item.identifier !== (pafType === 'New Frontier' ? 'leadartist' : pafType === 'Indie Series/Special Events' ? formSix.indieTopcredit?.identifier : 'director')) {
                item.creditType.forEach((credit) => {
                    credit.creditDetails = {
                        company: credit.creditDetails?.company ? credit.creditDetails?.company : '',
                        email: credit.creditDetails?.email ? credit.creditDetails?.email : '',
                        address1: credit.creditDetails?.address1 ? credit.creditDetails?.address1 : '',
                        address2: credit.creditDetails?.address2 ? credit.creditDetails?.address2 : '',
                        city: credit.creditDetails?.city ? credit.creditDetails?.city : '',
                        state: credit.creditDetails?.state ? credit.creditDetails?.state : '',
                        zipCode: credit.creditDetails?.zipCode ? credit.creditDetails?.zipCode : '',
                        country: credit.creditDetails?.country ? credit.creditDetails?.country : '',
                        phoneNumber: credit.creditDetails?.phoneNumber ? credit.creditDetails?.phoneNumber : '',
                        type: credit.creditDetails?.type ? credit.creditDetails?.type : '',
                        title: credit.creditDetails?.title ? credit.creditDetails?.title : '',
                        teamType: credit.creditDetails?.teamType ? credit.creditDetails?.teamType : '',
                    }
                })
            }
        });
        setisSubmitted(ProjectInfo.submitNo)
        forceUpdate()
        // eslint-disable-next-line
    }, [])


    useEffect(() => {

        formSix.credits.forEach(item => {
            if (item.identifier === (pafType === 'New Frontier' ? 'keycollaborator' : pafType === 'Indie Series/Special Events' ? formSix.indieAdditionalTopCredit?.identifier : 'screenwriter')) {
                item.creditType.forEach((credit, i) => {
                    if (i === 0 && credit.firstName === '' && credit.lastName === '') {
                        setIsFirstLastEmpty(true)
                    } else {
                        setIsFirstLastEmpty(false)
                    }

                })
            }
        })




        setFormSix({
            credits: ProjectInfo.credits,
            composer: ProjectInfo.composer,
            distributor: ProjectInfo.distributor,
            editor: ProjectInfo.editor,
            salesAgent: ProjectInfo.salesAgent.length > 0 ? ProjectInfo.salesAgent : [{
                address1: "",
                address2: "",
                city: "",
                company: "",
                country: "",
                email: "",
                firstName: "",
                lastName: "",
                middleName: "",
                typeofsale: '',
                phoneNumber: "",
                state: "",
                title: "",
                type: "",
                zipCode: "",
                _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString(),
            }],
            cinematographer: ProjectInfo.cinematographer,
            indieTopcredit: ProjectInfo.indieTopcredit,
            indieAdditionalTopCredit: ProjectInfo.indieAdditionalTopCredit,
        })
        // setisSubmitted(isSubmitted)
        setisSubmitted(ProjectInfo.submitNo)

        // eslint-disable-next-line
    }, [ProjectInfo])
    useEffect(() => {
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|allUpdate":
                    if (!response.error) {

                        dispatch({ type: 'FORM_DATA', payload: response.data })
                        setisSubmitted(response.data.submitNo)
                        setSavedMessage(true)
                        setTimeout(() => {
                            setSavedMessage(false)
                            setIsShare(false)
                        }, 1000);
                        setIsNext(true)
                    } else {
                        setIsNext(false)
                        setFailedMessage(true)
                        setTimeout(() => {
                            setFailedMessage(false)
                        }, 2000);
                    }
                    break;
                default:
                    break;
            }
        })
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

    const onCreditChange = (e, CID, IID) => {
        const { name, value } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
        console.log(CID, IID);

        formSix.credits.forEach(item => {
            if (item._id === CID) {
                item.creditType.forEach((credit) => {
                    if (credit._id === IID) {
                        if (name === 'firstName' || name === 'lastName' || name === 'middleName') {
                            credit[name] = value
                        }
                        else if (name === 'country') {
                            credit.creditDetails = {
                                ...credit.creditDetails,
                                [name]: value,
                                // state: ''
                            }
                        }
                        else {
                            credit.creditDetails = {
                                ...credit.creditDetails,
                                [name]: name === 'email' ? value.trim() : value
                            }
                        }
                    }
                })
            }
        })
        // data = { email: '', address1: '', phoneNumber: '', city: '', country: '', state: '', zipCode: '', }
        // newFrontierData = { email: '', address1: '', phoneNumber: '', city: '', country: '', state: '', zipCode: '', }
        // CheckOneValidation()
        // formSix.credits.forEach(item => {
        //     if (item.identifier === (pafType === 'New Frontier' ? 'keycollaborator' : pafType === 'Indie Series/Special Events' ? formSix.indieAdditionalTopCredit?.identifier : 'screenwriter')) {
        //         item.creditType.forEach((credit) => {
        //             // if (name === 'email' && credit.creditDetails.email) { 
        //             //     setErrormessage({ ...errormessage, email:value }) }
        //             // if (name === 'address1' && credit.creditDetails.address1) { setErrormessage({ ...errormessage, address1:value }) }
        //             // if (name === 'phoneNumber' && credit.creditDetails.phoneNumber) { setErrormessage({ ...errormessage, phoneNumber:value }) }
        //             // if (name === 'city' && credit.creditDetails.city) { setErrormessage({ ...errormessage, city:value }) }
        //             // if (name === 'country' && credit.creditDetails.country) { setErrormessage({ ...errormessage, country:value }) }
        //             // if (name === 'state' && credit.creditDetails.state) { setErrormessage({ ...errormessage, state:value }) }
        //             // if (name === 'zipCode' && credit.creditDetails.zipCode) { setErrormessage({ ...errormessage, zipCode:value }) }
        //             //come
        //             if (name === 'email' && credit.creditDetails.email) { data.email = value }
        //             if (name === 'address1' && credit.creditDetails.address1) { data.address1 = value }
        //             if (name === 'phoneNumber' && credit.creditDetails.phoneNumber) { data.phoneNumber = value }
        //             if (name === 'city' && credit.creditDetails.city) { data.city = value }
        //             if (name === 'country' && credit.creditDetails.country) { data.country = value }
        //             if (name === 'state' && credit.creditDetails.state) { data.state = value }
        //             if (name === 'zipCode' && credit.creditDetails.zipCode) { data.zipCode = value }
        //             console.log("data1234", data);

        //         })
        //     }
        // })
        forceUpdate()
    }

    const EditorChange = (e) => {
        const { name, value } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
        setFormSix({
            ...formSix, editor: { ...formSix.editor, [name]: name === 'email' ? value.trim() : value }
        })
    }
    const CinematographerChange = (e) => {
        const { name, value } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
        setFormSix({
            ...formSix, cinematographer: { ...formSix.cinematographer, [name]: name === 'email' ? value.trim() : value }
        })
    }
    const DistributorChange = (e) => {
        const { name, value } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
        setFormSix({
            ...formSix, distributor: { ...formSix.distributor, [name]: name === 'email' ? value.trim() : value }
        })
    }
    const ComposerChange = (e) => {
        const { name, value } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
        setFormSix({
            ...formSix, composer: { ...formSix.composer, [name]: name === 'email' ? value.trim() : value }
        })
    }
    const SalesAgentChange = (e, id) => {
        let newSalesAgent = formSix.salesAgent;
        const { name, value } = e.target
        if (!e.target.validity.valid) {
            return null;
        }
        newSalesAgent.forEach(sales => {
            if (sales._id === id) {
                sales[name] = name === 'email' ? value.trim() : value
            }
        })
        setFormSix({
            ...formSix, salesAgent: newSalesAgent
        })
    }

    const onSalesAgentAdd = (TYPE, id) => {
        let newSalesAgent = formSix.salesAgent;

        if (TYPE === "ADD") {
            let newAdded = {
                address1: "",
                address2: "",
                city: "",
                company: "",
                country: "",
                email: "",
                firstName: "",
                lastName: "",
                middleName: "",
                typeofsale: '',
                phoneNumber: "",
                state: "",
                title: "",
                type: "",
                zipCode: "",
                _id: (new Date().getTime() + Math.floor(Math.random() * 10000)).toString(),
            }
            newSalesAgent.push(newAdded)
            setFormSix({ ...formSix, salesAgent: newSalesAgent })
            // newSalesAgent.push(newAdded)
            // setSalesAgent(newSalesAgent)
        } else {

            newSalesAgent = newSalesAgent.filter(_ => _._id !== id)
            setFormSix({ ...formSix, salesAgent: newSalesAgent })
            // setSalesAgent(newSalesAgent)

        }
    }
    const onFormSave = (NAV) => {

        let data = {
            ...formSix,
            savePageNo: '6',
            projectId: ProjectInfo._id,
        }
        setFormSix((last) => {
            data = {
                ...data,
                ...last
            }
            return last
        })
        forceUpdate()
        if (NAV === 'NEXT') {
            data.completedStepNo = 6
        }else if(NAV === 'SHARE'){
            setIsShare(true)
        }
        apiServe.sendevent('paf|project|allUpdate', data)
    }
    console.log(`ProjectInfo.credits`, ProjectInfo.credits)
    const onNextPrev = () => {


        CheckOneValidation()

        if (Validator.current.allValid()) {
            // onFormSave()
            return true
        } else {
            Validator.current.showMessages()
            forceUpdate()
            setTimeout(() => {
                ErrorScroll()
            }, 500);
            return false

        }
    }
    const closeSuccessMessage = () => {
        setSavedMessage(false)
    }
    const closeFailedMessage = () => {
        setFailedMessage(false)
    }

    return (
        <div>
            <Header />
            <FormHeader step={6} />
            {!isShare && <SuccessMessage active={SavedMessage} close={closeSuccessMessage} text={'Save'} />}
            <FailedMessage active={failedMessage} close={closeFailedMessage} text={'Save'} />
            <div className="container form_inner step2 step_eight step_common">
                <div className="underline">
                    <div className='step sd_justbetween'>
                        <div className='step_no'>
                            <p>Page 6 of 9</p>
                        </div>
                        <div className='step_des '>
                            <p>After you submit at the end of Page 9, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons.</p>
                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival, and will be placed in the Sundance Institute Archives.</p>
                        </div>
                    </div>
                </div>
                <div className="form_content_questions">
                    <div className="form_content form_content_questions_inner pt_0">
                        <div className="form_content_top">
                            <div className="form_content_top_title pb_0">
                                <h3 className="mb_0">Team Information</h3>
                            </div>
                        </div>
                        {/* <TeamForm /> */}
                        {formSix?.credits.map((item) => {
                            return (
                                <>
                                    {item.identifier === (pafType === 'New Frontier' ? 'keycollaborator' : pafType === 'Indie Series/Special Events' ? formSix.indieAdditionalTopCredit?.identifier : 'screenwriter') ? <>{
                                        item.creditType.map((credit, i) => {
                                            if(credit.firstName !== ''){
                                                return (
                                                    <TeamForm required={((credit.firstName || credit.lastName) && i === 0) ? true : false} disabled={isSubmitted >= 3 ? true : false} CreditData={credit} item={item} index={i} onChange={onCreditChange} nameDisabled={isSubmitted >= 1 && !isFirstLastEmpty ? true : false} Error={((credit.firstName && credit.lastName && i === 0)) ? {
                                                        Email: Validator.current.message(`email${i}`, credit.creditDetails.email, 'Email_screenwriter|ScreenwriterValidEmail'),
                                                        address: Validator.current.message(' address', credit.creditDetails?.address1, 'address_screenwriter'),
                                                        Mobile: Validator.current.message('phone Number', credit.creditDetails?.phoneNumber, 'Phone|Phone|numeric'),
                                                        City: Validator.current.message(' City', credit.creditDetails?.city, 'City_screenwriter'),
                                                        Province: ((credit.firstName || credit.lastName) && (credit.creditDetails?.country === 'United States' || credit.creditDetails?.country === 'Canada')) ? Validator.current.message('Province', credit.creditDetails?.state, 'ProvinceScreen') : '',
                                                        Zip: Validator.current.message(' Zip ', credit.creditDetails?.zipCode, 'Zip_screenwriter'),
                                                        Country: Validator.current.message(' Country', credit.creditDetails?.country, 'Country_screenwriter'),
                                                    } : {
                                                        Email: Validator.current.message(`email${i}`, credit.creditDetails.email, 'ScreenwriterValidEmail'),
                                                    }} />
    
                                                )
                                            }
                                        })
                                    }</> : ''}
                                </>
                            )
                        })}

                        {pafType !== 'Indie Series/Special Events' && formSix?.credits.map((item) => {
                            return (
                                <>
                                    {item.identifier === 'producer' || item.identifier === 'producer' ? <>{
                                        item.creditType.map((credit, i) => {
                                            if(credit.firstName !== ''){
                                                return (
                                                    <TeamForm required={((credit.firstName || credit.lastName) && i === 100) ? true : false} disabled={isSubmitted >= 3 ? true : false} CreditData={credit} item={item} index={i} onChange={onCreditChange} title={`producer ${i + 1}`} nameDisabled={true} Error={(credit.firstName && credit.lastName) ? {
                                                        Email: Validator.current.message(`Email${i}`, credit?.creditDetails.email, 'ProducerValidEmail'),
                                                        address: Validator.current.message('Address Producer', credit?.creditDetails.address1, 'address_producer'),
                                                        Mobile: Validator.current.message('Mobile Producer', credit?.creditDetails.phoneNumber, 'Phone_producer|numeric'),
                                                        City: Validator.current.message('City Producer', credit?.creditDetails.city, 'City_producer'),
                                                        Province: ((credit.firstName || credit.lastName) && (credit?.creditDetails.country === 'United States' || credit?.country === 'Canada')) ? Validator.current.message('Province Producer', credit?.creditDetails.state, 'Province_producer') : '',
                                                        Zip: Validator.current.message('Zip Producer', credit?.creditDetails.zipCode, 'Zip_producer'),
                                                        Country: Validator.current.message('Country Producer', credit?.creditDetails.country, 'Country_producer'),
                                                    } : {Email: Validator.current.message(`Email${i}`, credit?.creditDetails.email, 'ProducerValidEmail')}} />
    
                                                )
                                            }
                                        })
                                    }</> : ''}
                                </>
                            )
                        })}
                        <TeamFormExtra disabled={isSubmitted >= 3 ? true : false} onChange={ComposerChange} CreditData={formSix.composer} title={'Composer'} Error={{Email: Validator.current.message('Email Composer', formSix.composer?.email, 'Email_composer|ComposerValidEmail'),
                                address: Validator.current.message('Address Composer', formSix.composer?.address1, 'Address_composer'),
                                Mobile: Validator.current.message('Mobile Composer', formSix.composer?.phoneNumber, 'Phone_composer|numeric'),
                                City: Validator.current.message('City Composer', formSix.composer?.city, 'City_composer'),
                                Province: ((formSix.composer.firstName || formSix.composer.lastName) && (formSix.composer?.country === 'United States' || formSix.composer?.country === 'Canada')) ? Validator.current.message('Province Composer', formSix.composer?.state, 'Province_composer') : '',
                                Zip: Validator.current.message('Zip Composer', formSix.composer?.zipCode, 'Zip_composer'),
                                Country: Validator.current.message('Country Composer', formSix.composer?.country, 'Country_composer')}} />

                        <TeamFormExtra disabled={isSubmitted >= 3 ? true : false} onChange={EditorChange} CreditData={formSix.editor} title={'Editor'} Error={{Email: Validator.current.message('Email Editor', formSix.editor?.email, 'Email_editor|EditorValidEmail'),
                                address: Validator.current.message('Address Editor', formSix.editor?.address1, 'Address_editor'),
                                Mobile: Validator.current.message('Mobile Editor', formSix.editor?.phoneNumber, 'Phone_editor|numeric'),
                                City: Validator.current.message('City Editor', formSix.editor?.city, 'City_editor'),
                                Province: ((formSix.editor.firstName || formSix.editor.lastName) && (formSix.editor?.country === 'United States' || formSix.editor?.country === 'Canada')) ? Validator.current.message('Province Editor', formSix.editor?.state, 'Province_editor') : '',
                                Zip: Validator.current.message('Zip Editor', formSix.editor?.zipCode, 'Zip_editor'),
                                Country: Validator.current.message('Country Editor', formSix.editor?.country, 'Country_editor')}}/>

                        <TeamFormExtra disabled={isSubmitted >= 3 ? true : false} onChange={CinematographerChange} CreditData={formSix.cinematographer} title={'Cinematographer'} Error={{Email: Validator.current.message('Email Cinematographer', formSix.cinematographer?.email, 'Email_cinematographer|CinematographerValidEmail'),
                                address: Validator.current.message('Address Cinematographer', formSix.cinematographer?.address1, 'Address_cinematographer'),
                                Mobile: Validator.current.message('Mobile Cinematographer', formSix.cinematographer?.phoneNumber, 'Phone_cinematographer|numeric'),
                                City: Validator.current.message('City Cinematographer', formSix.cinematographer?.city, 'City_cinematographer'),
                                Province: ((formSix.cinematographer.firstName || formSix.cinematographer.lastName) && (formSix.cinematographer?.country === 'United States' || formSix.cinematographer?.country === 'Canada')) ? Validator.current.message('Province Cinematographer', formSix.cinematographer?.state, 'Province_cinematographer') : '',
                                Zip: Validator.current.message('Zip Cinematographer', formSix.cinematographer?.zipCode, 'Zip_cinematographer'),
                                Country: Validator.current.message('Country Cinematographer', formSix.cinematographer?.country, 'Country_cinematographer')}} />
                        {
                            formSix.salesAgent.map((sales, i) => {
                                return <TeamFormExtra disabled={isSubmitted >= 3 ? true : false} onChange={SalesAgentChange} index={i} salesAgent={formSix.salesAgent} multiPal={isSubmitted >= 3 ? false : true} onSalesAgentAdd={onSalesAgentAdd} CreditData={sales} title={`Sales Agent ${i + 1}`} Error={{Email: Validator.current.message(`Email Sales${i}`, sales?.email, 'Email_sales|SalesAgentValidEmail'),
                                address: Validator.current.message('Address Sales', sales?.address1, 'Address_sales'),
                                Mobile: Validator.current.message('Mobile Sales', sales?.phoneNumber, 'Phone_sales|numeric'),
                                City: Validator.current.message('City Sales', sales?.city, 'City_sales'),
                                Province: ((sales.firstName || sales.lastName) && (sales?.country === 'United States' || sales?.country === 'Canada')) ? Validator.current.message('Province Sales', sales?.state, 'Province_sales') : '',
                                Zip: Validator.current.message('Zip Sales', sales?.zipCode, 'Zip_sales'),
                                Country: Validator.current.message('Country Sales', sales?.country, 'Country_sales')}}/>
                            })
                        }
                        <TeamFormExtra disabled={isSubmitted >= 3 ? true : false} onChange={DistributorChange} CreditData={formSix.distributor} title={'Distributor/ Gallery/ Institution'} Error={{Email: Validator.current.message('Email Distributor', formSix.distributor?.email, 'Email_distributor|DistributorValidEmail'),
                                address: Validator.current.message('Address Distributor', formSix.distributor?.address1, 'Address_distributor'),
                                Mobile: Validator.current.message('Mobile Distributor', formSix.distributor?.phoneNumber, 'Phone_distributor|numeric'),
                                City: Validator.current.message('City Distributor', formSix.distributor?.city, 'City_distributor'),
                                Province: ((formSix.distributor.firstName || formSix.distributor.lastName) && (formSix.distributor?.country === 'United States' || formSix.distributor?.country === 'Canada')) ? Validator.current.message('Province Distributor', formSix.distributor?.state, 'Province_distributor') : '',
                                Zip: Validator.current.message('Zip Distributor', formSix.distributor?.zipCode, 'Zip_distributor'),
                                Country: Validator.current.message('Country Distributor', formSix.distributor?.country, 'Country_distributor')}} />
                    </div>
                </div>
                <div className="footer">
                    <FormFooter step={6} onFormSave={onFormSave} isNext={isNext} checkValidate={onNextPrev} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Step6