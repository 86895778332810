import * as React from "react"

function UploaderIcon(props) {
  return (
    <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 37C0 16.5655 16.5655 0 37 0C57.4248 0.0233845 73.9766 16.5752 74 37C74 57.4345 57.4345 74 37 74C16.5655 74 0 57.4345 0 37ZM2.3125 37C2.3125 56.1574 17.8426 71.6875 37 71.6875C56.1484 71.6658 71.6658 56.1484 71.6875 37C71.6875 17.8426 56.1574 2.3125 37 2.3125C17.8426 2.3125 2.3125 17.8426 2.3125 37Z" fill="white"/>
    <path d="M43.0717 41.0114C43.3402 41.2587 43.6837 41.3809 44.0267 41.3809C44.3952 41.3809 44.7632 41.2391 45.0365 40.9576C45.5635 40.4148 45.539 39.5594 44.9818 39.0461L37.3137 31.9904C36.7761 31.4968 35.9365 31.4973 35.4021 31.9925L27.8255 38.9974C27.2688 39.5118 27.2464 40.3677 27.7755 40.9089C28.3041 41.4502 29.1836 41.4719 29.7399 40.9581L34.9628 36.1291V55.6479C34.9628 56.3946 35.585 57 36.3523 57C37.1197 57 37.7418 56.3946 37.7418 55.6479V36.1069L43.0717 41.0114Z" fill="white"/>
    <path d="M50.4477 26.675C50.276 26.675 50.1031 26.6797 49.9271 26.6895C47.6443 20.9312 41.8459 17 35.4393 17C27.0566 17 20.2038 23.4999 19.923 31.5915C16.0146 32.1348 13 35.414 13 39.3648C13 43.6944 16.6197 47.2173 21.0695 47.2173C21.0748 47.2173 21.0791 47.2173 21.0844 47.2173C21.087 47.2173 21.0897 47.2173 21.0918 47.2173L30.2546 47.1666C31.0225 47.1625 31.6404 46.5534 31.6367 45.8067C31.6319 45.0626 31.0108 44.4624 30.2472 44.4624C30.2445 44.4624 30.2418 44.4624 30.2397 44.4624L21.1657 44.5125C21.1131 44.51 21.0594 44.5094 21.0046 44.5125C18.1171 44.4784 15.779 42.1824 15.779 39.3648C15.779 36.5379 18.1326 34.2357 21.0333 34.2166C21.0307 34.2166 21.0307 34.2171 21.0339 34.2176C21.1104 34.2279 21.1875 34.2373 21.2668 34.2414C21.6576 34.2621 22.049 34.1193 22.3292 33.8466C22.6095 33.5733 22.7562 33.1966 22.7328 32.8111L22.7169 32.5772C22.7057 32.421 22.6935 32.2647 22.6935 32.1069C22.6935 25.2675 28.4115 19.7038 35.4393 19.7038C41.0196 19.7038 46.0384 23.3384 47.6432 28.5426C47.8437 29.192 48.5073 29.5977 49.1949 29.489C49.6724 29.414 50.0702 29.3793 50.4477 29.3793C54.7337 29.3793 58.221 32.7734 58.221 36.9451C58.221 41.0978 54.7486 44.4913 50.4807 44.5094C50.453 44.5094 50.4254 44.5105 50.3977 44.512H41.8763C41.1089 44.512 40.4868 45.1175 40.4868 45.8642C40.4868 46.6109 41.1089 47.2163 41.8763 47.2163H50.4865C50.5211 47.2163 50.5546 47.2153 50.5886 47.2127C56.338 47.1376 61 42.5508 61 36.9446C61 31.2825 56.2663 26.675 50.4477 26.675Z" fill="white"/>
    </svg>
    
    

  )
}

export default UploaderIcon
