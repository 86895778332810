import React, { useEffect, useState } from 'react'
// import Slider from "react-slick";
// Layouts
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import service from '../../common/service';
// import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import PafPreview from './PafPreview';
let sub
const Preview = () => {
    // const ProjectInfo = useSelector(e => e.ProjectInfo);
    const [ProjectInfo, setProjectInfo] = useState({})
    // const [, setProjectDetail] = useState({})
    const [ProjectDetail, setProjectDetail] = useState({})
    const [Loading, setLoading] = useState(true)
    const apiServe = new service();
    const { projectId } = useParams();
    const [previewId,setPreviwId] = useState()
    const [projecData,setProjectData] = useState()
   const  moveObjectAtIndex = (array, sourceIndex, destIndex) => {
       
        var placeholder = {};
        // remove the object from its initial position and
        // plant the placeholder object in its place to
        // keep the array length constant
        var objectToMove = array.splice(sourceIndex, 1, placeholder)[0];
        // place the object in the desired position
        array.splice(destIndex, 0, objectToMove);
        // take out the temporary object
        array.splice(array.indexOf(placeholder), 1);
        return array
    }
    const loader = () => {
        return (
            <div className="sd_loader"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="85px" height="85px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-tail">
                    <defs>
                    <filter id="tail-0.a4db4fdd84ecd" x="-100%" y="-100%" width="300%" height="300%" color-interpolation-filters="sRGB">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="3"/>
                        <feColorMatrix mode="matrix" values="1 0 0 0 0   0 1 0 0 0   0 0 1 0 0   0 0 0 60 -40" result="cm"/>
                    </filter>
                    </defs>
                    <g ng-attr-filter="url(#{{config.filterid}})" filter="url(#tail-0.a4db4fdd84ecd)" transform="rotate(54 50 50)">
                    <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="4s" begin="0s" repeatCount="indefinite"/>
                    <g transform="rotate(333.474 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="19" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#F63C16" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.13333333333333333 0 0.03333333333333333 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(331.178 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="18" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#F63C16" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.16666666666666669 0 0.06666666666666667 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(328.576 50.0001 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="17" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#F63C16" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.2 0 0.1 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(325.612 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="16" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#F63C16" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.23333333333333334 0 0.13333333333333333 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(322.212 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="15" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#F63C16" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.26666666666666666 0 0.16666666666666666 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(318.287 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="14" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#F63C16" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.30000000000000004 0 0.2 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(314.708 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="13" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#F63C16" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.33333333333333337 0 0.23333333333333334 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(309.018 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="12" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.3666666666666667 0 0.26666666666666666 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(302.443 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="11" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.4 0 0.3 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(294.767 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="10" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.43333333333333335 0 0.3333333333333333 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(285.715 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="9" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.4666666666666667 0 0.36666666666666664 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(274.96 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="8" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.5 0 0.4 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(263.29 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="7" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.5333333333333333 0 0.43333333333333335 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(247.243 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="6" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.5666666666666667 0 0.4666666666666667 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(233.28 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="5" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.6 0 0.5 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(210.448 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="4" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.6333333333333333 0 0.5333333333333333 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(189.723 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="3" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.6666666666666666 0 0.5666666666666667 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(167.547 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="2" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.7 0 0.6 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(151.39 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="1" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.7333333333333333 0 0.6333333333333333 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    <g transform="rotate(134.242 50 50)">
                        <g ng-attr-transform="translate(50 {{config.cy}})" transform="translate(50 19.6)">
                        <circle cx="0" cy="0" r="0" ng-attr-fill="{{config.fill}}" ng-attr-transform="scale({{config.scale}})" fill="#FE7800" transform="scale(0.48)"/>
                        </g>
                        <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" ng-attr-dur="{{config.speed2}}" keySplines="0.7666666666666666 0 0.6666666666666666 1" repeatCount="indefinite" dur="1"/>
                    </g>
                    </g>
                </svg>             
            </div>
        )
    }
    useEffect(() => {
        if (window.location.href.includes('paf-preview')) {
            var paramsData1 = window.location.search.replace('?data=', '')
            setPreviwId(paramsData1)
            console.log("paramsData1",paramsData1);
            if(paramsData1)
            {
                apiServe.sendevent('tempStorage|getData', {_id:paramsData1})
            }
            // setLoading(false)
        }else{
        sub = apiServe.sendevent('paf|project|previewForm', { projectUserId: projectId })
        setLoading(true)
        }
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {

                case "paf|project|previewForm":
                  
                    if (!response.error) {

                        try {
                            let ProjectInfodata = response.data
                            let cast =ProjectInfodata.credits.find(element => element?.identifier === 'principalcast')
                            let castIndex =ProjectInfodata.credits.findIndex(element => element?.identifier === 'principalcast')
                            let creditLastIndex = ProjectInfodata.credits.length 
                            if(cast?.identifier === 'principalcast' ){
                                ProjectInfodata.credits = moveObjectAtIndex(response.data.credits, castIndex ,creditLastIndex)    
                            } 
                                setProjectInfo(ProjectInfodata)
                                apiServe.sendevent('paf|project|getDetail', {})
                                setLoading(false)   
                            }
                         catch (error) {
                          
                        }
                    }
                    break;
                case "paf|project|getDetail":
                    if (!response.error) {
                        setLoading(false)
                        setProjectDetail(response.data)
                    }
                    break;
                case "tempStorage|getData":
                    setLoading(true)
                    if (!response.error) {
                        setProjectData(response.data.tempData)
                        let paramsData = apiServe.decrypt(response?.data?.tempData)
                        console.log("response=====>",paramsData);
                        // var self = this
                        paramsData.then(function (val) {
                            setProjectInfo(val)
                            // setLoading(false)
                            console.log(val);
                            apiServe.sendevent('paf|project|getDetail', {})
                        });
                    }
                    break;
                default:
                    break;
            }
        })
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

        return (
            <>
                {Loading ? loader() : <>
                    <Header />
                    <PafPreview ProjectInfo={ProjectInfo} ProjectDetail={ProjectDetail} />


                    <Footer /> </>}

            </>
        )
    }

    export default Preview
