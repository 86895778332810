import React, { useEffect, useRef } from 'react'
import Header from '../../../layout/Header'
import Footer from "../../../layout/Footer";
import FormHeader from './../FormHeader'
import TextArea from '../../../components/TextArea';
import Dropdown from '../../../components/Dropdown';
import FormFooter from './../FormFooter';
import Tooltip from '../../../components/Tooltip';
import { useState } from 'react';

import service from '../../../common/service';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessage from './../SuccessMessage';
import FailedMessage from './../FailedMessage';
import SimpleReactValidator from 'simple-react-validator';
import {ErrorScroll} from './../../../common/functions';
import { useHistory } from "react-router-dom";

let sub
const Step7 = () => {

    const apiServe = new service();
    const dispatch = useDispatch();
    const ProjectInfo = useSelector(e => e.ProjectInfo);
    const [SavedMessage, setSavedMessage] = useState(false)
    const [failedMessage, setFailedMessage] = useState(false)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const pafType = useSelector(e => e?.pafType);
    const [isNext, setIsNext] = useState(true)
    const [isShare, setIsShare] = useState(false)
    const history = useHistory()
    if (pafType === 'New Frontier') {
        history.goBack()
    }

    const [state] = useState({
        studentscreening: ['Yes', 'No'],
        ScreeningsandTickets: ['Yes', 'No'],
    })
    const [CommunityAudiences, setCommunityAudiences] = useState({
        freeCommunityScreening: ProjectInfo.freeCommunityScreening,
        studentScreening: ProjectInfo.studentScreening,
    })
    const [isSubmitted, setisSubmitted] = useState(ProjectInfo.submitNo)
    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            required: {  // name the rule
                message: 'Free Community Screening is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
                
            },

            require2: {  // name the rule
                message: 'Utah Student Screenings is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
                
            },

        }
    }))
    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "paf|project|allUpdate":
                    if (!response.error) {
                        dispatch({ type: 'FORM_DATA', payload: response.data })
                        setSavedMessage(true)
                        setTimeout(() => {
                            setSavedMessage(false)
                            setIsShare(false)
                        }, 1000);
                        setIsNext(true)
                    } else {
                        setFailedMessage(true)
                        setIsNext(false)
                        setTimeout(() => {
                            setFailedMessage(false)
                        }, 2000);
                    }
                    break;
                default:
                    break;
            }
        })
        setisSubmitted(ProjectInfo.submitNo)
        return () => {
            sub.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        setCommunityAudiences({
            freeCommunityScreening: ProjectInfo.freeCommunityScreening,
            studentScreening: ProjectInfo.studentScreening,
        })
        setisSubmitted(ProjectInfo.submitNo)
        // eslint-disable-next-line
    }, [ProjectInfo])
    const handleChangeStudent = (e) => {
        const { name, value } = e.target
        setCommunityAudiences({ ...CommunityAudiences, studentScreening: { ...CommunityAudiences.studentScreening, [name]: value } })

    }
    const handleChangeCommunity = (e) => {
        const { name, value } = e.target
        setCommunityAudiences({ ...CommunityAudiences, freeCommunityScreening: { ...CommunityAudiences.freeCommunityScreening, [name]: value } })

    }
    const onFormSave = (NAV) => {
        let data = {
            ...CommunityAudiences,
            savePageNo: '7',
            projectId: ProjectInfo._id
        }
        setCommunityAudiences((last) => {
            data = {
                ...data,
               ...last
            }
            return last
        });
        forceUpdate();
        if (NAV === 'NEXT') {
            data.completedStepNo = 7
        }else if(NAV === 'SHARE'){
            setIsShare(true)
        }
        apiServe.sendevent('paf|project|allUpdate', data)
    }
    const checkValidate = () => {
        if (Validator.current.allValid()) {
            return true
        } else {
            Validator.current.showMessages()
            forceUpdate()
            setTimeout(() => {
                ErrorScroll()
            }, 500);
            return false
        }
    }
    const closeSuccessMessage = () => {
        setSavedMessage(false)
    }
    const closeFailedMessage = () => {
        setFailedMessage(false)
    }
    return (
        <div>
            {console.log(CommunityAudiences)}
            <Header />
            <FormHeader step={7} />
            {!isShare && <SuccessMessage active={SavedMessage} close={closeSuccessMessage} text={'Save'} />}
            <FailedMessage active={failedMessage} close={closeFailedMessage} text={'Save'} />
            <div className="container step2 step_eight step_common">
                <div className='underline'>
                    <div className='step sd_justbetween' >
                        <div className='step_no'>
                            <p>Page 7 of 9</p>
                        </div>
                        <div className='step_des'>
                            <p>After you submit at the end of Page 9, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons.</p>

                            <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival, and will be placed in the Sundance Institute Archives.</p>
                        </div>
                    </div>
                </div>
                <div className="form_content">
                    <div className="form_content_top">
                        <div className="form_content_top_title border_bottom_2">
                            <h3>Utah Student and Community Audiences</h3>
                            <h4>Community Engagement Program</h4>
                            <p>Reach beyond the typical Sundance Film Festival attendee and interact with engaged local audiences including communities who may identify with your subject matter or young film fans who are excited to experience independent cinema for the first time.</p>
                        </div>
                    </div>
                    <div className="form_content_questions">
                        <div className="form_content_questions_inner">
                            <div className="form_screening">
                                <h4 className="tooltip_title">Free Utah Community Screenings and Tickets</h4>
                                <p>Our community program helps make independent film more accessible, inclusive, and equitable, bringing free Festival films to local audiences. The director or another team member must participate in a Q&A for any scheduled community screenings.</p>
                                <div className="field_row_inner">
                                    {/* <div className="sd_flex sd_flexwrap">
                                        <div className="question_title">
                                            <label className='required'>Would you like your film to be considered for the program? </label>
                                            <span className="sd_width_full sd_textuppercase">(The director or another team member must participate.)</span>
                                        </div>
                                        <Dropdown disable={isSubmitted >= 3 ? true : false} fpgerror={Validator.current.message('require', CommunityAudiences.freeCommunityScreening.flag, 'require2|required')} DefaultOption={'Select from Below'} options={state.ScreeningsandTickets} name="flag" value={CommunityAudiences.freeCommunityScreening.flag} onChange={handleChangeCommunity} />
                                    </div> */}
                                    <div className="form_screening_note">
                                        <TextArea disabled={isSubmitted >= 3 ? true : false} label="Are there any specific target audiences that you feel may especially connect with your film? For example, particular cultural communities, language speakers, age groups, etc? If you worked with any specific organizations or community groups, especially those with a Utah presence, please include these as well. Put N/A if not applicable. *" name="note" value={CommunityAudiences.freeCommunityScreening.note} handleChange={handleChangeCommunity} />
                                        <span style={{ color: 'red' }}>{Validator.current.message('Free Community Screening', CommunityAudiences.freeCommunityScreening.note, 'required')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form_content_questions">
                        <div className="form_content_questions_inner">
                            <div className="form_screening">
                                <h4 className="tooltip_title">Utah Student Screenings</h4>
                                <p>Our student program is a favorite among Festival artists, students, and educators alike. We screen a selection of features and short films for free to Utah students in grades 9–12, providing them the chance to experience a new perspective, gain exposure to the art of film, and interact with the creative team behind the story. The director or another team member must participate in a Q&A for any scheduled student screenings.</p>
                                <div className="field_row_inner">
                                    {/* <div className="sd_flex sd_flexwrap">
                                        <div className="question_title">
                                            <label className='required'>Would you like your film to be in the student screening program, AND is your film appropriate for 13–18-year-olds? </label>
                                            <span className="sd_textuppercase">(The director or another team member must participate.)</span>
                                        </div>
                                        <Dropdown disable={isSubmitted >= 3 ? true : false} fpgerror={Validator.current.message('require', CommunityAudiences.studentScreening.flag, 'require1|required')} options={state.studentscreening} onChange={handleChangeStudent} DefaultOption={'Select from Below'} name="flag" value={CommunityAudiences.studentScreening.flag} />
                                    </div> */}
                                    <div className="form_screening_note">
                                        <TextArea disabled={isSubmitted >= 3 ? true : false} label="Please note if your film includes strong language, substance abuse, violence (including guns), suicide, sex scenes, and/or nudity. *" name="note" value={CommunityAudiences.studentScreening.note} handleChange={handleChangeStudent} />
                                        <span style={{ color: 'red' }}>{Validator.current.message('Utah Student Screenings', CommunityAudiences.studentScreening.note, 'require2')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <FormFooter step={7} isNext={isNext} checkValidate={checkValidate} onFormSave={onFormSave} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Step7;