import React, { useState, useEffect } from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import TextField from '../../components/TextField'
import Banner from '../../layout/Banner'
import service from '../../common/service';
// import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

let sub;

function ResetPassword() {
    const apiServe = new service();
    // const selector = useSelector(state => state.User);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const { ResetVerification } = useParams();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState('')
    const [User, setUser] = useState({ projectUserId: '', createPassword: '', confirmPassword: '' });
    const clear = () => { setUser({...User, createPassword: '', confirmPassword: '' }); forceUpdate()};
 
    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setUser({ ...User, [name]: value })
    };
    const submit = (e) => {
        e.preventDefault();
        // if (User.createPassword === User.confirmPassword) {
        setErrorMessage('')
        if (validate() && ConValidate()) {
            apiServe.sendevent('paf|user|resetPassword', { verification: ResetVerification, password: User.confirmPassword })
        }
    };
    useEffect(() => {
        apiServe.sendevent('paf|user|checkVerification', { verification: ResetVerification })
        sub = apiServe.getevent().subscribe((response) => {
            console.log("not error")

            switch (response.event) {
                case "paf|user|checkVerification":
                    if (!response.error) {
                        setUser({ ...User, projectUserId: response.data.projectUserId })
                        // projectUserId
                    } else {
                        setErrorMessage(response.data.error)
                    }
                    // history.push('/')
                    break;
                case "paf|user|resetPassword":
                    if (!response.error) {
                        history.push('/')
                    } else {
                        setErrorMessage(response.data.error)
                    }
                    break;
                default:
                    break;
            }


        });
        return () => {
            sub.unsubscribe();
        }
        // eslint-disable-next-line
    }, []);

    const ConValidate = () => {
        if (!User.confirmPassword) {
            setErrorMessage('Confirm Password can not be blank')
            return false
        } else {
            if (User.createPassword !== User.confirmPassword) {
                setErrorMessage("Password and Confirm Password do not match")
                return false
            } else {
                setErrorMessage('')
                return true
            }
        }
    }
    const validate = () => {
        setErrorMessage('')
        if (!User.createPassword) {
            setErrorMessage("Password can not be blank")
            return false
        } else {
            const strongRegex = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})", "gm");
            if (strongRegex.test(User.createPassword)) {
                setErrorMessage('')
                return true
            } else {
                setErrorMessage("Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.")
                return false
            }
        }
    }
    return (
        <div>
            <Header />
            <Banner />
            <div className="sd_container_720">
                <div className="sd_auth_content">
                    <div className="sd_auth_content_top">
                        <h2 className="sd_auth_content_form_title  sd_textcenter">Reset Your password</h2>
                        <p className="sd_textcenter error_msg sd_textuppercase">{errorMessage}</p>
                    </div>
                    <form>
                        <div className="sd_form_group">
                            <TextField label="PROJECT USER ID" name="projectUserId" type="text" value={User.projectUserId} disabled />
                        </div>
                        <div className="sd_form_group">
                            <TextField label="CREATE PASSWORD" name="createPassword" type="password" value={User.createPassword} handleChange={handleChange} />
                        </div>
                        <div className="sd_form_group">
                            <TextField label="CONFIRM PASSWORD" name="confirmPassword" type="password" value={User.confirmPassword} handleChange={handleChange} />
                        </div>

                        <div className="sd_form_group sd_textcenter auth_button_wrap">
                            <button type='button' className="border_btn" onClick={clear}>Clear</button>
                            <button type="submit" className="border_btn" onClick={(e) => submit(e)}>Submit</button>
                        </div>
                    </form>

                </div>
            </div>
            <Footer />
        </div>
    )
}
export default ResetPassword