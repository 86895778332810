import React from 'react'

const SuccessMessage = (props) => {
    const { active, close, text, fullMessage } = props
    return (
        <div>
            <div className={`text_center success_form_msg ${active && "active"}`}>
                {fullMessage ? <p>{fullMessage}<span className="sd_p_cursor close_success" onClick={close}>X</span></p> : <p>{text} successful. <span className="sd_p_cursor close_success" onClick={close}>X</span></p>}
            </div>
        </div>
    )
}

export default SuccessMessage
