import React from 'react';
import ReactDOM from 'react-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './index.scss';
import App from './App';
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import reportWebVitals from './reportWebVitals';
import { PersistGate } from "redux-persist/integration/react";
import TagManager from 'react-gtm-module'


document.addEventListener('keypress', logKey);

let logger = function() {
    /* +-------------------------------------------------------------------+
     desc: Enable or disable logger and disable warn function.
     +-------------------------------------------------------------------+ */
    let oldConsoleLog = null;
    let pub = {};

    pub.enableLogger = function enableLogger() {
        if (oldConsoleLog == null) return;
        window['console']['log'] = oldConsoleLog;
        console.log('😀 === LOG ENABLED === 😃');
        console.log("😊 Happy debugging don't try to blame Backed person It is possible to have bugs on our side 😉");
      };
      pub.disableLogger = function disableLogger() {
      console.log('🤣 === 😈 LOG DISABLED 😈 === 🤣');
        oldConsoleLog = console.log;
        window['console']['log'] = () => {};
    };
    pub.disableWarn = function disableWarn() {
        window['console']['warn'] = () => {};
    };
    return pub;
}();

function logKey(e) {
    /* +-------------------------------------------------------------------+
     desc: define log enable disable key.
     +-------------------------------------------------------------------+ */
    if (e.ctrlKey && e.shiftKey && e.altKey) {
        switch (e.code) {
            case 'KeyE':
                logger.disableLogger();
                break;
            case 'KeyS':
                logger.enableLogger();
                break;
            default:
                break;
        }
    }
}

if (window.location.hostname !== 'localhost') {
    logger.disableLogger();
    logger.disableWarn();
} else {
    logger.disableWarn();
}

const tagManagerArgs = {
  gtmId: 'GTM-KQ6WMS7',
  auth:'',
  preview:''
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate >
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
